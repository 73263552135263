<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-card
      width="900px"
      min-height="500px"
      :loading="loading"
      class="ma-1"
      @keyup.native.escape="goBack"
    >
      <v-toolbar dense flat color="" class="gunmetal--text">
        <v-toolbar-title>
          <v-skeleton-loader
            v-if="!dataPresent"
            type="heading"
            :width="500"
            class="mt-5"
          />
          <span>{{ domain.description }}</span>
        </v-toolbar-title>

        <v-spacer />

        <bc-pojo-owner
          v-if="!loading && domain.owner && false && 'dzt ownt immer sysAdmin!'"
          :pojo="domain"
          pojotype="domain"
          @evOwner="alert(ownerHasChanged)"
        />

        <v-menu v-if="!loading" id="actions" offset-y left>
          <template v-slot:activator="{ on }">
            <v-btn icon title="weitere Aktionen" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list one-line dense>
            <v-list-item v-if="canEdit" @click="showEditPopup = true">
              <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
              Sphärendaten bearbeiten
            </v-list-item>

            <v-list-item
              disabled
              v-if="canDelete"
              @click="showDeleteDialog = true"
            >
              <v-list-item-icon
                ><v-icon>mdi-delete-outline</v-icon></v-list-item-icon
              >
              Sphäre löschen
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon title="schließen">
          <v-icon @click="goBack"> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>

      <bc-response-handler
        :axios-error="axiosError"
        :axios-success="axiosSuccess"
      />

      <bc-domain-edit
        :domain="domain"
        :show="showEditPopup"
        @close="showEditPopup = false"
        @data="applyData"
      />

      <bc-dialog-delete
        :show-dialog="showDeleteDialog"
        :title="`Sphäre ${domain.description} löschen`"
        :deleting="deleting"
        @evDelete="deleteDomain"
        @evCancel="showDeleteDialog = false"
      >
        Soll diese Sphäre und all die damit verknüpften Daten
        <b>endgültig gelöscht</b> werden? PS: not implemented yet ;-)
      </bc-dialog-delete>

      <v-card-text class="pa-2">
        <v-progress-circular
          v-if="!dataPresent"
          :size="66"
          :width="5"
          color="primary lighten-1"
          class="mt-15"
          style="width: 100%"
          indeterminate
        />
        <v-card v-if="dataPresent" flat>
          <v-card-title class="d-flex justify-space-between align-center pa-2">
            Sphärendaten
          </v-card-title>
          <v-divider />
          <v-card-text class="d-flex">
            <bc-avatar
              :can-edit="canEdit"
              :pojo="domain"
              :api-url="'domains/' + domain.slug"
              class="pb-5"
              :size="190"
              :collapsable="true"
              @stored="loadData"
            />

            <div class="d-flex flex-wrap">
              <bc-text-widget label="Kürzel">
                {{ domain.name }}
              </bc-text-widget>

              <bc-text-widget label="Beschreibung">
                {{ domain.description }}
              </bc-text-widget>

              <bc-text-widget label="Typ">
                <div class="d-flex justify-space-between">
                  {{ $boo.domainTypeData(domain.type).text }}
                  <v-icon :color="$boo.domainTypeData(domain.type).color">
                    {{ $boo.domainTypeData(domain.type).icon }}
                  </v-icon>
                </div>
              </bc-text-widget>

              <bc-text-widget label="Kommentar" flex="1 1 100%">
                {{ domain.notice }}
              </bc-text-widget>
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="dataPresent" flat>
          <v-card-title class="d-flex justify-space-between align-center pa-2">
            Statistik
          </v-card-title>
          <v-divider />
          <v-card-text id="statistik" class="d-flex flex-wrap align-start">
            <bc-text-widget label="User">
              <v-icon>mdi-account-multiple</v-icon> {{ domain.usersCount }}
              <v-list id="userlist" class="mt-3">
                <v-list-item
                  v-for="user in domain.users"
                  :key="user.id"
                  dense
                  style="min-height: 0"
                  class="pl-0"
                >
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>
                      {{ user.username }}
                      <router-link
                        v-if="user.personSlug"
                        :to="{
                          name: 'personView',
                          params: {
                            domain: domain.slug,
                            personSlug: user.personSlug,
                          },
                        }"
                        class="text-decoration-none"
                      >
                        | {{ user.label }}
                      </router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </bc-text-widget>

            <bc-text-widget label="Personen">
              <router-link
                :to="{ name: 'persons', params: { domain: domain.slug } }"
                class="text-decoration-none"
              >
                <v-icon>mdi-account-multiple</v-icon> {{ domain.personsCount }}
              </router-link>
            </bc-text-widget>

            <bc-text-widget label="Projekte">
              <v-icon>mdi-account-multiple</v-icon> {{ domain.projectsCount }}
            </bc-text-widget>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BcDomainEdit from '@/views/domain/DomainEdit';
import BcPojoOwner from '@/components/BcPojoOwner';

export default {
  name: 'DomainShow',

  components: { BcDomainEdit, BcPojoOwner },

  props: {},

  data() {
    return {
      domain: {},

      loading: true,

      axiosSuccess: null,
      axiosError: null,

      showEditPopup: false,

      showDeleteDialog: false,
      deleting: false,
    };
  },

  computed: {
    dataPresent() {
      return !this.$boo.objIsEmpty(this.domain);
    },
    ownsDomain() {
      if (!this.domain.owner) return null;
      return this.$store.state.auth.user.slug === this.domain.owner.slug;
    },
    canEdit() {
      // return (this.ownsDomain && this.$store.getters[this.$storenames.auth.getters.userCan]('domain.update')) ||
      //                this.$store.getters[this.$storenames.auth.getters.userCan]('domain.update.all')
      return this.$store.getters[this.$storenames.auth.getters.userCan](
        'domain.update'
      );
    },
    canDelete() {
      // return (this.ownsDomain && this.$store.getters[this.$storenames.auth.getters.userCan]('domain.delete'))
      //           || this.$store.getters[this.$storenames.auth.getters.userCan]('domain.delete.all' )
      return this.ownsDomain;
    },
    // transfer () {
    //   return this.$store.state.transfer
    // }
  },
  watch: {},

  created() {
    if (this.$route.params.domain) {
      this.domain = this.$route.params.domain;
    }
    this.loadData(); // (nach)laden
  },

  mounted() {
    // bei Änderungen einfach neuladen
    this.$emitter.on('domain', () => this.loadData());
  },

  methods: {
    goBack() {
      this.$router.push({ name: 'domains' });
    },

    loadData() {
      this.loading = true;
      window.axios
        .get(this.$BC.LBO_API_URL + 'domains/' + this.$route.params.domainSlug) // this.domain.slug ist bei reload undefined!
        .then((response) => {
          this.axiosSuccess = response;
          this.domain = response.data.success;
          // auf diese Sphäre umschalten!
          this.$store.commit('app/SETDOMAIN', this.domain);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.replace({ name: 'domains' });
            return;
          }
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    applyData(data) {
      this.domain = data;
      if (this.domain.slug !== this.$route.params.domainSlug) {
        this.$router.replace({ params: { domainSlug: this.domain.slug } });
      }
    },

    deleteDomain() {
      this.deleting = true;
      window.axios
        .delete(this.$BC.LBO_API_URL + 'domains/' + this.domain.slug)
        .then((response) => {
          this.axiosSuccess = response;
          this.$boo.reloadApiData();
          this.$router.replace({ name: 'domains' });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.deleting = false;
          this.showDeleteDialog = false;
        });
    },
    goToDomainRoute: function (domain, routeName) {
      this.$boo.setDomain(domain);
      this.$router.push({ name: routeName, params: { domain: domain.slug } });
    },
  },
};
</script>
──
