var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col'),_c('v-col',{staticClass:"d-flex justify-end"},[(_vm.hasInactivePtms)?_c('v-switch',{attrs:{"dense":"","label":"inaktive anzeigen"},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.filteredMember,"loading":_vm.loading,"hide-default-header":"","hide-default-footer":"","no-data-text":"Es wurden noch keine Projektmitglieder hinzugefügt.","loading-text":"Lade Projektmitglieder...","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',[_c('bc-avatar',{attrs:{"pojo":item,"size":40}})],1),_c('v-list-item-content',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.label)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                      item.project_memberships
                        ? item.project_memberships[0].role.name
                        : '[role.name]'
                    )}})],1),_c('v-col',[(item.project_memberships.length === 1)?_c('span',[(item.project_memberships[0].active)?_c('span',[(
                          item.project_memberships[0].start &&
                          item.project_memberships[0].end
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.project_memberships[0].start))+" - "+_vm._s(_vm.formatDate(item.project_memberships[0].end))+" ")]):_vm._e()]):_c('span',[_vm._v(" Inaktiv ")])]):_vm._e(),(item.project_memberships.length > 1)?_c('span',[_vm._v(" "+_vm._s(item.project_memberships.length)+" "),_c('v-icon',{attrs:{"title":item.project_memberships.length + ' Mitgliedschaften',"small":""}},[_vm._v("mdi-account-multiple")])],1):_vm._e(),(
                      item.project_memberships.filter(
                        (ptm) => ptm.timerecmodel_id
                      ).length
                    )?_c('v-icon',{staticClass:"pl-2",attrs:{"title":"Zeiterfassung","small":""}},[_vm._v("mdi-clock-outline")]):_vm._e(),_c('v-icon',{staticClass:"pl-2",attrs:{"title":item.project_memberships.every((ptm) => ptm.active)
                        ? 'aktiv'
                        : item.project_memberships.every((ptm) => !ptm.active)
                        ? 'inaktiv'
                        : '(in)aktive Mitgliedschaft(en)',"small":"","color":item.project_memberships.every((ptm) => ptm.active)
                        ? 'green'
                        : item.project_memberships.every((ptm) => !ptm.active)
                        ? 'red'
                        : ''}},[_vm._v(_vm._s('mdi-checkbox' + (item.project_memberships.length > 1 ? '-multiple' : '') + (item.project_memberships.every((ptm) => !ptm.active) ? '-blank-outline' : '-marked')))])],1)],1)],1)],1)]},proxy:true}],null,true)},[_c('v-card',{staticClass:"mb-5",attrs:{"outlined":"","flat":"","inset":""}},[_c('v-data-table',{attrs:{"headers":_vm.membershipHeaders,"items":item.project_memberships,"hide-default-footer":"","dense":"","hide-default-header":_vm.isMobile,"mobile-breakpoint":"200","item-class":_vm.itemRowBackground},on:{"click:row":_vm.editMember},scopedSlots:_vm._u([{key:"item.role",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$boo.getValueFromList(_vm.roleOptions, 'id', item.role.id, 'name'))+" ")]}},{key:"item.label",fn:function({ item }){return [_vm._v(" "+_vm._s(item.memberlabel || '-')+" ")]}},{key:"item.start",fn:function({ item }){return [_c('span',{class:`${
                  _vm.$dayjs() < _vm.$dayjs(item.start) && item.active
                    ? 'red--text'
                    : ''
                }`},[_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatTime(item.start))+" ")])]}},{key:"item.end",fn:function({ item }){return [_c('span',{class:`${
                  _vm.$dayjs() > _vm.$dayjs(item.end) && item.active
                    ? 'red--text'
                    : ''
                }`},[_vm._v(" "+_vm._s(_vm.formatDate(item.end))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatTime(item.end))+" ")])]}},{key:"item.timerecmodel",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$boo.getValueFromList( _vm.timerecOptions, 'id', item.timerecmodel_id, 'name' ))+" ")]}},{key:"item.auditor",fn:function({ item }){return [(item.auditor_id)?_c('span',[_vm._v(" "+_vm._s(_vm.$boo.getValueFromList( _vm.project.project_memberships, 'id', item.auditor_id, 'person' ).label || '-')+" ")]):_vm._e()]}},{key:"item.active",fn:function({ item }){return [_c('v-checkbox',{attrs:{"readonly":"","color":item.active ? 'green' : 'red'},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.action",fn:function({ item }){return [(_vm.canEdit && false)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"bearbeiten"},on:{"click":function($event){return _vm.editMember(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","small":"","to":_vm.$boo.domainRouteTo('personView', { personSlug: item.slug })}},[_vm._v(" Zum Kontakt ")]),_c('v-spacer'),(_vm.canEdit)?_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.addMember(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v(" mdi-account-plus ")]),_vm._v(" Mitgliedschaft hinzufügen ")],1):_vm._e()],1)],1)],1)]}}])}),_c('bc-response-handler',{attrs:{"axios-error":_vm.axiosError,"axios-success":_vm.axiosSuccess}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }