import boo from '@/boo';
import { TimeType } from '@/types/types';
import axios from 'axios';

export const bulkChangeTimeStatus = async (
  times: TimeType[],
  status_id: TimeType['status_id']
) => {
  const changePromises = times.map((time) => {
    return axios.patch(`${boo.lboUrl()}times/${time.id}/status`, {
      status_id,
    });
  });

  const results = await Promise.allSettled(changePromises);

  return results;
};

export const getTimesRequest = async (params?: Record<string, any>) => {
  const response = await axios.get(`${boo.lboUrl()}times/list`, { params });
  return response;
};

export const getTimeRequest = async (id: TimeType['id']) => {
  const response = await axios.get(`${boo.lboUrl()}times/${id}`);
  return response;
};
