<template>
  <div>
    <v-tooltip
      left
      open-delay="500"
      :color="chipContent.color"
      :disabled="!chipContent.label"
    >
      <template v-slot:activator="{ on }">
        <v-chip :color="chipContent.color" small outlined v-on="on">
          <v-icon small>
            {{ chipContent.icon }}
          </v-icon>
          <span v-if="!noText">
            {{ chipContent.label }}
          </span>
        </v-chip>
      </template>
      <span>{{ chipContent.label }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import boo from '@/boo';
import { Prop } from 'vue/types/v3-component-props';

export default defineComponent({
  name: 'BcTimeBillingStatusChip',

  props: {
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
    icon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    chipContent(): {
      label: string;
      color: string;
      icon: string;
    } {
      return {
        label: boo.statusInfo('timebilling', this.value)?.status.label || '',
        color: boo.statusInfo('timebilling', this.value)?.status.color || '',
        icon: boo.statusInfo('timebilling', this.value)?.status.icon || '',
      };
    },
  },

  watch: {},

  created() {},

  methods: {},
});
</script>
