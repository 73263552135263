<template>
  <div v-if="count">
    <v-btn text @click="$emit('click', $event)">
      <v-icon left> mdi-message-text-outline </v-icon>
      {{ count }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: null,
    },
    commentable: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
