<template>
  <v-dialog v-model="dialog" max-width="360px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="boo" class="mr-2">{{
        $boo.visibilityInfo(visibility, comment, commentable).icon
      }}</v-icon>
    </template>
    <v-card class="cdIncluded">
      <v-card-title>Sichtbarkeit</v-card-title>
      <v-card-text>Wähle aus, wer dein Kommentar sehen kann.</v-card-text>
      <v-card-text>
        <v-radio-group v-model="visibility" @change="returnValue">
          <v-radio :value="0">
            <template v-slot:label>
              <v-icon left small>{{
                $boo.visibilityInfo(0, comment, commentable).icon
              }}</v-icon
              >{{ $boo.visibilityInfo(0, comment, commentable).label }}
            </template>
          </v-radio>
          <v-radio :value="1">
            <template v-slot:label>
              <v-icon left small>{{
                $boo.visibilityInfo(1, comment, commentable).icon
              }}</v-icon
              >{{ $boo.visibilityInfo(1, comment, commentable).label }}
            </template>
          </v-radio>
          <v-radio :value="2">
            <template v-slot:label>
              <v-icon left small>{{
                $boo.visibilityInfo(2, comment, commentable).icon
              }}</v-icon
              >{{ $boo.visibilityInfo(2, comment, commentable).label }}
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 2,
    },
    comment: { type: Object, default: () => {} },
    commentable: { type: Object, default: () => {} },
  },
  data() {
    return {
      visibility: null,
      dialog: false,
    };
  },
  created() {
    this.visibility = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    returnValue() {
      this.$emit('input', this.visibility);
    },
  },
};
</script>

<style></style>
