var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"outlined":"","hover":"","min-height":"190px","min-width":_vm.isMobile ? '98%' : '313px',"max-width":"313px","data-cy":"personcard"},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-app-bar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),(_vm.person.employee)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase-outline ")])]}}],null,false,4198114494)},[_c('span',[_vm._v(_vm._s(_vm.person.label)+" ist Mitarbeiter*in!")])]):_vm._e(),(_vm.person.customer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-home-account ")])]}}],null,false,1041035966)},[_c('span',[_vm._v(_vm._s(_vm.person.label)+" ist bei uns Kunde!")])]):_vm._e(),_c('router-link',{staticClass:"text-decoration-none",attrs:{"link":"","to":{
        name: 'personView',
        params: { personSlug: _vm.person.slug },
        query: { tab: 'projects' },
      }}},[(_vm.person.projectsCount)?_c('span',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"title":"Projekte"}},[_vm._v("mdi-clipboard-text")]),_vm._v(" "+_vm._s(_vm.person.projectsCount)+" ")],1):_vm._e()]),(
        _vm.person.user &&
        _vm.$store.getters[_vm.$storenames.auth.getters.userCan]('user')
      )?_c('v-chip',{staticClass:"caption mr-1",attrs:{"color":"boo","icon":"","small":"","link":"","to":{
        name: 'personView',
        params: { personSlug: _vm.person.slug },
        query: { tab: 'acl' },
      }}},[_vm._v(" "+_vm._s(_vm.person.user.username)+" ")]):_vm._e()],1),_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"","size":"60"}},[_c('bc-avatar',{attrs:{"pojo":_vm.person,"size":50}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.person.sex)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.person.sex ? _vm.$boo.genderdata(_vm.person.sex).Anrede : ''))]):_vm._e()]),_c('v-list-item-title',{},[_vm._v(" "+_vm._s(_vm.person.label)+" "),(_vm.person.id === _vm.$store.state.auth.user.person.id)?_c('span',[_vm._v("(Du)")]):_vm._e()]),_c('v-list-item-subtitle',[(_vm.person.nickname != _vm.person.label)?_c('span',[_vm._v(_vm._s(_vm.person.nickname))]):_vm._e(),(_vm.person.nickname && _vm.person.birthday)?_c('span',[_vm._v(_vm._s(', '))]):_vm._e(),(_vm.person.birthday)?_c('span',[_vm._v(_vm._s(_vm.$dayjs().diff(_vm.person.birthday, 'years')))]):_vm._e()])],1)],1),_c('v-card-actions',[_c('v-spacer'),_vm._l((_vm.person.tags),function(tag,key){return _c('TagIconChip',{key:key,attrs:{"tag":tag}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }