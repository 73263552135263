<template>
  <bc-widget-frame
    data-cy="bc-dashboard-times-quick"
    v-if="ptmsFiltered.length"
    title="Deine Zeiterfassung"
    subtitle=""
    icon="mdi-play-circle-outline"
  >
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <bc-time-quick
      v-for="(ptm, n) in ptmsFiltered"
      :key="ptm.id"
      :last-item="n === ptmsFiltered.length - 1"
      :as-list-element="ptmsFiltered.length > 1"
      :ptm="ptm"
      :time="myRecordingTimes.find((t) => t.ptm.id === ptm.id)"
      @stored="addOrUpdateTime"
      @deleted="removeTime"
    />
    <v-btn
      class="mt-5"
      v-if="
        isRecording &&
        myQuickPTMs.length > 1 &&
        ptmsRecording.length < myQuickPTMs.length
      "
      block
      x-small
      text
      @click="showAllQuickTimes = !showAllQuickTimes"
    >
      {{
        showAllQuickTimes
          ? 'ruhende Projekte ausblenden'
          : 'alle Projekte anzeigen'
      }}
    </v-btn>
  </bc-widget-frame>
</template>

<script>
import store from '@/store';
import BcTimeQuick from '@/views/time/TimeQuick';
export default {
  name: 'BvDashboardTimesQuick',

  components: {
    BcTimeQuick,
  },

  data() {
    return {
      loading: true,

      axiosSuccess: null,
      axiosError: null,
      showAllQuickTimes: false,
    };
  },

  mounted() {},

  methods: {
    refreshData() {
      // this.$store.dispatch('data/getMyRecordingTimes');
      this.$store.dispatch('data/getMyQuickPtms');
      // this.$store.dispatch('data/getTimes');
    },
    addOrUpdateTime(time) {
      const index = this.myRecordingTimes.findIndex((t) => t.id === time.id);
      if (index === -1) {
        // start (neu) > an den Beginn der Liste (desc ordered)
        this.myRecordingTimes.unshift(time);
      } else {
        // stop > updaten
        // this.$set(this.myRecordingTimes, index, time);
        this.editTimeFirsttime(time);
      }
      this.refreshData(); // 'cause: ptm.recordingTimeentries[]
    },
    removeTime(time) {
      const index = this.myRecordingTimes.findIndex((t) => t.id === time.id);
      // if (index > -1) this.myRecordingTimes.splice(index, 1) // LuJ: das Aktualisieren von store.data wirft hierdurch einen mutation-error (?)
      this.refreshData(); // 'cause: ptm.recordingTimeentries[]
    },
    editTimeFirsttime(time) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          time: time,
          ptms: [time.ptm], // wir haben hier nur 1 quickable
          firsttime: true,
        },
      });
    },
  },
  computed: {
    myQuickPTMs() {
      return this.$store.state.data.myQuickPtms;
    },
    myRecordingTimes() {
      return store.getters.timesStore.recordingTimes;
    },
    isRecording() {
      return (
        this.myQuickPTMs.filter((ptm) => ptm.recordingTimeentries.length !== 0)
          .length > 0
      );
    },
    ptmsFiltered() {
      return !this.showAllQuickTimes && this.isRecording
        ? this.ptmsRecording
        : this.myQuickPTMs;
    },
    ptmsRecording() {
      return this.myQuickPTMs.filter(
        (ptm) => ptm.recordingTimeentries.length !== 0
      );
    },
  },
};
</script>
