<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Tag } from './tagTypes';

export default defineComponent({
  name: 'TagIconChip',
  data() {
    return {};
  },

  props: {
    tag: {
      type: Object as PropType<Tag>,
      default: () => {},
    },
  },
  computed: {
    tagTitle(): string {
      if (this.tag.name.length > 20)
        return this.tag.name.substring(0, 20) + '...';
      else return this.tag.name;
    },
  },
});
</script>

<template>
  <v-chip label class="ma-1"
    ><v-icon left>mdi-label</v-icon>{{ tagTitle }}</v-chip
  >
</template>
