import {
  TimeBillingOptionsType,
  TimeBillingType,
  TimeType,
  TimesOptionsType,
} from '../../types/types';
import { addOrReplaceItemInArray, mergeArray } from '../helpers';
import router from '@/plugins/router';
import { Commit } from 'vuex';
import { getTimeRequest, getTimesRequest } from '@/views/time/timesService';
import me from '@/me';

type TimeStoreStateType = {
  isLoading: boolean;
  times: TimeType[];
  timesOptions: TimesOptionsType;
};

// State object
const initialState = (): TimeStoreStateType => ({
  isLoading: false,
  times: [],
  timesOptions: {},
});

const state: TimeStoreStateType = initialState();

const getters = {
  times: (state: TimeStoreStateType) => state.times,
  recordingTimes: (state: TimeStoreStateType) =>
    state.times.filter((time) => !time.stop),

  myRecordingTimes: (state: TimeStoreStateType) =>
    state.times.filter(
      (time) => !time.stop && me.isMyPerson(time.ptm.person.id)
    ),

  getTimesOfTimebilling:
    (state: TimeStoreStateType) => (id: TimeBillingType['id']) => {
      return state.times.filter((time) => time.timebilling_id === id);
    },

  getTime: (state: TimeStoreStateType) => (id: TimeType['id']) => {
    const time = state.times.find((time) => time.id === id);
    return time;
  },
};

type ActionContext = {
  commit: Commit;
};

const actions = {
  fetchAllTimes: async ({ commit }: ActionContext) => {
    commit('setIsLoading', true);
    try {
      const response = await getTimesRequest();
      commit('setTimes', response.data.success);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsLoading', false);
    }
  },

  fetchSingleTime: async ({ commit }: ActionContext, id: TimeType['id']) => {
    try {
      commit('setIsLoading', true);
      const response = await getTimeRequest(id);
      commit('setTime', response.data.success);
    } catch (e) {
      console.log(`Error fetching time ${id}`, e);
      router.replace({ name: 'alltimes' });
    } finally {
      commit('setIsLoading', false);
    }
  },
};

const mutations = {
  setTimes(state: TimeStoreStateType, times: TimeType[]) {
    state.times = [...mergeArray(state.times, times, (a, b) => a.id === b.id)];
  },

  setTime(state: TimeStoreStateType, time: TimeType) {
    state.times = [...addOrReplaceItemInArray(state.times, time)];
  },

  removeTime(state: TimeStoreStateType, id: TimeType['id']) {
    state.times = [...state.times.filter((time) => time.id !== id)];
  },

  setTimesOptions(state: TimeStoreStateType, timesOptions: TimesOptionsType) {
    state.timesOptions = timesOptions;
  },

  setIsLoading: (state: TimeStoreStateType, status: boolean) => {
    state.isLoading = status;
  },

  RESET(state: any) {
    const newState = initialState();
    // @ts-ignore: next-line
    Object.keys(newState).forEach((key) => (state[key] = newState[key])); // TODO: ist das erlaubt?
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
