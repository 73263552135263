<template>
  <v-card flat color="transparent">
    <v-app-bar elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="
          $store.commit('contentDrawer/LOAD', {
            component: 'bc-notification-center',
            data: { notifications: null },
          })
        "
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>
    <bc-notification-title :data="data.title" />
    <v-component :is="notificationComponent" :notification="data" />
  </v-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    notificationComponent() {
      switch (this.data.type) {
        case 'Welcome':
          return 'bc-welcome-notification';
        case 'SystemUpdate':
          return 'bc-changelog-notification';
        case 'birthday':
          return 'bc-birthday-notification';
        case 'custom':
          return 'bc-custom-notification';
        default:
          return 'bc-default-notification';
      }
    },
  },
  created() {
    window.axios.patch(
      this.$BC.LBO_API_URL + 'notifications/' + this.data.id + '/read'
    ); // mark as read
    this.$store.dispatch('data/refresh', ['getNotifications']);
  },
};
</script>
