import me from '@/me';
import store from '@/store';
import { PersonType } from '@/types/types';

export const canEditOwnPerson = (person: Partial<PersonType>): boolean => {
  if (!store.state.auth.user?.person) return false;

  return (
    store.getters.auth.userCan('person.update') &&
    store.state.auth.user.person.id === person.id
  );
};

export const canEditPerson = (person: Partial<PersonType>): boolean => {
  return (
    (me.owns(person) && store.getters.auth.userCan('person.update')) ||
    store.getters.auth.userCan('person.update.all')
  );
};
