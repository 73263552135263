<template>
  <v-card
    class="d-flex align-center mx-2"
    flat
    @mouseover="hoveringCard = true"
    @mouseout="hoveringCard = false"
  >
    <v-text-field
      v-if="!isNew"
      :label="emailLabel"
      :value="emailText"
      readonly
      :disabled="isDeactivated"
      :hint="emailHint"
      :persistent-hint="true"
    >
      <template v-slot:prepend>
        <v-tooltip open-on-click bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <v-btn icon :href="emailURI" :disabled="isDeactivated" v-on="on">
              <v-icon :color="isUser ? 'boo' : 'undefined'">{{
                isUser
                  ? 'mdi-account-box'
                  : isDeactivated
                  ? 'mdi-minus-circle-outline'
                  : 'mdi-email'
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            isUser ? 'E-Mail-Adresse für Benutzeranmeldung' : 'Nachricht senden'
          }}</span>
        </v-tooltip>
      </template>
    </v-text-field>

    <v-dialog
      v-model="showDialog"
      persistent
      retain-focus
      max-width="500px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <template v-slot:activator="{ on }">
        <v-btn fab right x-small text v-on="on">
          <v-icon
            data-cy="iconNewMail"
            v-show="isNew || hoveringCard || $vuetify.breakpoint.mdAndDown"
          >
            {{ isNew ? 'mdi-email-plus' : 'mdi-dots-horizontal' }}
          </v-icon>
        </v-btn>
      </template>

      <v-card :loading="loading">
        <v-card-title color="boo">
          <v-icon large left class="mr-5">
            {{
              doEdit
                ? !isNew
                  ? 'mdi-email-open'
                  : 'mdi-email-plus'
                : 'mdi-email'
            }}
          </v-icon>
          {{
            isNew
              ? 'Neuer E-Mail-Kontakt'
              : doEdit
              ? deleteMode
                ? 'Mailkontakt löschen'
                : 'Mailkontakt bearbeiten'
              : 'E-Mail-Kontakt'
          }}
          <v-spacer />
          <v-switch
            v-if="canAdvanced"
            v-model="advanced"
            label="erweitert"
            class="right"
            color="teal lighten-3"
            dense
          />
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-3">
          <v-form
            ref="formEmail"
            v-model="valid"
            class="px-3"
            :lazy-validation="true"
            @keyup.native.enter="submit"
            @keyup.native.escape="cancel"
          >
            <v-alert v-if="isUser" dense text type="info"
              >E-Mail-Adresse für Benutzeranmeldung. Ändern/Deaktivieren/Löschen
              nicht möglich.</v-alert
            >

            <v-text-field
              v-model="emailLabel"
              label="Gruppe"
              :rules="TextRules"
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
              autofocus
            >
              <template v-slot:append-outer>
                <v-tooltip
                  :valid="canEdit"
                  open-on-click
                  bottom
                  max-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Bezeichnung der E-Mail-Adresse, nach der auch gruppiert
                    werden kann. Wird oberhalb der E-Mail-Adresse angezeigt. Die
                    E-Mail-Adressen werden nach diesem Wert sortiert.
                    Optional.</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>

            <v-text-field
              v-model="emailText"
              label="E-Mail-Adresse"
              :rules="EmailRules"
              :disabled="deleteMode || isDeactivated"
              :readonly="show || isUser"
              type="email"
            >
              <template v-slot:prepend>
                <v-tooltip open-on-click bottom max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      :href="emailURI"
                      :disabled="isDeactivated || !canSend"
                      v-on="on"
                    >
                      <v-icon>{{
                        isDeactivated ? 'mdi-minus-circle-outline' : 'mdi-email'
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Nachricht senden</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <v-text-field
              v-if="advanced"
              v-model="emailHint"
              label="Hinweis"
              :rules="TextRules"
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
            >
              <template v-slot:append-outer>
                <v-tooltip
                  :valid="canEdit"
                  open-on-click
                  bottom
                  max-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Ein kurzer Hinweis zu dieser E-Mail-Adresse. Wird unterhalb
                    angezeigt. Optional.</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>

            <v-textarea
              v-if="advanced"
              v-model="emailNotice"
              label="Notizen"
              auto-grow
              rows="4"
              outlined
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
            />
            <v-switch
              v-if="advanced"
              v-model="emailDeactivated"
              :label="
                emailDeactivated
                  ? 'E-Mail-Kontakt deaktiviert'
                  : 'E-Mail-Kontakt deaktivieren'
              "
              :value="true"
              dense
              :readonly="show"
              :disabled="deleteMode || isUser"
            >
              <template v-slot:append>
                <v-tooltip :valid="canEdit" open-on-click top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Zum Deaktivierten eines E-Mail-Kontaktes, ohne diesen
                    gänzlich zu löschen.</span
                  >
                </v-tooltip>
              </template>
            </v-switch>

            <v-checkbox
              v-if="!isNew && doEdit"
              :disabled="isUser || !canDelete"
              v-model="deleteMode"
              label="diesen E-Mail-Kontakt löschen"
              color="red darken-3"
              :value="true"
              hide-details
              dense
            >
              <template v-slot:append>
                <v-tooltip :valid="canEdit" open-on-click top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline </v-icon>
                  </template>
                  <span
                    >Zum endgültigen Löschen diese Checkbox anhaken und dann mit
                    LÖSCHEN bestätigen. Diese Aktion kann nicht rückgängig
                    gemacht werden.</span
                  >
                </v-tooltip>
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>

        <bc-response-handler
          :axios-error="axiosError"
          :axios-success="axiosSuccess"
        />

        <bc-btn-cancel-action
          :valid="valid"
          :show="show"
          :can-edit="canEdit"
          :enable-action="inputHasChanged"
          :action-running="storing"
          :loading="loading"
          :delete-btn="deleteMode"
          @evEdit="doEdit = true"
          @evAction="submit"
          @evDelete="submitDelete"
          @evCancel="cancel"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import BcBtnCancelAction from '@/components/global/BcBtnCancelAction.vue';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';

export default {
  name: 'MailContact',
  components: { BcResponseHandler, BcBtnCancelAction },

  props: {
    canEdit: Boolean,
    canAdvanced: Boolean,
    canDelete: Boolean,
    emailId: {
      type: Number,
      default: null,
    },
    emailData: {
      type: Object,
      default: null,
    },
    personId: { type: Number, default: null },
  },
  data() {
    return {
      userCountryCode: 'AT', // @@@ gehört in User-Setting !

      emailDeactivated: false,
      emailLabel: '',
      emailText: '',
      emailHint: '',
      emailNotice: '',
      deleteMode: false,

      emailTextLabel: 'E-Mail-Adresse',
      emailTextHint: '',
      emailTextError: false,

      loading: false,
      valid: true,
      showDialog: false,
      doEdit: false,
      storing: false,
      hoveringCard: false,

      axiosError: null,
      axiosSuccess: null,

      EmailRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => v.length <= 50 || 'Eingabe zu lang',
        (v) => this.$BC.EMAIL_PATTERN.test(v) || 'keine valide E-Mail-Adresse',
      ],
      TextRules: [(v) => v.length <= 30 || 'Eingabe zu lang'],
    };
  },

  computed: {
    isNew: function () {
      return !this.emailId;
    },
    show: function () {
      return !this.doEdit;
    },

    inputHasChanged: function () {
      if (this.isNew) return true;
      if (this.deleteMode) return true;

      if (!this.emailData || this.emailText === undefined) return false;

      return (
        this.emailDeactivated !== (this.emailData.deactivated === 1) ||
        this.emailLabel.trim() !== this.emailData.label ||
        this.emailText.trim() !== this.emailData.text ||
        this.emailHint.trim() !== this.emailData.hint ||
        this.emailNotice.trim() !== this.emailData.notice
      );
    },
    canSend: function () {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.emailText);
    },
    emailURI: function () {
      if (!this.canSend) return '';
      return `mailto: ${this.emailText}`;
    },
    isDeactivated: function () {
      return this.emailDeactivated === true;
    },
    advanced: {
      get: function () {
        return (
          this.$store.state.auth.settings.contactAdvanced === true &&
          this.canAdvanced
        );
      },
      set: function (newValue) {
        this.$store.commit('auth/SETSETTING', {
          item: 'contactAdvanced',
          value: newValue,
        });
        window.axios.post(this.$BC.LBO_URL + '/api/setting', {
          item: 'contactAdvanced',
          value: newValue,
        });
      },
    },
    isUser() {
      return this.emailData?.isUser;
    },
  },

  watch: {
    showDialog: function () {
      this.deleteMode = false;
      this.doEdit = this.isNew;
      if (this.isNew) {
        this.resetFields();
      } else {
        this.load();
      }
    },
  },

  mounted() {
    if (!this.isNew) {
      if (this.emailData) {
        this.emailDeactivated = this.emailData.deactivated === 1;
        this.emailLabel = this.emailData.label || '';
        this.emailText = this.emailData.text || '';
        this.emailHint = this.emailData.hint || '';
        this.emailNotice = this.emailData.notice || '';
      }
    }
  },

  methods: {
    load() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'emailcontacts/' + this.emailId)
        .then((response) => {
          this.axiosSuccess = response;
          this.emailDeactivated = response.data.success.deactivated === 1;
          this.emailLabel = response.data.success.label || '';
          this.emailText = response.data.success.text || '';
          this.emailHint = response.data.success.hint || '';
          this.emailNotice = response.data.success.notice || '';
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.showDialog = false;
      this.$refs.formEmail.resetValidation();
      this.doEdit = false;
      this.axiosError = null;
    },

    submit() {
      if (!this.$refs.formEmail.validate()) return;

      this.storing = true;

      const transfer = {
        id: this.emailId,
        person_id: this.personId,
        deactivated: this.emailDeactivated || false, // shall not be null
        label: this.emailLabel,
        text: this.emailText,
        hint: this.emailHint,
        notice: this.emailNotice,
      };
      // window.axios.post(this.$BC.LBO_URL + '/api/emailcontact', transfer)
      window
        .axios({
          method: this.emailId ? 'patch' : 'post',
          url:
            this.$boo.lboUrl() +
            'emailcontacts' +
            (this.emailId ? '/' + transfer.id : ''),
          data: transfer,
        })
        .then(() => {
          this.$emit('stored');
          this.showDialog = false;
          // this.$refs.formEmail.reset()
          this.$store.commit('app/SETSNACKBAR', {
            text: 'E-Mail-Kontakt gespeichert',
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },

    submitDelete() {
      this.storing = true;

      window.axios
        .delete(this.$boo.lboUrl() + 'emailcontacts/' + this.emailId)
        .then(() => {
          // this.$emit('stored')
          this.$emit('deleted', this.$vnode.key);
          this.showDialog = false;
          // this.$refs.formEmail.reset()
          this.$store.commit('app/SETSNACKBAR', {
            text: 'E-Mail-Kontakt gelöscht',
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },

    resetFields() {
      this.emailLabel = '';
      this.emailText = '';
      this.emailHint = '';
      this.emailNotice = '';
    },
  },
};
</script>
