<template>
  <v-card
    class="pa-2"
    style="flex: 1 1 300px"
    min-width="250px"
    max-width="350px"
    :color="$boo.domainTypeData(domain.type).color"
    :elevation="active ? 0 : 4"
    @click="$emit('click')"
  >
    <v-list-item>
      <v-list-item-avatar color="" size="60">
        <bc-avatar :pojo="domain" :size="50" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="d-flex justify-space-between">
          {{ domain.name }}
          <v-icon :title="$boo.domainTypeData(domain.type).text">
            {{ $boo.domainTypeData(domain.type).icon }}
          </v-icon>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ domain.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      <div v-if="showStats">
        <span v-if="domain.usersCount" class="mr-10">
          <v-icon title="User">mdi-account-key-outline</v-icon
          >{{ domain.usersCount }}
        </span>
        <span v-if="domain.personsCount" class="mr-10">
          <v-icon title="Personen">mdi-account-multiple</v-icon
          >{{ domain.personsCount }}
        </span>
        <span v-if="domain.projectsCount" class="mr-10">
          <v-icon title="Projekte">mdi-clipboard-text</v-icon
          >{{ domain.projectsCount }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DomainCard',
  components: {},

  props: {
    domain: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    showStats: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
──
