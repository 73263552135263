// abgekupfert by LuJ 4/20 //
https://github.com/imRohan/vue-confirmation-button/blob/master/dist/vue-confirmation-button.vue
// Made with love by Rohan Likhite

<template>
  <div>
    <v-btn
      v-if="status === 'BUTTON'"
      :width="width"
      color="alabaster"
      :disabled="disabled"
      @click="toQuery"
    >
      {{ btnText }}
    </v-btn>

    <v-btn
      v-if="status === 'QUERY'"
      :width="width"
      color="warning alabaster--text"
      class="d-flex justify-space-between"
      @click="toDone"
    >
      <v-icon left> mdi-information-outline </v-icon>
      {{ btnTextQueryCalced }}
      <span class="right">{{ countdownValue }} </span>
    </v-btn>

    <v-btn v-if="status === 'DONE'" :width="width" :disabled="true">
      {{ btnTextDoneCalced }}
      <v-icon right> mdi-check-circle-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BcBtnConfirm',

  props: {
    width: { type: String, default: 'auto' },

    btnText: { type: String, default: '' },
    btnTextQuery: { type: String, default: 'Bestätige bitte... ' },
    btnTextDone: { type: String, default: 'done' },

    disabled: { type: Boolean, default: false },

    queryTime: { type: Number, default: 3000 },
    doneTime: { type: Number, default: 5000 },
  },

  data() {
    return {
      status: 'BUTTON', // or "QUERY" or "DONE"

      resetTimer: false,

      countdownTimer: false,

      countdownValue: 100,
    };
  },

  computed: {
    btnTextQueryCalced() {
      return this.btnTextQuery === '' ? this.btnText : this.btnTextQuery;
    },
    btnTextDoneCalced() {
      return this.btnTextDone === '' ? this.btnText : this.btnTextDone;
    },
  },

  methods: {
    toQuery() {
      this.$emit('evQuery');
      this.status = 'QUERY';
      this.resetTimer = setTimeout(this.reset, this.queryTime);
      this.countdownValue = this.queryTime / 1000;
      this.countdownTimer = setInterval(this.countdown, 1000);
    },
    toDone() {
      clearTimeout(this.resetTimer);
      clearInterval(this.countdownTimer);
      this.$emit('evDone');
      this.status = 'DONE';
      setTimeout(this.reset, this.doneTime);
    },
    reset() {
      // console.log('reset')
      clearInterval(this.countdownTimer);
      this.status = 'BUTTON';
    },
    countdown() {
      this.countdownValue--;
      // console.log('countdownValue: ' + this.countdownValue)
      // console.log('step: ' + 100 / (this.queryTime / this.countdownStepMs))
    },
  },
};
</script>
