<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { deleteTagRequest } from './tagService';
import { Tag } from './tagTypes';

export default defineComponent({
  name: 'DeleteTagButton',
  props: {
    tag: { type: Object as PropType<Tag>, required: true },
    onSuccess: { type: Function, default: () => {}, required: true },
    onError: { type: Function, default: () => {}, required: true },
  },

  data() {
    return {
      showDeleteDialog: false,
      deleteDialogTitle: 'Tag löschen',
      deleting: false,
    };
  },

  computed: {
    tagIsUsed(): boolean {
      for (let [key, value] of Object.entries(this.tag.counts)) {
        if (value > 0) return true
      }
      return false
    },
    deleteDialogText(): string {
      return this.tagIsUsed
        ? `Achtung, der Tag <${this.tag.name}> wird aktuell verwendet! Soll er trotzdem jetzt gelöscht werden?`
        : `Soll der Tag <${this.tag.name}> gelöscht werden (er wird aktuell nirgends verwendet)?`
    },
    checkboxLabel(): string {
      return this.tagIsUsed
        ? 'Ja, löschen'
        : ''
    }
  },

  methods: {
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async deleteTag() {
      try {
        this.deleting = true;
        const response = await deleteTagRequest(this.tag.id, this.tagIsUsed);
        this.onSuccess(response);
      } catch (e: any) {
        console.log('Error deleting Tag', e);
        this.onError(e.response);
      } finally {
        this.deleting = false;
        this.closeDeleteDialog();
      }
    },
  },
});
</script>

<template>
  <div>
    <v-btn
      class="mt-5"
      text
      color="error"
      @click="openDeleteDialog"
      :loading="deleting"
      :disabled="deleting"
      >Tag löschen</v-btn
    >

    <bc-dialog-delete
      :show-dialog="showDeleteDialog"
      :title="deleteDialogTitle"
      :deleting="deleting"
      :checkbox-label="checkboxLabel"
      @evDelete="deleteTag"
      @evCancel="closeDeleteDialog"
    >
      {{ deleteDialogText }}
    </bc-dialog-delete>
  </div>
</template>
