<template>
  <div class="">
    <v-card-title color="boo">
      <v-icon large left class="mr-5">
        {{ icon }}
      </v-icon>
      {{ title }}
    </v-card-title>
    <v-divider />
  </div>
</template>
<script>
export default {
  name: 'BcDialogHeader',
  props: {
    icon: {
      type: String,
      default: 'mdi-note',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
