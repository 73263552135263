export enum ExternalTypes {
  bitrix24 = 'bitrix24',
}

export enum ExternalAction {
  create = 'create',
  add = 'add',
}

export const createTexts = (type: ExternalTypes, action: ExternalAction) => {
  const texts = {
    title: 'Titel',
    description: 'Beschreibung',
    urlLabel: 'URL',
  };

  switch (action) {
    case ExternalAction.create:
      switch (type) {
        case ExternalTypes.bitrix24:
          texts.title = 'Von Bitrix24';
          texts.description =
            'Erstelle eine neue Person, indem die Daten aus deinem Bitrix24 Kontakt importiert werden. Kopiere dazu einfach die URL deines Bitrix24 Kontakts aus der Adresszeile und füge sie hier ein.';
          texts.urlLabel = 'Bitrix24 URL';
          break;
        default:
          throw new Error('Unknown external type');
      }
      break;
    case ExternalAction.add:
      switch (type) {
        case ExternalTypes.bitrix24:
          texts.title = 'Bitrix24';
          texts.description =
            'Füge die Daten aus deinem Bitrix24 Kontakt zu einer bestehenden Person hinzu. Kopiere dazu einfach die URL deines Bitrix24 Kontakts aus der Adresszeile und füge sie hier ein.';
          texts.urlLabel = 'Bitrix24 URL';
          break;
        default:
          throw new Error('Unknown external type');
      }
      break;
  }

  return texts;
};
