import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import { createDirectStore } from 'direct-vuex';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

// uses direct-vuex to initialize store, enabling TS support, as described here: https://itnext.io/use-a-vuex-store-with-typing-in-typescript-without-decorators-or-boilerplate-57732d175ff3

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules,
  actions: {
    // resets state of all the modules
    // usage: this.$store.dispatch('reset');
    reset({ commit, dispatch }) {
      Object.keys(modules).forEach((moduleName) => {
        dispatch(`${moduleName}/reset`); // action
        // commit(`${moduleName}/RESET`) // mutation
      });
    },
  },
  strict: debug,
});

export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext };
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
