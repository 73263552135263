<template>
  <bc-widget-frame
    v-if="
      $store.getters[$storenames.auth.getters.userCan]('project.show') &&
      projects &&
      projects.length
    "
    title="Deine Projekte"
    subtitle=""
    icon="mdi-clipboard-text"
  >
    <v-list dense>
      <v-subheader>Deine zuletzt besuchten Projekte</v-subheader>

      <v-list-item-group>
        <v-list-item
          v-for="(project, n) in projects"
          :key="n"
          @click="showProject(project, statusOptions)"
        >
          <v-list-item-icon>
            <bc-avatar :pojo="project" :size="25" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="project.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn text :to="$boo.domainRouteTo('projects')"> Alle Projekte </v-btn>
    </v-card-actions>
  </bc-widget-frame>
</template>

<script>
export default {
  data() {
    return {
      projects: null,
      // loading: false
      axiosSuccess: null,
      axiosError: null,
    };
  },
  created() {
    if (
      this.$store.getters[this.$storenames.auth.getters.userCan]('project.show')
    )
      this.loadData();
  },
  methods: {
    loadData() {
      // this.loading = true
      window.axios
        .get(this.$boo.lboUrl() + 'projects?recent=5')
        .then((response) => {
          this.axiosSuccess = response;
          this.projects = response.data.success;
          this.statusOptions = response.data.options.status;
          // this.$boo.storeApiData(response.data.apiData) // apiData aktualisieren
        })
        .catch((error) => {
          this.axiosError = error;
        });
      // .finally(() => { this.loading = false })
    },
    showProject(project, statusOptions) {
      this.$router.push({
        name: 'projectView',
        params: {
          projectSlug: project.slug,
          project: project,
          statusOptions: statusOptions,
        },
      });
    },
  },
};
</script>
