<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ButtonAction } from './DropDownButton.vue';

export default defineComponent({
  name: 'DropDownButton',

  props: {
    primaryAction: {
      type: Object as PropType<ButtonAction>,
      required: true,
    },
    secondaryActions: {
      type: Array as PropType<ButtonAction[]>,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      fab: false,
      direction: 'top',
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    };
  },
});
</script>

<template>
  <v-btn
    v-if="!secondaryActions.filter((item) => item.showCondition).length"
    fab
    bottom
    right
    fixed
    color="boo"
    @click="primaryAction.action"
  >
    <v-icon>{{ primaryAction.icon }}</v-icon>
  </v-btn>
  <v-speed-dial
    v-else
    v-model="fab"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :direction="direction"
    :open-on-hover="hover"
    :transition="transition"
    fixed
  >
    <template v-slot:activator>
      <v-btn v-model="fab" dark fab class="boo">
        <v-icon v-if="fab"> mdi-close </v-icon>
        <v-icon v-else> {{ primaryAction.icon }} </v-icon>
      </v-btn>
    </template>
    <v-btn
      @click="primaryAction.action"
      dark
      color="boo"
      float-left
      style="transform: translateX(-40%)"
    >
      <v-icon left>{{ primaryAction.icon }}</v-icon>
      {{ primaryAction.label }}
    </v-btn>
    <v-btn
      v-for="(secondaryAction, key) in secondaryActions.filter(
        (item) => item.showCondition
      )"
      @click="secondaryAction.action"
      :key="key"
      dark
      color="boo"
      float-left
      style="transform: translateX(-40%)"
    >
      <v-icon left>{{ secondaryAction.icon }}</v-icon>
      {{ secondaryAction.label }}
    </v-btn>
  </v-speed-dial>
</template>
