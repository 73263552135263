import AppModule from './app.store';
import AuthModule from './auth.store';
import ContentDrawerModule from './contentDrawer.store';
import DataModule from './data.store';
import timeModule from './time.store';
import timebillingModule from './timebilling.store';

type StoreModules = {
  app: typeof AppModule;
  auth: typeof AuthModule;
  contentDrawer: typeof ContentDrawerModule;
  data: typeof DataModule;
  timebillingStore: typeof timebillingModule;
  timesStore: typeof timeModule;
};

const modules: StoreModules = {
  app: AppModule,
  auth: AuthModule,
  contentDrawer: ContentDrawerModule,
  data: DataModule,
  timebillingStore: timebillingModule,
  timesStore: timeModule,
};

export default modules;
