<template>
  <v-card :disabled="loading" flat color="transparent">
    <bc-btn-cancel-action
      v-if="dataDirty"
      :valid="valid"
      :enable-action="dataDirty"
      :action-running="storing"
      :header="true"
      :title="title"
      @evAction="submit"
      @evCancel="cancel"
    />

    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="$store.dispatch(this.$storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>

    <v-card-text>
      <v-form
        ref="formAddMember"
        v-model="valid"
        class="px-3"
        lazy-validation
        @keyup.native.enter="submit"
      >
        <v-list>
          <v-list-item class="px-0" v-if="memberPerson">
            <v-list-item-avatar>
              <bc-avatar :pojo="memberPerson" :size="40" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="memberPerson.label" />
            </v-list-item-content>
            <v-list-item-icon>
              <bc-info v-if="isNew"
                >Dieser Person wird eine zusätzliche Projektmitgliedschaft
                hinzugefügt</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <div v-else>
            <v-list-item class="px-0">
              <bc-autocomplete
                v-model="memberEdit.person_id"
                :items="memberShipsForAutocomplete"
              />
              <v-list-item-icon>
                <bc-info
                  >Person auswählen, welche Mitglied in diesem Projekt sein
                  soll</bc-info
                >
              </v-list-item-icon>
            </v-list-item>
            <v-alert v-if="autoCompleteHint" dense outlined type="info">
              {{ autoCompleteHint }}
            </v-alert>
          </div>
          <v-list-item class="px-0" v-else>
            <bc-autocomplete v-model="memberEdit.person_id" :items="persons" />
            <v-list-item-icon>
              <bc-info
                >Person auswählen, welche Mitglied in diesem Projekt sein
                soll</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-select
              v-model="memberEdit.role_id"
              :items="data.roleOptions"
              item-text="name"
              item-value="id"
              label="Rolle"
              :rules="roleRules"
            />
            <v-list-item-icon>
              <bc-info>Rolle dieser Mitgliedschaft im Projekt</bc-info>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-text-field
              v-model="memberEdit.memberlabel"
              label="Bezeichnung"
              :rules="labelRules"
            />
            <v-list-item-icon>
              <bc-info
                >Optional. Nützlich wenn eine Person mehrfach in einem Projekt
                vorkommt</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-select
              v-model="memberEdit.timerecmodel_id"
              :items="data.timerecOptions"
              item-text="name"
              item-value="id"
              label="Zeiterfassungsmodell"
              clearable
            />
            <v-list-item-icon>
              <bc-info
                >Nur wenn hier ein Modell ausgewählt wird, kann dieses
                Projektmitglied Zeiten erfassen</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="ml-6 px-0">
            <v-select
              v-if="data.project.project_memberships"
              v-model="memberEdit.auditor_id"
              :disabled="!memberEdit.timerecmodel_id"
              :items="
                data.project.project_memberships.filter(
                  (element) => element.person.user !== null
                )
              "
              item-text="role.name"
              item-value="id"
              label="Prüfer"
              clearable
            >
              <template v-slot:selection="{ item }">
                <v-list-item-avatar>
                  <bc-avatar :pojo="item.person" :size="40" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.person.label + ' als ' + item.role.name"
                  />
                </v-list-item-content>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <bc-avatar :pojo="item.person" :size="40" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.person.label + ' als ' + item.role.name"
                  />
                </v-list-item-content>
              </template>
            </v-select>
            <v-list-item-icon>
              <bc-info
                >Optional: Wer soll die Zeiten dieser Projektmitgliedschaft
                prüfen können?</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-list-item-content>
              <bc-datetime-widget
                v-model="memberEdit.start"
                date
                label-date="gilt ab"
                :suggest="{ date: $dayjs().startOf('month'), time: '00:00' }"
                prefix-weekday
                time
                may-be-empty
                show-time-edge-jumper
                :max="memberEdit.end ? $dayjs(memberEdit.end) : null"
              />
            </v-list-item-content>
            <v-list-item-icon>
              <bc-info
                >Ab wann Zeiten erfasst werden können (leer: keine
                Einschränkung).</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-list-item-content>
              <bc-datetime-widget
                v-model="memberEdit.end"
                date
                label-date="gilt bis"
                :suggest="{ date: $dayjs().endOf('month'), time: '23:59' }"
                prefix-weekday
                time
                may-be-empty
                show-time-edge-jumper
                :min="memberEdit.start ? $dayjs(memberEdit.start) : null"
              />
            </v-list-item-content>
            <v-list-item-icon>
              <bc-info
                >Bis wann Zeiten erfasst werden können (leer: keine
                Einschränkung).</bc-info
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item class="px-0">
            <v-list-item-content>
              <v-switch
                v-model="memberEdit.active"
                :label="`Mitgliedschaft ist ${
                  memberEdit.active ? 'aktiv' : 'nicht aktiv'
                }`"
              />
            </v-list-item-content>
            <v-list-item-icon>
              <bc-info
                >Inaktive Mitglieder können z.B. keine Zeiten mehr
                erfassen</bc-info
              >
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-form>

      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
      />
      <v-card-actions>
        <v-btn
          v-if="!isNew && data.member.person_id && memberEdit.id"
          class="mt-10"
          text
          color="error"
          :loading="deleting"
          :disabled="deleting || loading"
          @click="showDeleteDialog = true"
        >
          Mitgliedschaft löschen
        </v-btn>
      </v-card-actions>
      <v-spacer />
      <bc-dialog-delete
        :show-dialog="showDeleteDialog"
        title="Projektmitgliedschaft löschen"
        :deleting="deleting"
        @evCancel="showDeleteDialog = false"
        @evDelete="deleteMembership"
      >
        Soll diese Projektmitgliedschaft
        <strong>endgültig gelöscht</strong> werden?
      </bc-dialog-delete>
    </v-card-text>
    <v-card-text v-if="$boo.envIsDev()" class="blue">
      dataDirty: {{ dataDirty }} <br />

      {{ memberEdit }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectAddMember',

  props: {
    data: {
      type: Object,
      default: null,
      // [member] > bei edit einer bestehenden PTM
      // [person] > bei add-en
      // project, roleOptions, timerecOptions
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      storing: false,
      deleting: false,

      memberEdit: {},
      showDeleteDialog: false,

      axiosSuccess: null,
      axiosError: null,

      persons: [],

      personRules: [
        (v) => typeof v === 'number' || 'bitte eine Person auswählen',
      ],
      roleRules: [(v) => typeof v === 'number' || 'bitte eine Rolle auswählen'],
      labelRules: [
        (v) => !v || (v && v.length >= 3) || 'bitte mindestens 3 Zeichen',
        (v) => !v || (v && v.length <= 100) || 'bitte maximal 100 Zeichen',
      ],
    };
  },

  computed: {
    title() {
      if (this.data.person) return 'zusätzlich';
      if (this.data.member) return 'bearbeiten';
      if (this.isNew) return 'neu';
      return '<dev>';
    },
    isNew() {
      return this.data.member === undefined;
    },
    dataDirty() {
      return (
        JSON.stringify(this.memberEdit) !== JSON.stringify(this.data.member)
      );
    },
    memberPerson() {
      if (this.data.person) return this.data.person;
      if (this.data.member)
        return this.data.project.project_memberships.find(
          (m) => m.id === this.data.member.id
        ).person;
      return null;
    },
    memberShipsForAutocomplete() {
      return this.persons.map((person) => {
        const ptmCount = this.data.project.project_memberships.filter(
          (project) => project.person_id === person.id
        ).length;

        if (ptmCount > 0) {
          person.subtitle = this.createPTMSsubtitle(ptmCount);
        }
        return person;
      });
    },
    autoCompleteHint() {
      const ptmCount = this.data.project.project_memberships.filter(
        (project) => project.person_id === this.memberEdit.person_id
      ).length;

      if (ptmCount > 0) {
        return this.createPTMSsubtitle(ptmCount);
      }
    },
  },

  watch: {
    // egal - ist immer im edit-mode (gibt nur diesen)
    // dataDirty: function () {
    //   this.$store.commit('contentDrawer/LOCK', this.dataDirty)
    // }
  },

  created() {
    this.$store.commit('contentDrawer/LOCK', this.dataDirty);
    this.loadPersons();
    if (!this.isNew) {
      this.memberEdit = { ...this.data.member };
    }
    this.memberEdit.project_id = this.data.project.id;
    if (this.data.person) {
      this.memberEdit.person_id = this.data.person.id;
    }
  },

  methods: {
    cancel() {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
    submit() {
      if (!this.$refs.formAddMember.validate()) return;
      this.storing = true;
      window
        .axios({
          method: this.memberEdit.id ? 'put' : 'post',
          url:
            this.$boo.lboUrl() +
            'projectmemberships' +
            (this.memberEdit.id ? '/' + this.memberEdit.id : ''),
          data: this.memberEdit,
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.$emitter.emit('project');
          this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    selectMember(member) {
      this.memberEdit.person_id = member.id;
      this.memberEdit.label = member.label;
    },
    deleteMembership() {
      this.deleting = true;
      window.axios
        .delete(
          this.$boo.lboUrl() + 'projectmemberships/' + this.data.member.id
        )
        .then((response) => {
          this.axiosSuccess = response;
          this.$emitter.emit('project');
          this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.deleting = false;
          this.showDeleteDialog = false;
        });
    },
    loadPersons() {
      window.axios
        .get(`${this.$boo.lboUrl()}persons`)
        .then((response) => {
          this.persons = response.data.success;
        })
        .catch((error) => {
          this.$emit('error-received', error);
        })
        .finally(() => {});
    },
    createPTMSsubtitle(count) {
      if (count === 0) return;
      if (count === 1) return 'Hat bereits eine Mitgliedschaft im Projekt';
      return `Hat bereits ${count} Mitgliedschaften im Projekt`;
    },
  },
};
</script>
