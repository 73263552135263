<template>
  <div class="px-5">
    <v-btn class="boo" dark block :to="data.link">
      {{ data.text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>
