<template>
  <apexchart type="bar" :options="chartOptions" :series="yaxis" />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Bar Chart',
    },
    subtitle: {
      type: String,
      default: null,
    },
    xaxis: {
      type: Array,
      default: function () {
        return [];
      },
    },
    yaxis: {
      type: Array,
      default: function () {
        return [];
      },
    },
    toolbaroptions: {
      type: Object,
      default: function () {
        return { show: false };
      },
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      chartOptions: {
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          showForSingleSeries: true,
          show: this.showLegend,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
        chart: {
          toolbar: this.toolbaroptions,
          id: this.title,
          stacked: this.stacked,
          height: 400,
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#FF5E5B',
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
        tooltip: {
          theme: this.$vuetify.theme.isDark ? 'dark' : 'light',
        },
        xaxis: {
          categories: this.xaxis,
        },
      },
      series: this.yaxis,
    };
  },
};
</script>
