<template>
  <v-container fluid class="pa-0">
    <v-toolbar
      xv-if="isMobile"
      flat
      color="alabaster"
      class="gunmetal--text"
      width="100%"
      dense
    >
      <v-btn text class="pl-0" exact :to="$boo.domainRouteTo('projects')">
        <v-icon>mdi-chevron-left</v-icon>
        Projekte
      </v-btn>
      <v-spacer />
      <v-menu v-if="!loading" id="actions" offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn icon title="weitere Aktionen" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list one-line dense>
          <v-list-item
            v-if="canEdit"
            @click="
              $store.commit('contentDrawer/LOAD', {
                component: 'bc-project-edit',
                data: { project, statusOptions },
              })
            "
          >
            <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
            Projektdaten bearbeiten
          </v-list-item>
          <v-list-item v-if="canDelete" @click="showDialogProjectDelete = true">
            <v-list-item-icon
              ><v-icon>mdi-delete-outline</v-icon></v-list-item-icon
            >
            Projekt löschen
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card
      class="d-flex flex-wrap align-stretch"
      xcolor="grey lighten-2"
      flat
      min-height="90vh"
    >
      <v-card
        class="pa-2 flex"
        max-width="350px"
        :max-height="isMobile ? '240px' : '240px'"
        :min-height="isMobile ? '240px' : ''"
        :min-width="isMobile ? '100%' : ''"
        flat
        style="flex: 1"
      >
        <div align="center" class="xl-pb-5 py-5">
          <div>
            <bc-avatar
              :can-edit="canEdit"
              :pojo="project"
              :api-url="avatarAPIUrl"
              class=""
              :size="isMobile ? 100 : 150"
              :collapsable="true"
              @stored="reload"
            />
          </div>
          <div class="my-3">
            {{ project.name }}
          </div>
          <div width="100%">
            <Bc-Project-Status-Chip
              v-if="status"
              v-model="project.status_id"
              :items="statusOptions"
              :project="project"
              :readonly="!canEdit"
            />
          </div>
        </div>

        <v-card v-if="!isMobile" flat>
          <v-divider class="mt-5" />
          <bc-project-data
            :project="project"
            :loading="loading"
            @evUpdated="applyNewData"
          />
        </v-card>
      </v-card>
      <v-divider vertical />
      <v-card style="flex: 4" flat min-height="70vh">
        <v-tabs
          v-model="tab"
          flat
          show-arrows
          class="ma-md-5"
          :background-color="isMobile ? 'alabaster' : ''"
        >
          <v-tab v-if="isMobile" href="#data"> Über </v-tab>

          <v-tab href="#members"> Mitglieder </v-tab>

          <v-tab href="#billings"> Zeitabrechnungen </v-tab>

          <!-- <v-tab href="#comments"> Kommentare </v-tab> -->

          <!--
          <v-tab
            v-if="false"
            href="#activities"
          >
            Aktivitäten
          </v-tab>
           -->
        </v-tabs>

        <v-tabs-items v-model="tab" class="">
          <v-tab-item v-if="isMobile" value="data">
            <bc-project-data
              :project="project"
              :loading="loading"
              @evUpdated="applyNewData"
            />
          </v-tab-item>

          <v-tab-item value="members">
            <v-card flat>
              <v-card-title>
                Projektmitglieder <v-spacer />
                <v-btn
                  v-if="canEdit"
                  outlined
                  class="boo"
                  dark
                  @click="
                    $store.commit('contentDrawer/LOAD', {
                      component: 'bc-project-add-member',
                      data: { project, roleOptions, timerecOptions },
                    })
                  "
                >
                  <v-icon color="white" small> mdi-plus </v-icon>
                  Projektmitglied
                </v-btn>
              </v-card-title>
              <v-card-text>
                <bv-project-member-table
                  :member="persons"
                  :loading="loadingPersons"
                  :can-edit="canEdit"
                  :project="project"
                  :role-options="roleOptions"
                  :timerec-options="timerecOptions"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="billings">
            <v-card flat>
              <v-card-title
                >Zeitabrechnungen
                <v-spacer />
                <v-btn
                  v-if="canEdit"
                  class="boo"
                  @click="newTime"
                  dark
                  :disabled="
                    !project.project_memberships ||
                    project.project_memberships.length === 0 ||
                    activeTimeablePTMs.length === 0
                  "
                >
                  <v-icon color="white" small> mdi-plus </v-icon>
                  Zeiteintrag
                </v-btn>
              </v-card-title>
              <v-card-subtitle v-if="billingData.truncated" class="ml-5">
                &nbsp; (nur letzte 3 Monate)
              </v-card-subtitle>
              <v-card-text class="pa-0 pa-sm-5">
                <v-data-table
                  v-model="selectedTimeBillings"
                  :items="billingData.filtered"
                  :headers="billingHeaders"
                  Xdisable-sort
                  :show-select="false"
                  hide-default-footer
                  :items-per-page="timebillingsPerPage"
                  class="elevation-0 transparent"
                  :loading="loading"
                  mobile-breakpoint="200"
                  @click:row="openBilling"
                  :page.sync="billingPage"
                >
                  <template v-slot:top>
                    <div class="text-right">
                      <Bc-filter
                        v-model="billingData"
                        filterByPersons
                        filterByProjects
                        filterByDateRange
                        monthOnly
                        filterByStatusLevels
                      />
                    </div>
                  </template>

                  <template v-slot:item.month="{ item }">
                    {{ $dayjs(item.startdate).format('MMMM YYYY') }}
                  </template>

                  <template v-slot:item.ptm.person="{ item }">
                    <bc-ptm-item
                      :ptm="item.ptm"
                      :size="30"
                      viewpoint="project"
                      Xhide-role
                      link
                    />
                  </template>

                  <template v-slot:item.ptm.project="{ item }">
                    <bc-ptm-item
                      :ptm="item.ptm"
                      :size="30"
                      viewpoint="person"
                      link
                    />
                  </template>
                  <template v-slot:item.hours="{ item }">
                    {{ item.hours.toFixed(2) }}
                  </template>

                  <template v-slot:item.status_id="{ item }">
                    <bc-time-billing-status-chip :value="item.status_id" />
                  </template>

                  <template v-slot:no-data>
                    Keine Abrechnungen vorhanden.
                  </template>
                  <template v-slot:loading> Lade Abrechnungen... </template>

                  <template v-slot:footer>
                    <v-pagination
                      v-if="billingData.filtered.length > timebillingsPerPage"
                      v-model="billingPage"
                      :length="billingPageCount"
                      class="my-5"
                      total-visible="5"
                    ></v-pagination>
                  </template>
                </v-data-table>
                <v-row v-if="billingData.truncated" class="py-2 justify-center">
                  <div class="self-center">
                    <v-btn text @click="loadBillings(false)" small>
                      <v-icon left>mdi-reload-alert</v-icon>
                      Ältere Abrechnungen laden
                    </v-btn>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="false" value="activities">
            <v-card>
              <v-card-title> Aktivitäten </v-card-title>
              <v-card-text
                >Hier wirst du in Zukunft die Aktivitäten innerhalb des
                Projektes {{ project.label }} sehen.</v-card-text
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
        show-in-dialog
      />

      <bc-dialog-delete
        :show-dialog="showDialogProjectDelete"
        :title="`Projektdaten von ${project.name} löschen`"
        :deleting="projectDeleting"
        @evDelete="deleteProject"
        @evCancel="showDialogProjectDelete = false"
      >
        Soll dieses Projekt und all die damit verknüpften Daten
        <b>endgültig gelöscht</b> werden?
      </bc-dialog-delete>
    </v-card>
  </v-container>
</template>

<script>
import BvProjectMemberTable from '@/views/project/ProjectMemberTable';
import BcProjectData from '@/views/project/ProjectData';
import BcTimeBillingStatusChip from '@/views/timebilling/TimeBillingStatusChip';
import { canEditProject } from './helpers';

export default {
  name: 'BvProjectShow',

  components: {
    BvProjectMemberTable,
    BcProjectData,
    BcTimeBillingStatusChip,
  },

  data() {
    return {
      project: {},
      persons: [],
      billingData: {
        truncated: false,
        original: [],
        filtered: [],
        filter: {
          // dateRange: this.$dayjs().format('YYYY-MM'), // this month
          dateRange: '', // no default
          persons: [],
          projects: [],
          statusLevels: [],
        },
      },
      selectedTimeBillings: [],
      timebillingsPerPage: 10,
      billingPage: 1,

      loading: false,
      loadingPersons: false,
      loadingBillings: false,

      // name_changed: false,
      //
      axiosError: null,
      axiosSuccess: null,

      editable: false,
      projectUrl: this.$boo.lboUrl() + 'projects/',

      showDialogProjectDelete: false,
      projectDeleting: false,

      statusOptions: [],
      roleOptions: [],
      timerecOptions: [],
      // showProjectEditPopUp: false,
      // showProjectAddMemberPopUp: false,
      windowTop: 0,
    };
  },

  computed: {
    billingPageCount() {
      return Math.ceil(
        this.billingData.filtered.length / this.timebillingsPerPage
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    created_at_human() {
      return this.$dayjs(this.project.created_at).fromNow();
    },
    canEdit() {
      return canEditProject(this.project);
    },
    canDelete() {
      return (
        (this.$me.owns(this.project) &&
          this.$store.getters[this.$storenames.auth.getters.userCan](
            'project.delete'
          )) ||
        this.$store.getters[this.$storenames.auth.getters.userCan](
          'project.delete.all'
        ) ||
        this.project.isProjectLead
      );
    },
    projectSlug() {
      return this.$route.params.projectSlug;
    },
    avatarAPIUrl() {
      return 'projects/' + this.project.slug;
    },
    status() {
      if (!this.statusOptions) return false;
      // return this.statusOptions[this.project.status_id]?.name // Optional Chaining requires #391
      return this.statusOptions[this.project.status_id]
        ? this.statusOptions[this.project.status_id].name
        : null;
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    billingHeaders() {
      return this.billingHeadersAll.filter(
        (h) =>
          !h.hidden ||
          (h.hidden !== true &&
            !h.hidden.includes(this.$vuetify.breakpoint.name))
      );
    },
    billingHeadersAll() {
      return [
        // { text: 'Test', value: 'test', hidden: ['xs', 'sm', 'md', 'lg'] },

        {
          text: 'Monat',
          value: 'month',
          sortable: false,
          hidden: this.billingData.filter.dateRange.length > 0,
        },

        // hidden wenn nur 1 person
        { text: 'Person', value: 'ptm.person', sortable: false },

        {
          text: 'Einträge',
          align: 'center',
          value: 'entries',
          sortable: true,
          hidden: ['xs'],
        },
        { text: 'Stunden', align: 'center', value: 'hours', sortable: true },

        { text: '', value: 'status_id' },
      ];
    },
    activeTimeablePTMs() {
      return this.project.project_memberships.filter(
        (ptm) => ptm.timerecmodel && ptm.active
      );
    },
  },
  watch: {},

  created() {
    if (this.$route.params.project) {
      this.project = this.$route.params.project;
      this.statusOptions = this.$route.params.statusOptions;
    }
    this.reload();
  },

  mounted() {
    // bei Änderungen einfach neuladen
    this.$emitter.on('project', () => this.reload());
    this.$emitter.on('avatar.changed', () => this.reload());

    // Scroll to Top if changed to this route
    window.scrollTo(0, 0);
    var that = this;
    window.addEventListener('scroll', function () {
      that.windowTop = window.scrollY;
    });
  },

  methods: {
    goBack() {
      this.$router.push({ name: 'projects' });
    },
    reload() {
      this.loadProject();
      this.loadPersons();
      this.loadBillings();
    },
    loadProject() {
      this.loading = true;
      // this.error = null
      // this.valid = false
      window.axios
        .get(this.$boo.lboUrl() + 'projects/' + this.$route.params.projectSlug)

        .then((response) => {
          this.project = response.data.success;
          this.statusOptions = response.data.options.status;
          this.roleOptions = response.data.options.roles;
          this.timerecOptions = response.data.options.timerecmodels;
          // this.userslug = data.success.userslug || null
          this.valid = true;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // notFound soll ohne Fehlermeldung redirecten
            this.$router.replace({ name: 'projects' });
            return;
          }
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
          // this.name_changed = false
        });
    },
    loadPersons() {
      this.loadingPersons = true;
      window.axios
        .get(
          this.$boo.lboUrl() +
            'projects/' +
            this.$route.params.projectSlug +
            '/persons'
        )
        .then((response) => {
          this.persons = response.data.success;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loadingPersons = false;
        });
    },
    loadBillings(truncated = this.billingData.truncated) {
      this.loadingBillings = true;
      window.axios
        .get(this.$boo.lboUrl() + 'timebillings/list', {
          params: {
            projectSlug: this.$route.params.projectSlug,
            truncated,
          },
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.billingData.original = response.data.success;
          this.billingData.truncated = response.data.options.truncated;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loadingBillings = false;
        });
    },
    applyNewData(project) {
      if (this.project.slug !== project.slug) {
        this.$router.push(project.slug);
      }
      this.project = project;
      // this.showProjectEditPopUp = false
    },
    deleteProject() {
      this.projectDeleting = true;
      window.axios
        .delete(this.projectUrl + this.project.slug)
        .then((response) => {
          this.axiosSuccess = response.data.success;
          this.$router.replace({ name: 'projects' });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.showDialogProjectDelete = false;
          this.projectDeleting = false;
        });
    },
    openBilling(item) {
      if (this.selectedTimeBillings.length === 0) {
        this.$router.push({
          name: 'timebillingdetail',
          params: {
            billingID: item.id,
          },
        });
      } else {
        if (!this.selectedTimeBillings.includes(item)) {
          this.selectedTimeBillings.push(item);
        } else {
          this.selectedTimeBillings.splice(
            this.selectedTimeBillings.indexOf(item),
            1
          );
        }
      }
    },
    newTime() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          new: true,
          ptms: this.activeTimeablePTMs,
        },
      });
    },
  },
};
</script>
