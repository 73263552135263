<template>
  <v-card flat color="transparent" :disabled="loading">
    <bc-btn-cancel-action
      v-if="dataDirty"
      :valid="valid"
      :enable-action="isNew || dataDirty"
      action-text="speichern"
      :action-running="storing"
      header
      :title="title"
      @evAction="submit"
      @evCancel="$store.dispatch(this.$storenames.contentDrawer.actions.close)"
    />
    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn text @click="close">
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>

    <v-card-text class="pa-0" :loading="loading">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BcCdEdit',

  props: {
    loading: { type: Boolean, default: false },
    dataDirty: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
    storing: { type: Boolean, default: false },
    title: { type: String, default: '' },
    submit: { type: Function, default: {} },
  },

  data() {
    return {};
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  methods: {
    close: function () {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
  },
};
</script>
