<template>
  <bc-content-drawer-frame>
    <template v-slot:headerActionLeft>
      <v-btn @click="close" text color="boo">{{
        $vuetify.breakpoint.xs ? 'Zurück' : 'Schließen'
      }}</v-btn>
    </template>

    <template v-slot:headerExtension>
      <v-tabs v-model="tab" align-with-title>
        <!--
        <v-tab>Pojo-Details</v-tab>
 -->
        <!--
        <v-tab> Erweitert</v-tab>
 -->
        <v-tab @change="loadData()">
          <v-badge
            :value="unreadCommentCount"
            :content="commentCount"
            color="grey"
          >
            Kommentare
            <span v-if="commentCount">({{ commentCount }})</span>
          </v-badge>
        </v-tab>
      </v-tabs>
    </template>
    <!--
    <template v-slot:headerTitle>
      Titel
    </template>
 -->
    <v-tabs-items v-model="tab" class="transparent pt-1">
      <!--
      <v-tab-item
         :key="1" // braucht's das?
        >
        <v-card-text>
        </v-card-text>
      </v-tab-item>
 -->
      <!--
      <v-tab-item >
        <v-card-text>
        </v-card-text>
      </v-tab-item>
 -->

      <v-tab-item class="ma-0 pa-0">
        <Bc-comment-view @refresh="loadComments()" :data="data" />
      </v-tab-item>
    </v-tabs-items>
  </bc-content-drawer-frame>
</template>

<script>
export default {
  name: 'BcCdComment',

  props: {
    data: {
      type: Object,
      // nur zwecks Doku - defaults werden im created() gesetzt (weil überschrieben)
      default: function () {
        return {
          commentable: {}, // das kommentierte Pojo
          gotoComment: true, // zeigt sofort Kommentare an
        };
      },
    },
  },

  data() {
    return {
      tab: null,
    };
  },

  computed: {
    commentCount() {
      if (!this.data.commentable) return null;
      return this.data.commentable.comment_count;
    },
    unreadCommentCount() {
      // @@@ ToDo
      return 0;
    },
  },

  created() {
    // console.log('created')

    // activat comments tab
    if (this.data.gotoComment) this.tab = 2;
  },

  methods: {
    loadComments() {
      console.log('BcCdComment > loadComments(): could update data(?)');
      // @@@ ToDo
      // sollte (via vuex) die aktualisierten Kommentare nachladen. Dann stimmt auch commenCount() nach Veränderung
    },
    close: function () {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
  },
};
</script>
