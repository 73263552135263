<template>
  <div>
    <bc-notification-text :data="welcomeText" />
    <bc-notification-text data="Viel Erfolg wünscht dein boo-Team!" />
    <bc-user-feedback-form-dialog class="mx-5 pt-5" />
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      welcomeText:
        'Es freut uns sehr, dass du da bist! boo ist dein neuer Begleiter, um dir deinen Arbeitsalltag einfacher zu gestalten. Wenn du Fragen oder Feedback für uns hast, zögere nicht uns zu kontaktieren! Unsere App wird laufend weiterentwickelt und soll sich an deine Anforderungen anpassen.',
    };
  },
};
</script>
