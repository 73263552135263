<script lang="ts">
import { TimeBillingType } from '@/types/types';
import { findTimebillingsRequest } from '@/views/timebilling/timebillingService';
import { defineComponent } from 'vue';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import BcTimeBillingStatusChip from '@/views/timebilling/TimeBillingStatusChip.vue';

export default defineComponent({
  name: 'TimebillingTab',

  components: {
    BcResponseHandler,
    BcTimeBillingStatusChip,
  },

  // props: {
  //   value: {
  //     type: String,
  //     required: true,
  //   },
  // },

  data() {
    return {
      selectedTimeBillings: [] as TimeBillingType[],
      timebillingsPerPage: 10,
      billingPage: 1,
      loading: false,
      loadingBillings: false,
      axiosError: null,

      billingData: {
        truncated: false,
        original: [],
        filtered: [],
        filter: {
          // dateRange: this.$dayjs().format('YYYY-MM'), // aktuelles Monat
          dateRange: '', // no default
          persons: [],
          projects: [],
          statusLevels: [],
        },
      },
    };
  },

  computed: {
    billingPageCount(): number {
      return Math.ceil(
        this.billingData.filtered.length / this.timebillingsPerPage
      );
    },
    billingHeaders(): any {
      return this.billingHeadersAll.filter(
        (h: any) =>
          !h.hidden ||
          (h.hidden !== true &&
            !h.hidden.includes(this.$vuetify.breakpoint.name))
      );
    },
    billingHeadersAll(): any {
      return [
        {
          text: 'Monat',
          value: 'month',
          sortable: false,
          hidden: this.billingData.filter.dateRange.length > 0,
        },

        // hidden wenn nur 1 person
        { text: 'Projekt', value: 'ptm.project', sortable: false },

        {
          text: 'Einträge',
          align: 'center',
          value: 'entries',
          sortable: true,
          hidden: ['xs'],
        },
        { text: 'Stunden', align: 'center', value: 'hours', sortable: true },

        { text: '', value: 'status_id' },
      ];
    },
  },
  methods: {
    openBilling(item: TimeBillingType) {
      if (this.selectedTimeBillings.length === 0 && item.id) {
        this.$router.push({
          name: 'timebillingdetail',
          params: {
            billingID: item.id?.toString(),
          },
        });
      } else {
        if (!this.selectedTimeBillings.includes(item)) {
          this.selectedTimeBillings.push(item);
        } else {
          this.selectedTimeBillings.splice(
            this.selectedTimeBillings.indexOf(item),
            1
          );
        }
      }
    },
    async loadBillings(truncated?: boolean) {
      this.loadingBillings = true;

      const searchParams = {
        personSlug: this.$route.params.personSlug,
        truncated: truncated || this.billingData.truncated,
      };

      try {
        const response = await findTimebillingsRequest(searchParams);
        this.billingData.original = response.data.success;
        this.billingData.truncated = response.data.options.truncated;
      } catch (error: any) {
        this.axiosError = error;
      } finally {
        this.loadingBillings = false;
      }
    },
  },
  created() {
    this.loadBillings();
  },
});
</script>

<template>
  <v-card>
    <v-card-title>Zeitabrechnungen</v-card-title>
    <bc-response-handler :axios-error="axiosError" />

    <v-card-subtitle v-if="billingData.truncated" class="ml-5">
      &nbsp; (nur letzte 3 Monate)
    </v-card-subtitle>
    <v-card-text class="pa-0 pa-sm-5">
      <v-data-table
        v-model="selectedTimeBillings"
        :items="billingData.filtered"
        :headers="billingHeaders"
        Xdisable-sort
        :show-select="false"
        hide-default-footer
        :items-per-page="timebillingsPerPage"
        class="elevation-0 transparent"
        :loading="loading"
        mobile-breakpoint="200"
        @click:row="openBilling"
        :page.sync="billingPage"
      >
        <template v-slot:top>
          <div class="text-right">
            <Bc-filter
              v-model="billingData"
              filterByPersons
              filterByProjects
              filterByDateRange
              monthOnly
              filterByStatusLevels
            />
          </div>
        </template>

        <template v-slot:item.month="{ item }">
          {{ $dayjs(item.startdate).format('MMMM YYYY') }}
        </template>

        <template v-slot:item.ptm.person="{ item }">
          <bc-ptm-item
            :ptm="item.ptm"
            :size="30"
            viewpoint="project"
            hide-role
            link
          />
        </template>

        <template v-slot:item.ptm.project="{ item }">
          <bc-ptm-item :ptm="item.ptm" :size="30" viewpoint="person" link />
        </template>
        <template v-slot:item.hours="{ item }">
          {{ item.hours.toFixed(2) }}
        </template>

        <template v-slot:item.status_id="{ item }">
          <bc-time-billing-status-chip :value="item.status_id" />
        </template>

        <template v-slot:no-data> Keine Abrechnungen vorhanden. </template>
        <template v-slot:loading> Lade Abrechnungen... </template>

        <template v-slot:footer>
          <v-pagination
            v-if="billingData.filtered.length > timebillingsPerPage"
            v-model="billingPage"
            :length="billingPageCount"
            class="my-5"
            total-visible="5"
          ></v-pagination>
        </template>
      </v-data-table>
      <v-row v-if="billingData.truncated" class="py-2 justify-center">
        <div class="self-center">
          <!-- <v-btn text @click="loadBillings(false)" small>
              <v-icon left>mdi-reload-alert</v-icon>
              Ältere Abrechnungen laden
            </v-btn> -->
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>
