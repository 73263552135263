<script lang="ts">
import { DataTableHeaderType, PersonType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import { VDataTable } from 'vuetify/lib';
import BcAvatar from '@/components/global/BcAvatar.vue';
import boo from '@/boo';
import PersonCard from './PersonCard.vue';

export default defineComponent({
  name: 'PersonsDataTable',

  components: {
    VDataTable,
    BcAvatar,
    PersonCard,
  },

  props: {
    persons: {
      type: Array as PropType<PersonType[]>,
      required: true,
    },
    headers: {
      type: Array as PropType<DataTableHeaderType[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      view: 0,
    };
  },

  watch: {},

  computed: {
    headersFiltered(): DataTableHeaderType[] {
      return this.headers.filter?.((header) => header.visible);
    },
  },

  methods: {
    showPerson(person: any) {
      this.$router.push({
        name: 'personView',
        params: {
          personSlug: person.slug,
          person,
        },
      });
    },
    customFilter(_value: string, search: string, item: PersonType) {
      const haystack = item.label
        .concat(' ', item.tags.map((tag) => tag.name).join(' '))
        .concat(' ', item.employee ? 'Mitarbeiter*in' : '')
        .concat(' ', item.customer ? 'Kunde' : '')
        .concat(' ', item.user?.username ? item.user?.username : '')
        .concat(' ', item.initial)
        .toLowerCase();
      return haystack.includes(search.toLowerCase());
    },
    genderData: boo.genderdata,
    openSingleResult() {
      const filteredPersons = this.persons.filter((person) =>
        this.customFilter('', this.search, person)
      );
      if (filteredPersons.length === 1) {
        this.showPerson(filteredPersons[0]);
      }
    },
  },
});
</script>

<template>
  <div>
    <!-- We could change this to use a full text search like this: https://www.npmjs.com/package/minisearch -->
    <v-text-field
      dense
      outlined
      rounded
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      label="Suche nach Name, Tags, ..."
      single-line
      hide-details
      clearable
      autofocus
      @keydown.native.enter="openSingleResult"
    ></v-text-field>

    <v-card-actions>
      <v-spacer />
      <v-btn-toggle class="ml-2" mandatory v-model="view" size="small">
        <v-btn>
          <v-icon>mdi-format-list-text</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>

    <v-data-table
      ref="personDataTable"
      class="transparent"
      :items="persons"
      item-key="id"
      :search="search"
      :headers="headersFiltered"
      :sort-by="['latest_access']"
      :sort-desc="[true]"
      @click:row="showPerson"
      :custom-filter="customFilter"
      :loading="loading"
      mobile-breakpoint="200"
      :items-per-page="view === 1 ? 9 : 10"
      :hide-default-header="view === 1"
    >
      <template v-slot:item="{ item }" v-if="view === 1">
        <person-card
          class="float-left"
          :person="item"
          edit
          clickable
          @click="showPerson(item)"
        />
      </template>

      <template v-slot:item.label="{ item }">
        <div class="d-flex align-center">
          <bc-avatar :pojo="item" :size="30" class="mr-5" />
          <div class="d-flex flex-column">
            <span v-if="item.sex" style="font-size: 10px">
              {{ genderData(item.sex).Anrede }}
            </span>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </template>

      <template v-slot:item.tags="{ item }">
        <v-chip
          v-for="tag in item.tags"
          :key="tag.name"
          class="my-2 mr-1"
          small
          outlined
        >
          {{ tag.name }}
        </v-chip>
      </template>

      <template v-slot:item.attributes="{ item }">
        <v-tooltip v-if="item.employee" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-1" v-bind="attrs" v-on="on">
              mdi-briefcase-outline
            </v-icon>
          </template>
          <span>{{ item.label }} ist Mitarbeiter*in!</span>
        </v-tooltip>

        <v-tooltip v-if="item.customer" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-1" v-bind="attrs" v-on="on">
              mdi-home-account
            </v-icon>
          </template>
          <span>{{ item.label }} ist bei uns Kunde!</span>
        </v-tooltip>

        <v-tooltip v-if="item.user?.username" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip small v-bind="attrs" v-on="on" outlined>
              <v-icon small class="mr-1" color="boo" left>
                mdi-account-key-outline
              </v-icon>
              {{ item.user.username }}
            </v-chip>
          </template>
          <span>{{ item.label }} hat einen User bei uns!</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
