<script lang="ts">
import { PropType, defineComponent } from 'vue';
import BcTextWidget from '@/components/global/BcTextWidget.vue';
import { BitrixUser } from '@/types/bitrix';
import { ExternalDataSource } from '@/types/types';
import boo from '@/boo';
import store from '@/store';
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';

export default defineComponent({
  name: 'BitrixUserDataTab',

  components: {
    BcTextWidget,
    CopyToClipboardButton,
  },

  props: {
    user: {
      type: Object as PropType<BitrixUser>,
      default: () => {
        return {};
      },
    },
    source: {
      type: Object as PropType<ExternalDataSource>,
      default: () => {},
    },
  },

  data() {
    console.log('USER', this.source);

    return {
      showBitrixUserProperties: [
        {
          label: 'Vorname',
          key: 'NAME',
        },
        {
          label: 'Nachname',
          key: 'LAST_NAME',
        },
        {
          label: 'Email',
          key: 'EMAIL',
        },
      ] as { label: string; key: keyof BitrixUser }[],
    };
  },
});
</script>

<template>
  <div>
    <div v-if="user">
      <v-card-title>
        Bitrix24 User
        <v-spacer />
        <v-btn :href="source.url" target="_blank"> In Bitrix24 ansehen </v-btn>
        <copy-to-clipboard-button :text-to-copy="source.url" />
      </v-card-title>
      <bc-text-widget
        v-for="(property, key) in showBitrixUserProperties"
        v-if="user?.[property.key]"
        :label="property.label"
        :key="key"
      >
        {{ user[property.key] }}
      </bc-text-widget>
    </div>
  </div>
</template>
