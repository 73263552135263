<template>
  <bc-widget-frame v-if="filteredTiles" title="" icon="" flat>
    <v-btn
      v-for="tile in filteredTiles"
      :key="tile.index"
      large
      tile
      block
      class="pa-8 mt-n4 mb-8"
      :to="tile.to"
    >
      <v-icon>{{ tile.icon }}</v-icon>
      <v-spacer />
      {{ tile.text }}
    </v-btn>
  </bc-widget-frame>
</template>
<script>
export default {
  name: 'BcDashboardMenutiles',

  data() {
    return {
      tiles: [
        {
          if: this.$me.can('person.show'),
          to: this.$boo.domainRouteTo('persons'),
          icon: 'mdi-account-multiple',
          text: 'Kontakte',
        },
        {
          if: this.$me.can('project.show'),
          to: this.$boo.domainRouteTo('projects'),
          icon: 'mdi-clipboard-text',
          text: 'Projekte',
        },
        {
          if: this.$me.has('canMyTimes'),
          to: this.$boo.domainRouteTo('times'),
          icon: 'mdi-clock',
          text: 'Zeiten',
        },
      ],
    };
  },

  computed: {
    filteredTiles() {
      return this.tiles.filter((t) => t.if);
    },
  },

  methods: {},
};
</script>
