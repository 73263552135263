<template>
  <v-card
    outlined
    hover
    class="ma-1"
    min-height="190px"
    :min-width="isMobile ? '98%' : '313px'"
    max-width="313px"
    @click="$emit('click')"
    data-cy="personcard"
  >
    <v-app-bar dense flat>
      <v-spacer />
      <v-tooltip v-if="person.employee" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-1" v-bind="attrs" v-on="on">
            mdi-briefcase-outline
          </v-icon>
        </template>
        <span>{{ person.label }} ist Mitarbeiter*in!</span>
      </v-tooltip>
      <v-tooltip v-if="person.customer" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-1" v-bind="attrs" v-on="on">
            mdi-home-account
          </v-icon>
        </template>
        <span>{{ person.label }} ist bei uns Kunde!</span>
      </v-tooltip>

      <router-link
        class="text-decoration-none"
        link
        :to="{
          name: 'personView',
          params: { personSlug: person.slug },
          query: { tab: 'projects' },
        }"
      >
        <span v-if="person.projectsCount" class="mr-2">
          <v-icon title="Projekte">mdi-clipboard-text</v-icon>
          {{ person.projectsCount }}
        </span>
      </router-link>
      <v-chip
        v-if="
          person.user &&
          $store.getters[$storenames.auth.getters.userCan]('user')
        "
        color="boo"
        class="caption mr-1"
        icon
        small
        link
        :to="{
          name: 'personView',
          params: { personSlug: person.slug },
          query: { tab: 'acl' },
        }"
      >
        {{ person.user.username }}
      </v-chip>
    </v-app-bar>
    <v-list-item>
      <v-list-item-avatar color="" size="60">
        <bc-avatar :pojo="person" :size="50" />
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="d-flex justify-space-between">
          <span v-if="person.sex" class="caption">{{
            person.sex ? $boo.genderdata(person.sex).Anrede : ''
          }}</span>
        </div>
        <v-list-item-title class="">
          {{ person.label }}
          <span v-if="person.id === $store.state.auth.user.person.id"
            >(Du)</span
          >
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="person.nickname != person.label">{{
            person.nickname
          }}</span>
          <span v-if="person.nickname && person.birthday">{{ ', ' }}</span>
          <span v-if="person.birthday">{{
            $dayjs().diff(person.birthday, 'years')
          }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-spacer />
      <TagIconChip v-for="(tag, key) in person.tags" :tag="tag" :key="key" />
    </v-card-actions>
  </v-card>
</template>

<script>
import TagIconChip from '@/components/tags/TagIconChip.vue';

export default {
  name: 'PersonCard',

  components: { TagIconChip },

  props: {
    person: {
      type: Object,
      default: () => {
        return {
          forename: 'Vorname',
          surname: 'Zuname',
          customer: 'Kunde',
          employee: 'Mitarbeiter',
        };
      },
    },
    edit: Boolean,
    clickable: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  mounted() {},

  methods: {},
};
</script>
──
