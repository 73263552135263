<template>
  <div class="flex">
    <v-chip-group v-if="!editMode" show-arrows>
      <v-chip
        v-for="(message, n) in messages"
        color="transparent"
        :key="n"
        @click="sendComment(message)"
      >
        {{ message }}</v-chip
      >
    </v-chip-group>
    <v-alert v-else border="left" colored-border color="boo" class="pa-1 ma-2">
      <v-card-text class="d-flex pb-0 font-weight-bold"
        >Kommentar bearbeiten <v-spacer />
        <v-btn text small @click="cancelEdit">Abbrechen</v-btn>
      </v-card-text>
      <v-card-text class="pt-0">{{ commentToEdit.text }}</v-card-text>
    </v-alert>
    <v-textarea
      v-model="message"
      auto-grow
      row-height="1"
      class="px-2 pb-2"
      placeholder="Schreibe ein Kommentar..."
      outlined
      append-icon="mdi-send"
      hide-details
      @keydown.enter="sendComment(message)"
    >
      <template v-slot:append>
        <bc-comment-visibility-switch
          v-model="comment_visiblity"
          :commentable="commentable"
        />
        <v-icon :disabled="message.length < 1" @click="sendComment(message)">{{
          editMode ? 'mdi-check' : 'mdi-send'
        }}</v-icon>
      </template>
    </v-textarea>
  </div>
</template>
<script>
export default {
  props: {
    commentable: {
      type: Object,
      default: () => {},
    },
    commentToEdit: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      storing: false,
      axiosSuccess: null,
      axiosError: null,

      comment_visiblity: 2,

      message: '',
      messages: [
        'Danke!',
        'Sehr gut!',
        ...this.$BC.CHATSYMBOLS,
        // 'Bitte überprüfe diesen Eintrag nochmal.',
      ],
    };
  },
  computed: {
    editMode() {
      return !!this.commentToEdit;
    },
  },
  methods: {
    sendComment(message) {
      this.storing = true;

      let transfer = {
        text: message,
        owner: this.$store.state.user,
        commentable_type: this.commentable.commentable_type,
        commentable_id: this.commentable.id,
        visibility: this.comment_visiblity,
        status_id: 20,
      };

      if (!this.editMode) this.$emit('messageSent', transfer);

      window
        .axios({
          method: this.editMode ? 'put' : 'post',
          url: `${this.$boo.lboUrl()}comment${
            this.editMode ? '/' + this.commentToEdit.id : ''
          }`,
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.$emit('reset', response.data.success);
          // console.log('emit')
          this.$emitter.emit('comments.updated');
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.message = '';
          this.storing = false;
        });
    },
    cancelEdit() {
      this.$emit('reset');
      this.message = '';
    },
  },
  watch: {
    commentToEdit(newVar, oldVar) {
      if (newVar !== oldVar && newVar) {
        this.message = this.commentToEdit.text;
      }
    },
  },
};
</script>
