export const RESET = (state: Record<string, any>, initialState: unknown) => {
  const newState = initialState;
  // @ts-ignore: next-line
  Object.keys(newState).forEach((key) => (state[key] = newState[key])); // TODO: ist das erlaubt?
};

export const addOrReplaceItemInArray = <
  T extends Record<string, any> & { id: any }
>(
  array: Array<T>,
  item: T
): Array<T> => {
  const index = array.findIndex((existingItem) => existingItem.id === item.id);
  if (index !== -1) {
    array.splice(index, 1, item);
  } else {
    array.push(item);
  }
  return array;
};

export const mergeArray = <T>(
  a: T[],
  b: T[],
  predicate = (a: T, b: T) => a === b
) => {
  const c = [...a]; // copy to avoid side effects
  // replace items from B in copy C if they're already present
  b.forEach((bItem) => {
    const index = c.findIndex((cItem) => predicate(bItem, cItem));
    if (index !== -1) {
      c[index] = bItem;
    } else {
      c.push(bItem);
    }
  });
  return c;
};
