<template>
  <div>
    <v-list>
      <bc-text-widget v-if="person.sex" label="Anrede">
        {{ person.sex ? $boo.genderdata(person.sex).Anrede : '-' }}
      </bc-text-widget>
      <v-divider v-if="isMobile && person.sex" />

      <bc-text-widget v-if="person.forename" label="Vorname" data-cy="vorname">
        {{ person.forename }}
      </bc-text-widget>
      <v-divider v-if="isMobile && person.forename" />

      <bc-text-widget v-if="person.surname" label="Nachname">
        {{ person.surname }}
      </bc-text-widget>
      <v-divider v-if="isMobile && person.surname" />

      <bc-text-widget v-if="person.nickname" label="Spitzname">
        {{ person.nickname }}
      </bc-text-widget>
      <v-divider v-if="isMobile && person.nickname" />

      <bc-text-widget v-if="person.birthday" label="Geburtsdatum">
        {{ $dayjs(person.birthday).format('D. MMM YYYY') }}
      </bc-text-widget>
      <v-divider v-if="isMobile && person.birthday" />

      <v-list-item v-if="person.employee">
        <v-list-item-content>
          <v-list-item-title>ist MitarbeiterIn</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon class="mr-1"> mdi-briefcase-outline </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="isMobile && person.employee" />

      <v-list-item v-if="person.customer">
        <v-list-item-content>
          <v-list-item-title>ist KundIn</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon class="mr-1"> mdi-home-account </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="isMobile && person.customer" />

      <v-list-item v-if="person.owner">
        <v-list-item-content>
          <v-list-item-title>Zuständigkeit</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <bc-pojo-owner
            v-if="!loading"
            :pojo="person"
            pojotype="person"
            @evOwner="setOwner"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider v-if="isMobile && person.owner" />

    <bc-text-widget label="Tags">
      <TagSelector
        :taggable-type="'persons'"
        :taggable="person"
        :can-edit="canEdit"
      />
    </bc-text-widget>
  </div>
</template>

<script lang="ts">
//@ts-ignore
import BcPojoOwner from '@/components/BcPojoOwner';
import TagSelector from '@/components/tags/TagSelector.vue';
import { PersonType } from '@/types/types';
import { defineComponent } from 'vue';
import { canEditPerson } from './helpers';

export default defineComponent({
  name: 'PersonData',

  components: { BcPojoOwner, TagSelector },

  props: {
    person: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    canEdit(): boolean {
      return canEditPerson(this.person);
    },
  },
  methods: {
    setOwner(person: PersonType) {
      this.$emit('evUpdated', person);
      if (
        !this.$me.owns(person) &&
        !this.$store.getters[this.$storenames.auth.getters.userCan](
          'person.show.all'
        )
      ) {
        this.$router.replace('persons');
      }
    },
  },
});
</script>
