<template>
  <v-card :flat="flat" outlined hover>
    <v-card-title v-if="title">
      <v-icon v-if="icon" left>
        {{ icon }}
      </v-icon>
      <v-badge :content="badge" :value="badge" offset-x="5" offset-y="5">{{
        title
      }}</v-badge>
      <v-spacer />
      <slot name="action" />
    </v-card-title>
    <v-card-subtitle v-if="subtitle">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'BvWidgetFrame',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'mdi-alert',
    },
    flat: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
      default: null,
    },
    actionButton: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
