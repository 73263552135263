<template>
  <v-tooltip open-on-click bottom :max-width="maxWidth">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" small>
        {{ icon }}
      </v-icon>
    </template>

    <slot />
  </v-tooltip>
</template>

<script>
export default {
  name: 'BcInfo',

  props: {
    maxWidth: {
      type: [String, Number],
      default: 300,
    },
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
  },
};
</script>
