<template>
  <v-menu open-on-hover offset-y>
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
      show-in-dialog
    />

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="itemsToExport.length === 0"
        :loading="loading"
        color="primary"
        v-bind="attrs"
        text
        v-on="on"
      >
        {{
          itemsToExport.length > 1 ? $boo.spellnumber(itemsToExport.length) : ''
        }}
        {{ itemsToExport.length === 1 ? 'Eintrag' : 'Einträge' }} exportieren
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item
        v-if="$me.can('time.export.pdf') && pdf"
        @click="downloadPDF"
      >
        <v-list-item-icon><v-icon>mdi-file-pdf-box</v-icon></v-list-item-icon>
        <v-list-item-title>als PDF</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$me.can('time.export.django') && django"
        :disabled="!itemsToExport.every((billing) => billing.status_id === 30)"
        @click="downloadDjango"
      >
        <v-list-item-icon
          ><v-icon>mdi-file-excel-outline</v-icon></v-list-item-icon
        >
        <v-list-item-title>für Django</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$me.can('time.export.csv') && csv"
        @click="downloadCSV"
      >
        <v-list-item-icon><v-icon>mdi-file-excel</v-icon></v-list-item-icon>
        <v-list-item-title>als CSV</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'TimeBulkExportButton',

  props: {
    itemsToExport: {
      type: Array,
      default: () => [],
    },
    type: {
      // was exportiert werden soll
      type: String,
      validator: function (value) {
        return ['timebilling', 'times'].indexOf(value) !== -1;
      },
      default: null,
    },
    pdf: { type: Boolean, default: false },
    csv: { type: Boolean, default: false },
    django: { type: Boolean, ddefault: false },
  },

  data() {
    return {
      axiosError: null,
      axiosSuccess: null,
      loading: false,
    };
  },

  computed: {
    // isMobile () {
    //   return this.$vuetify.breakpoint.name === 'xs'
    // },
    apiURL() {
      if (this.type === 'timebilling')
        return this.$boo.lboUrl() + 'timebillings';
      if (this.type === 'times') return this.$boo.lboUrl() + 'times';
      console.error('TimeBulkExportButton > unknown type: ' + this.type);
    },
  },

  methods: {
    downloadPDF() {
      this.loading = true;
      // https://medium.com/@kennethteh90/open-download-pdf-in-vue-from-rails-api-cdd1402eea77
      window.axios
        .request({
          url: this.$boo.lboUrl() + `timebillings/bulkpdf`,
          method: 'POST',
          responseType: 'blob',
          data: {
            // ids: this.itemsToExport // so werden die gesamten Objekte übermittelt
            ids: this.itemsToExport.map((item) => item['id']), // nur die IDs
          },
          // params: {}
        })
        .then((response) => {
          this.axiosSuccess = response; // weiß nicht, ob überhaupt sinnvoll
          return response.data; // erforderlich!
        })
        .then((blob) => {
          const data = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          if (false) {
            link.target = '_blank'; // show in browser
          } else {
            link.download =
              this.$dayjs().format('YYYY-MM-DD') +
              '_boo_Export-PDF-ZeitAbrechnungen.zip'; // for download
          }
          link.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadCSV() {
      alert('sorry, csv bulk export not implemented yet....');
    },
    downloadDjango() {
      this.loading = true;
      // https://medium.com/@kennethteh90/open-download-pdf-in-vue-from-rails-api-cdd1402eea77
      window.axios
        .request({
          url: this.$boo.lboUrl() + `timebillings/bulkdjango`,
          method: 'POST',
          responseType: 'blob',
          data: {
            // ids: this.itemsToExport // so werden die gesamten Objekte übermittelt
            ids: this.itemsToExport.map((item) => item['id']), // nur die IDs
          },
          // params: {}
        })
        .then((response) => {
          this.axiosSuccess = response; // weiß nicht, ob überhaupt sinnvoll
          return response.data; // erforderlich!
        })
        .then((blob) => {
          const data = URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          if (false) {
            link.target = '_blank'; // show in browser
          } else {
            link.download =
              this.$dayjs().format('YYYY-MM-DD') +
              '_boo_Export-PDF-ZeitAbrechnungen.zip'; // for download
          }
          link.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
