<script lang="ts">
import DeleteTagButton from '@/components/tags/DeleteTagButton.vue';
import {
  createTagRequest,
  updateTagRequest,
} from '@/components/tags/tagService';
import { Tag } from '@/components/tags/tagTypes';
import { defineComponent, PropType } from 'vue';

type TagEditType = {
  tag: Tag;
  onTagChanged: () => void;
};

export default defineComponent({
  name: 'TagEdit',
  props: {
    data: { type: Object as PropType<TagEditType>, default: null },
  },
  data() {
    return {
      loading: false,
      storing: false,
      tagEdit: {} as Tag,
      valid: false,
      tagNameLabel: 'Tag-Name',
      tagInputRules: {
        name: [
          (v: string) => !!v || 'Bitte gib einen Tag-Namen ein',
          (v: string) =>
            (v && v.length <= 50) ||
            'Der Tag darf nicht länger als 50 Zeichen sein',
        ],
      },
      axiosError: null as any,
    };
  },
  created() {
    if (!this.isNew) {
      this.tagEdit = { ...this.data.tag };
      // this.loadPerson() @@@LuB TODO: Hier sollte man die Person nachladen???
    }
  },
  computed: {
    isNew(): boolean {
      return !this.data.tag;
    },
    title() {
      if (this.isNew) {
        return { name: 'Neu', icon: 'mdi-clipboard' };
      } else {
        return { name: 'Bearbeiten', icon: 'mdi-clipboard' };
      }
    },
    inputHasChanged(): boolean {
      if (this.isNew) return true;
      return JSON.stringify(this.tagEdit) !== JSON.stringify(this.data.tag);
    },
  },
  methods: {
    async submit() {
      try {
        this.storing = true;
        if (this.isNew) {
          const response = await createTagRequest(this.tagEdit);
        } else {
          const response = await updateTagRequest(this.tagEdit);
        }
        this.data.onTagChanged();
      } catch (e: any) {
        this.axiosError = e.response;
      } finally {
        this.storing = false;
        this.closeDrawer();
      }
    },
    closeDrawer() {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
    onDeleteError(e: any) {
      this.axiosError = e;
    },
  },
  components: { DeleteTagButton },
});
</script>

<template>
  <v-card :disabled="loading" color="transparent" flat>
    <bc-btn-cancel-action
      v-if="inputHasChanged"
      :valid="valid"
      :enable-action="inputHasChanged"
      :action-running="storing"
      :header="true"
      :title="title.name"
      @evAction="submit"
      @evCancel="closeDrawer"
    />
    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="$store.dispatch($storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>

    <v-card-text class="mt-3">
      <v-form
        ref="formAddTag"
        v-model="valid"
        class="px-3"
        @keyup.native.enter="submit"
        @keyup.native.escape="closeDrawer"
      >
        <v-text-field
          v-model="tagEdit.name"
          :label="tagNameLabel"
          autocomplete="off"
          :rules="tagInputRules.name"
        />
      </v-form>
    </v-card-text>
    <v-card-text v-if="$boo.envIsDev()" class="blue">
      InputHasChanged: {{ inputHasChanged }} <br />
      {{ tagEdit }} <br />
      valid: {{ valid }}
    </v-card-text>

    <DeleteTagButton
      v-if="!isNew"
      :tag="tagEdit"
      :on-success="
        () => {
          closeDrawer();
          data.onTagChanged();
        }
      "
      :on-error="onDeleteError"
    />

    <bc-response-handler :axios-error="axiosError" />
  </v-card>
</template>
