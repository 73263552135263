<template>
  <v-dialog v-model="show" persistent width="300">
    <v-card>
      <v-card-title class="justify-center mb-10">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular
          :rotate="90"
          :size="150"
          :width="20"
          :value="progressShown"
          color="boo"
        >
          {{ progress }} / {{ max }}
        </v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressShown() {
      let i = (this.progress / this.max) * 100;
      return i;
    },
  },
};
</script>

<style></style>
