<template>
  <v-avatar :color="color" size="40" class="align-self-end">
    <span v-if="isLastMessageOfAuthor && author">
      {{ author.label.charAt(0).toUpperCase() }}
    </span>
  </v-avatar>

  <!--
  <bc-avatar
    :pojo="author"
    :size="50"
  />
 -->
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      default: () => {},
    },
    isLastMessageOfAuthor: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    color() {
      return this.isLastMessageOfAuthor ? 'dark' : 'transparent';
    },
  },
};
</script>
