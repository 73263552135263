<script lang="ts">
import TagSelector from '@/components/tags/TagSelector.vue';
import BcResponseHandler from '../components/global/BcResponseHandler.vue';
import { defineComponent } from 'vue';
import store from '../store';

export default defineComponent({
  name: 'TestBen',
  components: { TagSelector },

  data() {
    console.log(store.state.data.persons);
    return {
      person: store.state.data.persons.find((person) => person.id === 1),
    };
  },
});
</script>

<template>
  <div>
    <v-card-title> Bennis DEV-Corner. </v-card-title>
  </div>
</template>
