<template>
  <div>
    <v-card-title>Zusammenfassung</v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
          <tr v-for="(item, n) in checklist" :key="n">
            <td>{{ item.title }}</td>
            <td class="text-right">
              <v-icon class="mr-1" small :color="icon(item).color">
                {{ icon(item).icon }}
              </v-icon>
            </td>
            <td class="text-left">
              {{ icon(item).value }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </div>
</template>
<script>
export default {
  props: {
    checklist: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    icon(item) {
      var icon = 'mdi-close';
      var color = 'red';
      var value = 'Pflichtfeld';

      if (
        (item.mandatory === true && item.value) ||
        (!item.mandatory && item.value)
      ) {
        icon = 'mdi-check';
        color = 'green';
        value = item.value;
      } else if (!item.mandatory && (!item.value || item.value)) {
        icon = '';
        color = '';
        value = '';
      }

      return { icon: icon, color: color, value: value };

      //   if (item.mandatory) {
      //     return item.value ? 'mdi-check' : 'mdi-close'
      //   }
    },
  },
};
</script>
