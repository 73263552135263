<template>
  <v-menu
    ref="showDatePicker"
    v-model="showDatePicker"
    :close-on-content-click="true"
    :nudge-right="0"
    :return-value.sync="dateEdit"
    transition="scale-transition"
    offset-x
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date"
        :label="label"
        v-bind="attrs"
        append-icon="mdi-calendar"
        :rules="rules"
        v-on="on"
        @click:append="showDatePicker = true"
      />
    </template>
    <v-date-picker v-model="dateEdit" color="boo" scrollable />
  </v-menu>
</template>

<script>
export default {
  name: 'BcDatePicker',

  props: {
    date: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      default: 'Datum',
    },
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      showDatePicker: false,
      dateEdit: null,
    };
  },

  watch: {
    dateEdit() {
      if (this.dateEdit != null) {
        this.$emit('dateselected', this.dateEdit);
      }
    },
  },

  methods: {
    selectDate() {
      this.showDatePicker = false;
    },
  },
};
</script>
