<script lang="ts">
import store from '@/store';
import { PropType, defineComponent } from 'vue';
import { ExternalAction, ExternalTypes, createTexts } from './helpers';
import {
  addExternalDataSourceURLRequest,
  createPersonFromExternalRequest,
} from '../personService';
import DevelopmentNotice from '@/components/DevelopmentNotice.vue';
import { PersonType } from '@/types/types';
import BcContentDrawerFrame from '@/components/global/contentDrawer/BcContentDrawerFrame.vue';

type CreateProp = {
  action: ExternalAction.create;
};

type AddProp = {
  action: ExternalAction.add;
  personSlug: PersonType['slug'];
  onChange: (person: PersonType) => void;
};

type PropData = {
  type: ExternalTypes;
} & (CreateProp | AddProp);

export default defineComponent({
  name: 'EditPersonExternalDataSources',
  props: {
    data: { type: Object as PropType<PropData>, default: null },
  },
  data() {
    // const texts = createTexts(this.data.type, this.data.action);
    return {
      valid: true,
      storing: false,
      loading: false,
      url: '',
      axiosError: null,
    };
  },
  methods: {
    cancel() {
      store.dispatch.contentDrawer.close();
    },
    async submit() {
      this.loading = true;
      try {
        if (this.data.action === ExternalAction.create) {
          const createdPerson = await createPersonFromExternalRequest({
            url: this.url,
          });
          this.goToPerson(createdPerson.slug);
        } else if (this.data.action === ExternalAction.add) {
          const person = await addExternalDataSourceURLRequest({
            url: this.url,
            slug: this.data.personSlug,
          });
          store.dispatch.contentDrawer.close();
          this.data.onChange?.(person);
        } else {
          throw new Error('Unknown action');
        }
      } catch (e: any) {
        this.axiosError = e;
      } finally {
        this.loading = false;
      }
    },
    goToPerson(slug: string) {
      store.dispatch.contentDrawer.close();
      this.$router.push({
        name: 'personView',
        params: { personSlug: slug },
      });
    },
  },
  computed: {
    texts(): { title: string; description: string; urlLabel: string } {
      return createTexts(this.data.type, this.data.action);
    },
    urlRule() {
      return (url: string | undefined) => {
        const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
        if (!url || !urlRegex.test(url)) {
          return 'Bitte gib eine gültige URL ein.';
        }
        if (!url.startsWith('https://')) {
          return 'Die URL muss mit "https://" beginnen."';
        }
        return true;
      };
    },
  },
  components: { DevelopmentNotice, BcContentDrawerFrame },
});
</script>

<template>
  <BcContentDrawerFrame :disabled="loading">
    <template v-slot:headerActionLeft>
      <v-btn @click="cancel" text color="boo"> Abbrechen</v-btn>
    </template>

    <template v-slot:headerTitle> {{ texts.title }} </template>

    <template v-slot:headerActionRight>
      <v-btn @click="submit" color="boo">Speichern</v-btn>
    </template>

    <v-card-text class="mt-3">
      {{ texts.description }}
      <v-form
        ref="formAddPerson"
        v-model="valid"
        class="px-3"
        lazy-validation
        @keyup.native.enter="submit"
        @keyup.native.escape="cancel"
      >
        <v-text-field
          v-model="url"
          :label="texts.urlLabel"
          :rules="[urlRule]"
          :loading="loading"
        ></v-text-field>
      </v-form>

      <bc-response-handler :axios-error="axiosError" />
      <DevelopmentNotice>
        Nutze diese URLs um im Development eine Person via Bitrix anzulegen:<br />
        https://bitrix.assistenz24.at/company/personal/user/8/ <br />
        https://bitrix.assistenz24.at/crm/contact/details/2728/
      </DevelopmentNotice>
    </v-card-text>
  </BcContentDrawerFrame>
</template>
