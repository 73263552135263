<template>
  <bv-frame
    headline="Projekte"
    :loading="loading"
    :new-button="
      $store.getters[$storenames.auth.getters.userCan]('project.create')
    "
    new-button-label="Neues Projekt"
    @refresh="loadData"
    @newButton="newProject"
  >
    <bc-response-handler :axios-error="axiosError" />

    <projects-data-table
      :projects="projects"
      :headers="headers"
      :status-options="statusOptions"
      :loading="loading"
    />
  </bv-frame>
</template>

<script lang="ts">
import BvFrame from '@/views/Frame.vue';
import BvProjectCard from '@/views/project/ProjectCard.vue';
import { defineComponent } from 'vue';
import ProjectsDataTable from './ProjectsDataTable.vue';
import { createProjectsDataTableHeaders } from './projectsDataTableHeaders';

export default defineComponent({
  components: { BvFrame, BvProjectCard, ProjectsDataTable },

  data() {
    return {
      projects: [],
      loading: false,
      axiosError: null,
      apiPath: this.$boo.lboUrl() + 'projects',
      statusOptions: [],
    };
  },
  computed: {
    headers() {
      return createProjectsDataTableHeaders(this.$vuetify.breakpoint);
    },
  },

  created() {
    this.loadData(); // (nach)laden
  },
  mounted() {
    // Scroll to Top if changed to this route
    window.scrollTo(0, 0);
  },
  methods: {
    loadData() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'projects')
        .then((response: any) => {
          this.projects = response.data.success;
          this.statusOptions = response.data.options.status;
          this.$boo.storeApiData(response.data.apiData); // apiData aktualisieren
        })
        .catch((error: any) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    newProject() {
      this.$store.commit('contentDrawer/OPEN', 'bc-project-edit');
    },
  },
});
</script>
