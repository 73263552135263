<template>
  <div>
    <span v-if="available === null" style="font-size: 15px" class="pl-5">
      ?
    </span>
    <v-switch
      v-if="available !== null"
      v-model="switchStatus"
      dense
      color="boo"
      class="ml-6 mt-1 mb-n2"
      :loading="storing"
      :disabled="storing || !available"
      @change="change"
    />
    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />
  </div>
</template>

<script>
export default {
  name: 'UserAbilitiesSwitch',

  components: {
    // BcErrorMsg
  },
  props: {
    domainId: {
      type: Number,
      default: null,
    },
    domain: {
      type: Object,
      default: null,
    },
    ability: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      switchStatus: false,
      storing: false,

      axiosError: null,
      axiosSuccess: null,
    };
  },

  // computed: {
  // },

  // watch: {
  // },

  created() {
    this.switchStatus = this.value;
  },

  methods: {
    change() {
      this.storing = true;
      window.axios
        .post(
          `${this.$boo.lboUrl()}user/${this.user.slug}/ability/${
            this.domain.slug
          }/${this.ability.name}`,
          { value: this.switchStatus }
        )
        .then((response) => {
          this.axiosSuccess = response;
          this.$emit('evStored', response.data.success);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.switchStatus = this.value;
          this.storing = false;
        });
    },
  },
};
</script>
