<template>
  <bc-widget-frame
    v-if="$store.state.data.myQuickPtms.length > 0 && recordedTimes.length"
    title="Erfasste Stunden"
    :subtitle="subtitle"
    icon="mdi-signal-cellular-3"
    badge="beta"
  >
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <bc-bar-chart
      v-if="yaxis.length > 0"
      :yaxis="yaxis"
      :xaxis="xaxis"
      stacked
      :showLegend="showLegend"
    />
  </bc-widget-frame>
</template>

<script>
import store from '@/store';

export default {
  name: 'BcDashbaordTimesWeekly',

  data() {
    return {
      statusLevels: [10, 20, 30],
      axiosSuccess: null,
      axiosError: null,
      weekCount: 4,
      loading: false,
    };
  },
  computed: {
    recordedTimes() {
      return store.state.timesStore.times.filter((item) => {
        return (
          this.statusLevels.includes(item.status_id) &&
          item.ptm.person.id === this.$store.state.auth.user.person.id
        );
      });
    },
    subtitle() {
      return `Summe der letzten ${this.$boo.spellnumber(
        this.weekCount
      )} Wochen.`;
    },
    range() {
      return this.$boo.getPreviousWeeks(this.weekCount);
    },
    xaxis() {
      let axis = this.range.map((week) => {
        return `KW ${week.cw}`;
      });
      axis.length > 0 ? (axis[axis.length - 1] = 'Diese Woche') : '';
      return axis;
    },
    yaxis() {
      if (!this.recordedTimes) return [];
      let data = [];

      if (this.allPTMS) {
        this.allPTMS.forEach((ptm) => {
          let entry = {};
          entry.name = `${ptm.project.label}`;
          entry.data = [];
          this.range.forEach((week) => {
            let filtered = this.recordedTimes.filter((item) => {
              return (
                this.$dayjs(item.start) >= this.$dayjs(week.start) &&
                this.$dayjs(item.start) <= this.$dayjs(week.end) &&
                item.ptm.id === ptm.id
              );
            });
            entry.data.push((this.sum(filtered, 'duration') / 60).toFixed(2));
          });
          data.push(entry);
        });
      }
      return data;
    },
    allPTMS() {
      if (this.recordedTimes) {
        return this.$boo.uniqueObjects(this.recordedTimes.map((o) => o.ptm));
      }
      return false;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    showLegend() {
      return this.allPTMS.length <= 3;
    },
  },

  methods: {
    sum(items, prop) {
      // return prop
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    refreshData() {
      this.$store.dispatch('data/refresh', ['refreshTimes', 'getPTMs']);
    },
    filterData() {},
  },

  watch: {},

  created() {
    this.refreshData();
  },
};
</script>
