<template>
  <bc-widget-frame
    data-cy="bc-dashboard-todos"
    title="Deine Aufgaben"
    subtitle=""
    :icon="
      todos.length ? 'mdi-sticker-alert-outline' : 'mdi-sticker-check-outline'
    "
  >
    <!-- <v-skeleton-loader v-if="$store.state.data.isLoading" type="list-item-avatar" /> -->

    <v-card-text
      v-if="todos.length === 0"
      style="height: 80%"
      class="text-center d-flex flex-column justify-center"
    >
      <div v-if="isLoading">
        <v-icon color="grey">mdi-timer-sand mdi-48px</v-icon>
        <p class="mt-8">Analyse der Zeiten und Abrechnungen läuft ...</p>
      </div>
      <div v-else>
        <v-icon color="success">mdi-thumb-up-outline mdi-48px</v-icon>
        <p class="mt-8">Super, es ist alles erledigt!</p>
      </div>
    </v-card-text>

    <v-list v-else>
      <v-list-item
        v-for="todo in todos"
        :key="todo.index"
        :to="todo.to"
        @click="if (todo.click) todo.click.fn(todo.click.param);"
      >
        <v-list-item-icon>
          <v-icon :color="color(todo.level)">{{ todo.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap">{{
            todo.title
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">{{
            todo.subtitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip v-if="todo.info" open-on-click bottom max-width="300px">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline </v-icon>
            </template>
            <span>{{ todo.info }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </bc-widget-frame>
</template>

<script>
import store from '@/store';

export default {
  name: 'BcDashboardTodos',

  components: {},

  data() {
    return {
      // loading: false,
    };
  },

  computed: {
    times() {
      return store.state.timesStore.times;
    },
    timebillings() {
      return store.state.timebillingStore.timebillings;
    },
    ptms() {
      return store.state.data.ptms;
    },
    isLoading() {
      return (
        store.state.timesStore.isLoading ||
        store.state.timebillingStore.isLoading
      );
    },

    mytimes() {
      return this.times.filter((time) =>
        this.$me.isMyPerson(time.ptm.person.id)
      );
    },

    timebillingsOld() {
      return this.timebillings.filter(
        (timebilling) =>
          this.$dayjs(timebilling.startdate) < this.$dayjs().startOf('month')
      );
    },

    todos() {
      let todos = [];

      if (!this.timebillings.length || !this.times.length) return todos;

      /* Doku/Erklärung
      todos.push({
        icon: 'mdi-xxx',
        title: 'erste Zeile; Titel',
        subtitle: 'Zweite Zeile; Untertitel',
        info: 'weitere Infos im Tooltip'
        level: [null], warn, error
        to: [link]
        click: [function]
      })
      */

      // laufender Zeiteintrag
      if (this.timesStatusRecording.length) {
        todos.push({
          icon: 'mdi-clock-outline mdi-spin',
          // title: `Laufende Zeiterfassung seit ${hours} Stunden`,
          title: `Laufende${
            this.timesStatusRecordingOverdue.length ? ', überfällige' : ''
          } Zeiterfassung${this.timesStatusRecording.length > 1 ? 'en' : ''} `,
          subtitle: this.timesStatusRecordingOverdue.length
            ? 'Die maximal zulässige Dauer wurde bereits überschritten!'
            : 'Stoppe die Zeit, wenn du fertig bist',
          level: this.timesStatusRecordingOverdue.length ? 'error' : '',
          click:
            this.timesStatusRecording.length === 1
              ? { fn: this.openTime, param: this.timesStatusRecording[0] }
              : null,
        });
      }

      // offene ZEs die erfasst werden müssen
      if (this.timesStatusOffen.length)
        todos.push({
          icon: this.$boo.statusInfo('time', 0).status.icon,
          title:
            this.timesStatusOffen.length === 1
              ? 'Ein noch offener Zeiteintrag'
              : `${this.$boo.capitalizeFirstLetter(
                  this.$boo.spellnumber(this.timesStatusOffen.length).toString()
                )} noch offene${
                  this.timesStatusOffenOld.length ? ', alte' : ''
                } Zeiteinträge`,
          subtitle:
            this.timesStatusOffen.length === 1
              ? this.timesStatusOffenOld.length
                ? 'Bitte erfassen oder löschen!'
                : 'Bitte kontrollieren und erfassen'
              : 'Diese Zeiteinträge müssen noch erfasst werden',
          info: '"Offene" Zeiteinträge müssen "erfasst" und dann "bestätigt" werden, damit sie zählen.',
          level:
            this.timesStatusOffen.length > 5 || this.timesStatusOffenOld.length
              ? 'warn'
              : '',
          click:
            this.timesStatusOffen.length === 1
              ? { fn: this.openTime, param: this.timesStatusOffen[0] }
              : null,
          to:
            this.timesStatusOffen.length === 1
              ? null
              : this.$boo.domainRouteTo(
                  'mytimes',
                  {},
                  {
                    filter: encodeURIComponent(
                      JSON.stringify({
                        statusLevels: [0],
                      })
                    ),
                  }
                ),
        });

      // erfasste ZEs die bestätigt werden müssen
      if (this.timesStatusPruefen.length)
        todos.push({
          icon: this.$boo.statusInfo('time', 10).status.icon,
          title:
            this.timesStatusPruefen.length === 1
              ? 'Ein noch zu prüfender Zeiteintrag'
              : `${this.$boo.capitalizeFirstLetter(
                  this.$boo
                    .spellnumber(this.timesStatusPruefen.length)
                    .toString()
                )} noch zu bestätigende${
                  this.timesStatusPruefenOld.length ? ', alte' : ''
                } Zeiteinträge` +
                (this.timesStatusPruefenOld.length &&
                this.timesStatusPruefenOldBillings.length > 1
                  ? ` in ${this.$boo.spellnumber(
                      this.timesStatusPruefenOldBillings.length
                    )} alten Abrechnungen`
                  : ''),
          subtitle: this.timesStatusPruefenOld.length
            ? 'Bitte vor allem Zeiteinträge vom letzten Monat kontrollieren!'
            : 'Bitte prüfen und bestätigen',
          info: '"Erfasste" Zeiteinträge müssen "bestätigt" werden, damit sie verarbeitet werden können.',
          level: this.timesStatusPruefenOld.length ? 'warn' : '',
          click:
            // wenn's nur 1 ZE gibt
            this.timesStatusPruefen.length === 1
              ? { fn: this.openTime, param: this.timesStatusPruefen[0] }
              : null,
          to:
            // sonst zur ältesten betroffenen Abrechnung
            this.timesStatusPruefen.length === 1
              ? null
              : this.$boo.domainRouteTo('timebillingdetail', {
                  billingID: this.timesStatusPruefenBillings[0]?.id,
                }),
        });

      // bestätigte ZEs die gesperrt werden müssen
      if (this.timesStatusLock.length) {
        todos.push({
          icon: this.$boo.statusInfo('time', 20).status.icon,
          title:
            this.timesStatusLock.length === 1
              ? 'Ein noch zu sperrender Zeiteintrag'
              : `${this.$boo.capitalizeFirstLetter(
                  this.$boo.spellnumber(this.timesStatusLock.length).toString()
                )} noch zu sperrende Zeiteinträge`,
          subtitle: '',
          info: '"Bestätigte" Zeiteinträge müssen "gesperrt" werden, damit die Bestätigung nicht mehr zurückgenommen werden kann.',
          level: '',
          click:
            // wenn's nur 1 ZE gibt
            this.timesStatusLock.length === 1
              ? { fn: this.openTime, param: this.timesStatusLock[0] }
              : null,
          to:
            // sonst zur ältesten betroffenen Abrechnung
            this.timesStatusLock.length === 1
              ? null
              : this.$boo.domainRouteTo('timebillingdetail', {
                  billingID: this.timesStatusLockBillings[0]?.id,
                }),
        });
      }

      // Abrechnungen in Arbeit
      let tb = this.timebillingsOld.filter(
        (timebilling) =>
          this.$me.isMyPerson(timebilling.ptm.person.id) && // meine
          timebilling.status_id === 0 && // und in Arbeit
          timebilling.status_permission_level.includes(10) // darf einreichen (notwendig?)
      );
      if (tb.length)
        todos.push({
          icon: this.$boo.statusInfo('timebilling', 0).status.icon,
          title:
            tb.length === 1
              ? `${this.$dayjs(tb[0].enddate).format(
                  'MMMM'
                )}-Abrechnung einreichen`
              : 'Überfällige Abrechnungen einreichen',
          subtitle:
            this.$dayjs().date() > 5
              ? 'Abrechnungen bitte gleich nach Monatsende abschließen!'
              : '',
          info: 'Abrechnungen sind zunächst "in Arbeit" und müssen am Monatsende "eingereicht" werden',
          level: this.$dayjs().date() > 5 ? 'error' : 'warn',
          to:
            tb.length === 1
              ? this.$boo.domainRouteTo('timebillingdetail', {
                  billingID: tb[0]?.id,
                })
              : this.$boo.domainRouteTo(
                  'timebilling',
                  {},
                  {
                    filter: encodeURIComponent(
                      JSON.stringify({ statusLevels: [0] })
                    ),
                  }
                ),
        });

      // Abrechnungen freizugeben
      tb = this.timebillingsOld.filter(
        (timebilling) =>
          timebilling.status_id === 10 && // eingereicht
          timebilling.status_permission_level.includes(20) && // darf prüfen
          this.$me.isMyPerson(timebilling.ptm.auditor?.person?.id) // muss prüfen
      );
      if (tb.length)
        todos.push({
          icon: this.$boo.statusInfo('timebilling', 10).status.icon,
          title:
            tb.length === 1
              ? `${this.$dayjs(tb[0].enddate).format(
                  'MMMM'
                )}-Abrechnung freigeben`
              : 'Eingereichte Abrechnungen freigeben',
          subtitle:
            this.$dayjs().date() > 5
              ? 'Abrechnungen bitte gleich nach Monatsende freigeben!'
              : '',
          info: '"Eingereichte" Abrechnungen müssen "freigegeben" werden, damit sie verarbeitet werden können.',
          level: this.$dayjs().date() > 5 ? 'error' : 'warn',
          to:
            tb.length === 1
              ? this.$boo.domainRouteTo('timebillingdetail', {
                  billingID: tb[0].id,
                })
              : this.$boo.domainRouteTo(
                  'timebilling',
                  {},
                  {
                    filter: encodeURIComponent(
                      JSON.stringify({ statusLevels: [10] })
                    ),
                  }
                ),
        });

      // Abrechnungen abschließen
      tb = this.timebillingsOld.filter(
        (timebilling) =>
          timebilling.status_id === 20 && // freigegeben
          // timebilling.status_permission_level.includes(30) // darf abschließen
          timebilling.ptm.project.isProjectLead // bin PL
      );
      if (tb.length)
        todos.push({
          icon: this.$boo.statusInfo('timebilling', 20).status.icon,
          title:
            tb.length === 1
              ? `${this.$dayjs(tb[0].enddate).format(
                  'MMMM'
                )}-Abrechnung abschließen`
              : 'Freigegebene Abrechnungen abschließen',
          subtitle:
            this.$dayjs().date() > 5
              ? 'Abrechnungen bitte gleich nach Monatsende abschließen!'
              : '',
          info: '"Freigegebene" Abrechnungen müssen "abgeschlossen" werden, damit die Freigabe nicht mehr zurückgenommen werden kann.',
          level: this.$dayjs().date() > 5 ? 'error' : 'warn',
          to:
            tb.length === 1
              ? this.$boo.domainRouteTo('timebillingdetail', {
                  billingID: tb[0].id,
                })
              : this.$boo.domainRouteTo(
                  'timebilling',
                  {},
                  {
                    filter: encodeURIComponent(
                      JSON.stringify({ statusLevels: [20] })
                    ),
                  }
                ),
        });

      return todos;
    },

    // todosText() {
    //   switch (this.todos.length) {
    //     case 0:
    //       return 'Keine Aufgaben';
    //     case 1:
    //       return 'Deine Aufgaben';
    //     default:
    //       return `Du hast ${this.$boo.spellnumber(this.todos.length)} Aufgaben`;
    //   }
    // },

    timesStatusRecording() {
      return store.getters.timesStore.myRecordingTimes;
    },

    timesStatusRecordingOverdue() {
      if (!this.ptms.length) return [];
      return this.timesStatusRecording.filter((time) => {
        const recMins = this.$dayjs().diff(time.start, 'minute', true);
        const durationMax = this.ptms.find((ptm) => ptm.id === time.ptm.id)
          .timerecmodel.duration_max;
        return durationMax && recMins > durationMax;
      });
    },

    // "offene", nicht laufende ZEs
    timesStatusOffen() {
      return this.mytimes.filter((time) => time.status_id === 0 && time.stop);
    },
    // nur ZEs aus Vormonat(en)
    timesStatusOffenOld() {
      return this.timesStatusOffen.filter(
        (time) => this.$dayjs().diff(time.start, 'day', true) > 7
      );
    },
    // "offene" ZEs wo ich prüfen bzw. freigeben soll
    timesStatusPruefen() {
      return this.times.filter((t) => {
        return (
          t.status_id === 10 &&
          t.status_permission_level.includes(20) &&
          this.$me.isMyPerson(t.ptm.auditor?.person?.id)
        );
      });
    },

    // geprüfte ZEs die noch nicht gesperrt sind
    timesStatusLock() {
      return this.times.filter((t) => {
        return (
          t.status_id === 20 &&
          t.status_permission_level.includes(30) &&
          t.ptm.project.isProjectLead
        );
      });
    },

    // nur ZEs aus Vormonat(en)
    timesStatusPruefenOld() {
      return this.timesStatusPruefen.filter(
        (time) => this.$dayjs().month() !== this.$dayjs(time.start).month()
      );
    },
    timesStatusPruefenBillings() {
      if (!this.timesStatusPruefen?.length) return [];
      return this.$boo
        .uniqueObjects(
          this.timesStatusPruefen
            .map((time) =>
              this.timebillings.find((tb) => tb.id === time.timebilling_id)
            )
            .filter((item) => item !== undefined)
        )
        .sort(this.$boo.sortBy('startdate'));
    },
    timesStatusPruefenOldBillings() {
      if (!this.timesStatusPruefenOld?.length) return [];
      return this.$boo
        .uniqueObjects(
          this.timesStatusPruefenOld
            .map((time) =>
              this.timebillings.find((tb) => tb.id === time.timebilling_id)
            )
            .filter((item) => item !== undefined)
        )
        .sort(this.$boo.sortBy('startdate'));
    },
    timesStatusLockBillings() {
      if (!this.timesStatusLock?.length) return [];
      return this.$boo
        .uniqueObjects(
          this.timesStatusLock
            .map((time) =>
              this.timebillings.find((tb) => tb.id === time.timebilling_id)
            )
            .filter((item) => item !== undefined)
        )
        .sort(this.$boo.sortBy('startdate'));
    },
  },

  methods: {
    color(level) {
      switch (level) {
        case 'warn':
          return 'boo';
        case 'error':
          return 'red';
        default:
          return 'grey';
      }
    },
    openTime(time, gotoComment) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          timeId: time.id,
          ptms: this.ptms.filter((ptm) => ptm.id === time.ptm.id),
        },
      });
    },
  },
};
</script>
