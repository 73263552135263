<template>
  <v-dialog v-model="show" max-width="480" persistent retain-focus scrollable>
    <v-card :loading="loading">
      <v-card-title color="boo">
        <v-icon large left class="mr-5">
          {{ !isNew ? 'mdi-pencil-box-outline' : 'mdi-plus-box-outline' }}
        </v-icon>
        {{ !isNew ? 'Sphäre bearbeiten' : 'Neue Sphäre erstellen' }}
      </v-card-title>

      <v-divider />

      <v-card-text class="mt-3">
        <v-form
          ref="form"
          v-model="valid"
          class="px-3"
          lazy-validation
          @keyup.native.enter="submit"
          @keyup.native.escape="close"
        >
          <v-text-field
            v-model="domainEdit.name"
            label="Kürzel"
            :rules="nameRules"
          />
          <v-text-field
            v-model="domainEdit.description"
            label="Bezeichnung"
            :rules="descriptionRules"
          />
          <v-radio-group v-model="domainEdit.type" mandatory row>
            <v-radio label="Standard" :value="1" />
            <v-radio label="privat Sphäre" :value="2" />
          </v-radio-group>
          <v-textarea
            v-model="domainEdit.notice"
            label="Kommentar"
            rows="3"
            auto-grow
          />
        </v-form>
      </v-card-text>

      <bc-response-handler
        :axios-error="axiosError"
        :axios-success="axiosSuccess"
      />

      <bc-btn-cancel-action
        :valid="valid"
        :enable-action="dataDirty"
        :action-text="isNew ? 'erstellen' : 'speichern'"
        :action-running="storing"
        @evAction="submit"
        @evCancel="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DomainEdit',

  props: {
    show: {
      type: Boolean,
      deafult: false,
    },
    domain: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      storing: false,

      axiosError: null,
      axiosSuccess: null,

      domainEdit: {},

      nameRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => (v && v.length >= 3) || 'mindestens 3 Zeichen',
        (v) => (v && v.length <= 50) || 'maximal 50 Zeichen',
      ],
      descriptionRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => (v && v.length >= 3) || 'mindestens 3 Zeichen',
        (v) => (v && v.length <= 100) || 'maximal 100 Zeichen',
      ],
    };
  },

  computed: {
    isNew: function () {
      return !this.domain;
    },
    dataDirty: function () {
      return JSON.stringify(this.domain) !== JSON.stringify(this.domainEdit);
      // Problem: trim, andere Ordnung
      // Alternative wäre lodash
      // https://www.samanthaming.com/tidbits/33-how-to-compare-2-objects/
      // https://www.npmjs.com/package/vue-lodash
    },
  },

  watch: {
    show: function (shown) {
      if (shown && !this.isNew) {
        // console.log('DomainEdit > watch show ' + shown)

        // Daten übernehmen
        this.domainEdit = { ...this.domain }; // clonen (statt byRef zuweisen!) mittels "Spread syntax"
        // sicherheitshalber nachladen
        this.loadData();
      }
    },
  },

  created() {
    // console.log('DomainEdit created')
  },

  mounted() {
    // console.log('DomainEdit mounted')
  },

  methods: {
    loadData() {
      // refresh data
      this.loading = true;
      window.axios
        .get(this.$BC.LBO_API_URL + 'domains/' + this.domain.slug)
        .then((response) => {
          this.axiosSuccess = response;
          this.domainEdit = response.data.success;
          this.$emit('data', { ...this.domainEdit }); // clone übergeben!
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close: function () {
      this.$emit('close');
      // this.$refs.form.reset()
    },

    submit: function () {
      if (!this.$refs.form.validate()) return;

      this.storing = true;
      const transfer = {
        slug: this.isNew ? null : this.domain.slug,
        name: this.domainEdit.name,
        description: this.domainEdit.description,
        notice: this.domainEdit.notice,
        type: this.domainEdit.type,
        // user_id: this.isNew
        //   ? this.$store.state.auth.user.id // wird eh der user im BE genommen
        //   : this.domainEdit.user_id // wird so nie geändert
      };
      // console.log(transfer)
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$BC.LBO_API_URL +
            'domains' +
            (this.isNew ? '' : '/' + this.domain.slug),
          data: transfer,
        })
        .then((response) => {
          // console.log(response)
          this.axiosSuccess = response;
          const domain = response.data.success;
          this.$boo.reloadApiData();
          if (this.isNew) {
            // goto domain
            this.$router.push({
              name: 'domainView',
              params: {
                domainSlug: domain.slug,
                domain: domain,
              },
            });
          } else if (domain.slug !== this.domain.slug) {
            // slug wurde neu generiert
            this.$store.commit('app/SETDOMAIN', domain);
            // URL ändern
            this.$router.replace({
              name: 'domainView',
              params: {
                domainSlug: domain.slug,
              },
            });
            window.location.reload(false); // reload
            return;
          }
          this.$emitter.emit('domain');
          this.close();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
  },
};
</script>
