<template>
  <bv-frame
    headline="Meine Zeiten"
    subtitle="Überprüfe und bearbeite hier deine Projektarbeitszeiten."
    :new-button="myTimeablePTMs.length > 0"
    new-button-label="Neuer Zeiteintrag"
    :loading="$store.state.isLoading"
    @newButton="newTime"
    @refresh="refreshData()"
  >
    <v-card
      flat
      :class="`mb-5 ${isMobile ? 'transparent' : ''}`"
      :outlined="!isMobile"
    >
      <v-card-text class="pa-0 pa-sm-5">
        <times-data-table :items="listData.filtered" :headers="headers">
          <template v-slot:top>
            <Bc-filter
              v-model="listData"
              filterByDateRange
              filterByProjects
              filterByStatusLevels
              onlyMine
            />

            <div class="d-flex justify-space-between">
              <v-card-title
                v-if="listData.filtered.length > 1"
                class="body-1 pt-2"
              >
                <p>
                  <span class="font-weight-bold">
                    {{ $boo.formatDec(listData.filtered.length) }}
                  </span>
                  Einträge |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec(
                        $boo.sumArray(listData.filtered, 'duration') / 60
                      )
                    }}
                  </span>
                  Stunden {{ isFiltered ? '(gefiltert)' : '' }}
                </p>
              </v-card-title>

              <div class="self-center"></div>
            </div>
          </template>
        </times-data-table>
      </v-card-text>
    </v-card>
  </bv-frame>
</template>

<script>
import BvFrame from '@/views/Frame';
import BcTimeStatusChip from '@/views/time/TimeStatusChip';
import BcTimeRecording from '@/views/time/TimeRecording';
import store from '@/store';
import TimesDataTable from './TimesDataTable.vue';
import { createMyTimesHeaders } from './timeHeaders';
import BcFilter from '@/components/global/filter/BcFilter.vue';
import me from '@/me';

export default {
  name: 'MyTimes',

  components: {
    BcFilter,
    BvFrame,
    BcTimeStatusChip,
    BcTimeRecording,
    TimesDataTable,
  },

  props: {},

  data() {
    return {
      filteredList: [],
      filter: {
        persons: [store.state.auth.user.person.id],
        dateRange: [],
      },
      pauses: false,
    };
  },

  computed: {
    headers() {
      return createMyTimesHeaders(this.$vuetify.breakpoint);
    },
    listData: {
      get() {
        return {
          original: store.state.timesStore.times,
          filtered: this.filteredList,
          filter: this.filter,
        };
      },
      set(newValue) {
        this.filteredList = newValue.filtered;
        this.filter = newValue.filter;
      },
    },
    myTimeablePTMs() {
      return store.getters.data.timeablePTMs.filter((ptm) =>
        me.isMyPerson(ptm.person.id)
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    isFiltered() {
      return this.listData.original.length !== this.listData.filtered.length;
    },
  },

  watch: {},
  created() {
    this.refreshData();

    if (
      this.listData.original.filter(
        (time) => this.$dayjs(time.start).month === this.$dayjs().month
      ).length
    ) {
      // wenn es zumindest einen ZE im aktuellen Monat gibt, dann default auf dieses filtern
      this.filter.dateRange = [
        this.$dayjs().startOf('month').format('YYYY-MM-DD'),
        this.$dayjs().endOf('month').format('YYYY-MM-DD'),
      ];
    }
  },

  methods: {
    refreshData() {
      store.dispatch.timesStore.fetchAllTimes();
      store.dispatch.data.getPTMs();
    },

    setTime(time) {
      store.commit.data.setTime(time);
    },

    newTime() {
      // sicherheitshalber aktualisieren
      store.dispatch.data.getPTMs();
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          new: true,
          ptms: this.myTimeablePTMs,
        },
      });
    },
  },
};
</script>
