<template>
  <v-container fluid class="pa-0">
    <v-toolbar
      xv-if="isMobile"
      flat
      color="alabaster"
      class="gunmetal--text"
      width="100%"
      dense
    >
      <v-btn text class="pl-0" exact :to="$boo.domainRouteTo('persons')">
        <v-icon>mdi-chevron-left</v-icon>
        Personen
      </v-btn>
      <v-spacer />
      <v-menu
        v-if="
          !loading &&
          computedPersonContextMenu.filter((item) => item.visibility).length > 0
        "
        id="actions"
        offset-y
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn icon title="weitere Aktionen" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list one-line dense>
          <v-list-item
            v-for="(item, key) in computedPersonContextMenu"
            :key="key"
            v-if="item.visibility"
            @click="item.action"
          >
            <v-list-item-icon
              ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
            >
            {{ item.label }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card
      class="d-flex flex-wrap align-stretch"
      xcolor="grey lighten-2"
      flat
      min-height="90vh"
    >
      <v-card
        class="pa-2 flex"
        max-width="350px"
        :max-height="isMobile ? '240px' : '240px'"
        :min-height="isMobile ? '240px' : ''"
        :min-width="isMobile ? '100%' : ''"
        flat
        style="flex: 1"
      >
        <div align="center" class="xl-pb-5 py-5">
          <div>
            <bc-avatar
              :can-edit="canEdit || canEditOwnPerson"
              :pojo="person"
              :api-url="avatarAPIUrl"
              class=""
              :size="isMobile ? 100 : 150"
              no-tooltip
              @stored="reload"
            />
          </div>
          <div class="my-3">
            <span>{{ person.label }}</span>
            <span
              v-if="person.nickname && person.nickname != person.label"
              class="font-weight-light"
            >
              {{ `&nbsp;&ldquo;${person.nickname}&rdquo;` }}
            </span>
            <span v-if="person.birthday">
              {{ '&nbsp;(' + $dayjs().diff(person.birthday, 'years') + ')' }}
            </span>
            <p v-if="amI">(du selber)</p>
          </div>
          <div>
            <v-btn
              v-if="phonesSorted.length === 1"
              icon
              outlined
              small
              color="boo"
              class="mx-1"
              :href="'tel:' + phonesSorted[0].text"
            >
              <v-icon color="boo" small> mdi-phone </v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              outlined
              small
              color="boo"
              class="mx-1"
              @click="isMobile ? (tab = 1) : (tab = 0)"
            >
              <v-icon color="boo" small> mdi-phone-plus </v-icon>
            </v-btn>
            <v-btn
              v-if="mailsSorted.length === 1"
              icon
              outlined
              small
              color="boo"
              class="mx-1"
              :href="'mailto:' + mailsSorted[0].text"
            >
              <v-icon color="boo" small> mdi-email </v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              outlined
              small
              color="boo"
              class="mx-1"
              @click="isMobile ? (tab = 1) : (tab = 0)"
            >
              <v-icon color="boo" small> mdi-email-plus </v-icon>
            </v-btn>
          </div>
        </div>

        <v-card v-if="!isMobile" flat>
          <v-divider class="mt-5" />
          <bc-person-data
            :person="person"
            :loading="loading"
            @evUpdated="applyNewData"
          />
        </v-card>
      </v-card>

      <v-divider vertical />

      <v-card style="flex: 4" flat min-height="70vh">
        <v-tabs v-model="tab">
          <v-tab
            v-for="(tab, key) in computedTabs"
            :key="key"
            v-if="tab.visibility"
            >{{ tab.title }}</v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(tab, key) in computedTabs"
            :key="key"
            v-if="tab.visibility"
          >
            <v-component :is="tab.component" v-bind="tab.props" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
      />

      <bv-user-edit
        :show="showUserEdit"
        :person-label="person.label"
        :person="person"
        @evClose="showUserEdit = false"
        @evStored="userStored"
      />

      <bc-dialog-delete
        :show-dialog="showDialogPersonDelete"
        :title="`Personendaten von ${person.label} löschen`"
        :deleting="personDeleting"
        @evDelete="deletePerson"
        @evCancel="showDialogPersonDelete = false"
      >
        Soll dieser Personeneintrag und all die damit verknüpften Daten
        <b>endgültig gelöscht</b> werden?
      </bc-dialog-delete>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import BvPhoneContact from '@/views/crm/PhoneContact.vue';
import BvMailContact from '@/views/crm/MailContact.vue';
import BvUserEdit from '@/views/user/UserEdit.vue';
// import BcPersonActivities from '@/views/crm/person/PersonActivities'
import BcPersonData from '@/views/crm/person/PersonData.vue';
import BvUserShow from '@/views/user/UserShow.vue';
import BcTimeBillingStatusChip from '@/views/timebilling/TimeBillingStatusChip.vue';
import { defineComponent } from 'vue';
import {
  PersonType,
  UserType,
  TimeBillingType,
  PhoneType,
  EmailType,
  PTMType,
  ExternalDatasourceType,
} from '@/types/types';
import me from '@/me';
import {
  deletePersonRequest,
  getPersonFromSlugRequest,
  makePersonCustomerRequest,
  makePersonEmployeeRequest,
  removePersonCustomerStatusRequest,
  removePersonEmployeeStatusRequest,
} from '../personService';
import store from '@/store';
import TimebillingTab from './tabs/TimebillingTab.vue';
import BitrixUserDataTab from './tabs/BitrixUserDataTab.vue';
import ProjectTab from './tabs/ProjectTab.vue';
import UserTab from './tabs/UserTab.vue';
import ContactTab from './tabs/ContactTab.vue';
import PersonData from '@/views/crm/person/PersonData.vue';
import BitrixCRMDataTab from './tabs/BitrixCRMDataTab.vue';
import { canEditOwnPerson, canEditPerson } from '../helpers';

export default defineComponent({
  name: 'BvPersonShow',

  components: {
    BvPhoneContact,
    BvMailContact,
    BvUserShow,
    BvUserEdit,
    BcPersonData,
    BcTimeBillingStatusChip,
    TimebillingTab,
    BitrixUserDataTab,
  },

  props: {
    personSlug: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      person: {
        owner: {
          slug: '', // so initialisiert, sparen wir uns JS-Errors while loading
        },
      } as Partial<PersonType>,

      userslug: null as string | null,

      timebillings: [],
      selectedTimeBillings: [] as TimeBillingType[],

      loadingBillings: false,

      valid: false,
      loading: true,
      error: null,

      axiosSuccess: null,
      axiosError: null,

      showPersonPopup: false,

      showDialogPersonDelete: false,
      personDeleting: false,

      showUserEdit: false,

      gender: {
        m: { text: 'männlich', Anrede: 'Herr' },
        w: { text: 'weiblich', Anrede: 'Frau' },
        d: { text: 'divers', Anrede: '' },
      },

      hoveringFoto: false,

      fotofile: null,
      fotorules: [
        (v: any) => !v || v.size < 1024 * 1024 * 5 || 'Datei zu groß (>5MB)',
        (v: any) =>
          !v ||
          v.type === 'image/jpg' ||
          v.type === 'image/jpeg' ||
          v.type === 'image/png' ||
          'ungültiges Dateiformat',
      ],
      fotofileUrl: '',
      fotovalid: false,

      tab: null as null | number,
    };
  },

  computed: {
    computedPersonContextMenu(): {
      visibility: boolean;
      icon: string;
      label: string;
      action: Function;
    }[] {
      const menuItems = [
        {
          visibility: this.canEdit || this.canEditOwnPerson,
          icon: 'mdi-pencil',
          label: 'Stammdaten bearbeiten',
          action: () =>
            store.commit.contentDrawer.LOAD({
              component: 'bc-person-edit',
              data: {
                person: this.person,
                onSuccess: (person: PersonType) => (this.person = person),
              },
            }),
        },
        {
          visibility: store.getters.auth.userCan('user') && !this.isUser,
          icon: 'mdi-account-key-outline',
          label: 'als User aktivieren',
          action: () => (this.showUserEdit = true),
        },
        {
          visibility:
            !this.person.employee &&
            this.canEdit &&
            store.getters.auth.userCan('employee.create'),
          icon: 'mdi-briefcase-outline',
          label: 'zum Mitarbeiter machen',
          action: this.makeEmployee,
        },
        {
          visibility:
            this.person.employee &&
            this.canEdit &&
            store.getters.auth.userCan('employee.delete'),
          icon: 'mdi-briefcase-outline',
          label: 'als Mitarbeiter entfernen',
          action: this.removeEmployeeStatus,
        },
        {
          visibility:
            !this.person.customer &&
            this.canEdit &&
            store.getters.auth.userCan('customer.create'),
          icon: 'mdi-home-account',
          label: 'zum Kunden machen',
          action: this.makeCustomer,
        },
        {
          visibility:
            this.person.customer &&
            this.canEdit &&
            store.getters.auth.userCan('customer.delete'),
          icon: 'mdi-home-account',
          label: 'als Kunde entfernen',
          action: this.removeCustomerStatus,
        },
        {
          visibility: this.canDelete,
          icon: 'mdi-delete-outline',
          label: 'Person löschen',
          action: () => (this.showDialogPersonDelete = true),
        },
        {
          visibility:
            (Boolean(this.person.external_datasources?.length) &&
              store.getters.auth.userCan('bitrix.user.show.all')) ||
            store.getters.auth.userCan('bitrix.person.show.all'),
          icon: 'mdi-link-variant',
          label: 'Verknüpfungen',
          action: () =>
            store.commit.contentDrawer.LOAD({
              component: 'BcShowExternalConnectionsDrawer',
              data: {
                sources: this.person.external_datasources,
                slug: this.person.slug,
                onChange: (person: PersonType) => {
                  this.person = person;
                },
              },
            }),
        },
      ];

      return menuItems;
    },
    computedTabs(): any {
      const tabs = [
        {
          title: 'Daten',
          href: '#data',
          component: PersonData,
          visibility: this.isMobile,
          props: {
            person: this.person,
            loading: this.loading,
          },
        },
        {
          title: 'Kontakt',
          href: '#contact',
          component: ContactTab,
          visibility: true,
          props: {
            person: this.person,
            phonesSorted: this.phonesSorted,
            mailsSorted: this.mailsSorted,
            canEdit: this.canEdit || this.canEditOwnPerson,
            canContactAdvanced: this.canContactAdvanced,
            reload: this.reload,
            removePhone: this.removePhone,
            removeMail: this.removeMail,
          },
        },
        {
          title: 'Projekte',
          href: '#projects',
          component: ProjectTab,
          visibility:
            (me.can('project.show') || false) && this.ptmsSorted.length > 0,
          props: {
            ptms: this.ptmsSorted,
          },
        },
        {
          title: 'Abrechnungen',
          href: '#data',
          component: TimebillingTab,
          visibility:
            (me.can('project.show') || false) && this.ptmsSorted.length > 0,
          props: {
            ptms: this.ptmsSorted,
          },
        },
        {
          title: 'Bitrix24 User',
          href: '#bitrix',
          component: BitrixUserDataTab,
          visibility: Boolean(
            this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id === ExternalDatasourceType.BITRIX_USER
            )
          ),
          props: {
            user: this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id === ExternalDatasourceType.BITRIX_USER
            )?.data,
            source: this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id === ExternalDatasourceType.BITRIX_USER
            ),
          },
        },
        {
          title: 'Bitrix24 Kontakt',
          href: '#bitrixContact',
          component: BitrixCRMDataTab,
          visibility: Boolean(
            this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id ===
                ExternalDatasourceType.BITRIX_CRM_CONTACT
            )
          ),
          props: {
            crm: this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id ===
                ExternalDatasourceType.BITRIX_CRM_CONTACT
            )?.data,
            source: this.person.external_datasources?.find(
              (source) =>
                source.datasource_type_id ===
                ExternalDatasourceType.BITRIX_CRM_CONTACT
            ),
          },
        },
        {
          title: 'User',
          href: '#user',
          component: UserTab,
          visibility: Boolean(this.person.user?.slug),
          props: {
            person: this.person,
            userslug: this.userslug,
          },
        },
      ];

      return tabs;
    },

    phonesSorted(): PhoneType[] {
      if (!this.person.phones) return [];
      return this.$sortArray(this.person.phones, {
        by: ['deactivated', 'label'],
      });
    },
    mailsSorted(): EmailType[] {
      if (!this.person.emailcontacts) return [];
      return this.$sortArray(this.person.emailcontacts, {
        by: ['deactivated', 'label'],
      });
    },
    canEdit(): boolean {
      return canEditPerson(this.person);
    },
    canEditOwnPerson(): boolean {
      return canEditOwnPerson(this.person);
    },
    canDelete(): boolean {
      return (
        (me.owns(this.person) &&
          this.$store.getters[this.$storenames.auth.getters.userCan](
            'person.delete'
          )) ||
        this.$store.getters[this.$storenames.auth.getters.userCan](
          'person.delete.all'
        )
      );
    },
    canContactAdvanced(): boolean {
      //@ts-ignore
      return this.$store.getters[this.$storenames.auth.getters.userCan](
        'contact.advanced'
      );
    },
    isUser(): boolean {
      return Boolean(this.person.user?.slug);
    },
    // ownershipChangeable () {
    //   if (!this.$store.getters[this.$storenames.auth.getters.userCan]('person.update')) return false

    //   return true
    // },
    amI(): any {
      return (
        this.person.id &&
        this.person.id === this.$store.state.auth.user.person.id
      );
    },
    avatarAPIUrl(): string {
      return 'persons/' + this.person.slug;
    },
    transfer(): any {
      return this.$store.state.transfer;
    },
    isMobile(): boolean {
      //@ts-ignore
      return this.$vuetify.breakpoint.name === 'xs';
    },
    ptmsSorted(): PTMType[] {
      if (!this.person.project_memberships) return []; // not loaded yet
      return this.$sortArray(this.person.project_memberships, {
        // by: ['project.label'], // funktioniert so nicht!
        // ==> https://www.npmjs.com/package/sort-array
        by: ['label'],
        computed: {
          label: (ptm) => ptm.project.label.toLowerCase(),
        },
        // order: 'desc'
      });
    },
  },

  created() {
    if (this.$route.params.person) {
      this.person = this.$route.params.person as unknown as PersonType;
    }

    this.loadData();
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    PersonPopupClosed() {
      this.showPersonPopup = false;
    },
    applyNewData(person: PersonType) {
      this.person = person;
    },
    reload() {
      this.loadData();
    },
    removePhone(phoneId: string) {
      this.person.phones = this.person.phones?.filter(
        (phone) => phone.id !== phoneId
      );
    },
    removeMail(mailId: string) {
      this.person.emailcontacts = this.person.emailcontacts?.filter(
        (emailcontact) => emailcontact.id !== mailId
      );
    },
    async loadData() {
      this.loading = true;
      this.error = null;
      this.valid = false;

      try {
        const person = await getPersonFromSlugRequest({
          slug: this.personSlug,
        });

        this.person = person;
      } catch (error: any) {
        if (error.response.status === 404) {
          this.$router.replace({ name: 'persons' });
          return;
        }
        this.axiosError = error;
      } finally {
        this.loading = false;
      }
    },

    async deletePerson() {
      this.personDeleting = true;

      try {
        await deletePersonRequest(this.personSlug);
        //TODO: funktioniert die überhaupt noch??
        store.commit.app.SETSNACKBAR({
          text: `Personendaten von ${this.person.label} gelöscht`,
        });

        this.$router.replace({ name: 'persons' });
      } catch (error: any) {
        this.axiosError = error;
      } finally {
        this.personDeleting = false;
        this.showDialogPersonDelete = false;
      }
    },
    async makeCustomer() {
      try {
        const person = await makePersonCustomerRequest(this.personSlug);
        this.person = person;
      } catch (error: any) {
        this.axiosError = error;
      }
    },
    async makeEmployee() {
      try {
        const person = await makePersonEmployeeRequest(this.personSlug);
        this.person = person;
      } catch (error: any) {
        this.axiosError = error;
      }
    },
    async removeCustomerStatus() {
      try {
        await removePersonCustomerStatusRequest(this.personSlug);
        this.person.customer = null;
      } catch (error: any) {
        this.axiosError = error;
      }
    },
    async removeEmployeeStatus() {
      try {
        await removePersonEmployeeStatusRequest(this.personSlug);
        this.person.employee = false;
      } catch (error: any) {
        this.axiosError = error;
      }
    },
    userStored(user: UserType) {
      this.showUserEdit = false;
      this.userslug = user.slug;
    },
    setOwner(person: PersonType) {
      console.log('setOwner');
      this.person = person;
      if (
        !this.$me.owns(this.person) &&
        !this.$store.getters[this.$storenames.auth.getters.userCan](
          'person.show.all'
        )
      ) {
        this.$router.replace('persons');
      }
    },
  },
});
</script>
