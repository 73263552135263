<template>
  <v-app-bar tag="div" fixed :bottom="bottom">
    <slot name="actionLeft" />
    <v-toolbar-title class="flex text-center" v-if="!bottom" width="100%">
      <slot name="title" />
    </v-toolbar-title>
    <slot name="actionRight" />
    <template v-slot:extension v-if="hasExtensionSlot">
      <slot name="extensionContent" />
    </template>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    bottom: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasExtensionSlot() {
      return !!this.$slots.extensionContent;
    },
  },
};
</script>
