import boo from '@/boo';
import { PersonType } from '@/types/types';
import axios from 'axios';

export const createPersonFromExternalRequest = async ({
  url,
}: {
  url: string;
}): Promise<PersonType> => {
  const response = await axios.post(`${boo.lboUrl()}persons/fromExternal`, {
    url,
  });

  return response.data.success;
};

export const removeExternalDataSourceRequest = async ({
  slug,
  dataSourceId,
}: {
  slug: string;
  dataSourceId: number;
}) => {
  const response = await axios.delete(
    `${boo.lboUrl()}persons/${slug}/externalDatasources`,
    {
      data: {
        datasource_id: dataSourceId,
      },
    }
  );

  return response.data.success;
};

export const addExternalDataSourceURLRequest = async ({
  slug,
  url,
}: {
  slug: string;
  url: string;
}) => {
  const response = await axios.patch(
    `${boo.lboUrl()}persons/${slug}/externalDatasourcesURL`,
    {
      url,
    }
  );

  return response.data.success;
};

export const getPersonFromSlugRequest = async ({
  slug,
}: {
  slug: string;
}): Promise<PersonType> => {
  const response = await axios.get(`${boo.lboUrl()}persons/${slug}`);
  return response.data.success;
};

export const deletePersonRequest = async (slug: string) => {
  const response = await axios.delete(`${boo.lboUrl()}persons/${slug}`);
  return response.data.success;
};

export const makePersonCustomerRequest = async (slug: string) => {
  const response = await axios.post(`${boo.lboUrl()}persons/${slug}/customer`);
  return response.data.success;
};

export const makePersonEmployeeRequest = async (slug: string) => {
  const response = await axios.post(`${boo.lboUrl()}persons/${slug}/employee`);
  return response.data.success;
};

export const removePersonCustomerStatusRequest = async (slug: string) => {
  const response = await axios.delete(
    `${boo.lboUrl()}persons/${slug}/customer`
  );
  return response.data.success;
};

export const removePersonEmployeeStatusRequest = async (slug: string) => {
  const response = await axios.delete(
    `${boo.lboUrl()}persons/${slug}/employee`
  );
  return response.data.success;
};
