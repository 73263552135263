<template>
  <v-card flat>
    <bc-btn-cancel-action
      v-if="fileIsSelected"
      :valid="fileIsSelected && valid"
      enable-action
      :action-running="storing"
      action-text="Hochladen"
      :header="true"
      @evAction="upload"
      @evCancel="cancel"
    />

    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="$store.dispatch($storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>

    <v-card-subtitle>Bitte wähle ein Bild zum Hochladen aus:</v-card-subtitle>
    <v-card-text>
      <v-form id="Upload" ref="form" v-model="valid" class="px-4">
        <div class="d-flex align-center">
          <v-file-input
            v-model="file"
            @change="generatePreviewUrl"
            type="file"
            :rules="rules"
            :accept="filetypes"
            :placeholder="title + ' hinzufügen'"
            :prepend-icon="icon"
            label=""
            show-size
            dense
            class="caption"
          />
        </div>
      </v-form>
    </v-card-text>

    <v-img :src="previewUrl" class="ma-6" />

    <v-card-text v-if="$boo.envIsDev()" class="blue">
      file selected? {{ fileIsSelected }}
      <div v-if="fileIsSelected">
        <br />type: {{ file.type }} <br />size: {{ file.size }} <br />typeOk:
        {{ filetypes.includes(file.type) }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BcUploadDialog',

  props: {
    data: {
      type: Object,
      default: function () {
        return {
          // nur Doku! Nachdem alles mit data überschrieben wird, müssen defaults in created() gesetzt werden
          image: true, // Bild upload
          pdf: false, // pdf upload
          filesize: 5, // erlaubte Größe in MB
          apiUrl: '', // URL-Segment
        };
      },
    },
  },

  data() {
    return {
      file: {}, // File object

      previewUrl: null,

      valid: false,

      storing: false,

      pojo: {
        image_path: '',
        initial: 'B',
      },
    };
  },

  created() {
    // defaults
    if (!this.data.filesize) this.data.filesize = 5;
  },

  computed: {
    icon() {
      if (this.data.image) return 'mdi-camera';
      if (this.data.pdf) return 'mdi-file-pdf-box';
      return 'mdi-paperclip';
    },
    rules() {
      return [
        (v) =>
          !this.fileIsSelected ||
          v.size < this.data.filesize * 1024 * 1024 ||
          this.title + ' zu groß (max. ' + this.data.filesize + ' MB)',
        (v) =>
          !this.fileIsSelected ||
          this.filetypes.includes(v.type) ||
          'ungültiges Dateiformat',
      ];
    },
    title() {
      if (this.data.image) return 'Bild';
      if (this.data.pdf) return 'Dokument';
      return 'Datei';
    },
    filetypes() {
      if (this.data.image)
        return [
          'image/png',
          'image/jpeg',
          'image/bmp',
          'image/jpg',
          'image/gif',
        ];
      if (this.data.pdf) return ['.pdf'];
      return [];
    },
    filetype() {
      return this.filetypes.join(', ');
    },
    fileIsSelected() {
      return this.file instanceof File;
    },
  },

  watch: {
    fileIsSelected: function () {
      this.$store.commit('contentDrawer/LOCK', this.fileIsSelected);
    },
  },

  methods: {
    generatePreviewUrl() {
      this.previewUrl = this.file ? URL.createObjectURL(this.file) : null;
    },
    upload() {
      this.storing = true;
      const formData = new FormData();
      formData.append('file', this.file);

      window.axios
        .post(this.$boo.lboUrl() + this.data.apiUrl + '/setAvatar', formData)
        .then((response) => {
          const pojo = response.data.success;
          this.$store.commit('app/SETSNACKBAR', {
            text: this.title + ' erfolgreich gespeichert',
          });
          this.$emitter.emit('uploaded');
          this.cancel();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.file = null;
          this.storing = false;
        });
    },
    cancel() {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
  },
};
</script>
