import boo from '@/boo';
import store from '@/store';
import { TimeType } from '@/types/types';
import { Breakpoint } from 'vuetify/types/services/breakpoint';

const uniqueProjects = (times: TimeType[]) => {
  if (times.length === 0) return [];
  return boo.uniqueObjects(times.map((time) => time.ptm.project));
};

const uniquePersons = (times: TimeType[]) => {
  if (times.length === 0) return [];
  return boo.uniqueObjects(times.map((time) => time.ptm.person));
};

export const createMyTimesHeaders = (breakpoint: Breakpoint) => {
  const times = store.state.timesStore.times;

  return [
    { text: 'Datum', value: 'date', visible: true, sortable: false },
    {
      text: breakpoint.mobile ? 'Projekt' : 'Projekt / Rolle',
      value: 'ptm.project',
      visible: uniqueProjects(times).length >= 2,
    },
    {
      text: 'Beginn',
      value: 'start',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'Ende',
      value: 'stop',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'von/bis',
      value: 'startstop',
      visible: breakpoint.smAndDown,
    },
    { text: 'Stunden', align: 'center', value: 'duration', visible: true },
    {
      text: 'Status',
      value: 'statusSwitch',
      visible: true,
    },
    {
      text: '',
      value: 'comment_count',
      visible: breakpoint.mdAndUp,
    },
  ];
};

export const createTimesHeader = (breakpoint: Breakpoint) => {
  const times = store.state.timesStore.times;

  return [
    { text: 'Datum', value: 'date', visible: true, sortable: false },
    // hidden wenn nur 1 project
    {
      text: breakpoint.mobile ? 'Person' : 'Person / Rolle',
      value: 'person',
      visible: uniquePersons(times).length >= 2,
    },

    {
      text: breakpoint.mobile ? 'Projekt' : 'Projekt / Rolle',
      value: 'ptm.project',
      visible: uniqueProjects(times).length >= 2,
    },

    {
      text: 'Beginn',
      value: 'start',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'Ende',
      value: 'stop',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'von/bis',
      value: 'startstop',
      visible: breakpoint.smAndDown,
    },

    {
      text: 'Pause',
      align: 'center',
      value: 'pause',
      visible: breakpoint.mdAndUp,
    },
    { text: 'Stunden', align: 'center', value: 'duration', visible: true },

    { text: 'Status', value: 'statusSwitch', visible: true },

    {
      text: '',
      value: 'comment_count',
      visible: breakpoint.mdAndUp,
    },
  ];
};

export const createTimesHeaderForTimebilling = (breakpoint: Breakpoint) => {
  return [
    { text: 'Datum', value: 'date', visible: true, sortable: false },

    {
      text: 'Beginn',
      value: 'start',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'Ende',
      value: 'stop',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'von/bis',
      value: 'startstop',
      visible: breakpoint.smAndDown,
    },

    // hidden wenn überall 0

    { text: 'Stunden', align: 'center', value: 'duration', visible: true },

    { text: 'Status', value: 'status' },
    {
      text: 'Status',
      value: 'statusSwitch',
      visible: true,
    },
    {
      text: '',
      value: 'comment_count',
      visible: breakpoint.mdAndUp,
    },
  ];
};

export const createTimesHeaderForDashboardList = (breakpoint: Breakpoint) => {
  return [
    { text: 'Datum', value: 'date', sortable: false, visible: true },
    { text: 'Stunden', value: 'duration', sortable: false, visible: true },
    { text: 'Status', value: 'status', sortable: false, visible: true },
  ];
};
