<template>
  <bc-widget-frame
    v-if="$store.state.auth.attributes[$store.state.app.domain.slug].canMyTimes"
    title="Stundenübersicht"
    :subtitle="`Summen deiner letzten ${weekCount} Wochen`"
    icon="mdi-clock"
  >
    <template v-slot:action>
      <v-btn @click="loadTimes" icon :loading="loading"
        ><v-icon>mdi-refresh</v-icon></v-btn
      >
    </template>

    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <bc-bar-chart v-if="yaxis" :yaxis="yaxis" :xaxis="xaxis" stacked />
  </bc-widget-frame>
</template>
<script>
export default {
  data() {
    return {
      axiosSuccess: null,
      axiosError: null,
      today: this.$dayjs(),
      weekCount: 4,
      startOfWeek: this.$dayjs().startOf('week').add(1, 'day'),
      times: null,
      loading: false,

      yaxis: null,
    };
  },
  computed: {
    range() {
      return this.$boo.getPreviousWeeks(this.$dayjs(), this.weekCount);
    },
    xaxis() {
      let axis = this.range.map((week) => {
        return `KW ${week.cw}`;
      });
      axis.length > 0 ? (axis[axis.length - 1] = 'Diese Woche') : '';
      return axis;
    },
    allProjects() {
      if (this.times) {
        return this.$boo.uniqueObjects(this.times.map((o) => o.ptm.project));
      }
      return false;
    },
  },
  methods: {
    loadTimes() {
      this.loading = true;
      this.yaxis = null;
      window.axios
        .get(this.$boo.lboUrl() + 'times/list', {
          params: {
            onlyMine: true,
            recording: false,
          },
        })
        .then((response) => {
          this.axiosSuccess = response;
          // this.times = response.data.success
          this.$set(this, 'times', response.data.success);
          this.statusOptions = response.data.options.status;
          this.levelOptions = response.data.options.statuslevel;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
          this.calcYaxis();
        });
    },
    sum(items, prop) {
      // return prop
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    calcYaxis() {
      let data = [];

      if (this.allProjects) {
        this.allProjects.forEach((project) => {
          let entry = {};
          entry.name = project.label;
          entry.data = [];
          this.range.forEach((week) => {
            let filtered = this.times.filter((item) => {
              return (
                this.$dayjs(item.start) >= this.$dayjs(week.start) &&
                this.$dayjs(item.start) <= this.$dayjs(week.end) &&
                item.ptm.project.id === project.id
              );
            });
            entry.data.push((this.sum(filtered, 'duration') / 60).toFixed(2));
          });
          data.push(entry);
        });
      }
      this.yaxis = [...data];
    },
  },
  created() {
    this.loadTimes();
  },
};
</script>
