<script lang="ts">
import { defineComponent, PropType } from 'vue';
import TagIconChip from './TagIconChip.vue';
import { assignTagsRequest, getTagsRequest } from './tagService';
import { Tag, taggableType } from './tagTypes';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'TagSelector',
  props: {
    taggable: {
      type: Object,
      default: () => {
        return {
          tags: [],
        };
      },
    },
    taggableType: {
      type: String as PropType<keyof typeof taggableType>,
      default: undefined,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {
        console.log('onSuccess not defined');
      },
    },
    onError: {
      type: Function,
      default: () => {
        console.log('onError not defined');
      },
    },
  },
  data() {
    return {
      tagSelectorIsOpen: false,
      availableTags: [] as Tag[],
      currentTags: [] as Tag[],
      selectedTags: [] as Tag['id'][],
      tagSearch: '',
    };
  },
  computed: {
    showComponentAs() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 'VDialog';
      } else {
        return 'VMenu';
      }
    },
  },
  async created() {
    this.availableTags = await getTagsRequest();
    this.currentTags = this.taggable.tags || [];
    this.selectedTags = this.taggable.tags?.map((tag: Tag) => tag.id);
  },
  methods: {
    async saveTags() {
      try {
        const response = await assignTagsRequest({
          tag_ids: this.selectedTags,
          taggableType: this.taggableType,
          taggable_slug: this.taggable.slug,
        });

        this.currentTags = response.tags;
        this.taggable.tags = response.tags;
        this.tagSelectorIsOpen = false;
        this.onSuccess?.();
      } catch (e) {
        console.log(e);
        this.onError?.();
      }
    },
  },
  components: { TagIconChip },
});
</script>

<template>
  <v-component
    :key="`tagSelector-${canEdit}`"
    :is="showComponentAs"
    :close-on-content-click="false"
    v-model="tagSelectorIsOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="canEdit" color="primary" dark v-bind="attrs" v-on="on" text
        ><v-icon left>mdi-tag</v-icon> Tags hinzufügen
      </v-btn>

      <div v-bind="attrs" v-on="canEdit ? on : undefined">
        <TagIconChip v-for="tag in currentTags" :tag="tag" :key="tag.id" />
        <span class="text-caption" v-if="currentTags.length === 0"
          >Keine Tags</span
        >
      </div>
    </template>

    <v-card>
      <v-card-title v-if="availableTags.length > 7">
        <v-text-field
          v-model="tagSearch"
          append-icon="mdi-magnify"
          label="Tags suchen"
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-list dense>
        <v-list-item
          v-for="tag in availableTags.filter((tag) =>
            tag.name.includes(tagSearch)
          )"
          :key="tag.id"
          class="pt-0"
        >
          <v-list-item-action class="mr-3">
            <!-- @vue-expect-error -->
            <v-checkbox
              color="primary"
              v-model="selectedTags"
              :label="tag.name"
              :value="tag.id"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="saveTags">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-component>
</template>
