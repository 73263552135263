<template>
  <v-app id="BOO gui">
    <v-dialog
      :value="$store.state.auth.userActive"
      persistent
      max-width="290"
      class="ma-5"
    >
      <v-card>
        <v-card-title>Zugang deaktiviert</v-card-title>
        <v-card-text>
          Dev-LuJ überlegt noch, ob es sinnvoll ist, dass sich ein deaktivierter
          User überhaupt anmelden kann...
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbar.timeout"
      right
      bottom
      :color="snackbar.color"
      dense
    >
      <span>{{ snackbar.text }}</span>
      <v-spacer />
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="hideSnackbar">
          <v-icon small> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-app-bar
      v-if="UserLoggedIn"
      app
      clipped-left
      elevate-on-scroll
      class="pr-1 gunmetal"
    >
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        color="white"
        @click.stop="showDrawer = !showDrawer"
      />
      <v-spacer class="hidden-sm-and-up" />
      <!-- <v-spacer class="hidden-sm-and-down" /> -->
      <!-- <v-text-field
        full-width
        background-color="white"
        outlined
        dense
        rounded
        prepend-inner-icon="mdi-magnify"
        hide-details
        autocomplete="off"
        placeholder="Suche"
      /> -->
      <v-img
        :src="require('../public/img/boo-logo.svg')"
        class="ml-12"
        style="cursor: pointer"
        height="70"
        max-width="80"
        contain
        @click="goHome"
      />
      <span class="white--text mt-5 ml-1">{{ booSubtitle }}</span>

      <v-spacer />
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp && $me.isActive()"
        icon
        @click="refreshButtonAction"
        :loading="refreshButtonIsLoading"
      >
        <v-icon color="white">mdi-refresh</v-icon>
      </v-btn>

      <v-btn
        data-cy="open-notifications-button"
        v-if="$me.isActive()"
        icon
        @click="openNotificationCenter"
      >
        <v-badge
          color="boo"
          :value="unreadNotificationCount"
          offset-y="10"
          :content="unreadNotificationCount"
        >
          <v-icon color="white"> mdi-bell </v-icon>
        </v-badge>
      </v-btn>

      <!--  closeOnClick closeOnContentClick       -->
      <v-menu
        data-cy="user_menu"
        id="user_menu"
        class="caption text-right float-right"
        open-on-click
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn data-cy="user_menu_activator" class="" icon large v-on="on">
            <bc-avatar :pojo="domain" :size="32" />
            <!-- <v-icon>mdi-menu-down</v-icon> -->
          </v-btn>
        </template>

        <v-list subheader dense class="pb-0">
          <v-list-item two-line>
            <v-list-item-avatar size="50">
              <bc-avatar :pojo="user.person" :size="50" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-1 font-weight-bold">
                {{ user.username }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              <!-- <v-list-item-subtitle>zuletzt aktiv {{ $dayjs().to($dayjs(user.lastaction_at)) }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="grey lighten-4" />

        <v-list dense class="domainList">
          <v-subheader>Deine Sphäre{{ domCount > 1 ? 'n' : '' }}</v-subheader>
          <v-list-item
            v-for="d in domains"
            :key="d.id"
            two-line
            :disabled="domCount == 1"
            @click="switchDomain(d)"
          >
            <v-list-item-avatar>
              <bc-avatar :pojo="d" :size="40" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                {{ d.name }}
                <bc-domain-icon :domain="d" />
              </v-list-item-title>
              <v-list-item-subtitle>{{ d.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item disabled>
            <v-list-item-icon class="mr-6">
              <v-icon class="ml-2" disabled> mdi-plus </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title disabled="!userCan('domain.edit')">
                Neue Sphäre erstellen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="grey lighten-4" />

        <v-list dense>
          <v-subheader>Deine Einstellungen</v-subheader>

          <!-- <div
            v-if="false"
            id="userSection"
          >
            <v-subheader>{{ user.username }}</v-subheader>
            <v-list-item>
              <v-list-item-avatar>
                <v-img src="http://lbo.local/upload/images/luj1.jpg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>meine Seite</v-list-item-title>
                <v-list-item-subtitle>noch nicht aktiviert...</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div> -->
          <v-list-item @click="toggleTheme">
            <v-list-item-title
              >Anzeige
              {{ $vuetify.theme.dark ? 'hell' : 'dunkel' }}</v-list-item-title
            >
            <v-list-item-avatar>
              <v-icon>{{
                $vuetify.theme.dark
                  ? 'mdi-weather-sunny'
                  : 'mdi-moon-waning-crescent'
              }}</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item
            v-if="$store.state.auth.user.pwset"
            link
            to="/password/reset"
          >
            <v-list-item-title>Passwort ändern</v-list-item-title>
            <v-list-item-avatar>
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item data-cy="logout-button" @click="logout">
            <v-list-item-title>{{ user.username }} abmelden</v-list-item-title>
            <v-list-item-avatar>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <bc-user-feedback-form-dialog class="mx-5 pt-5" />
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="UserLoggedIn && hasMenu && $me.isActive()"
      ref="drawer"
      v-model="showDrawer"
      app
      clipped
      width="220"
      mini-variant-width="50"
      :mini-variant="DrawerMini"
      :expand-on-hover="DrawerMini"
      class="gunmetal"
      dark
      elevation="1"
      hide-overlay
    >
      <template v-slot:prepend>
        <v-layout v-if="false" justify-center>
          <div v-if="true" class="title">
            <span class="font-weight-bold">BackOffice</span>
            <span class="font-weight-light">Online</span>
          </div>
          <div v-else>
            <span class="font-weight-bold">LBO</span>
          </div>
        </v-layout>

        <v-layout class="hidden-sm-and-up" justify-center>
          <v-img
            :src="require('../public/img/boo-brand.png')"
            class="my-3"
            style="cursor: pointer"
            contain
            height="80"
            @click="goHome"
          />
        </v-layout>
      </template>

      <v-list>
        <v-list-item
          v-if="$store.state.auth.attributes.canSeeDomains"
          link
          to="/domains"
        >
          <v-list-item-icon>
            <v-icon>mdi-buffer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sphären</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="domainSet"
          link
          :to="$boo.domainRouteTo('dashboard')"
        >
          <v-list-item-icon size="24" class="">
            <bc-avatar :pojo="domain" :size="25" :left="true" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="domainSet && userCan('tags.all')"
          link
          :to="$boo.domainRouteTo('tagsView')"
        >
          <v-list-item-icon size="24" class="">
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="userCan('person.show')"
          link
          :to="$boo.domainRouteTo('persons')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Personen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="userCan('organization.show')"
          link
          :to="$boo.domainRouteTo('organizations')"
        >
          <v-list-item-icon>
            <v-icon>mdi-city</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Organisationen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="userCan('customer.show')"
          link
          :to="$boo.domainRouteTo('clients')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kunden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="userCan('project.show')"
          link
          :to="$boo.domainRouteTo('projects')"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projekte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="timeMenu.length === 1"
          link
          :to="$boo.domainRouteTo(timeMenu[0].linkToRoute)"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zeiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-if="timeMenu.length > 1" no-action :value="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Zeiten</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in timeMenu"
            :key="item.key"
            link
            :to="$boo.domainRouteTo(item.linkToRoute)"
            dense
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="$boo.envIsDev()"
          class="blue"
          link
          :to="{ name: 'dev_test' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-atom</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dev-Test</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$boo.envIsDev() && $boo.userIsAdmin()"
          class="blue"
          link
          :to="{ name: 'ben' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-atom</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dev-Ben</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append justify-center mb-3>
        <v-list>
          <BooNextListItem v-if="canUseBoo3" />
        </v-list>

        <v-layout v-if="false" justify-start float-left>
          <v-btn icon x-small text @click="toggleTheme">
            <v-icon small> mdi-eye </v-icon>
          </v-btn>
        </v-layout>

        <v-layout v-if="false" justify-end>
          <v-btn
            icon
            text
            class="hidden-sm-and-down"
            @click="DrawerMini = !DrawerMini"
          >
            <v-icon small>
              {{ DrawerMini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
            </v-icon>
          </v-btn>
        </v-layout>

        <div class="caption text-center grey--text mb-5">
          <span :title="`boobe v${$store.state.app.boobeVersion}`">
            v{{ $BC.VERSION }}
          </span>
          &nbsp;© boo {{ new Date().getFullYear() }}
          <br />
          <v-btn
            v-if="false"
            :href="$BC.LBO_URL"
            target="_blank"
            text
            class="caption text-center grey--text"
          >
            BOOBe
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="ma-0">
      <div
        v-if="versionCompatible === false"
        id="checkVersion"
        class="cdIncluded"
      >
        <v-tooltip bottom color="blue" :disabled="!$boo.envIsDev()">
          <template v-slot:activator="{ on, attrs }">
            <v-alert
              class="text-center mb-0"
              dense
              type="info"
              color="boo"
              :dismissible="$boo.envIsDev()"
              v-bind="attrs"
              v-on="on"
            >
              <span style="cursor: pointer" @click="reloadPage">
                Wir haben boo verbessert - um die neue Version zu laden, bitte
                hier klicken.
              </span>
            </v-alert>
          </template>
          boo: <b>{{ $BC.VERSION }}</b> vs. expected:
          <b>{{ $store.state.app.expectedVersion }}</b> <br />{{
            versionCompatible
              ? 'Versionen sind kompatibel'
              : versionCompatible === false
              ? 'Versionskonflikt erkannt!'
              : 'Version unbekannt'
          }}
          <br />(Dieser Tooltip und dismissible nur in DEV)
        </v-tooltip>
      </div>
      <v-progress-linear :active="$store.state.data.isLoading" indeterminate />
      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
      />
      <router-view v-if="!loading" />
    </v-main>
    <bc-content-drawer />
    <v-footer v-if="$vuetify.breakpoint.mobile" class="mt-12 caption">
      <span :title="`boobe v${$store.state.app.boobeVersion}`">
        v{{ $BC.VERSION }}
      </span>
      &nbsp;© boo {{ new Date().getFullYear() }}
    </v-footer>
    <bc-maintenance-dialog />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { mapGetters } from 'vuex';
//@ts-ignore // vue-vpreferences is not typed..
import { preference } from 'vue-preferences';
import { DomainType } from './types/types';
import store from './store';
import BcMaintenanceDialog from '@/components/BcMaintenanceDialog.vue';
import boo from './boo';
import BooNextListItem from './components/BooNextListItem.vue';

export default defineComponent({
  name: 'BooApp',
  components: { BcMaintenanceDialog, BooNextListItem },

  props: {
    source: { type: String, default: '' },
  },

  data() {
    return {
      showDrawer: false,
      DrawerMini: false,

      loading: false,
      notifications: [],

      axiosSuccess: {},
      axiosError: {},
    };
  },

  computed: {
    ...mapGetters({
      userCan: 'auth/userCan',
    }),

    darkmode: preference('app.darkmode', { defaultValue: true }),

    showSnackbar: {
      get() {
        return store.state.app.snackbar.show;
      },
      set() {
        store.commit.app.HIDESNACKBAR();
      },
    },

    snackbar(): any {
      return this.$store.state.app.snackbar;
    },
    user(): any {
      return this.$store.state.auth.user;
    },
    domains(): any {
      return this.$store.state.auth.domains;
    },
    domCount(): any {
      return this.$boo.objCount(this.domains);
    },
    domain(): any {
      return this.$store.state.app.domain;
    },
    domainSet(): any {
      return !this.$boo.objIsEmpty(this.domain);
    },
    UserLoggedIn(): any {
      if (this.user === null) {
        return false;
      }
      return this.user.slug !== null;
    },
    avatar(): any {
      return {
        color: 'tuscany',
        abbr: this.$boo.objIsEmpty(this.domain)
          ? '?'
          : this.domain.name.substring(0, 1),
      };
    },
    // canMenuTime () {
    //   return this.$store.getters.auth.userCan('time') || this.canMenuTimeMyTimes
    // },
    canMenuTimeMyTimes(): any {
      try {
        return this.$store.state.auth.attributes[this.domain.slug].canMyTimes;
        // || this.$me.isSysadmin()
      } catch {
        return false;
      }
    },
    canMenuTimeAllTimes(): any {
      try {
        return this.$store.state.auth.attributes[this.domain.slug].canAllTimes;
        // || this.$me.isSysadmin()
      } catch {
        return false;
      }
    },
    timeMenu(): any {
      let tm = [];
      if (this.canMenuTimeMyTimes)
        tm.push({ label: 'Meine Zeiten', linkToRoute: 'mytimes' });
      if (this.canMenuTimeAllTimes)
        tm.push({ label: 'Alle Zeiten', linkToRoute: 'alltimes' });
      if (this.canMenuTimeMyTimes || this.canMenuTimeAllTimes)
        tm.push({ label: 'Abrechnungen', linkToRoute: 'timebilling' });
      // if (this.$store.getters.auth.userCan("time.admin"))
      if (this.userCan('time.admin'))
        tm.push({ label: 'ZEM', linkToRoute: 'timerecmodels' });
      return tm;
    },
    // ob Menü-Drawer angezeigt werden soll
    // @@@ noch nicht perfekt: sollte automatisch erkennen, dass >1 Auswahl möglich ist; sonst diese automatisch wählen oder so...
    hasMenu(): any {
      return (
        // this.$store.auth.getters[this.$storenames.auth.getters.userCan]("domain.show") ||
        // ... ist nicht möglich ...
        // this.$store.getters[this.$storenames.auth.getters.userCan]("domain.show") ||
        // ... ist grauslich ... aber
        this.userCan('domain.show') ||
        // ... ist durch computed mapGetter möglich
        this.userCan('person.show') ||
        this.userCan('organization.show') ||
        this.userCan('customer.show') ||
        this.userCan('project.show') ||
        this.timeMenu.length
      );
    },
    booSubtitle(): any {
      if (this.$boo.envIsDev())
        return this.$vuetify.breakpoint.xs
          ? 'dev'
          : `dev as ${this.user.username}`;

      if (this.$boo.envIsTest())
        return this.$vuetify.breakpoint.xs
          ? 'testing'
          : `testing as ${this.user.username}`;

      if (this.$boo.envIsStaging())
        return this.$vuetify.breakpoint.xs
          ? 'on stage'
          : `on stage as ${this.user.username}`;

      return ''; // Prod
    },
    unreadNotificationCount(): any {
      return this.$store.state.data.notifications.filter(
        (n: { read: any }) => !n.read
      ).length;
    },
    // contentDrawerComponent() {
    //   return this.$store.state.contentDrawer.component;
    // },
    versionCompatible(): any {
      // console.log(this.$BC.VERSION)
      // console.log(this.$store.auth.version)
      if (!this.$store.state.app.expectedVersion || this.$BC.VERSION === null)
        return null;

      return this.$BC.VERSION === this.$store.state.app.expectedVersion;
    },
    refreshButtonIsLoading(): boolean {
      return (
        store.state.data.isLoading ||
        store.state.timebillingStore.isLoading ||
        store.state.timesStore.isLoading
      );
    },
    canUseBoo3(): boolean {
      return this.$store.getters[this.$storenames.auth.getters.userCan]('boo3');
    },
  },
  watch: {
    // contentDrawerComponent(newVal, oldVal) {
    //   if (newVal === "" && oldVal === "bc-notification-center") {
    //     this.$store.dispatch("data/refresh", ["getNotifications"]);
    //   }
    // },
    user(newVal, oldVal): any {
      if (!this.user || this.$BC.ENV === 'development') return;
      this.$Bugsnag.setUser(this.user.id, '', this.user.username);
    },
  },

  created() {
    // Check Notifications
    if (this.$me.isActive()) {
      this.$store.dispatch(this.$storenames.data.actions.refresh, [
        'getNotifications',
      ]);
    }
  },

  mounted() {
    // Theme
    this.$vuetify.theme.dark = this.darkmode as boolean;
    // size > drawer
    this.showDrawer = this.$vuetify.breakpoint.lgAndUp;
  },

  methods: {
    openNotificationCenter() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-notification-center',
        data: {
          notifications: this.notifications,
        },
      });
    },
    logout() {
      store.dispatch.auth.logout();
      this.$router.push({ name: 'login' });
    },
    hideSnackbar() {
      this.$store.commit('app/HIDESNACKBAR');
    },
    toggleTheme() {
      this.darkmode = !this.darkmode;
      this.$vuetify.theme.dark = this.darkmode as boolean;
    },
    checkCookie() {
      const user = this.$cookies.get('user');
      if (user === null) {
        return false;
      }
      return true;
    },
    goHome() {
      if (
        store.state.auth.domains &&
        boo.objCount(store.state.auth.domains) === 1
      ) {
        const domains = Object.values(store.state.auth.domains);
        this.$router.push({
          name: 'dashboard',
          params: { domain: domains[0].slug },
        });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    switchDomain: function (domain: DomainType) {
      this.$boo.setDomain(domain);
      if (this.$route.meta && !this.$route.meta.withoutDomain) {
        this.$router.push({ params: { domain: domain.slug } });
        //@ts-ignore
        this.$router.go(); // https://michaelnthiessen.com/force-re-render/
      }
    },
    // setDomain: function (domain: DomainType) {
    //   this.$boo.setDomain(domain);
    //   this.$router.push('/' + domain.slug + '/dashboard/');
    // },
    reloadPage() {
      window.location.reload();
    },
    refreshButtonAction() {
      this.$store.dispatch('data/refresh', [
        'getPTMs',
        'getMyQuickPtms',
        'getNotifications',
        'getPersons',
      ]);
      store.dispatch.timesStore.fetchAllTimes();
      store.dispatch.timebillingStore.fetchAllTimebillings();
    },
  },
});
</script>

<style scoped>
.domainList {
  max-height: 300px;
  overflow-y: auto;
}
</style>
