<template>
  <bv-frame headline="Personen" :loading="loading" @refresh="loadData">
    <template v-slot:buttonRight>
      <DropDownButton
        v-if="
          !isMobile &&
          $store.getters[$storenames.auth.getters.userCan]('person.create')
        "
        :primaryAction="newButtonAction.primary"
        :secondaryActions="newButtonAction.secondary"
      />
      <FABGroup
        v-else-if="
          isMobile &&
          $store.getters[$storenames.auth.getters.userCan]('person.create')
        "
        :primaryAction="newButtonAction.primary"
        :secondaryActions="newButtonAction.secondary"
      />
    </template>

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />

    <PersonsDataTable
      :persons="persons"
      :headers="headers"
      :loading="loading"
    />
  </bv-frame>
</template>

<script lang="ts">
import DropDownButton from '@/components/DropDownButton.vue';
import FABGroup from '@/components/FABGroup.vue';
import store from '@/store';
import BvFrame from '@/views/Frame.vue';
import BvPersonCard from '@/views/crm/person/PersonCard.vue';
import { ExternalAction } from '@/views/crm/person/createFromExternal/helpers';
import PersonsDataTable from './PersonsDataTable.vue';
import { defineComponent } from 'vue';
import { createPersonsDataTableHeaders } from './personDataTableHeaders';

export default defineComponent({
  name: 'Persons',

  components: {
    BvFrame,
    BvPersonCard,
    DropDownButton,
    FABGroup,
    PersonsDataTable,
  },

  data() {
    return {
      persons: [],
      loading: false,

      axiosSuccess: null,
      axiosError: null,

      apiPath: this.$boo.lboUrl() + 'persons',
    };
  },

  methods: {
    loadData() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'persons')
        .then((response: any) => {
          this.axiosSuccess = response;
          this.persons = response.data.success;
          // apiData aktualisieren
          this.$boo.storeApiData(response.data.apiData);
        })
        .catch((error: any) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    newPerson() {
      this.$store.commit('contentDrawer/OPEN', 'bc-person-edit');
    },
    newPersonFromBitrix() {
      store.commit.contentDrawer.LOAD({
        component: 'EditPersonExternalDataSources',
        data: { type: 'bitrix24', action: ExternalAction.create },
      });
    },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    newButtonAction(): any {
      return {
        primary: {
          label: 'Neue Person',
          icon: 'mdi-plus',
          action: this.newPerson,
          showCondition: store.getters.auth.userCan('person.create'),
        },
        secondary: [
          {
            showCondition:
              store.getters.auth.userCan('bitrix.user.show.all') ||
              store.getters.auth.userCan('bitrix.person.show.all'),
            label: 'aus Bitrix24 importieren',
            icon: 'mdi-account-plus',
            action: this.newPersonFromBitrix,
          },
        ],
      };
    },
    headers() {
      return createPersonsDataTableHeaders(this.$vuetify.breakpoint);
    },
  },

  created() {
    this.loadData();
  },
  mounted() {
    // Scroll to Top if changed to this route
    window.scrollTo(0, 0);
  },
});
</script>
