<template>
  <bc-content-drawer-frame>
    <!-- <template v-slot:headerExtension> Extension. </template> -->
    <template v-slot:headerActionLeft>
      <v-btn @click="close" text color="boo"
        ><v-icon left large color="boo">{{ 'mdi-chevron-left' }}</v-icon>
        {{ 'Zurück' }}</v-btn
      >
    </template>
    <!-- <template v-slot:headerActionRight> Right. </template> -->

    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />
    Hier kommt Zeug hinein. Denke ich.<br />

    <template v-slot:dev>
      {{ trmEdit }}
    </template>
  </bc-content-drawer-frame>
  <!-- <bc-cd-edit
    :loading="loading"
    :dataDirty="dataDirty"
    :valid="valid"
    :isNew="isNew"
    :storing="storing"
    :title="title"
    :submit="submit"
  >

    {{ this.trmEdit }}


            <v-card-actions
              v-if="data.trm"
            >
              <v-btn
                class="mt-5"
                text
                color="error"
                :loading="deleting"
                :disabled="loading || !canDelete || deleting"
                @click="showDeleteDialog=true"
              >
                Model löschen
              </v-btn>
            </v-card-actions>

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />


    <bc-dialog-delete
      :show-dialog="showDeleteDialog"
      title="Model löschen"
      checkbox-label="Ja, ich brauch es wirklich nicht mehr!"
      :deleting="deleting"
      @evDelete="deleteTrm"
      @evCancel="showDeleteDialog=false"
    >
      Soll dieses ZE-Model gelöscht werden?
    </bc-dialog-delete>

  </bc-cd-edit> -->
</template>

<script>
export default {
  name: 'BcTrmEdit',

  props: {
    data: {
      type: Object,
      // nur zwecks Doku - defaults werden im created() gesetzt!
      default: function () {
        return {
          trm: {},
          new: false, // bestehenden oder neu
        };
      },
    },
  },

  data() {
    return {
      trmEdit: {},

      valid: false, // Eingaben gültig
      loading: true,
      storing: false,
      showDeleteDialog: false,
      deleting: false,

      axiosError: null,
      axiosSuccess: null,

      dataDirty: false,

      ruleName: [() => this.timeTrm.name.trim !== '' || 'Eingabe erforderlich'],
    };
  },

  computed: {
    isNew() {
      return this.data.new;
    },
    title() {
      if (!this.canEdit) return '';
      return this.isNew ? 'Neu' : 'Bearbeiten';
    },
    canEdit() {
      if (this.isNew) return true;
      if (this.$boo.objIsEmpty(this.trmEdit)) return false;
      return true;
    },
    canDelete() {
      return this.canEdit;
    },
    modeEdit() {
      return this.isNew || (this.canEdit && this.dataDirty);
    },
    modeShow() {
      return !this.modeEdit;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  watch: {
    dataDirty: function () {
      this.$store.commit('contentDrawer/LOCK', this.dataDirty);
    },
    showDeleteDialog: function (shown) {
      this.dataDirty = shown;
    },
  },

  created() {
    // console.log('created')

    // data-default setzen
    // if (this.data.new === undefined) this.data.new = false

    if (this.isNew) {
      // neues ZEM
      // suggestion
    } else {
      // bestehendes ZEM
      this.trmEdit = { ...this.data.trm };
      // this.prepareTrmEdit()
      this.loadData(); // nachladen
    }

    this.loading = false;
  },

  methods: {
    loadData() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'times/recmodels/' + this.trmEdit.id)
        .then((response) => {
          this.axiosSuccess = response;
          this.trmEdit = response.data.success;

          // this.prepareTimeEdit()
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          //   this.$router.replace({ name: 'timerecmodels' })
          //   this.close()
          //   return
          // }
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkDirty() {
      try {
        // jedenfalls dirty
        if (this.isNew) {
          this.dataDirty = true;
          return;
        }
        // jedenfalls nicht dirty
        if (
          this.$boo.objIsEmpty(this.trmEdit) ||
          this.$boo.objIsEmpty(this.data.trm)
        ) {
          this.dataDirty = false;
          return;
        }
        // prüfe alle Felder
        const checkData = [];

        // if (this.timeEdit.ptm_id !== this.data.time.ptm.id) checkData.push('ptm')

        // console.log(checkData)
        this.dataDirty = checkData.length > 0;
      } catch (error) {
        console.log(this.data.trm);
        console.error(error.message);
      }
    },
    submit: function () {
      if (!this.$refs.form.validate()) return;
      if (this.trmEdit.start === null) return;

      this.storing = true;

      alert('dev todo storing...');

      const transfer = {
        id: this.trmEdit.id,
        comment: this.trmEdit.comment ? this.trmEdit.comment : '',
      };
      // console.log(transfer)
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$boo.lboUrl() + 'trms' + (this.isNew ? '' : '/' + transfer.id),
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          // this.$emitter.emit('ev.tme.updated', response.data.success)
          this.$emitter.emit('ev.trm');
          this.close();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    close: function () {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
    deleteTrm() {
      this.deleting = true;

      window.axios
        .delete(this.$boo.lboUrl() + 'trms/' + this.trmEdit.id)
        .then((response) => {
          this.axiosSuccess = response;
          this.$emitter.emit('ev.trms');
          this.close();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.showDeleteDialog = false;
          this.deleting = false;
        });
    },
  },
};
</script>
