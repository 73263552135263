<template>
  <div class="d-flex ma-1">
    <v-chip link class="text-truncate">
      <v-avatar left>
        <bc-avatar :pojo="item" :size="25" />
      </v-avatar>
      {{ item.label }}
    </v-chip>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
