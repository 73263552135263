<template>
  <div>
    <v-btn
      data-cy="accept-button"
      :disabled="disabled"
      :loading="loading"
      @click.stop="click"
      :class="btnClass"
      small
      rounded
      :outlined="hasWarning"
    >
      <v-icon v-if="icon" small left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>

    <bc-dialog-confirm
      :show-dialog="showDialog"
      title="Abrechnung einreichen"
      @evProceed="proceed"
      @evCancel="showDialog = false"
    >
      <header>
        <slot name="warning"></slot>
      </header>
    </bc-dialog-confirm>
  </div>
</template>

<script>
import BcDialogConfirm from '@/components/global/BcDialogConfirm.vue';

export default {
  name: 'BcAcceptButton',
  components: { BcDialogConfirm },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDialog: false,
    };
  },

  computed: {
    hasWarning() {
      return !!this.$slots['warning'];
    },
    btnClass() {
      return this.hasWarning ? 'ma-1 boo--text' : 'ma-1 boo white--text';
    },
  },

  methods: {
    click() {
      if (this.hasWarning) {
        this.showDialog = true;
      } else {
        this.proceed();
      }
    },
    proceed() {
      this.showDialog = false;
      this.$emit('click');
    },
  },
};
</script>
