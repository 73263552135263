<template>
  <div :class="{ 'text-left': leftAlign }">
    <v-list-item class="px-0">
      <v-list-item-avatar v-if="avatarPerson" :class="{ 'mr-0': onlyAvatar }">
        <bc-avatar :pojo="ptm.person" :size="size" />
      </v-list-item-avatar>

      <v-list-item-avatar v-else :class="{ 'mr-0': onlyAvatar }">
        <bc-avatar :pojo="ptm.project" :size="size" />
      </v-list-item-avatar>

      <v-list-item-content v-if="!onlyAvatar">
        <v-list-item-title>
          <span v-if="showPerson && ptm.person">
            <router-link
              v-if="toPerson"
              :to="toPerson"
              class="text-decoration-none"
              >{{ formatLabel(ptm.person.label) }}</router-link
            >
            <span v-else>{{ formatLabel(ptm.person.label) }}</span>
          </span>
          <span v-if="showProject && showPerson"> @ </span>
          <span v-if="showProject">
            <router-link
              v-if="toProject"
              :to="toProject"
              class="text-decoration-none text-wrap"
              >{{ formatLabel(ptm.project.label) }}</router-link
            >
            <span v-else>{{ formatLabel(ptm.project.label) }}</span>
          </span>
        </v-list-item-title>

        <v-list-item-subtitle v-if="!hideRole">
          <span>als {{ ptm.role.name }}</span>
          <span v-if="ptm.memberlabel"> / {{ ptm.memberlabel }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'BcPtmItem',

  // components: {  },

  props: {
    ptm: {
      type: Object,
      default: null,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    viewpoint: {
      type: String,
      default: null,
      validator: function (value) {
        if (!value) return true; // null is ok
        return ['person', 'project'].indexOf(value) !== -1;
      },
    },
    hideRole: {
      // Rolle im Projekt nicht anzeigen
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 40,
    },
    onlyAvatar: {
      // nur Avatar, kein Text
      type: Boolean,
      default: false,
    },
  },

  // data () {
  //   // return {}
  // },

  computed: {
    showProject() {
      return this.viewpoint === 'person' || !this.viewpoint;
    },
    showPerson() {
      return this.viewpoint === 'project' || !this.viewpoint;
    },
    avatarProject() {
      return !this.hideAvatar && this.showProject;
    },
    avatarPerson() {
      return !this.hideAvatar && this.showPerson;
    },
    toProject() {
      if (!this.link || !this.$me.can('project.show')) return '';
      return {
        name: 'projectView',
        params: {
          domain: this.$store.state.app.domain.slug,
          projectSlug: this.ptm.project.slug,
        },
      };
    },
    toPerson() {
      if (!this.link || !this.$me.can('person.show')) return '';
      return {
        name: 'personView',
        params: {
          domain: this.$store.state.app.domain.slug,
          personSlug: this.ptm.person.slug,
        },
      };
    },
  },
  methods: {
    formatLabel(label) {
      return label.length > 30 ? label.slice(0, 30) + '...' : label;
    },
  },
};
</script>
