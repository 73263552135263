<template>
  <div>
    <v-text-field
      v-model="field_string"
      background-color=""
      :clearable="clearable"
      :outlined="outlined"
      :rounded="rounded"
      :dense="dense"
      prepend-inner-icon="mdi-magnify"
      hide-details
      :autofocus="$vuetify.breakpoint.lgAndUp"
      autocomplete="off"
      :label="label"
      class="mt-5 mb-2"
      @input="queue_request"
      data-cy="search-text-field"
    />
    <span
      v-if="recent.length > 0 && showRecent"
      class="d-flex flex-wrap"
      style="align-items: center"
    >
      <!-- Zuletzt gesucht: -->
      <!-- <v-icon>mdi-account-search</v-icon> -->
      <span
        v-for="(item, n) in recent"
        :key="n"
        @click="$emit('clickRecent', item)"
      >
        <bc-search-recent-item :item="item" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BcSearch',
  props: {
    apiPath: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Suche',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    customer: {
      type: Boolean,
      default: false,
    },
    showRecent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      field_string: '',
      recent: '',

      apidata: null,
      loading: false,
    };
  },
  watch: {
    field_string() {
      if (!this.field_string) {
        this.$emit('reset-search');
      }
    },
  },
  methods: {
    queue_request() {
      if (!this.loading && this.field_string) {
        // console.log(this.field_string)
        this.send_request(this.field_string);
      }
    },
    send_request(searchString) {
      this.loading = true;
      window.axios
        .get(this.apiPath, {
          params: {
            filter: searchString,
            customer: this.customer ? this.customer : undefined,
          },
        })
        .then((response) => {
          this.apidata = response.data.success;
          this.$emit('data-received', this.apidata);
        })
        .catch((error) => {
          this.$emit('error-received', error);
        })
        .finally(() => {
          this.loading = false;
          if (searchString !== this.field_string) {
            this.send_request(this.field_string);
          }
        });
    },
  },
  created() {
    window.axios
      .get(`${this.apiPath}/?recent=5${this.customer ? '&customer=true' : ''}`)
      .then((response) => {
        this.recent = response.data.success;
      });
  },
};
</script>

<style scoped></style>
