<template>
  <v-autocomplete
    v-model="item"
    :items="items"
    @input="returnValue"
    @click:clear="clearSelection"
    :label="label"
    :item-text="itemtext"
    :item-value="itemvalue"
    outlined
    clearable
    :multiple="multiple"
    class="ma-2"
    hide-details
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0">
        <span>{{ item.label }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text text-caption">
        (+{{ value.length - 1 }} weitere)
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemvalue: {
      type: String,
      default: 'id',
    },
    itemtext: {
      type: String,
      default: 'label',
    },
    label: {
      type: String,
      default: 'Auswahl treffen...',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      item: null,
    };
  },
  methods: {
    returnValue() {
      this.$emit('input', this.item);
    },
    clearSelection() {
      this.item = [];
      this.returnValue();
    },
  },
  created() {
    this.item = [...this.value];
    this.returnValue();
  },
};
</script>

<style></style>
