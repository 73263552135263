<template>
  <bc-content-drawer-frame data-cy="bc-time-edit">
    <template v-slot:headerActionLeft>
      <v-btn data-cy="closeButton" @click="close" text color="boo">
        <v-icon v-if="!dataDirty" left large color="boo">
          {{ dataDirty ? 'mdi-close' : 'mdi-chevron-left' }}
        </v-icon>
        {{ dataDirty ? 'Abbrechen' : isMobile ? 'Zurück' : 'Schließen' }}
      </v-btn>
    </template>

    <template v-slot:headerActionRight>
      <v-btn
        data-cy="saveButton"
        v-if="modeEdit"
        :loading="storing"
        :disabled="!dataDirty || !valid || !selectedPTMs.length"
        color="boo"
        @click="submit"
      >
        Speichern
      </v-btn>
    </template>

    <template v-slot:headerExtension>
      <v-tabs v-model="tab" align-with-title>
        <v-tab>Zeiteintrag</v-tab>

        <v-tab :disabled="isNew || modeEdit" @change="loadData()">
          <v-badge
            :value="unreadCommentCount"
            :content="commentCount"
            color="grey"
          >
            Kommentare
            <span v-if="commentCount">({{ commentCount }})</span>
          </v-badge>
        </v-tab>
      </v-tabs>
    </template>

    <template v-slot:headerTitle>
      {{ title }}
    </template>

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />

    <v-tabs-items v-model="tab" class="transparent pt-1">
      <v-tab-item :key="1">
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            :disabled="formDisabled"
            class="px-0"
            @keyup.native.escape="close"
          >
            <v-skeleton-loader
              v-if="!isNew && loadingPTMs"
              class="ml-n3 mr-15"
              type="list-item-avatar-two-line"
            />
            <v-alert v-if="!selectedPTMs.length" type="warning" dense outlined
              >Es können keine Zeiten erfasst werden</v-alert
            >
            <bc-ptm-item
              v-if="ptmDisplay === 'item-person'"
              :ptm="timeEdit.ptm"
              viewpoint="person"
              class="ml-8 mb-4"
            />
            <bc-ptm-item
              v-if="ptmDisplay === 'item' || ptmWithoutTrm"
              :ptm="timeEdit.ptm"
              class="ml-8 mb-4"
            />

            <v-alert v-if="canEdit && ptmWithoutTrm" dense type="warning">
              Zu dieser Projektmitgliedschaft wurde die
              Zeiterfassungsmöglichkeit deaktiviert. Dieser Zeiteintrag könnte
              daher nur auf nachfolgende Mitgliedschaft umgebucht werden.
            </v-alert>
            <v-autocomplete
              data-cy="ptm-autocomplete"
              v-if="ptmDisplay === 'list'"
              v-model="timeEdit.ptm_id"
              :items="
                [...selectedPTMs].sort($boo.sortBy('person.label')).reverse()
              "
              :filter="
                (item, queryText) =>
                  item.person.label
                    .toLowerCase()
                    .includes(queryText.toLowerCase()) ||
                  item.project.label
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
              "
              item-text="person.label"
              item-value="id"
              label="Projektmitgliedschaft wählen"
              :allow-overflow="false"
              clearable
              open-on-clear
              auto-select-first
              validate-on-blur
              no-data-text="keine passende Mitgliedschaft gefunden..."
              prepend-icon="mdi-clipboard-text"
              outlined
              :disabled="!isNew && false"
              :rules="rulePTMs"
              @input="checkDirty"
            >
              <template v-slot:selection="{ item }">
                <bc-ptm-item
                  :ptm="item"
                  :viewpoint="
                    selectedPTMs.every((ptm) => $me.isMyPerson(ptm.person.id))
                      ? 'person'
                      : null
                  "
                />
              </template>
              <template v-slot:item="{ item }">
                <bc-ptm-item
                  data-cy="ptm-item"
                  :ptm="item"
                  :viewpoint="
                    selectedPTMs.every((ptm) => $me.isMyPerson(ptm.person.id))
                      ? 'person'
                      : null
                  "
                />
              </template>
            </v-autocomplete>

            <bc-datetime-widget
              data-cy="datetime-start"
              v-model="timeEdit.start"
              :label-date="moreThen24hours ? 'Beginn' : 'Tag'"
              label-time="von"
              date
              time
              :disabled="formDisabled"
              outlined
              :two-lines="!moreThen24hours"
              :time-interval="timerecmodel && timerecmodel.interval"
              show-date-choice
              show-time-now-jumper
              prefix-weekday
              :suggest="{
                date: $dayjs().second(0).millisecond(0),
                time: $dayjs().format($BC.F_DE_TIME),
              }"
              :min="data.billing ? $dayjs(data.billing.startdate) : null"
              :max="
                data.billing
                  ? $dayjs(data.billing.enddate).endOf('day')
                  : $dayjs().add(1, 'week')
              "
              :rules="ruleStart"
              @input="calcTime"
            />
            <bc-datetime-widget
              data-cy="datetime-stop"
              v-model="timeEdit.stop"
              label-date="Ende"
              label-time="bis"
              date
              :disabled="formDisabled"
              :hide-date="!moreThen24hours"
              :relative-date="timeEdit.start"
              time
              may-be-empty
              outlined
              :time-interval="timerecmodel && timerecmodel.interval"
              show-time-now-jumper
              prefix-weekday
              :suggest="{
                date: $dayjs().second(0).millisecond(0),
                time: $dayjs().format($BC.F_DE_TIME),
              }"
              @input="calcTime"
            />
            <div
              v-if="
                (timerecmodel && timerecmodel.pause_input) || timeEdit.pause
              "
            >
              <v-text-field
                data-cy="pause"
                ref="PauseTF"
                v-model="timeEdit.pause"
                label="Pause (Minuten)"
                autocomplete="off"
                outlined
                dense
                prepend-icon="mdi-coffee-outline"
                :rules="rulePause"
                @focus="
                  $nextTick(() =>
                    $refs.PauseTF.$el
                      .querySelector('input')
                      .setSelectionRange(0, 999)
                  )
                "
                @keyup="PauseTextfieldKeyup"
                @input="calcTime"
              >
                <template
                  v-if="
                    timerecmodel && !timerecmodel.pause_input && timeEdit.pause
                  "
                  v-slot:append-outer
                >
                  <v-tooltip open-on-click bottom max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on">
                        mdi-message-alert-outline
                      </v-icon>
                    </template>
                    <span
                      >Das hinterlegte Zeiterfassungsmodell lässt die Eingabe
                      einer Pause eigentlich nicht zu.</span
                    >
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-slider
                v-if="isMobile && false"
                v-model="timeEdit.pause"
                dense
                class="ml-6 mt-n5"
                xtrack-color="secondary"
                xcolor="secondary"
                max="90"
                :step="timerecmodel.interval"
                ticks
                @input="calcTime"
              />
            </div>
            <v-text-field
              data-cy="duration"
              :value="durationTime"
              label="Dauer"
              dense
              prepend-icon="mdi-clock"
              disabled
              style="width: 200px"
              :xrules="ruleDuration"
            />

            <bc-time-activities
              v-if="
                (timerecmodel &&
                  timerecmodel.activities &&
                  timerecmodel.activities.length) ||
                (timeEdit && timeEdit.activities && timeEdit.activities.length)
              "
              v-model="timeEdit.activities"
              :options="
                timerecmodel ? timerecmodel.activities : timeEdit.activities
              "
              @input="checkDirty"
            />

            <v-textarea
              data-cy="notice"
              v-if="
                (timerecmodel && timerecmodel.comment_input) || timeEdit.notice
              "
              v-model="timeEdit.notice"
              prepend-icon="mdi-note-text-outline"
              autocomplete="off"
              outlined
              dense
              clearable
              auto-grow
              rows="3"
              label="Notiz"
              @input="checkDirty"
            >
              <template
                v-if="
                  timerecmodel && !timerecmodel.comment_input && timeEdit.notice
                "
                v-slot:append-outer
              >
                <v-tooltip open-on-click bottom max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" v-bind="attrs" v-on="on">
                      mdi-message-alert-outline
                    </v-icon>
                  </template>
                  <span
                    >Das hinterlegte Zeiterfassungsmodell lässt die Erfassung
                    einer Notiz eigentlich nicht zu.</span
                  >
                </v-tooltip>
              </template>
            </v-textarea>
          </v-form>

          <v-card-actions v-if="time && data.firsttime">
            <v-btn
              class="mt-0"
              color="error"
              :loading="deleting"
              :disabled="loading || !canDelete || deleting"
              @click="showDeleteDialog = true"
            >
              {{
                timeEdit.stop
                  ? 'Zeiteintrag nicht speichern'
                  : 'Zeiterfassung abbrechen'
              }}
            </v-btn>
          </v-card-actions>

          <v-list v-if="!loading && !isNew" subheader class="mx-8">
            <v-list-item
              dense
              two-line
              class="ml-n11 pr-n11"
              :disabled="modeEdit"
            >
              <v-list-item-icon class="mt-n1">
                <v-tooltip open-on-click bottom max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon class="mt-3 mb-n5" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Solange ein Zeiteintrag offen ist, kann dieser noch
                    geändert werden. Erst wenn er erfasst ist, kann er bestätigt
                    werden und zählt.</span
                  >
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content
                v-if="
                  timeEdit.status_id !== null &&
                  timeEdit.status_id !== undefined
                "
                class="ml-n5"
              >
                <p>
                  {{ statusInfotext }}
                </p>

                <Status-switch
                  v-if="!isRecording"
                  :disabled="modeEdit"
                  class="mt-2"
                  v-model="timeEdit.status_id"
                  :item="timeEdit"
                  type="time"
                  @Xinput="statusChanged"
                  :on-success="setTime"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card-actions v-if="time.created_at && !data.firsttime">
            <v-btn
              class="mt-5"
              text
              color="error"
              :loading="deleting"
              :disabled="loading || !canDelete || deleting"
              @click="showDeleteDialog = true"
            >
              Zeiteintrag löschen
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-tab-item>

      <v-tab-item :key="3" class="ma-0 pa-0">
        <Bc-comment-view
          @refresh="loadData()"
          :data="{ commentable: timeEdit }"
        />
      </v-tab-item>
    </v-tabs-items>

    <bc-dialog-delete
      :show-dialog="showDeleteDialog"
      :title="isRecording ? 'Zeitmessung verwerfen?' : 'Zeiteintrag löschen'"
      checkbox-label=""
      :deleting="deleting"
      @evDelete="deleteTimeentry"
      @evCancel="showDeleteDialog = false"
    >
      {{ isRecording ? '' : 'Soll dieser Zeiteintrag gelöscht werden?' }}
    </bc-dialog-delete>

    <v-card-text v-if="$boo.envIsDev() && false" class="blue">
      Start: <br />{{ timeEdit.start }}<br />
      Stop: <br />{{ timeEdit.stop }}<br />
    </v-card-text>
  </bc-content-drawer-frame>
</template>

<script>
import BcTimeActivities from '@/views/time/TimeActivities';
import store from '@/store';
import boo from '@/boo';
import BcContentDrawerFrame from '@/components/global/contentDrawer/BcContentDrawerFrame.vue';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import BcPtmItem from '@/components/global/BcPtmItem.vue';
import BcDialogDelete from '@/components/global/BcDialogDelete.vue';

export default {
  name: 'TimeEdit',
  components: {
    BcDialogDelete,
    BcPtmItem,
    BcResponseHandler,
    BcContentDrawerFrame,
    BcTimeActivities,
  },

  props: {
    data: {
      type: Object,
      // nur zwecks Doku - defaults werden im created() gesetzt!
      default: function () {
        return {
          timeId: null,
          ptms: [],
          new: false, // keinen bestehenden ZE laden, sondern einen neuen erstellen
          hasRecordingTime: false, // es gibt für diesen PTM bereits eine laufende Zeitmessung
          // levelOptions: [],
          // statusOptions: [],
          firsttime: false, // ZE gerade erstellt
          gotoComment: false,
        };
      },
    },
  },

  data() {
    return {
      valid: false, // Eingaben gültig
      loadingPTMs: false, // ptms werden schon im data übergeben und dzt auch nicht nachgeladen
      storing: false,
      // stored: false, // um einen new nach dem Speichern anders behandeln zu können
      deleting: false,
      axiosError: null,
      axiosSuccess: null,
      tab: null,

      timeEdit: {
        start: null,
        stop: null,
        pause: null,
      },
      durationTime: '',
      dataDirty: false,

      ptms: [], // weil sie im data.ptm nicht reaktiv sind

      ruleDuration: [() => this.timeEdit.duration >= 0 || 'ungültige Dauer'],
      rulePTMs: [
        () => !!this.timeEdit.ptm_id || 'bitte Projektmitgliedschaft auswählen',
      ],
      // Rule bei DT-Widget funzt so nicht - müsste zum Input durchgereicht werden
      ruleStart: [() => this.timeEdit.start !== null || 'Zeit eintippen!!'],
      rulePause: [
        (v) => !v || /^[0-9]{1,3}$/.test(v) || 'bitte nur 1-3 Ziffern',
      ],

      showDeleteDialog: false,
      now: false,
    };
  },

  computed: {
    formDisabled() {
      return (
        this.loading ||
        !this.canEdit ||
        this.deleting ||
        !this.selectedPTMs.length
      );
    },
    time() {
      return store.getters.timesStore.getTime(this.data.timeId) || {};
    },
    loading() {
      return store.state.timesStore.isLoading;
    },
    isNew() {
      return this.data.new;
      // && !this.stored
    },
    // die in diesem Fall auswählbaren, weil im Context sinnvollen PTMs (das sind nicht alle möglichen!)
    selectedPTMs() {
      return this.ptms.filter(
        (ptm) =>
          ptm.timerecmodel && // muss ZEM haben
          (ptm.active || // nur aktive
            ptm.project.isProjectLead) // oder ich bin PL
      );
    },
    title() {
      if (!this.modeEdit) return '';
      return this.isNew ? 'Neu' : 'Bearbeiten';
    },
    canEdit() {
      if (this.isNew) return true;
      // if (this.data.statusOptions.length === 0) return false;
      if (this.$boo.objIsEmpty(this.timeEdit)) return false;
      // if (this.timeEdit.status_id === 30) return false; // bereits erledigt
      if (this.timeEdit.status_id >= 10) return false; // mehr als erfasst

      return this.canStatus || this.ImOwner; // darf owner auch ?
      // return this.canStatus && this.ImOwner // darf nur owner ?
      // return this.canStatus // oder jeder ??
    },
    canStatus() {
      if (this.selectedPTMs.length === 0) return false; // keine ptms > nur show
      return this.canRecord || this.canCheck;
    },
    myLevels() {
      return this.time.status_permission_level || [];
    },
    canRecord() {
      if (!this.myLevels.includes(10)) return false;
      return this.timeEdit.status_id === 0;
    },
    canCheck() {
      if (!this.myLevels.includes(20)) return false;
      return (
        (this.timeEdit.status_id === 0 && this.myLevels.includes(10)) ||
        this.timeEdit.status_id === 10
      );
    },
    // canComplete() {
    //   if (!this.myLevels.includes(30)) return false
    //   return this.timeEdit.status_id === 20
    // },
    canDelete() {
      return this.timeEdit.status_id === 0 && (this.canEdit || this.ImOwner); // canEdit ODER isOwner (oder?? #BOO-485)
      // return this.canEdit && this.ImOwner
    },
    modeEdit() {
      return this.isNew || (this.canEdit && this.dataDirty);
    },
    modeShow() {
      return !this.modeEdit;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    // liefert hier manchmal "undefined" !?
    isRecording() {
      // return this.timeEdit && this.timeEdit.start && !this.timeEdit.stop
      // stimmt erst, wenn die gespeicherten Daten so sind!
      return this.time && this.time.start && !this.time.stop;
    },
    ptm() {
      if (!this.selectedPTMs) return null; // PTMs noch nicht geladen
      if (this.selectedPTMs.length === 1) return this.selectedPTMs[0]; // nur eines möglich
      if (!this.timeEdit.ptm_id) return null; // noch kein PTM ausgewählt
      return this.selectedPTMs.find((p) => p.id === this.timeEdit.ptm_id);
    },
    // bestehende ptm nicht in PTMs > das ptm hat kein ZEM mehr
    ptmWithoutTrm() {
      if (boo.objIsEmpty(this.time)) return false;
      if (!this.time || this.loadingPTMs) return null; // noch nicht geladen
      if (this.time.ptm.timerecmodel) return false; // bei show nur in time
      return (
        this.selectedPTMs.find((p) => p.id === this.time.ptm.id) === undefined
      );
    },
    // how to display ptm item || item-person || list || none
    ptmDisplay() {
      if (!this.isNew && this.$boo.objIsEmpty(this.time)) return 'none'; // bestehend - noch nicht geladen
      if (!this.selectedPTMs?.length) return 'none'; // keine PTMs - noch nicht geladen
      // if (this.ptmWithoutTrm) return 'item'
      if (this.isNew || this.canEdit) {
        if (this.selectedPTMs.length > 1) return 'list'; // mehrere
        if (this.$me.isMyPerson(this.selectedPTMs?.[0]?.person.id))
          return 'item-person'; // nur meine
      }
      return 'item';
    },
    starttime() {
      return this.nearestIntervalTo(this.now).format('HH:mm');
    },
    timerecmodel() {
      // return this.ptm?.timerecmodel // Optional Chaining requires #391
      return this.ptm ? this.ptm.timerecmodel : null;
    },
    moreThen24hours() {
      return (
        this.timerecmodel &&
        (this.timerecmodel.duration_max === 0 ||
          this.timerecmodel.duration_max > 60 * 24)
      );
    },
    ImOwner() {
      return this.$me.owns(this.timeEdit);
    },
    commentCount() {
      if (!this.time) return null;
      if (this.timeEdit.comments) return this.timeEdit.comments.length;
      return this.time.comment_count;
    },
    unreadCommentCount() {
      // @@@ ToDo
      return 0;
    },
    statusInfotext() {
      if (this.isRecording)
        return 'Bitte die bis-Zeit eintragen und speichern.';
      if (this.timeEdit.status_id === 0)
        return 'Der Zeiteintrag ist noch nicht erfasst.';
      if (this.timeEdit.status_id === 10)
        return 'Der Zeiteintrag muss nun bestätigt werden.';
      if (this.timeEdit.status_id === 20)
        return 'Der Zeiteintrag kann gesperrt werden.';
      if (this.timeEdit.status_id === 30)
        return 'Der Zeiteintrag wurde geprüft und gesperrt.';
      return '';
    },
  },

  watch: {
    now: {
      handler: function () {
        setTimeout(() => {
          this.now = this.$dayjs();
        }, 10000);
      },
      immediate: true,
    },
    dataDirty: function () {
      this.$store.commit('contentDrawer/LOCK', this.dataDirty);
    },
    showDeleteDialog: function (shown) {
      this.dataDirty = shown;
    },
    time() {
      if (boo.objIsEmpty(this.time)) return;
      this.timeEdit = { ...this.time };
      this.prepareTimeEdit();
    },
  },

  created() {
    // console.log('created');
    this.now = this.$dayjs();

    // activate comments tab
    if (this.data.gotoComment) this.tab = 2;

    // data-default setzen
    if (this.data.hasRecordingTime === undefined)
      this.data.hasRecordingTime = false;
    if (this.data.new === undefined) this.data.new = false;

    // Zeiteintrag
    if (this.isNew) {
      // neuer Zeiteintrag
      // suggestion
      this.timeEdit.start = this.nearestIntervalTo(this.$dayjs()); // wirkt nicht immer, weil ptm noch nicht ausgewählt ist > kein trm ...
    } else {
      // bestehender Zeiteintrag
      this.timeEdit = { ...this.time };
      this.prepareTimeEdit();
      this.loadData(); // nachladen
    }

    // PTM(s)
    if (this.data.ptms) {
      // die zulässigen PTMs werden von der aufrufenden Komponente mitübergeben
      this.ptms = this.data.ptms;
    } else {
      // falls keine PTMs mitgegeben wurden, sind alle zulässig ...
      this.ptms = store.getters.data.timeablePTMs;
      // sicherheitshalber nachladen/aktualisieren
      store.dispatch.data.getPTMs();
    }
    this.processPTMs();

    this.checkDirty();
  },

  methods: {
    loadData() {
      // refresh data
      store.dispatch.timesStore.fetchSingleTime(this.data.timeId);
    },
    setTime(time) {
      store.commit.data.setTime(time);
    },
    // spezielles Umwandeln für Verwendung hier intern
    prepareTimeEdit() {
      // this.timeEdit.ptm_id = this.timeEdit.ptm.id
      this.$set(this.timeEdit, 'ptm_id', this.timeEdit.ptm.id); // Anserschmäh um die reactivity zu gewährleisten ;-)
      this.processPTMs();
      this.timeEdit.start = this.$dayjs(this.timeEdit.start);
      this.timeEdit.stop = this.timeEdit.stop
        ? this.$dayjs(this.timeEdit.stop)
        : null;
      this.calcTime(); // für durationTime
    },

    processPTMs() {
      if (this.selectedPTMs?.length === 1 && this.canEdit) {
        this.timeEdit.ptm = this.selectedPTMs[0];
        this.timeEdit.ptm_id = this.selectedPTMs[0].id;
      }
    },
    calcTime() {
      // console.log('TimeEdit > calcTime')

      if (
        this.timeEdit.start &&
        this.timeEdit.start.isValid?.() &&
        !this.moreThen24hours
      ) {
        // stop-Tag ermitteln weil nicht eingebbar
        // this.timeEdit.stop = this.timeEdit.start
      }

      const pause = this.$boo.trimNumber(this.timeEdit.pause);
      // console.log('pause getrimmt: ' + pause)

      // Dauer
      this.durationTime = '';
      this.timeEdit.duration = 0;
      if (
        this.timeEdit.start &&
        this.timeEdit.start.isValid?.() &&
        this.timeEdit.stop &&
        this.timeEdit.stop.isValid?.() &&
        this.timeEdit.stop > this.timeEdit.start
      ) {
        this.timeEdit.duration =
          this.timeEdit.stop.diff(this.timeEdit.start, 'minutes') - pause;
        // console.log('this.timeEdit.duration: ' + this.timeEdit.duration)

        // Tage >24 Std fehlen
        this.durationTime = this.$dayjs()
          .startOf('year')
          .minute(this.timeEdit.duration)
          .format(this.$BC.F_DE_TIME);
      }
      // console.log(this.timeEdit.duration)
      this.checkDirty();
    },
    // Überprüfung auf Veränderung
    checkDirty() {
      // console.log('checkDirty')
      try {
        // jedenfalls dirty
        if (this.isNew) {
          this.dataDirty = true;
          return;
        }
        // jedenfalls nicht dirty
        if (
          this.$boo.objIsEmpty(this.timeEdit) ||
          this.$boo.objIsEmpty(this.time)
        ) {
          this.dataDirty = false;
          return;
        }
        // prüfe alle Felder
        const checkData = [];
        if (this.timeEdit.ptm_id !== this.time.ptm.id) checkData.push('ptm');

        if (
          this.timeEdit.start &&
          this.$boo.datetimeToISO(this.timeEdit.start) !==
            this.$boo.datetimeToISO(this.time.start)
        )
          checkData.push('start');

        if (
          (this.timeEdit.stop && !this.time.stop) || // war vorher leer
          (this.timeEdit.stop &&
            this.$boo.datetimeToISO(this.timeEdit.stop) !==
              this.$boo.datetimeToISO(this.time.stop))
        )
          checkData.push('stop');

        if (
          this.$boo.trimNumber(this.timeEdit.duration) !==
          this.$boo.trimNumber(this.time.duration)
        )
          checkData.push('duration');

        if (
          this.$boo.trimNumber(this.timeEdit.pause) !==
          this.$boo.trimNumber(this.time.pause)
        )
          checkData.push('pause');

        if (
          JSON.stringify(this.timeEdit.activities) !==
          JSON.stringify(this.time.activities)
        )
          checkData.push('activities');

        if (
          this.$boo.trimString(this.timeEdit.notice) !==
          this.$boo.trimString(this.time.notice)
        )
          checkData.push('notice');

        // console.log(checkData)
        this.dataDirty = checkData.length > 0;
      } catch (error) {
        console.error(error.message);
      }
    },
    submit: function () {
      if (!this.$refs.form.validate()) return;
      if (this.timeEdit.start === null) return;

      this.storing = true;

      const transfer = {
        id: this.timeEdit.id,
        // user_id: this.timeEdit.user_id, // wird eh der user im BE genommen
        project_membership_id: this.timeEdit.ptm_id,
        start: this.timeEdit.start.toISOString(),
        stop: this.timeEdit.stop ? this.timeEdit.stop.toISOString() : '',
        pause: this.timeEdit.pause * 1,
        duration: this.timeEdit.duration,
        notice: this.timeEdit.notice ? this.timeEdit.notice : '',
        status_id: this.timeEdit.status_id ?? 0,
        activities: this.timeEdit.activities,
        billing_id: this.data.billing?.id,
      };
      // console.log(transfer)
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$boo.lboUrl() +
            'times' +
            (this.isNew ? '' : '/' + transfer.id),
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          store.commit.timesStore.setTime(response.data.success);
          // this.$store.dispatch('data/refresh', [
          //   // 'getTimes',
          //   // 'getMyRecordingTimes',
          // ]);
          this.dataDirty = false;
          // this.$emitter.emit('ev.times');
          // this.close()
          // this.stored = true

          if (this.isNew) {
            this.close();
          }
        })
        .catch((error) => {
          console.log(error);
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    close: function () {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
    },
    // ToDo: wird nicht mehr benötigt(?)
    statusChanged(time) {
      this.timeEdit.status_id = time.status_id;
      this.timeEdit.updated_at = time.updated_at;
      // this.$emitter.emit('ev.time.updated', response.data.success)
      // this.$emitter.emit('ev.times');
      this.loadData();
    },
    deleteTimeentry() {
      this.deleting = true;
      window.axios
        .delete(this.$boo.lboUrl() + 'times/' + this.timeEdit.id)
        .then((response) => {
          this.axiosSuccess = response;
          store.commit.timesStore.removeTime(this.timeEdit.id);
          this.close();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.showDeleteDialog = false; // allfällige error-msg kommt im parent
          this.deleting = false;
        });
    },
    applyOwner(time) {
      this.time = time;
    },
    nearestIntervalTo(timestamp) {
      if (!this.timerecmodel) return timestamp.second(0).millisecond(0);
      const interval = this.timerecmodel.interval;
      if (!interval) return timestamp.second(0).millisecond(0);
      const mins = timestamp.diff(timestamp.startOf('date'), 'minute');
      return timestamp
        .startOf('date')
        .add(Math.round(mins / interval) * interval, 'minute');
    },
    PauseTextfieldKeyup(keyup) {
      switch (keyup.key) {
        case 'ArrowUp':
          this.timeEdit.pause =
            (typeof this.timeEdit.pause === 'number'
              ? this.timeEdit.pause
              : 0) + this.timerecmodel.interval;
          this.$nextTick(() =>
            this.$refs.PauseTF.$el
              .querySelector('input')
              .setSelectionRange(0, 99)
          );
          break;
        case 'ArrowDown':
          this.timeEdit.pause = Math.max(
            0,
            this.timeEdit.pause - this.timerecmodel.interval
          );
          this.$nextTick(() =>
            this.$refs.PauseTF.$el
              .querySelector('input')
              .setSelectionRange(0, 99)
          );
          break;
      }
      this.calcTime();
    },
  },
};
</script>
