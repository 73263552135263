<template>
  <div>
    <v-dialog
      v-if="!expectedResponse"
      :value="displayMe && !oked"
      persistent
      max-width="380"
      @keydown.enter="clickOK"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="error alabaster--text">
          {{ errorStatusText }} {{ errorStatusCode }}
          <v-spacer />
          <v-icon color="alabaster" right>{{ icon }}</v-icon>
        </v-card-title>
        <v-card-text class="mt-4 error--text">{{ message }}</v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="center" @click="clickOK">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="expectedResponse && showInDialog"
      :value="displayMe && !oked"
      persistent
      max-width="380"
      @keydown.enter="clickOK"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="warning alabaster--text">
          {{ errorStatusText }}
        </v-card-title>
        <v-card-text class="mt-4 black--text">
          {{ message }}
          <ul v-if="errors">
            <li v-for="(msg, field) in errors" :key="field">
              {{ msg[0] }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="center" @click="clickOK">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      v-if="expectedResponse && !showInDialog"
      :value="displayMe"
      :type="type"
      :icon="false"
      class="pa-3 ma-2"
      XXXdismissible="Vorsicht: kann nachher nicht mehr angezeigt werden!"
    >
      <div class="d-flex justify-space-between alabaster--text">
        <span>
          {{ message }}
          <ul v-if="errors">
            <li v-for="(msg, field) in errors" :key="field">
              {{ msg[0] }}
            </li>
          </ul>
        </span>
        <v-spacer />
        <v-icon
          color="alabaster"
          right
          :title="errorStatusText + ' ' + errorStatusCode"
        >
          {{ icon }}
        </v-icon>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'BcResponseHandler',

  props: {
    axiosSuccess: { type: Object, default: null }, // response von axios.then()
    axiosError: { type: [Error, Object, null], default: null }, // error von axios.catch()
    localWarning: { type: String, default: '' }, // ohne axios; direkt von parent gesetzte Warnung
    alertType: { type: String, default: 'error' }, // 'error', 'warning', 'info'
    displaySuccess: { type: Boolean, default: false }, // im Erfolgsfall Meldung in Komponente anzeigen
    displaySnackbar: { type: Boolean, default: true }, // Success in snack bar ausgeben
    warnOnError: { type: Boolean, default: true }, // bei type==error trotzdem nur warnen (z.B. PW-reset)
    showInDialog: { type: Boolean, default: false }, // meldet immer im v-dialog statt im v-alert (z.B. falls zu wenig Platz)
  },

  data() {
    return {
      displayMe: false,
      message: '',
      errorStatusCode: 0,
      errorStatusText: '',
      expectedResponse: false, // expected bedeutet typischerweise Warnung; replace-t danach nicht nach home!
      errors: {},
      type: 'error',
      oked: false, // user clicked ok ;-)
      loglevel: '', // trace, debug, info, warn, error
    };
  },

  computed: {
    envIsDev() {
      return this.$BC.ENV === 'development';
    },
    icon() {
      switch (this.type) {
        case 'error':
          return 'mdi-weather-lightning';
        case 'warning':
          return 'mdi-weather-cloudy';
        case 'success':
          return 'mdi-check';
        case 'info':
          return 'mdi-information-outline';
        default:
          return 'mdi-weather-night';
      }
    },
  },

  watch: {
    localWarning() {
      // console.log('ResponseHandler > localWarning()')
      this.displayMe = false;
      if (this.localWarning) {
        this.displayMe = true;
        this.message = this.localWarning;
        this.expectedResponse = true;
      }
    },
    axiosSuccess() {
      // console.log('ResponseHandler > watch axiosSuccess')
      this.displayMe = false;

      const data = this.axiosSuccess.data;
      if (!data) return;

      this.type = 'success';
      this.expectedResponse = true;
      this.message = data.message;
      this.loglevel = data.loglevel;
      this.displayMe = this.displaySuccess;

      // apiData reinladen
      if (data.apiData) {
        this.$boo.storeApiData(data.apiData);
      }
      // snack bar
      if (this.displaySnackbar && ['info', 'warn'].includes(this.loglevel)) {
        this.$store.commit('app/SETSNACKBAR', { text: this.message });
      }
      // LBO-Version
      this.$store.commit('app/SETEXPECTEDVERSION', data.version?.boo2_version);
      this.$store.commit('app/SETBOOBEVERSION', data.version?.lbo_version);
    },
    axiosError() {
      // console.log('ResponseHandler > watch axiosError')
      this.displayMe = false;
      if (this.axiosError == null) return;

      if (this.axiosError.request?.responseType === 'blob') {
        // console.log('response is blob!!!')

        // let calcError = this.getBlobError()
        // @@@ LuJ dunno who to go on...
        // https://github.com/axios/axios/issues/815
        // https://stackoverflow.com/questions/65600421/vue-js-axios-responsetype-blob-or-json-object

        this.oked = false;
        this.errorStatusCode = '';
        this.errorStatusText = 'Fehler beim Download';
        this.message = 'Leider hat da etwas nicht geklappt...';
        this.type = 'warning';
        this.displayMe = true;
      } else if (this.axiosError.response) {
        if (this.axiosError.response.status === 503) return;

        this.oked = false;
        this.displayMe = true;
        this.message = this.axiosError.response.data.message;
        this.errorStatusCode = this.axiosError.response.status;
        this.errorStatusText = this.axiosError.response.statusText;
        // this.expectedResponse = [401, 412, 422].includes(this.errorStatusCode)
        this.expectedResponse =
          this.warnOnError || [412, 422, 501].includes(this.errorStatusCode);
        this.type =
          this.expectedResponse || this.warnOnError ? 'warning' : 'error';
        switch (this.errorStatusCode) {
          case 401: // HTTP_UNAUTHORIZED
            this.type = 'error';
            // this.message = 'Nicht authorisiert - ungültige Zugangsdaten!'
            this.message = this.message
              ? this.message.charAt(0).toUpperCase() + this.message.slice(1)
              : 'Nicht authorisiert - ungültige Zugangsdaten!';
            // this.message = 'E-Mail-Adresse bzw. Passwort falsch!'
            break;

          // case 412: // HTTP_PRECONDITION_FAILED
          //   this.errors = this.axiosError.response.data.errors
          //   this.message = 'Unprocessable Entity'
          //   break

          case 422: // HTTP_UNPROCESSABLE_ENTITY
            // Validation-Error
            this.errors = this.axiosError.response.data.errors;
            switch (Object.keys(this.errors).length) {
              case 0:
                // this.type = 'error'
                this.message = 'Unprocessable Entity';
                break;
              case 1:
                this.message = this.errors[Object.keys(this.errors)[0]][0];
                this.errors = {};
                break;
              default:
                this.message = 'Bitte Eingaben kontrollieren:';
            }
            break;

          case 500: // HTTP_INTERNAL_SERVER_ERROR
            this.type = 'error';
            this.message =
              'boo steht zur Zeit leider nicht zur Verfügung - bitte versuche es später wieder...';
            break;

          case 503: // Service Unavailable > Laravel Maintenance Mode
            this.type = 'warning';
            this.errorStatusText = 'maintenance mode activated';
            this.message =
              'Wegen Wartungsarbeiten steht boo leider zur Zeit nicht zur Verfügung.';
            break;
        }

        if (this.axiosError.response.data.apiData) {
          // apiData reinladen, falls vorhanden
          this.$boo.storeApiData(this.axiosError.response.data.apiData);
        }
      } else {
        // Error ohne .response
        // console.log(this.axiosError)
        this.errorStatusCode = null;
        this.errorStatusText = 'BOObE offline';
        this.type = 'error';
        this.message = this.envIsDev
          ? 'Keine response von BOObE - Devs überprüfen bitte das SSL-Zertifikat am Backend-Server...!'
          : 'boo ist derzeit offline - bitte versuche es später wieder...';
        this.displayMe = true;
      }
    },
    alertType() {
      this.type = this.alertType;
    },
  },

  methods: {
    clickOK() {
      this.oked = true;
      this.$emit('evOked');
      if (!this.expectedResponse) {
        if (this.UserLoggedIn()) {
          // wenn.. angemeldet
          this.$router.replace({ name: 'home' }); // > go home
        } else {
          // ...nicht angemeldet
          if (this.$route.name !== 'login') {
            // um duplicates redirect zu vermeiden
            this.$router.replace({ name: 'login' }); // > go login
          }
        }
      }
    },
    UserLoggedIn() {
      if (this.$store.state.auth.user === null) {
        return false;
      }
      return this.$store.state.auth.user.slug !== null;
    },
    async getBlobError() {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          this.axiosError.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(this.axiosError));
        };
        reader.onerror = () => {
          reject(this.axiosError);
        };
        reader.readAsText(this.axiosError.response.data);
      });
    },
  },
};
</script>
