<template>
  <bv-frame headline="Kund*innen" :loading="loading" :refresh-button="false">
    <bc-response-handler :axios-error="axiosError" />
    <PersonsDataTable :persons="clients" :headers="headers" />
  </bv-frame>
</template>

<script lang="ts">
import BvFrame from '@/views/Frame.vue';
import BvPersonCard from '@/views/crm/person/PersonCard.vue';
import { defineComponent } from 'vue';
import PersonsDataTable from './person/PersonsDataTable.vue';
import { createPersonsDataTableHeaders } from './person/personDataTableHeaders';

export default defineComponent({
  name: 'BvClients',

  components: { BvFrame, BvPersonCard, PersonsDataTable },

  data() {
    return {
      clients: [],
      loading: false,

      axiosError: null,

      apiPath: this.$boo.lboUrl() + 'persons',
    };
  },
  computed: {
    headers() {
      return createPersonsDataTableHeaders(this.$vuetify.breakpoint);
    },
  },
  created() {
    this.loading = true;
    window.axios
      .get(this.$boo.lboUrl() + 'persons', { params: { customer: 'true' } })
      .then((response: any) => {
        this.clients = response.data.success;
        this.$boo.storeApiData(response.data.apiData);
      })
      .catch((error: any) => {
        this.axiosError = error;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    showPerson(person: any) {
      this.$router.push({
        name: 'personView',
        params: {
          personSlug: person.slug,
          person: person,
        },
      });
    },
  },
});
</script>
