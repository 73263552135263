<template>
  <v-app>
    <v-card flat color="transparent" :disabled="disabled">
      <Bc-content-drawer-header>
        <template v-slot:extensionContent>
          <slot name="headerExtension" />
        </template>
        <template v-slot:actionLeft>
          <slot name="headerActionLeft" />
        </template>
        <template v-slot:actionRight>
          <slot name="headerActionRight" />
        </template>
        <template v-slot:title>
          <slot name="headerTitle" />
        </template>
      </Bc-content-drawer-header>
      <v-sheet
        :style="cdContentStyle"
        flat
        color="transparent"
        min-height="100%"
      >
        <slot />
        <v-card v-if="$boo.envIsDev() && hasDevSlot" color="primary">
          <v-card-title>Dev-Tools</v-card-title>
          <v-card-text><slot name="dev" /></v-card-text>
        </v-card>
      </v-sheet>
    </v-card>
  </v-app>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasExtensionSlot() {
      return !!this.$slots.headerExtension;
    },
    hasDevSlot() {
      return !!this.$slots.dev;
    },
    cdContentStyle() {
      if (this.hasExtensionSlot) return 'padding-top: 110px';
      return 'padding-top: 80px';
    },
  },
};
</script>

<style></style>
