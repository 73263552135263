<template>
  <v-card flat :disabled="loading">
    <div
      align="center"
      :class="{
        'mx-2': true,
        'd-flex justify-space-between align-center':
          asListElement && !isRecording,
      }"
    >
      <div id="quickPtmLabel" class="order-1">
        <bc-ptm-item :ptm="ptm" viewpoint="person" link left-align />
      </div>

      <div id="quickCircular" class="order-3">
        <v-progress-circular
          :rotate="90"
          :size="asListElement && !isRecording ? 50 : 100"
          :width="isRecording ? 4 : 2"
          :indeterminate="loading || storing"
          :value="isRecording ? (counter / 60) * 100 : 0"
          color="boo"
          class="my-2"
        >
          <v-btn
            data-cy="startRecordingButton"
            v-if="!isRecording && isNew"
            x-large
            fab
            icon
            color="success"
            :disabled="deleting || storing"
            @click="startRecording"
          >
            <v-icon x-large> mdi-play </v-icon>
          </v-btn>

          <v-btn
            data-cy="deleteRecordingButton"
            v-if="isRecording && !canStopNow"
            x-large
            fab
            icon
            color="error"
            :disabled="deleting || storing"
            @click="deleteTimeentry"
          >
            <v-icon large> mdi-delete-forever-outline </v-icon>
          </v-btn>

          <v-btn
            data-cy="stopRecordingButton"
            v-if="isRecording && canStopNow"
            x-large
            fab
            icon
            :disabled="deleting || storing"
            color="error"
            @click="stopRecording"
          >
            <v-icon x-large> mdi-stop </v-icon>
          </v-btn>
        </v-progress-circular>
      </div>

      <div
        v-if="isRecording"
        id="quickRecInfo"
        class="order-2 d-flex justify-center"
      >
        <div style="cursor: pointer" class="mr-2" @click="editTime">
          <bc-time-recording class="my-0" :time="timeEdit" type="clock" />
          {{ recordingSince }}
        </div>
        <v-tooltip
          v-if="recordingTooltip"
          open-on-click
          bottom
          max-width="300px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>{{ recordingTooltip }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-divider v-if="asListElement && !lastItem" class="my-2" />

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
      :display-snackbar="false"
    />
  </v-card>
</template>

<script>
import store from '@/store';
import BcTimeRecording from '@/views/time/TimeRecording';

export default {
  name: 'BcTimeQuick',
  components: {
    BcTimeRecording,
  },

  props: {
    time: {
      type: Object,
      default: null,
    },
    // statusOptions: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    // levelOptions: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    ptm: {
      type: Object,
      default: () => {},
    },
    asListElement: {
      type: Boolean,
      default: false,
    },
    lastItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      storing: false,
      deleting: false,

      axiosError: null,
      axiosSuccess: null,

      timeEdit: {
        start: null,
        stop: null,
      },
      timeOriginal: {}, // unbearbeitet

      now: false,
    };
  },

  computed: {
    isNew() {
      return !this.time;
    },
    isRecording() {
      return this.timeEdit.start && !this.timeEdit.stop;
    },
    recordingSince() {
      if (!this.isRecording) return '';
      try {
        let ret = this.hours > 0 ? 'seit ' : 'beginnt ';
        if (this.days < -6)
          ret += 'am ' + this.timeEdit.start.format('D. MMMM');
        else if (this.days < -1)
          ret += 'am ' + this.timeEdit.start.format('dddd');
        else if (this.days < 0)
          ret += 'morgen um ' + this.timeEdit.start.format('HH:mm');
        else if (this.days === 0 && this.hours < 0)
          ret += 'um ' + this.timeEdit.start.format('HH:mm');
        else if (this.days === 0) ret += this.timeEdit.start.format('HH:mm');
        else if (this.days < 2)
          ret += 'gestern um ' + this.timeEdit.start.format('HH:mm');
        else if (this.days < 6) ret += this.timeEdit.start.format('dddd');
        else ret += this.timeEdit.start.format('D. MMMM');
        return ret;
      } catch {
        return '';
      } // this.timeEdit kein dayJS
    },
    hours() {
      return this.now.diff(this.timeEdit.start, 'hour', true);
    },
    days() {
      // @@@ funktioniert nicht richtig über den Jahreswechsel... ;-)
      try {
        return this.now.dayOfYear() - this.timeEdit.start.dayOfYear();
      } catch {
        return null;
      } // this.timeEdit kein dayJS
    },
    counter() {
      return this.$dayjs().startOf('year').add(this.hours, 'hour').format('ss');
    },
    stoptime() {
      let stop = this.nearestIntervalTo(this.now);
      while (
        stop.diff(this.timeEdit.start, 'minutes') <
        this.ptm.timerecmodel.duration_min
      ) {
        stop = stop.add(this.ptm.timerecmodel.interval, 'minutes');
      }
      return stop;
    },
    canStopNow() {
      return (
        this.time &&
        !this.time.stop &&
        this.nearestIntervalTo(this.now) > this.$dayjs(this.time.start)
      );
    },
    recordingTooltip() {
      let msg = '';
      if (this.ptm.timerecmodel.interval > 1) {
        msg =
          ' Die Zeiterfassung ist nur im ' +
          this.ptm.timerecmodel.interval +
          '-Minuten-Interval möglich.';
        if (!this.canStopNow) {
          // while trashbin
          if (this.timeEdit.start < this.$dayjs()) {
            msg +=
              ' Sie hat um ' +
              this.timeEdit.start.format('HH:mm') +
              ' begonnen.';
          } else {
            msg +=
              ' Sie beginnt um ' + this.timeEdit.start.format('HH:mm') + '.';
          }
        }
      }
      if (this.canStopNow) {
        if (
          this.ptm.timerecmodel.interval !==
            this.ptm.timerecmodel.duration_min &&
          this.nearestIntervalTo(this.now).diff(
            this.timeEdit.start,
            'minutes'
          ) < this.ptm.timerecmodel.duration_min
        ) {
          msg +=
            ' Die Dauer beträgt zumindest ' +
            this.ptm.timerecmodel.duration_min +
            ' Minuten.';
        }
        if (this.ptm.timerecmodel.interval > 1) {
          msg +=
            ' Wenn du jetzt Stopp drückst, endet diese Zeiterfassung um ' +
            this.stoptime.format('HH:mm') +
            '.';
        }
      }
      if (msg) msg += ' Klicke auf die Zeit, um Start/Stop selber festzulegen.';
      return msg.trim();
    },
  },

  watch: {
    now: {
      handler: function () {
        setTimeout(() => {
          this.now = this.$dayjs();
        }, 1000);
      },
      immediate: true,
    },
    time() {
      // console.log('watch > time')
      if (this.time) {
        this.timeEdit = { ...this.time };
        this.prepareTimeEdit(this.time);
      } else {
        this.initTimeEdit();
      }
    },
  },

  created() {
    // console.log('created')
    this.now = this.$dayjs();
  },

  mounted() {
    // console.log('mounted')
    this.initTimeEdit();
    if (!this.isNew) {
      // console.log('created > not new')
      this.timeEdit = { ...this.time }; // von Prop holen
      this.prepareTimeEdit(this.time);
    }
    // dzt. wird immer ev.times verwendet
    var self = this; // damit this in closure ist
    this.$emitter.on('ev.time.updated', function (updatedTime) {
      // console.log('timeQuick evUpdated')
      if (self.time && self.time.id === updatedTime.id)
        self.$emit('stored', updatedTime);
    });
    this.$emitter.on('ev.time.deleted', function (deletedTime) {
      // console.log('timeQuick evDelted')
      if (self.time && self.time.id === deletedTime.id)
        self.$emit('deleted', deletedTime);
    });
  },

  methods: {
    initTimeEdit() {
      this.$store.dispatch('data/refresh', [
        // 'getTimes',
        // 'getMyRecordingTimes',
        'getPTMs',
      ]);
      this.timeEdit = {
        start: null,
        stop: null,
      };
      this.processPTMs();
    },
    // timeEdit herrichten; z.B. start = dayjs
    prepareTimeEdit(time) {
      // console.log('prepareTimeEdit')
      this.$set(this.timeEdit, 'ptm_id', time.ptm.id);
      this.processPTMs();
      this.timeEdit.start = this.$dayjs(time.start);
      this.timeEdit.stop = time.stop ? this.$dayjs(time.stop) : null;
      this.calcTime(); // für durationTime
    },
    // gültiges PTM automatisch übernehmen
    processPTMs() {
      // console.log('processPTMs')
      this.timeEdit.ptm = this.ptm;
      this.timeEdit.ptm_id = this.ptm.id;
      // if (this.ptms.length === 1) {
      //   this.timeEdit.ptm = this.ptms[0]
      //   this.timeEdit.ptm_id = this.ptms[0].id
      // } else {
      //   console.log('TimeQuick: please select ptm!')
      // }
    },
    startRecording() {
      this.timeEdit.start = this.nearestIntervalTo(this.now);
      this.calcTime();
      this.submit();
    },
    async stopRecording() {
      this.timeEdit.stop = this.stoptime;
      this.calcTime();
      await this.submit();
      this.initTimeEdit();
    },
    calcTime() {
      // console.log('TimeEdit > calcTime')
      // Dauer
      this.durationTime = '';
      this.timeEdit.duration = 0;
      if (
        this.timeEdit.start &&
        this.timeEdit.start.isValid() &&
        this.timeEdit.stop &&
        this.timeEdit.stop.isValid() &&
        this.timeEdit.stop > this.timeEdit.start
      ) {
        this.timeEdit.duration =
          this.timeEdit.stop.diff(this.timeEdit.start, 'minutes') -
          this.timeEdit.pause;
        // console.log('this.timeEdit.duration: ' + this.timeEdit.duration)

        // Tage >24 Std fehlen
        this.durationTime = this.$dayjs()
          .startOf('year')
          .minute(this.timeEdit.duration)
          .format(this.$BC.F_DE_TIME);
      }
      // console.log(this.timeEdit.duration)
    },
    submit: async function () {
      if (this.timeEdit.start === null) return;

      this.storing = true;

      // this.timeEdit.status_id = this.isRecording
      //   ? 0 // offen/recording
      //   : 10 // erfasst
      this.timeEdit.status_id = 0; // immer offen.... (?)

      const transfer = {
        id: this.timeEdit.id,
        project_membership_id: this.timeEdit.ptm_id,
        start: this.timeEdit.start.toISOString(),
        stop: this.timeEdit.stop ? this.timeEdit.stop.toISOString() : '',
        pause: this.timeEdit.pause * 1,
        duration: this.timeEdit.duration,
        comment: this.timeEdit.comment ? this.timeEdit.comment : '',
        status_id: this.timeEdit.status_id,
      };
      // console.log(transfer)
      await window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$boo.lboUrl() +
            'times' +
            (this.isNew ? '' : '/' + transfer.id),
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.timeEdit = response.data.success;
          this.timeOriginal = response.data.success;
          this.prepareTimeEdit(this.timeOriginal);

          store.commit.timesStore.setTime(response.data.success);

          // this.$emit("stored", response.data.success);
          // this.$emitter.emit("ev.times");
          // this.$emitter.emit('ev.time.updated', response.data.success)
          this.$store.dispatch('data/refresh', [
            'getPTMs',
            'getMyQuickPtms',
            // 'getTimes',
            // 'getMyRecordingTimes',
          ]);
          if (!this.isRecording) this.editTime(); // nur beim Stoppen anzeigen
        })
        .catch((error) => {
          this.axiosError = error;
          this.initTimeEdit();
        })
        .finally(() => {
          this.storing = false;
        });
    },
    deleteTimeentry() {
      this.deleting = true;
      window.axios
        .delete(this.$boo.lboUrl() + 'times/' + this.timeEdit.id)
        .then((response) => {
          this.axiosSuccess = response;
          store.commit.timesStore.removeTime(this.timeEdit.id);

          this.initTimeEdit();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    nearestIntervalTo(timestamp) {
      if (!this.ptm.timerecmodel) return timestamp;
      const interval = this.ptm.timerecmodel.interval;
      if (!interval) return timestamp;
      const mins = timestamp.diff(timestamp.startOf('date'), 'minute');
      return timestamp
        .startOf('date')
        .add(Math.round(mins / interval) * interval, 'minute');
    },
    editTime() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          timeId: this.time.id,
          // ptms: this.ptms,
          ptms: [this.ptm], // wir haben hier nur 1 quickable
          // statusOptions: this.statusOptions,
          // levelOptions: this.levelOptions,
          firsttime: true,
        },
      });
    },
  },
};
</script>
