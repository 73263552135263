<template>
  <div>
    <v-list>
      <bc-text-widget v-if="project.name" label="Projekttitel">
        {{ project.name }}
      </bc-text-widget>
      <v-divider v-if="isMobile && project.name" />

      <bc-text-widget v-if="project.customer" label="KundIn">
        {{ project.customer ? project.customer.label : '' }}
      </bc-text-widget>
      <v-divider v-if="isMobile && project.customer" />

      <bc-text-widget v-if="project.description" label="Beschreibung">
        {{ project.description }}
      </bc-text-widget>
      <v-divider v-if="isMobile && project.description" />

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Zuständigkeit</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <bc-pojo-owner
            v-if="!loading"
            :pojo="project"
            pojotype="project"
            @evOwner="setOwner"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider v-if="isMobile && project.owner" />

    <!-- //TODO Tags are not yet implemented for projects -->
    <bc-text-widget label="Tags">
      <TagSelector
        :taggable-type="'projects'"
        :taggable="project"
        :can-edit="canEdit"
      />
    </bc-text-widget>
  </div>
</template>

<script lang="ts">
//@ts-ignore
import BcPojoOwner from '@/components/BcPojoOwner';
import TagSelector from '@/components/tags/TagSelector.vue';
import { ProjectType } from '@/types/types';
import { defineComponent, PropType } from 'vue';
import me from '@/me';
import { canEditProject } from './helpers';

export default defineComponent({
  name: 'ProjectData',

  components: { BcPojoOwner, TagSelector },

  props: {
    project: {
      type: Object as PropType<ProjectType>,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    canEdit(): boolean {
      return canEditProject(this.project);
    },
  },
  methods: {
    setOwner(project: ProjectType) {
      this.$emit('evUpdated', project);
      if (
        !this.$me.owns(project) &&
        !this.$store.getters[this.$storenames.auth.getters.userCan](
          'project.show.all'
        )
      ) {
        this.$router.replace('project');
      }
    },
  },
});
</script>
