import Vue from 'vue';
import VueRouter from 'vue-router';
import Test from '@/views/Test.vue';
import TestBen from '@/views/TestBen.vue';
import Login from '@/views/Login.vue';
import PasswordForgot from '@/views/PasswordForgot.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import AcceptInvite from '@/views/AcceptInvite.vue';
import Home from '@/views/Home.vue';
import Dashboard from '@/views/Dashboard.vue';
import Lost from '@/views/Lost.vue';
import Persons from '@/views/crm/person/Persons.vue';
import PersonShow from '@/views/crm/person/show/PersonShow.vue';
import Clients from '@/views/crm/Clients.vue';
import Projects from '@/views/project/Projects.vue';
import ProjectShow from '@/views/project/ProjectShow.vue';
import myTimes from '@/views/time/myTimes.vue';
import Times from '@/views/time/Times.vue';
import Timerecmodels from '@/views/time/Timerecmodels.vue';
import Domains from '@/views/domain/Domains.vue';
import DomainShow from '@/views/domain/DomainShow.vue';
import TimeBillingListView from '@/views/timebilling/TimeBillingListView.vue';
import TimeBillingDetailView from '@/views/timebilling/TimeBillingDetailView.vue';
import store from '@/store';
import boo from '@/boo';

//@ts-ignore
import TagsView from '@/views/tags/TagsView';
import { Axios, AxiosError } from 'axios';

Vue.use(VueRouter);

/*  avoid error NavigationDuplicated
 *  https://www.programmersought.com/article/82985528840/
 *  ToDo: brauchen wir das noch?
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  //@ts-ignore
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { guestAccess: true, withoutDomain: true },
    beforeEnter: (to: any, from: any, next: Function) => {
      // console.log(router.app.$cookies) // funzt - im Gegensatz zu .app.$store
      if (router.app.$cookies.get('user') != null) {
        // will angemeldet zum login
        next({ path: '/' });
      } else {
        next();
      }
    },
  },
  {
    path: '/password/forgot',
    name: 'password_forgot',
    component: PasswordForgot,
    meta: { guestAccess: true, withoutDomain: true },
  },
  {
    path: '/password/reset',
    name: 'password_reset',
    component: PasswordReset,
    meta: { guestAccess: true, withoutDomain: true },
  },
  {
    path: '/invite',
    name: 'invite',
    component: AcceptInvite,
    meta: { guestAccess: true, withoutDomain: true },
  },

  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { withoutDomain: true },
  },

  {
    path: '/domains',
    name: 'domains',
    component: Domains,
    meta: { withoutDomain: true },
    beforeEnter: beforeEnterCheckPermission(['domain.show']),
  },
  {
    path: '/domains/:domainSlug',
    name: 'domainView',
    component: DomainShow,
    meta: { withoutDomain: true },
    props: true,
    beforeEnter: beforeEnterCheckPermission(['domain.show']),
  },

  {
    path: '/:domain/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },

  {
    path: '/:domain/projects',
    name: 'projects',
    component: Projects,
    beforeEnter: beforeEnterCheckPermission(['project.show']),
  },
  {
    path: '/:domain/projects/:projectSlug',
    name: 'projectView',
    component: ProjectShow,
    props: true,
    beforeEnter: beforeEnterCheckPermission(['project.show']),
  },

  {
    path: '/:domain/persons',
    name: 'persons',
    component: Persons,
    beforeEnter: beforeEnterCheckPermission(['person.show']),
  },
  {
    path: '/:domain/clients',
    name: 'clients',
    component: Clients,
    beforeEnter: beforeEnterCheckPermission(['customer.show']),
  },
  {
    path: '/:domain/persons/:personSlug',
    name: 'personView',
    component: PersonShow,
    props: true,
    beforeEnter: beforeEnterCheckPermission(['person.show']),
  },

  {
    path: '/:domain/times/mine/:monthUrl?',
    name: 'mytimes',
    component: myTimes,
    props: true,
  },
  // {
  //   path: '/:domain/times',
  //   name: 'times',
  //   component: Times
  // },
  {
    path: '/:domain/times/all',
    name: 'alltimes',
    component: Times,
  },
  {
    path: '/:domain/times/billing',
    name: 'timebilling',
    component: TimeBillingListView,
  },
  {
    path: '/:domain/times/billing/:billingID',
    name: 'timebillingdetail',
    props: true,
    component: TimeBillingDetailView,
  },

  // {
  //   path: '/:domain/times/:id',
  //   name: 'timeView',
  //   component: Times,
  //   props: true
  // },

  {
    path: '/:domain/times/models',
    name: 'timerecmodels',
    component: Timerecmodels,
    beforeEnter: beforeEnterCheckPermission(['time.admin']),
  },
  // {
  //   path: '/:domain/timerecmodels/:id',
  //   name: 'timerecmodelView',
  //   component: TimerecmodelShow,
  //   props: true
  // },

  {
    path: '/:domain/tags',
    name: 'tagsView',
    component: TagsView,
    props: true,
  },

  {
    path: '/dev/test',
    name: 'dev_test',
    component: Test,
    meta: { withoutDomain: true },
    beforeEnter: checkIfEnvIsDev(),
  },

  {
    path: '/dev/ben',
    name: 'ben',
    component: TestBen,
    meta: { withoutDomain: true },
    beforeEnter: checkIfEnvIsDev(),
  },

  {
    path: '*',
    name: 'lost',
    component: Lost,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function checkIfEnvIsDev() {
  return (to: any, from: any, next: Function) => {
    if (boo.envIsDev()) next();
    else next('/');
  };
}

function beforeEnterCheckPermission(permissions: string[]) {
  return (to: any, from: any, next: Function) => {
    if (boo.userIsAdmin()) {
      // admin darf alles
      next();
      return;
    }

    const grantedPermissions = permissions.filter((permission) =>
      store.getters.auth.userCan(permission)
    );
    if (grantedPermissions.length === permissions.length) {
      // alle geforderten sind vorhanden
      console.log('Permissions granted: ', grantedPermissions);
      next();
      return;
    }

    const deniedPermissions = permissions.filter(
      (permission) => !grantedPermissions.includes(permission)
    );
    console.log('Permissions denied: ', deniedPermissions);
    next({ path: '/' });
  };
}

router.beforeEach(async (to: any, from: any, next: any) => {
  const canAccessAsGuest = to.matched.some(
    (record: any) => record.meta.guestAccess
  );
  const hasUserCookie: boolean = router.app.$cookies.get('user') != null;

  if (to.name === from.name) {
    console.log(
      'Same route, no need to check permissions. Maybe only query has changed.',
      to.name,
      from.name
    );
    next();
    return;
  }

  if (canAccessAsGuest) {
    console.log('Can access as guest: ', to.name);
    next();
    return;
  }

  if (!hasUserCookie) {
    console.log('no user cookie > login');
    next({ name: 'login' });
    return;
  }

  const hasUserInStore = store.state.auth?.user;
  const needToGetApiData = hasUserCookie && !hasUserInStore;
  console.log({ hasUserCookie, hasUserInStore, needToGetApiData });

  if (needToGetApiData) {
    console.log('hasUserCookie, but no user in store, trying to get user');
    try {
      await store.dispatch.auth.getApiData();
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 503) {
        console.log('BE is in maintenance mode, not logging out!');
      } else {
        store.dispatch.auth.logout().then(() => {
          next({ name: 'login' });
        });
      }
    }
  }

  guardOfInactiveUser(to, next);
  guardOfNonExistentDomains(to, next);
  setDomainIfNecessary(to);

  if (didOverrideBrowserBackButton()) {
    next(false);
    return;
  }

  // ok, let's move forward :)
  next();
});

function setDomainIfNecessary(to: any): void {
  if (
    store.state.auth.user &&
    store.state.auth.domains &&
    Object.keys(store.state.auth.domains as any)?.includes(to.params.domain)
  ) {
    boo.setDomain((store.state.auth.domains as any)[to.params.domain]);
  }
}

function didOverrideBrowserBackButton(): boolean {
  if (store.state.contentDrawer.visible) {
    console.log('only close content drawer');
    store.dispatch.contentDrawer.close();
    return true;
  }
  return false;
}

function guardOfNonExistentDomains(to: any, next: any): void {
  if (
    !to.meta.withoutDomain &&
    to.params.domain &&
    store.state.auth.domains &&
    !Object.keys(store.state.auth.domains).includes(to.params.domain)
  ) {
    console.log('domain "' + to.params.domain + '" does not exist');
    next({ name: 'home' });
  }
}

function guardOfInactiveUser(to: any, next: any): void {
  const userIsActive = !!store.state.auth.user?.active;
  console.log('User is', userIsActive ? 'active' : 'inactive');
  if (!userIsActive && to.name !== 'home') next({ name: 'home' });
}

export default router;
