import boo from '@/boo';
import axios from 'axios';
import { Tag, taggableType } from './tagTypes';

type patchTagsRequest = {
  taggable: 'persons';
  slug: string;
  tag_ids: number[];
};

export const addTagsToTaggableRequest = async (props: patchTagsRequest) => {
  const { taggable, slug, tag_ids } = props;

  const apiURL = `${boo.lboUrl()}${taggable}/${slug}`;

  try {
    const response = await axios.put(apiURL, { tag_ids });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

type createTagRequest = {
  name: string;
};

export const createTagRequest = async (props: createTagRequest) => {
  const { name } = props;

  const apiURL = `${boo.lboUrl()}tags`;

  try {
    const response = await axios.post(apiURL, { name });
    return response.data;
  } catch (e) {
    console.log('Error in createTagRequest', e);
    throw e;
  }
};

export const updateTagRequest = async (props: Tag) => {
  const { id } = props;

  const apiURL = `${boo.lboUrl()}tags/${id}`;

  try {
    const response = await axios.patch(apiURL, props);
    return response.data;
  } catch (e) {
    console.log('Error in updateTagRequest', e);
    throw e;
  }
};

export const deleteTagRequest = async (id: Tag['id'], forced: boolean) => {
  const apiURL = `${boo.lboUrl()}tags/${id}`;

  try {
    const response = await axios.delete(apiURL, { data: { forced } });
    return response.data;
  } catch (e) {
    console.log('Error in deleteTagRequest', e);
    throw e;
  }
};

export const getTagsRequest = async (): Promise<Tag[]> => {
  try {
    const response = await axios.get(`${boo.lboUrl()}tags`);
    return response.data.success;
  } catch (e) {
    console.log('Error in getTagsRequest', e);
    throw e;
  }
};

type assignTagsRequest = {
  tag_ids: number[];
  taggableType: keyof typeof taggableType;
  taggable_slug: string;
};

export const assignTagsRequest = async (props: assignTagsRequest) => {
  const { tag_ids, taggableType, taggable_slug } = props;
  const apiURL = `${boo.lboUrl()}${taggableType}/${taggable_slug}/tags`;

  try {
    const response = await axios.patch(apiURL, {
      tag_ids: tag_ids,
    });
    return response.data.success;
  } catch (e) {
    console.log('Error in assignTagsRequest', e);
    throw e;
  }
};
