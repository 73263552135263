import Vue from 'vue';
import Vuetify from 'vuetify';

/* https://vuetifyjs.com/en/styles/colors
    importieren, wenn viele Farben benötigt werden; bzw. für unten
*/
// import colors from 'vuetify/lib/util/colors'

// Translation provided by Vuetify (typescript)
import { en, de } from 'vuetify/src/locale';

// VeeValidate
// import { ValidationProvider } from 'vee-validate'
// Vue.component('ValidationProvider', ValidationProvider) // Register it globally
// require('@/plugins/validate.js')

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de, en },
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#FF9900',

        // boo brandbook
        boo: '#FF9900',
        gunmetal: '#2C363F',
        alabaster: '#ECEBE4',
        tuscany: '#CC998D',
        sunset: '#FF5E5B',
        secondary: '#2C363F',

        // default
        // primary: '#1976D2',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
        // anchor = primary

        // possible changes, using pre-defined material colors (import oben!)
        // primary: colors.purple,
        // secondary: colors.grey.darken1,
        // accent: colors.shades.black,
        // error: colors.red.accent3,
        // info: ,
        // success: ,
        // warning: ,
        // anchor: '#8c9eff',
      },
      dark: {
        primary: '#FF9900',

        // boo brandbook
        boo: '#FF9900',
        gunmetal: '#2C363F',
        alabaster: '#000000',
        tuscany: '#CC998D',
        sunset: '#FF5E5B',
        secondary: '#FFFFFF',

        // primary: colors.blue.lighten3,
      },
    },
  },
});
