<template>
  <div>
    <div v-for="(block, n) in notification.blocks" :key="n">
      <v-component :is="block.name" :data="block.data" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
