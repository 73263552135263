import store from './store';
import { ID, UserType } from './types/types';

/**
 * Funktionen rund um den aktuell angemeldeten User - um mich
 * this.$me.function
 */
export default {
  me: function (): any {
    return store.state?.auth.user;
  },

  amI(user: UserType): any {
    return user.id === this.me()?.id;
  },

  isMyPerson(id: ID): boolean {
    return id === this.me()?.person.id;
  },

  isAn: function (role: string) {
    return (
      store.state.app.domain &&
      store.state.auth.permissions?.roles[store.state.app.domain.slug].includes(
        role
      )
    );
  },
  isA: function (role: string) {
    return this.isAn(role);
  },

  isSysadmin: function () {
    return this.isA('sysadmin');
  },

  isAdmin: function () {
    return this.isA('admin') || this.isSysadmin();
  },

  /**
   * bin ich owner dieses Pojos?
   * @param  {object} pojo ein Objekt mit dem Parameter user_id
   * @return {bool}
   */
  owns: function (pojo: any) {
    if (!pojo) return null;
    if (pojo.user_id) return pojo.user_id === this.me()?.id;
    if (pojo.owner) return pojo.owner.id === this.me()?.id;
    return null;
  },

  // TODO: Return type sucks - needs to be checked
  isActive: function () {
    if (!this.me()) return null;
    // console.log('me > active = ' + this.me().active)
    return this.me()?.active;
  },

  // check my abilities in active domain
  can: function (ability: any) {
    return this.canIn(ability, store.state.app.domain);
  },

  // check my ability in a certain domain
  canIn: function (ability: any, domain: any) {
    return store.state.auth.permissions?.abilities[
      typeof domain === 'string' ? domain : domain.slug
    ].includes(ability);
  },

  // check my attributes
  has: (attribute: string) => {
    if (store.state.app.domain) {
      return store.state.auth.attributes?.[store.state.app.domain?.slug][
        attribute
      ];
    }
    return false;
  },
};
