<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DevelopmentNotice',
});
</script>

<template>
  <v-card-text v-if="$boo.envIsDev()" class="blue">
    <v-icon left>mdi-information-outline</v-icon>
    <span class="white--text">
      <strong>Entwicklungshinweis:</strong><br />
      <slot />
    </span>
  </v-card-text>
</template>
