<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="monthOnly ? true : false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    max-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="ma-2"
        label="Zeitraum"
        :value="dateTitle"
        readonly
        v-bind="attrs"
        clearable
        hide-details
        @click:clear="clearDate"
        v-on="on"
        outlined
      >
        <template v-if="showPrevNextButtons" v-slot:prepend-inner>
          <v-icon @click="prevRange"> mdi-chevron-double-left </v-icon>
        </template>
        <template v-if="showPrevNextButtons" v-slot:append>
          <v-icon @click="nextRange"> mdi-chevron-double-right </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-sheet>
      <div class="boo white--text pa-5">
        {{ date.length === 0 ? 'Zeitraum wählen' : dateTitle }}
      </div>
      <div>
        <v-date-picker
          v-model="date"
          first-day-of-week="1"
          no-title
          :range="!monthOnly"
          :allowed-dates="
            (month) => {
              if (monthOnly) {
                return items.includes(month);
              }
              return true;
            }
          "
          :type="monthOnly ? 'month' : 'date'"
          @input="returnValue"
        >
          <template v-slot:default>
            <div class="d-flex flex-wrap">
              <v-card-text class="ma-0 pa-0" v-if="!monthOnly">
                <div>
                  <v-chip class="ma-1" @click.stop="setDate('yesterday')">
                    Gestern
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('today')">
                    Heute
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('lastWeek')">
                    Letzte Woche
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('thisWeek')">
                    Diese Woche
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('lastMonth')">
                    Letzter Monat
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('thisMonth')">
                    Dieser Monat
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('lastYear')">
                    Letztes Jahr
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('thisYear')">
                    Dieses Jahr
                  </v-chip>
                  <v-chip class="ma-1" @click="setDate('')">
                    Gesamter Zeitraum
                  </v-chip>
                </div>
              </v-card-text>
              <v-card-actions v-if="isMobile">
                <v-btn
                  text
                  @click="showDatePicker = false"
                  class="caption block"
                  >Schließen</v-btn
                >
              </v-card-actions>
            </div>
          </template>
        </v-date-picker>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    monthOnly: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: '',
      showDatePicker: false,
    };
  },
  computed: {
    dateTitle() {
      if (this.date.length > 0) {
        switch (this.rangeType) {
          case 'day':
            return this.$dayjs(this.date[0]).format('ddd, D. MMMM YYYY');
          case 'week':
            return `KW ${this.$dayjs(
              this.date[0]
            ).week()} (${this.smartDateRangeText()})`;
          case 'month':
            return this.$dayjs(
              typeof this.date === 'object' ? this.date[0] : this.date
            ).format('MMMM YYYY');
          case 'year':
            return this.$dayjs(this.date[0]).format('YYYY');
          case 'custom':
            return this.smartDateRangeText();
          case 'all':
            return 'alle Daten';
          default:
        }
      }
      return null;
    },
    rangeType() {
      if (this.monthOnly) {
        return 'month';
      } else {
        const from = this.date[0];
        const to = this.date[1];

        if (to === from) {
          return 'day';
        } else if (
          from === this.$dayjs(from).startOf('week').format('YYYY-MM-DD') &&
          to === this.$dayjs(from).endOf('week').format('YYYY-MM-DD')
        ) {
          return 'week';
        } else if (
          from === this.$dayjs(from).startOf('month').format('YYYY-MM-DD') &&
          to === this.$dayjs(from).endOf('month').format('YYYY-MM-DD')
        ) {
          return 'month';
        } else {
          return 'custom';
        }
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    showPrevNextButtons() {
      return (
        !(this.rangeType === 'custom' || this.rangeType === '') &&
        this.date.length !== 0
      );
    },
  },
  methods: {
    returnValue() {
      this.$emit('input', this.date);
    },
    smartDateRangeText() {
      const from = this.$dayjs(this.date[0]).utc(true).startOf('day');
      const to = this.$dayjs(this.date[1]).utc(true).endOf('day');

      if (!from.isValid || !to.isValid) return '';

      // Bereich
      let ret = from.format('D.');
      if (from.month() !== to.month() || from.year() !== to.year()) {
        ret += from.format(' MMM. ');
      }
      if (from.year() !== to.year()) ret += from.format('YYYY');
      ret += ' - ';
      ret += to.format('D.');
      ret += to.format(' MMM. ');
      ret += to.format('YYYY');

      return ret;
    },
    clearDate() {
      this.date = typeof this.date === 'object' ? [] : '';
      this.returnValue();
    },
    setDate(range) {
      switch (range) {
        case 'today':
          this.date = [
            this.$dayjs().startOf('day').format('YYYY-MM-DD'),
            this.$dayjs().endOf('day').format('YYYY-MM-DD'),
          ];
          break;
        case 'yesterday':
          this.date = [
            this.$dayjs()
              .subtract(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.$dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
          ];
          break;
        case 'thisWeek':
          this.date = [
            this.$dayjs().startOf('week').format('YYYY-MM-DD'),
            this.$dayjs().endOf('week').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastWeek':
          this.date = [
            this.$dayjs()
              .subtract(1, 'week')
              .startOf('week')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'week')
              .endOf('week')
              .format('YYYY-MM-DD'),
          ];
          break;
        case 'thisMonth':
          this.date = [
            this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            this.$dayjs().endOf('month').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastMonth':
          this.date = [
            this.$dayjs()
              .subtract(1, 'month')
              .startOf('month')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'month')
              .endOf('month')
              .format('YYYY-MM-DD'),
          ];
          break;
        case 'thisYear':
          this.date = [
            this.$dayjs().startOf('year').format('YYYY-MM-DD'),
            this.$dayjs().endOf('year').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastYear':
          this.date = [
            this.$dayjs()
              .subtract(1, 'year')
              .startOf('year')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'year')
              .endOf('year')
              .format('YYYY-MM-DD'),
          ];
          break;
        case '':
          this.date = [];
          break;
        case 'custom':
          break;
        default:
          alert('TimeFilter > unknown range "' + date + '"');
      }
      this.showDatePicker = false;
      this.returnValue();
    },
    prevRange() {
      let prevRange = [];
      if (!this.monthOnly) {
        prevRange[0] = this.$dayjs(this.date[0])
          .subtract(1, this.rangeType)
          .format('YYYY-MM-DD');
        if (this.rangeType == 'month') {
          // Monatsende muss berechnet werden (28-31)
          prevRange[1] = this.$dayjs(this.date[1])
            .subtract(1, this.rangeType)
            .endOf('month')
            .format('YYYY-MM-DD');
        } else {
          prevRange[1] = this.$dayjs(this.date[1])
            .subtract(1, this.rangeType)
            .format('YYYY-MM-DD');
        }
      } else {
        prevRange = this.$dayjs(this.date)
          .subtract(1, this.rangeType)
          .format('YYYY-MM');
      }
      this.date = prevRange;
      this.returnValue();
    },
    nextRange() {
      let nextRange = [];
      if (!this.monthOnly) {
        nextRange[0] = this.$dayjs(this.date[0])
          .add(1, this.rangeType)
          .format('YYYY-MM-DD');
        if (this.rangeType == 'month') {
          // Monatsende muss berechnet werden (28-31)
          nextRange[1] = this.$dayjs(this.date[1])
            .add(1, this.rangeType)
            .endOf('month')
            .format('YYYY-MM-DD');
        } else {
          nextRange[1] = this.$dayjs(this.date[1])
            .add(1, this.rangeType)
            .format('YYYY-MM-DD');
        }
      } else {
        nextRange = this.$dayjs(this.date)
          .add(1, this.rangeType)
          .format('YYYY-MM');
      }
      this.date = nextRange;
      this.returnValue();
    },
  },
  mounted() {
    this.date = JSON.parse(JSON.stringify(this.value));
    this.returnValue();
  },
  watch: {
    date() {
      // Ist Date ein Objekt
      if (typeof this.date === 'object') {
        if (this.date[1] < this.date[0]) {
          // wenn to < from
          this.date[0] = this.date[1]; // from setzen
          this.date.pop(); // to löschen
        } else {
          // und ist voll? Dann schließe das Fenster
          if (this.date.length === 2) {
            this.showDatePicker = false;
          }
        }
      }
    },
  },
};
</script>

<style></style>
