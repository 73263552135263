<template>
  <bc-widget-frame
    v-if="showWidget"
    title="Deine letzten Zeiten"
    subtitle=""
    icon="mdi-clock"
    @actionButtonClicked="newTime"
  >
    <template v-slot:action>
      <v-btn
        data-cy="bc-dashboard-times-list-new-time"
        v-if="$store.getters[$storenames.data.getters.timeablePTMs].length > 0"
        small
        icon
        color="boo"
        :disabled="!ptms.length"
        @click="newTime"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <div id="mylatesttimes">
      <times-data-table
        :items="timesForDataTable"
        :headers="headers"
        :items-per-page="rows"
        :hide-default-footer="true"
      />
    </div>
  </bc-widget-frame>
</template>

<script lang="ts">
import BcTimeStatusChip from '@/views/time/TimeStatusChip.vue';
import BcTimeRecording from '@/views/time/TimeRecording.vue';
import store from '@/store';
import TimesDataTable from '@/views/time/TimesDataTable.vue';
import { defineComponent } from 'vue';
import { TimeType } from '@/types/types';
import { Dayjs } from 'dayjs';
import { createTimesHeaderForDashboardList } from '@/views/time/timeHeaders';

export default defineComponent({
  name: 'BvDashboardTimesList',

  components: {
    BcTimeStatusChip,
    BcTimeRecording,
    TimesDataTable,
  },

  data() {
    return {
      loading: false,

      axiosSuccess: null,
      axiosError: null,

      rows: 5, // wie viele Zeiten/Zeilen auflisten; wäre ein nice user-setting
    };
  },

  computed: {
    showWidget(): boolean {
      return (
        this.$store.state.auth.attributes[this.$store.state.app.domain.slug]
          .canMyTimes && this.timesForDataTable.length > 0
      );
    },
    timesForDataTable(): TimeType[] {
      return this.times.filter((item: TimeType) => {
        return item.ptm.person.id === this.$store.state.auth.user.person.id;
      });
    },
    headers() {
      return createTimesHeaderForDashboardList(this.$vuetify.breakpoint);
    },
    times(): TimeType[] {
      return store.state.timesStore.times;
    },
    ptms() {
      return this.$store.state.data.ptms;
    },
  },

  mounted() {},

  methods: {
    refreshData() {
      store.dispatch.timesStore.fetchAllTimes();
      this.$store.dispatch('getPTMs');
    },

    newTime() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          new: true,
          // ptms: this.ptms, // null → alle ermöglichen
        },
      });
    },
    openTime(time: TimeType, gotoComment: boolean) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          timeId: time.id,
          ptms: this.ptms,
          gotoComment: gotoComment === true,
        },
      });
    },
    isRecording(time: TimeType) {
      return time.start && !time.stop;
    },
    formatEnde(stop: string) {
      return stop ? this.$dayjs(stop).format('HH:mm') : '';
    },
    daydiff(oDT: Dayjs) {
      return this.$dayjs().dayOfYear() - oDT.dayOfYear();
    },
  },
});
</script>
