<template>
  <v-navigation-drawer
    ref="contentDrawer"
    v-model="visible"
    v-click-outside="{
      handler: onClickOutside,
      closeConditional: clickOutsideConditional,
      include: clickOutsideInclude,
    }"
    right
    class="elevation-1"
    width="500px"
    fixed
    :app="isMobile"
    :temporary="locked"
    :permanent="locked"
    :hide-overlay="!locked"
    xoverlay-color="white"
    xoverlay-opacity="0.5"
    stateless
  >
    <component :is="component" v-if="component" :data="drawerData" />
    <slot />
  </v-navigation-drawer>
</template>
<script>
import BcProjectEdit from '@/views/project/ProjectEdit';
import BcProjectAddMember from '@/views/project/ProjectAddMember';
import BcPersonEdit from '@/views/crm/person/PersonEdit';
import EditPersonExternalDataSources from '@/views/crm/person/createFromExternal/EditPersonExternalDataSources.vue';
import BcTimeEdit from '@/views/time/TimeEdit';
import BcTrmEdit from '@/views/time/TrmEdit';
import BcShowExternalConnectionsDrawer from '@/views/crm/person/createFromExternal/ShowExternalConnectionsDrawer.vue';
import BcTagEdit from '@/views/tags/TagEdit';

export default {
  name: 'ContentDrawer',

  components: {
    BcTimeEdit,
    BcProjectEdit,
    BcProjectAddMember,
    BcPersonEdit,
    BcTrmEdit,
    EditPersonExternalDataSources,
    BcShowExternalConnectionsDrawer,
    BcTagEdit,
  },

  props: {
    // right: {
    //   type: Boolean,
    //   default: false
    // },
    // temporary: {
    //   type: Boolean,
    //   default: false
    // },
    // drawer: {
    //   type: Object,
    //   default: () => {}
    // }
  },

  data() {
    return {
      // lockBackground: false, // Hintergrund (=Hauptseite) ist gesperrt
      hideoverlay: false,

      temporary: false, // versteht LuJ noch nicht

      // CD wurde innert letzter Sekunde erst geöffnet; benötigt, weil click-outside erst nach dem eigentlichem (outside) Click-Event exekutiert wird > würde also gleich wieder schließen.
      // @@@ deswegen ist auch schneller Wechsel z.B. in Zeiten-Liste nicht möglich, weil erst neugeladen und dann geschlossen wird
      justOpened: false,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    component() {
      return this.$store.state.contentDrawer.component;
    },
    drawerData() {
      return this.$store.state.contentDrawer.data;
    },
    visible: {
      get() {
        // console.log('BcCD > get visible: ' + this.$store.state.contentDrawer.visible)
        return this.$store.state.contentDrawer.visible;
      },
      // weil v-model vom Drawer und bei Klick in backgroud
      set(newValue) {
        // console.log('BcCD > set visible to: ' + newValue)
        this.$store.commit(
          newValue ? 'contentDrawer/SHOW' : 'contentDrawer/HIDE'
        );
      },
    },
    locked() {
      return this.$store.state.contentDrawer.locked;
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.justOpened = true;
        setTimeout(() => {
          this.justOpened = false;
        }, 1000);
      }
    },
  },
  methods: {
    clickOutsideInclude() {
      // HTML mit class="cdIncluded" triggert click-outside nicht
      return Object.values(document.querySelectorAll('.cdIncluded'));
      // Problem: z.B. List-Items von select bekommen diese class nicht :-/
    },
    onClickOutside() {
      // console.log('click-outside')
      if (
        this.$store.state.contentDrawer.visible &&
        !this.$store.state.contentDrawer.locked &&
        !this.justOpened
      ) {
        this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
      }
    },
    clickOutsideConditional(e) {
      // Problem: z.B. List-Items von select sind NICHT descandents vom v-select, sondern werden parallel im DOM generiert :-/
      // return this.$refs.contentDrawer.$el.contains(e.target)

      // Event-MouseClick-x mit links von CD vergleichen
      return e.clientX < this.$refs.contentDrawer.$el.offsetLeft;
    },
  },
};
</script>
