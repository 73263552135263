<template>
  <bv-frame
    headline="ZE-Modelle"
    subtitle="Hier kannst du Zeiterfasssungsmodelle verwalten"
    :loading="loading"
    new-button
    new-button-label="Neues Model"
    @newButton="newTRM"
    @refresh="loadData"
  >
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <v-card :loading="loading" flat class="pb-4" :outlined="!isMobile">
      <v-card-text class="pa-0 pa-sm-5">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="trms"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="200"
          @click:row="openTRM"
        >
          <template v-slot:item.created_at="{ item }">
            <span>{{ $dayjs(item.created_at).format('DD.MM.YYYY') }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </bv-frame>
</template>

<script>
import BvFrame from '@/views/Frame';

export default {
  name: 'BvTimerecmodels',

  components: {
    BvFrame,
  },

  // props: {
  // },

  data() {
    return {
      loading: false,

      trms: [],

      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Beschreibung', value: 'description' },
        // { text: 'erstellt von', value: 'owner.label' },
        // { text: 'erstellt am', value: 'created_at' },
        // { text: '', value: 'actions', sortable: false }
      ],

      axiosSuccess: null,
      axiosError: null,
    };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  methods: {
    loadData() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'times/recmodels')
        .then((response) => {
          this.axiosSuccess = response;
          this.trms = response.data.success;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newTRM() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-trm-edit',
        data: {
          new: true,
        },
      });
    },
    appendData(trm) {
      this.trms.push(trm);
      // this.activeRow = trm.id
    },
    openTRM(trm) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-trm-edit',
        data: {
          trm,
        },
      });
    },
  },
};
</script>
──
