<script lang="ts">
import { DataTableHeaderType, ProjectType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import { VDataTable } from 'vuetify/lib';
import BcAvatar from '@/components/global/BcAvatar.vue';
import { getProjectStatusColor } from './helpers';
import ProjectCard from './ProjectCard.vue';

export default defineComponent({
  name: 'ProjectsDataTable',

  components: {
    VDataTable,
    BcAvatar,
    ProjectCard,
  },

  props: {
    projects: {
      type: Array as PropType<ProjectType[]>,
      required: true,
    },
    statusOptions: {
      type: Array as PropType<{ id: number; name: string }[]>,
      required: true,
    },
    headers: {
      type: Array as PropType<DataTableHeaderType[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      view: 0,
    };
  },

  watch: {},

  computed: {
    headersFiltered(): DataTableHeaderType[] {
      return this.headers.filter?.((header) => header.visible);
    },
  },

  methods: {
    showProject(project: any) {
      if (!project) return;
      this.$router.push({
        name: 'projectView',
        params: {
          projectSlug: project.slug,
          project: project,
        },
      });
    },
    customFilter(_value: string, search: string, item: ProjectType) {
      const haystack = item.label
        .concat(' ', item.tags.map((tag) => tag.name).join(' '))
        .concat(
          ' ',
          this.statusOptions.find((status) => status.id === item.status_id)
            ?.name || ''
        )
        .concat(' ', item.description || '')
        .toLowerCase();
      return haystack.includes(search.toLowerCase());
    },
    getProjectStatusColor,
    openSingleResult() {
      const filteredProjects = this.projects.filter((project) =>
        this.customFilter('', this.search, project)
      );
      if (filteredProjects.length === 1) {
        this.showProject(filteredProjects[0]);
      }
    },
  },
});
</script>

<template>
  <div>
    <v-text-field
      dense
      outlined
      rounded
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      label="Suche nach Name, Tags, Status, Beschreibung..."
      single-line
      hide-details
      autofocus
      clearable
      @keydown.native.enter="openSingleResult"
    ></v-text-field>

    <v-card-actions>
      <v-spacer />
      <v-btn-toggle class="ml-2" mandatory v-model="view" size="small">
        <v-btn>
          <v-icon>mdi-format-list-text</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>

    <v-data-table
      class="transparent"
      :items="projects"
      item-key="id"
      :search="search"
      :headers="headersFiltered"
      :sort-by="['latest_access']"
      :sort-desc="[true]"
      @click:row="showProject"
      :custom-filter="customFilter"
      :loading="loading"
      mobile-breakpoint="200"
      :items-per-page="view === 1 ? 9 : 10"
      :hide-default-header="view === 1"
    >
      <template v-slot:item="{ item }" v-if="view === 1">
        <project-card
          :project="item"
          :status-options="statusOptions"
          class="float-left"
          @click="showProject(item)"
        />
      </template>

      <template v-slot:item.label="{ item }">
        <div class="d-flex align-center">
          <bc-avatar :pojo="item" :size="30" class="mr-5" />
          <span>{{ item.label }}</span>
        </div>
      </template>

      <template v-slot:item.tags="{ item }">
        <v-chip
          v-for="tag in item.tags"
          :key="tag.name"
          class="my-2 mr-1"
          small
          outlined
        >
          {{ tag.name }}
        </v-chip>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          :color="getProjectStatusColor(item.status_id)"
          class="white--text"
          small
          outlined
        >
          {{
            statusOptions.find((status) => status.id === item.status_id)?.name
          }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>
