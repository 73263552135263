<template>
  <div class="text-right">
    <div v-if="myLevels.length > 0">
      <status-chip
        v-if="
          !canDispute &&
          !canRecord &&
          !canCheck &&
          !canComplete &&
          !showOnlyAction
        "
        :close="canWidthdraw && !storing"
        @close="changeStatus(value - 10)"
        :label="$boo.statusInfo(type, value).status.label"
        :icon="$boo.statusInfo(type, value).status.icon"
        :loading="storing"
      />
      <dispute-button
        v-if="canDispute"
        :disabled="disabled"
        @click="changeStatus(value - 10)"
        :loading="storing"
        :item="item"
        :label="$boo.statusInfo(type, value, this.$me.owns(item)).prev.label"
      />
      <accept-button
        v-if="canRecord"
        :disabled="disabled"
        @click="changeStatus(10)"
        :loading="storing"
        :label="$boo.statusInfo(type, 0).next.label"
        :icon="$boo.statusInfo(type, 0).next.icon"
      >
        <template #warning>
          <div v-if="recordBillingWarning">
            <p>
              Beachte, dass danach keine Zeiten für
              {{ $dayjs(item.enddate).format('MMMM') }} mehr eingetragen werden
              können (solange die Abrechnung noch nicht bestätigt wurde, kannst
              du die Einreichung wieder rückgängig machen).
            </p>
            <p>
              {{ $dayjs(item.enddate).format('MMMM') }}-Abrechnung trotzdem
              jetzt einreichen?
            </p>
          </div>
        </template>
      </accept-button>
      <accept-button
        v-if="canCheck"
        :disabled="disabled"
        @click="changeStatus(20)"
        :loading="storing"
        :label="$boo.statusInfo(type, 10).next.label"
        :icon="$boo.statusInfo(type, 10).next.icon"
      />
      <accept-button
        v-if="canComplete"
        :disabled="disabled"
        @click="changeStatus(30)"
        :loading="storing"
        :label="$boo.statusInfo(type, 20).next.label"
        :icon="$boo.statusInfo(type, 20).next.icon"
      />
    </div>
    <div v-else>
      <status-chip
        v-if="!showOnlyAction"
        :label="$boo.statusInfo(type, value).status.label"
        :loading="storing"
      />
    </div>

    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
      show-in-dialog
    />
  </div>
</template>

<script>
import acceptButton from './acceptButton.vue';
import disputeButton from './disputeButton.vue';
import statusChip from './statusChip.vue';
import store from '@/store';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';

export default {
  name: 'BcStatusSwitch',

  components: { BcResponseHandler, acceptButton, disputeButton, statusChip },

  props: {
    value: {
      type: Number,
      default: null,
      validator: function (value) {
        return [null, 0, 10, 20, 30].indexOf(value) !== -1;
      },
    },
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'time',
      validator: function (value) {
        return ['time', 'timebilling'].indexOf(value) !== -1;
      },
    },
    onSuccess: {
      type: Function,
      default: () => console.log('onSuccess not implemented'),
    },
    // set true for action (like clicking the button manually)
    processNow: {
      type: Boolean,
      default: false,
    },
    showOnlyAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      storing: false,
      axiosSuccess: null,
      axiosError: null,
    };
  },

  computed: {
    apiURL() {
      switch (this.type) {
        case 'timebilling':
          return `${this.$boo.lboUrl()}timebillings/${this.item.id}/status`;
        case 'time':
          return `${this.$boo.lboUrl()}times/${this.item.id}/status`;
        default:
          console.log('Type not defined');
          return;
      }
    },
    myLevels() {
      return this.item.status_permission_level || [];
    },
    canRecord() {
      if (!this.myLevels.includes(10)) return false;
      return this.value === 0;
    },
    canCheck() {
      if (!this.myLevels.includes(20)) return false;
      return (
        (this.value === 0 && this.myLevels.includes(10)) || this.value === 10
      );
    },
    canComplete() {
      if (!this.myLevels.includes(30)) return false;
      return this.value === 20;
    },
    canDispute() {
      return (
        (this.myLevels.includes(20) && this.value === 10) ||
        (this.myLevels.includes(30) && this.value === 20)
      );
    },
    canWidthdraw() {
      return (
        (this.myLevels.includes(20) && this.value === 30) ||
        (this.myLevels.includes(10) && this.value === 20) ||
        (this.myLevels.includes(0) && this.value === 10)
      );
    },
    recordBillingWarning() {
      return (
        this.type === 'timebilling' &&
        this.$dayjs(this.item.enddate) > this.$dayjs()
      );
    },
  },

  watch: {
    processNow: function (newState) {
      if (newState === false) return;
      this.changeStatus(this.value + 10);
    },
  },

  methods: {
    changeStatus(id) {
      this.storing = true;
      window.axios
        .patch(this.apiURL, { status_id: id })
        .then((response) => {
          this.axiosSuccess = response;

          if (this.type === 'time') {
            store.commit.timesStore.setTime(response.data.success);
          }
          if (this.type === 'timebilling') {
            store.commit.timebillingStore.setTimebilling(response.data.success);
          }

          store.commit.timesStore.setTime(response.data.success);
          // this.onSuccess?.(response.data.success);
          // this.$emit('input', response.data.success.status_id); // @@@ LuJ: das wirft console error; TimeEdit.vue
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
          this.$emit('process-finished');
        });
    },
  },
};
</script>
