export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Url-Base for API-Call
export const LBO_URL = process.env.VUE_APP_BOOBE_URL;
export const LBO_API_URL = `${process.env.VUE_APP_BOOBE_URL}/api/` as const;

// Environment
export const ENV = process.env.NODE_ENV;
export const VERSION = process.env.VERSION;

// Pusher Channels
export const PUSHER_KEY = process.env.PUSHER_KEY;
export const PUSHER_CLUSTER = process.env.PUSHER_CLUSTER;

// Date/time Formate
export const S_DE_DATE = 'TT.MM.JJJJ';
export const S_DE_TIME = 'hh:mm';
export const S_DE_DATETIME = S_DE_DATE + ' ' + S_DE_TIME;

export const F_DE_DATE = 'DD.MM.YYYY';
export const F_DE_DATE_NICE = 'dd, ' + F_DE_DATE;
export const F_DE_TIME = 'HH:mm';
export const F_DE_DATETIME = F_DE_DATE + ' ' + F_DE_TIME;
export const F_DE_DATETIME_NICE = 'dd, ' + F_DE_DATETIME;

export const F_EN_TIME = F_DE_TIME;
export const F_EN_DATE = 'YYYY-MM-DD';
export const F_EN_DATETIME = F_EN_DATE + ' ' + F_EN_TIME;

// https://unicode.org/emoji/charts/full-emoji-list.html
export const CHATSYMBOLS = ['👍', '🙂', '😀', '👻', '💤']; // , '❤️']

export const BOO_NEXT_URL =
  process.env.VUE_APP_BOO_NEXT_URL || 'https://next.booapp.eu';
