<script lang="ts">
import { PropType, defineComponent } from 'vue';

export type ButtonAction = {
  showCondition: boolean;
  label: string;
  icon: string;
  action: () => void;
};

export default defineComponent({
  name: 'DropDownButton',

  props: {
    primaryAction: {
      type: Object as PropType<ButtonAction>,
      required: true,
    },
    secondaryActions: {
      type: Array as PropType<ButtonAction[]>,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {};
  },
});
</script>

<template>
  <v-btn-toggle dense>
    <v-btn
      style="opacity: 1"
      dense
      class="boo"
      @click="primaryAction.action"
      v-if="primaryAction.showCondition"
    >
      <v-icon color="white" small>{{ primaryAction.icon }}</v-icon>
      {{ primaryAction.label }}</v-btn
    >
    <v-menu
      offset-y
      v-if="secondaryActions.filter((item) => item.showCondition).length"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn style="opacity: 1" v-bind="attrs" v-on="on" text class="boo">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(secondaryAction, key) in secondaryActions.filter(
            (item) => item.showCondition
          )"
          @click="secondaryAction.action"
          :key="key"
        >
          <v-list-item-icon
            ><v-icon small>{{ secondaryAction.icon }}</v-icon></v-list-item-icon
          >
          <v-list-item-title>{{ secondaryAction.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn-toggle>
</template>
