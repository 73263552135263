<template>
  <v-card
    class="d-flex align-center mx-2"
    flat
    @mouseover="hoveringCard = true"
    @mouseout="hoveringCard = false"
  >
    <v-text-field
      v-if="!isNew"
      :label="phoneLabel"
      :value="phoneText"
      readonly
      :disabled="isDeactivated"
      :hint="phoneHint"
      :persistent-hint="true"
    >
      <template v-slot:prepend>
        <v-tooltip v-if="canNotCall" open-on-click bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <v-btn icon>
              <v-icon v-on="on">
                {{ isDeactivated ? 'mdi-minus-circle-outline' : thisPhoneIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{
              $store.getters[$storenames.auth.getters.userCan](
                'person.update'
              ) &&
              canAdvanced &&
              !isDeactivated
                ? "Um diese Nummer per Klick anzurufen, muss die Funktion 'Telefonie' in diesem Telefonkontakt aktiviert sein."
                : 'automatischer Anruf nicht möglich'
            }}
          </span>
        </v-tooltip>
        <v-tooltip v-if="canCall" open-on-click bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <v-btn icon :href="phoneURI" :disabled="isDeactivated" v-on="on">
              <v-icon>{{
                isDeactivated ? 'mdi-minus-circle-outline' : thisPhoneIcon
              }}</v-icon>
            </v-btn>
          </template>
          <span>Diese Nummer anrufen</span>
        </v-tooltip>
      </template>
    </v-text-field>

    <v-dialog
      v-model="showDialog"
      persistent
      retain-focus
      max-width="500px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <template v-slot:activator="{ on }">
        <v-btn fab right x-small text v-on="on">
          <v-icon
            v-show="isNew || hoveringCard || $vuetify.breakpoint.mdAndDown"
            :data-cy="isNew ? 'iconNewPhone' : 'iconDots'"
          >
            {{ isNew ? 'mdi-phone-plus' : 'mdi-dots-horizontal' }}
          </v-icon>
        </v-btn>
      </template>

      <v-card :loading="loading">
        <v-card-title color="boo">
          <v-icon large left class="mr-5">
            {{
              doEdit
                ? !isNew
                  ? 'mdi-phone-settings'
                  : 'mdi-phone-plus'
                : 'mdi-phone'
            }}
          </v-icon>
          {{
            isNew
              ? 'neuer Telefonkontakt'
              : doEdit
              ? deleteMode
                ? 'Tel-Kontakt löschen'
                : 'Tel-Kontakt bearbeiten'
              : 'Telefonkontakt'
          }}
          <v-spacer />
          <v-switch
            v-if="canAdvanced"
            v-model="advanced"
            label="erweitert"
            class="right"
            color="teal lighten-3"
            dense
          />
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-3">
          <v-form
            ref="formPhone"
            v-model="valid"
            class="px-3"
            :lazy-validation="true"
            @keyup.native.enter="submit"
            @keyup.native.escape="cancel"
          >
            <v-text-field
              v-model="phoneLabel"
              label="Gruppe"
              :rules="TextRules"
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
              autofocus
            >
              <template v-slot:append-outer>
                <v-tooltip
                  :valid="canEdit"
                  open-on-click
                  bottom
                  max-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Bezeichnung des Kontakts, nach dem auch gruppiert werden
                    kann. Wird oberhalb der Telefonnummer angezeigt. Die
                    Telefonkontakte werden nach diesem Wert sortiert.
                    Optional.</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>

            <v-text-field
              v-if="show"
              v-model="phoneText"
              label="Telefonnummer"
              readonly
              :prepend-icon="thisPhoneIcon"
            />

            <div v-if="doEdit" class="d-flex justify-space-between">
              <v-select
                v-if="advanced"
                v-model="phoneIcon"
                :disabled="deleteMode || isDeactivated"
                :items="phoneIconItems"
                class="mr-4"
                style="max-width: 100px"
              >
                <template slot="prepend-inner">
                  <v-icon right>
                    {{ phoneIcon ? thisPhoneIcon : '' }}
                  </v-icon>
                </template>
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-2"> {{ data.item.icon }} </v-icon
                  >{{ data.item.label }}
                </template>
                <template v-slot:append-outer>
                  <v-tooltip
                    :valid="canEdit"
                    open-on-click
                    bottom
                    max-width="300px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="doEdit" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span
                      >Das auswählbare Icon kann optisch auf die Funktionalität
                      dieses Telefonkontaktes hinweisen.</span
                    >
                  </v-tooltip>
                </template>
              </v-select>

              <v-combobox
                v-model="phoneText"
                :disabled="deleteMode || isDeactivated"
                :label="phoneTextLabel"
                :rules="PhoneRules"
                :hint="phoneTextHint"
                :error="phoneTextError"
                no-filter
                :xmenu-props="{ openOnClick: phoneTextDroppeddown }"
                :items="phoneTextProposal"
                @update:search-input="proposePhoneText($event)"
                @keyup.native.enter.stop
                @keyup.native.escape.stop
                data-cy="comboPhonenumber"
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    :valid="canEdit"
                    open-on-click
                    bottom
                    max-width="300px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="doEdit" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span
                      >Die Telefonnummer sollte im internationalen Format
                      gespeichert werden, damit sie eindeutig ist und die
                      automatische Wahl unterstützt.</span
                    >
                  </v-tooltip>
                </template>
              </v-combobox>
            </div>

            <v-text-field
              v-if="advanced"
              v-model="phoneHint"
              label="Bemerkung"
              :rules="TextRules"
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
            >
              <template v-slot:append-outer>
                <v-tooltip
                  :valid="canEdit"
                  open-on-click
                  bottom
                  max-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Ein kurzer Hinweis zu diesem Kontakt. Wird unterhalb der
                    Telefonnummer angezeigt. Optional.</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>

            <div
              v-if="show && phoneFeatures"
              class="d-flex justify-space-between mb-5"
            >
              <v-chip v-if="canCall" :href="phoneURI">
                <v-icon class="mr-1"> mdi-phone </v-icon>anrufen
              </v-chip>
              <v-chip v-if="canWhatsapp" :href="whatsappURI" target="_blank">
                <v-icon class="mr-1"> mdi-whatsapp </v-icon>chatten
              </v-chip>
            </div>
            <v-select
              v-if="advanced && doEdit"
              v-model="phoneFeatures"
              :disabled="deleteMode || isDeactivated"
              :items="phoneIconFeatures"
              :menu-props="{ offsetY: true }"
              label="Funktionen"
              chips
              multiple
              hide-selected
              deletable-chips
              :readonly="show"
              @keyup.native.enter.stop
              @keyup.native.escape.stop
            >
              <template slot="item" slot-scope="data">
                <v-icon class="mr-2"> {{ data.item.icon }} </v-icon
                >{{ data.item.text }}
              </template>
              <template slot="no-data"> alles ausgewählt </template>
              <template v-slot:append-outer>
                <v-tooltip
                  :valid="canEdit"
                  open-on-click
                  bottom
                  max-width="300px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Was kann dieser Telefonanschluss?</span>
                </v-tooltip>
              </template>
            </v-select>

            <v-textarea
              v-if="advanced"
              v-model="phoneNotice"
              label="Notizen"
              auto-grow
              rows="4"
              outlined
              :disabled="deleteMode || isDeactivated"
              :readonly="show"
            />
            <v-switch
              v-if="advanced"
              v-model="phoneDeactivated"
              :label="
                phoneDeactivated ? 'Nummer deaktiviert' : 'Nummer deaktivieren'
              "
              :value="true"
              dense
              :readonly="show"
            >
              <template v-slot:append>
                <v-tooltip :valid="canEdit" open-on-click top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="doEdit" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Zum Deaktivierten eines Eintrags, ohne die Nummer gänzlich
                    zu löschen.</span
                  >
                </v-tooltip>
              </template>
            </v-switch>

            <v-checkbox
              v-if="!isNew && doEdit"
              v-model="deleteMode"
              label="diesen Telefonkontakt löschen"
              color="red darken-3"
              :value="true"
              hide-details
              dense
              :disabled="!canDelete"
            >
              <template v-slot:append>
                <v-tooltip :valid="canEdit" open-on-click top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline </v-icon>
                  </template>
                  <span
                    >Zum endgültigen Löschen diese Checkbox anhaken und dann mit
                    LÖSCHEN bestätigen. Diese Aktion kann nicht rückgängig
                    gemacht werden.</span
                  >
                </v-tooltip>
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>

        <bc-response-handler
          :axios-error="axiosError"
          :axios-success="axiosSuccess"
        />

        <bc-btn-cancel-action
          :valid="valid"
          :show="show"
          :can-edit="canEdit"
          :enable-action="inputHasChanged"
          :action-running="storing"
          :loading="loading"
          :delete-btn="deleteMode"
          @evEdit="doEdit = true"
          @evAction="submit"
          @evDelete="submitDelete"
          @evCancel="cancel"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export default {
  name: 'PhoneContact',

  props: {
    canEdit: Boolean,
    canAdvanced: Boolean,
    canDelete: Boolean,
    phoneId: {
      type: Number,
      default: null,
    },
    phoneData: {
      type: Object,
      default: null,
    },
    personId: { type: Number, default: null },
  },
  data() {
    return {
      userCountryCode: 'AT', // @@@ gehört in User-Setting !

      phoneDeactivated: false,
      phoneLabel: '',
      phoneText: '',
      phoneHint: '',
      phoneNotice: '',
      phoneIcon: '',
      phoneFeatures: this.phoneFeaturesDefault,
      deleteMode: false,

      phoneNumber: {},

      phoneTextDroppeddown: false,
      phoneTextProposal: [],
      phoneTextLabel: 'Telefonnummer',
      phoneTextHint: '',
      phoneTextError: false,

      phoneFeaturesDefault: 'mdi-phone',

      axiosError: null,
      axiosSuccess: null,

      loading: false,
      valid: true,
      showDialog: false,
      doEdit: false,
      storing: false,
      hoveringCard: false,

      PhoneRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => v.length > 3 || 'Eingabe zu kurz',
        (v) => v.length <= 30 || 'Eingabe zu lang',
      ],
      TextRules: [(v) => v.length <= 30 || 'Eingabe zu lang'],

      phoneIconItems: [
        {
          value: 'smart',
          text: '',
          icon: 'mdi-cellphone',
          label: 'Smartphone',
        },
        {
          value: 'cell',
          text: '',
          icon: 'mdi-cellphone-basic',
          label: 'Handy',
        },
        {
          value: 'wire',
          text: '',
          icon: 'mdi-phone-classic',
          label: 'Festnetz',
        },
        {
          value: 'system',
          text: '',
          icon: 'mdi-deskphone',
          label: 'Systemtelefon',
        },
        { value: 'pager', text: '', icon: 'mdi-keyboard', label: 'Pager' },
      ],
      phoneIconFeatures: [
        { value: 'call', text: 'Telefonie', icon: 'mdi-phone' },
        { value: 'sms', text: 'SMS', icon: 'mdi-keyboard' },
        { value: 'whatsapp', text: 'WhatsApp', icon: 'mdi-whatsapp' },
      ],
    };
  },

  computed: {
    isNew: function () {
      return !this.phoneId;
    },
    show: function () {
      return !this.doEdit;
    },

    inputHasChanged: function () {
      if (this.isNew) return true;
      if (this.deleteMode) return true;

      if (!this.phoneData || this.phoneText === undefined) return false;

      return (
        this.phoneDeactivated !== (this.phoneData.deactivated === 1) ||
        this.phoneLabel.trim() !== this.phoneData.label ||
        this.phoneText.trim() !== this.phoneData.text ||
        this.phoneHint.trim() !== this.phoneData.hint ||
        this.phoneNotice.trim() !== this.phoneData.notice ||
        this.phoneIcon !== this.phoneData.icon ||
        this.phoneFeaturesString !== this.phoneData.features
      );
    },
    phoneFeaturesString: function () {
      if (!this.phoneFeatures) return '';
      return this.phoneFeatures.toString();
    },
    phoneURI: function () {
      if (!this.phoneText) return false;
      return parsePhoneNumberFromString(
        this.phoneText,
        this.userCountryCode
      ).getURI();
    },
    whatsappURI: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        // Annahme: klein = Handy...
        return (
          'https://wa.me/' +
          parsePhoneNumberFromString(this.phoneText, this.userCountryCode)
            .number
        );
      }
      return (
        'https://api.whatsapp.com/send?phone=' +
        parsePhoneNumberFromString(this.phoneText, this.userCountryCode).number
      );
    },
    isDeactivated: function () {
      return this.phoneDeactivated === true;
    },
    canCall: function () {
      return this.phoneFeaturesString.includes('call');
    },
    canNotCall: function () {
      return !this.canCall;
    },
    canWhatsapp: function () {
      return this.phoneFeaturesString.includes('whatsapp');
    },
    advanced: {
      get: function () {
        return (
          this.$store.state.auth.settings.contactAdvanced === true &&
          this.canAdvanced
        );
      },
      set: function (newValue) {
        this.$store.commit('auth/SETSETTING', {
          item: 'contactAdvanced',
          value: newValue,
        });
        window.axios.post(this.$BC.LBO_URL + '/api/setting', {
          item: 'contactAdvanced',
          value: newValue,
        });
      },
    },
    thisPhoneIcon: function () {
      if (!this.phoneIcon) return 'mdi-phone';
      return this.phoneIconItems.find((x) => x.value === this.phoneIcon).icon;
    },
  },

  watch: {
    showDialog: function () {
      this.deleteMode = false;
      this.doEdit = this.isNew;
      if (this.isNew) {
        this.reset();
      } else {
        this.load();
      }
    },
  },

  mounted() {
    if (!this.isNew) {
      if (this.phoneData) {
        this.phoneDeactivated = this.phoneData.deactivated === 1;
        this.phoneLabel = this.phoneData.label;
        this.phoneText = this.phoneData.text;
        this.phoneHint = this.phoneData.hint;
        this.phoneNotice = this.phoneData.notice;
        this.phoneIcon = this.phoneData.icon;
        this.phoneFeatures = this.phoneData.features.split(',');
      }
    }
  },

  methods: {
    reset() {
      this.phoneDeactivated = '';
      this.phoneLabel = '';
      this.phoneText = '';
      this.phoneHint = '';
      this.phoneNotice = '';
      this.phoneIcon = '';
      this.phoneFeatures = '';
      // this.$refs.formPhone.resetValidation()
    },
    load() {
      this.loading = true;
      window.axios
        .get(this.$boo.lboUrl() + 'phones/' + this.phoneId)
        .then((response) => {
          this.axiosSuccess = response;
          this.phoneDeactivated = response.data.success.deactivated === 1;
          this.phoneLabel = response.data.success.label || '';
          this.phoneText = response.data.success.text || '';
          this.phoneHint = response.data.success.hint || '';
          this.phoneNotice = response.data.success.notice || '';
          this.phoneIcon = response.data.success.icon || '';
          this.phoneFeatures = response.data.success.features?.split(',') || [];
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.showDialog = false;
      this.$refs.formPhone.resetValidation();
      this.doEdit = false;
    },

    submit() {
      if (!this.$refs.formPhone.validate()) return;
      if (!this.doEdit) return;
      if (!this.inputHasChanged) return;

      this.storing = true;

      const transfer = {
        id: this.phoneId,
        person_id: this.personId,
        deactivated: this.phoneDeactivated || false, // shall not be null
        label: this.phoneLabel,
        text: this.phoneText,
        hint: this.phoneHint,
        notice: this.phoneNotice,
        icon: this.phoneIcon,
        features: this.phoneFeaturesString,
      };
      window
        .axios({
          method: this.phoneId ? 'patch' : 'post',
          url:
            this.$boo.lboUrl() +
            'phones' +
            (this.phoneId ? '/' + transfer.id : ''),
          data: transfer,
        })
        .then(() => {
          this.$emit('stored');
          this.showDialog = false;
          // this.$refs.formPhone.reset()
          this.$store.commit('app/SETSNACKBAR', {
            text: 'Telefonkontakt gespeichert',
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },

    submitDelete() {
      this.storing = true;

      window.axios
        .delete(this.$boo.lboUrl() + 'phones/' + this.phoneId)
        .then(() => {
          this.$emit('deleted', this.$vnode.key);
          this.showDialog = false;
          // this.$refs.formPhone.reset()
          this.$store.commit('app/SETSNACKBAR', {
            text: 'Telefonkontakt gelöscht',
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },

    proposePhoneText(event) {
      // console.log(event)
      const PHONETYPE = {
        MOBILE: 'Handy',
        FIXED_LINE: 'Festnetz',
        FIXED_LINE_OR_MOBILE: 'Festnetz oder Handy',
        PREMIUM_RATE: 'gebührenpflichtig',
        TOLL_FREE: 'gebührenfrei',
        SHARED_COST: 'SHARED_COST',
        VOIP: 'VOIP',
        PERSONAL_NUMBER: 'PERSONAL_NUMBER',
        PAGER: 'PAGER',
        UAN: 'UAN',
        VOICEMAIL: 'VOICEMAIL',
      };

      this.phoneTextProposal = [];

      if (event) {
        const proposals = [];

        this.phoneNumber = parsePhoneNumberFromString(
          event,
          this.userCountryCode
        );

        this.phoneTextError = true;
        this.phoneTextLabel = 'Telefonnummer';

        if (this.phoneNumber) {
          proposals.push(this.phoneNumber.format('INTERNATIONAL'));
          if (this.phoneNumber.country === this.userCountryCode) {
            proposals.push(this.phoneNumber.format('NATIONAL'));
          }

          this.phoneTextDroppeddown = true;

          this.phoneTextError = !this.phoneNumber.isPossible();

          if (this.phoneNumber.isPossible()) {
            this.phoneTextLabel = this.phoneNumber.country;

            const phonetype = this.phoneNumber.getType();
            this.phoneTextLabel += phonetype
              ? ` (${PHONETYPE[phonetype]})`
              : '';

            if (!this.phoneNumber.isValid()) {
              this.phoneTextLabel += '; Vorsicht, Nummer eventuell ungültig';
            }
          }
        }
        this.phoneTextProposal = proposals;
      }
    },
  },
};
</script>
