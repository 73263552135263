var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('bv-frame',{attrs:{"headline":"Personen","loading":_vm.loading},on:{"refresh":_vm.loadData},scopedSlots:_vm._u([{key:"buttonRight",fn:function(){return [(
        !_vm.isMobile &&
        _vm.$store.getters[_vm.$storenames.auth.getters.userCan]('person.create')
      )?_c('DropDownButton',{attrs:{"primaryAction":_vm.newButtonAction.primary,"secondaryActions":_vm.newButtonAction.secondary}}):(
        _vm.isMobile &&
        _vm.$store.getters[_vm.$storenames.auth.getters.userCan]('person.create')
      )?_c('FABGroup',{attrs:{"primaryAction":_vm.newButtonAction.primary,"secondaryActions":_vm.newButtonAction.secondary}}):_vm._e()]},proxy:true}])},[_c('bc-response-handler',{attrs:{"axios-error":_vm.axiosError,"axios-success":_vm.axiosSuccess}}),_c('PersonsDataTable',{attrs:{"persons":_vm.persons,"headers":_vm.headers,"loading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }