<template>
  <v-chip
    data-cy="status-chip"
    color="grey"
    outlined
    :close="close"
    @click:close="$emit('close')"
  >
    <v-icon v-if="icon && !loading" :left="true" small>
      {{ icon }}
    </v-icon>
    <v-progress-circular
      v-if="loading"
      size="20"
      width="2"
      indeterminate
      color="primary"
    />
    <span v-else-if="label">{{ label }}</span>
  </v-chip>
</template>

<script>
export default {
  name: 'BcStatusChip',

  props: {
    close: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
