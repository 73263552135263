<script lang="ts">
import { PersonType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import BvUserShow from '@/views/user/UserShow.vue';
import UserSettings from '@/views/user/UserSettings.vue';

export default defineComponent({
  name: 'UserTab',

  components: {
    BvUserShow,
    UserSettings,
  },

  props: {
    person: {
      type: Object as PropType<PersonType>,
      required: true,
      default: () => {},
    },
    userslug: {
      required: true,
      default: '',
    },
  },
  data() {
    return {};
  },
});
</script>

<template>
  <v-card>
    <v-card-title>
      <v-badge content="beta" offset-x="5" offset-y="5">
        User-Einstellungen
      </v-badge>
    </v-card-title>
    <bv-user-show
      :userslug="person.user.slug"
      :person="person"
      :embedded="true"
    />
  </v-card>
</template>
