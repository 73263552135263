<template>
  <v-card-text class="mt-3 body-1">
    {{ data }}
  </v-card-text>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: null,
    },
  },
};
</script>
