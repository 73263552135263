<script lang="ts">
import { EmailType, PersonType, PhoneType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import BvPhoneContact from '@/views/crm/PhoneContact.vue';
import BvMailContact from '@/views/crm/MailContact.vue';
import { canEditPerson } from '../../helpers';

export default defineComponent({
  name: 'ContactTab',

  components: {
    BvPhoneContact,
    BvMailContact,
  },

  props: {
    person: {
      type: Object as PropType<PersonType>,
      default: () => {},
      required: true,
    },
    phonesSorted: {
      type: Array as PropType<PhoneType[]>,
      default: () => [],
    },
    mailsSorted: {
      type: Array as PropType<EmailType[]>,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
    },
    canContactAdvanced: {
      type: Boolean,
    },
    reload: {
      type: Function,
    },
    removePhone: {
      type: Function,
    },
    removeMail: {
      type: Function,
    },
  },

  computed: {
    canDeleteContact(): boolean {
      return canEditPerson(this.person);
    },
  },
});
</script>

<template>
  <v-card>
    <v-card-title> Kontaktmöglichkeiten </v-card-title>
    <v-card
      id="Telefonkontakte"
      width="100%"
      style="display: inline-block"
      outlined
    >
      <v-card-title>
        Telefon-Kontakte
        <v-spacer />
        <bv-phone-contact
          v-if="canEdit"
          :can-edit="canEdit"
          :can-advanced="canContactAdvanced"
          :person-id="person.id"
          @stored="reload"
        />
      </v-card-title>
      <v-card-text v-if="phonesSorted.length === 0">
        Noch keine Telefonkontakte vorhanden.
      </v-card-text>
      <bv-phone-contact
        v-for="phone in phonesSorted"
        :key="phone.id"
        :can-edit="canEdit"
        :can-advanced="canContactAdvanced"
        :can-delete="canDeleteContact"
        :phone-id="phone.id"
        :phone-data="phone"
        :person-id="person.id"
        @stored="reload"
        @deleted="removePhone"
      />
    </v-card>
    <v-card
      id="Mailadressen"
      width="100%"
      style="display: inline-block"
      class="mt-2"
      outlined
    >
      <v-card-title>
        E-Mail-Kontakte
        <v-spacer />
        <bv-mail-contact
          v-if="canEdit"
          :can-edit="canEdit"
          :can-advanced="canContactAdvanced"
          :person-id="person.id"
          @stored="reload"
        />
      </v-card-title>
      <v-card-text v-if="mailsSorted.length === 0">
        Noch keine E-Mail-Kontakte vorhanden.
      </v-card-text>
      <bv-mail-contact
        v-for="mail in mailsSorted"
        :key="mail.id"
        :can-edit="canEdit"
        :can-advanced="canContactAdvanced"
        :can-delete="canDeleteContact"
        :email-id="mail.id"
        :email-data="mail"
        :person-id="person.id"
        @stored="reload"
        @deleted="removeMail"
      />
    </v-card>
  </v-card>
</template>
