import { DataTableHeaderType } from '@/types/types';
import { Breakpoint } from 'vuetify/types/services/breakpoint';

export const createPersonsDataTableHeaders = (
  breakpoint: Breakpoint
): DataTableHeaderType[] => {
  return [
    { text: 'Name', value: 'label', visible: true },
    {
      text: 'Tags',
      value: 'tags',
      visible: breakpoint.mdAndUp,
    },
    {
      text: '',
      value: 'attributes',
      align: 'end',
      sortable: false,
      visible: true,
    },
  ];
};
