<template>
  <div :class="{ 'd-flex align-center': !twoLines }" style="max-width: 440px">
    <span v-if="!date && !time">please choose date or time, dev...</span>

    <v-component
      :is="isMobile ? 'VDialog' : 'VMenu'"
      v-model="showDatePicker"
      offset-y
      max-width="290px"
      min-width="auto"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          data-cy="date-input"
          v-if="date"
          v-show="!hideDate"
          ref="BcDateTF"
          v-model="dtf"
          :outlined="outlined"
          dense
          :maxlength="$BC.S_DE_DATE.length"
          :label="labelDate"
          :placeholder="$BC.S_DE_DATE"
          :disabled="!!relativeDate && false"
          autocomplete="off"
          clearable
          :error-messages="errorMsgDate"
          :prefix="prefix"
          @focus="dtfFocus(true)"
          @input.native.stop="DateTextfieldChanged"
          @keyup="DateTextfieldKeyup"
          @keydown="TextfieldKeydown"
          @click:clear.stop="clearDateTextfield"
          v-bind="attrs"
          v-on="isMobile ? on : undefined"
          prepend-icon="mdi-calendar"
          @click:prepend="showDatePicker = !showDatePicker"
        />
      </template>
      <v-card v-if="showDateChoice">
        <v-card-actions class="justify-space-between">
          <v-btn text small class="mx-0" @click="setDay(-3)">
            {{ $dayjs().add(-3, 'day').format('dd') }}
          </v-btn>
          <v-btn text small class="mx-0" @click="setDay(-2)">
            {{ $dayjs().add(-2, 'day').format('dd') }}
          </v-btn>
          <v-btn text small class="mx-0" @click="setDay(-1)"> gestern </v-btn>
          <v-btn text small class="mx-0" @click="setDay(0)"> heute </v-btn>
        </v-card-actions>
      </v-card>
      <v-date-picker
        ref="datepicker"
        v-model="dp"
        :first-day-of-week="1"
        scrollable
        :show-week="showWeek"
        :locale-first-day-of-year="4"
        :min="min ? min.toISOString() : ''"
        :max="max ? max.toISOString() : ''"
      >
        <v-btn text @click="showDatePicker = false"> Abbrechen </v-btn>
        <v-spacer />
        <v-btn :disabled="!dp" @click="datePicked(dp)"> OK </v-btn>
      </v-date-picker>
    </v-component>

    <v-component
      :is="isMobile ? 'VDialog' : 'VMenu'"
      v-model="showTimePicker"
      offset-y
      max-width="290px"
      min-width="auto"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          data-cy="time-input"
          v-if="time"
          ref="BcTimeTF"
          v-model="ttf"
          :outlined="outlined"
          dense
          :maxlength="$BC.S_DE_TIME.length"
          :label="labelTime"
          autocomplete="off"
          clearable
          :error-messages="errorMsgTime"
          v-bind="attrs"
          v-on="isMobile ? on : undefined"
          @focus="ttfFocus(true)"
          @input.native.stop="TimeTextfieldChanged"
          @keyup="TimeTextfieldKeyup"
          @click:clear.stop="clearTimeTextfield"
          prepend-icon="mdi-av-timer"
          @click:prepend="showTimePicker = !showTimePicker"
        />
      </template>
      <v-card>
        <v-card-actions>
          <v-btn v-if="showTimeEdgeJumper" text @click="setDayStart()">
            00:00
          </v-btn>
          <v-spacer />
          <v-btn v-if="showTimeNowJumper" text @click="setTimeNow()">
            {{ nearestIntervalTo($dayjs()).format($BC.F_DE_TIME) }}
          </v-btn>
          <v-spacer />
          <v-btn v-if="showTimeEdgeJumper" text @click="setDayEnd()">
            23:59
          </v-btn>
        </v-card-actions>
        <v-time-picker
          ref="timepicker"
          v-model="tp"
          format="24hr"
          :allowed-minutes="(m) => m % timeInterval === 0"
          scrollable
        >
          <v-btn text @click="showTimePicker = false"> Abbrechen </v-btn>
          <v-spacer />
          <v-btn :disabled="!tp" @click="timePicked(tp)"> OK </v-btn>
        </v-time-picker>
      </v-card>
    </v-component>
  </div>
</template>

<script>
export default {
  name: 'BcDatetimeWidget',

  props: {
    value: {
      // input für v-model
      type: [Object, String], // $dayjs || YYYY-MM-DD || 2020-11-22T18:26:00.000Z || null
      default: null,
    },
    labelDate: {
      type: String,
      default: 'Datum',
    },
    labelTime: {
      type: String,
      default: 'Zeit',
    },
    // rules: {
    //   type: Array,
    //   default: function () { return [] }
    // },
    date: {
      type: Boolean,
      default: false,
    },
    mayBeEmpty: {
      // leer stört Validierung nicht
      type: Boolean,
      default: false,
    },
    hideDate: {
      // don't show date (but render)
      type: Boolean,
      default: false,
    },
    relativeDate: {
      type: [Object, String], // Referenz für <24hr
      default: null,
    },
    time: {
      type: Boolean,
      default: false,
    },
    prefixWeekday: {
      // if true > "TTT" links von Eingabe
      type: Boolean,
      default: false,
    },
    showWeek: {
      type: Boolean,
      default: false,
    },
    suggest: {
      // if true > auto-vervollständigt passendestes Datum; startet Picker mit Jahr
      type: Object,
      default: function () {
        return {
          date: null, // dayjs-Object
          time: null, // HH:mm
        };
      },
    },
    min: {
      // nicht früher als
      type: Object, // $dayjs
      default: function () {
        return this.$dayjs.utc('1900');
      },
    },
    max: {
      // nicht später als
      type: Object, // $dayjs
      default: function () {
        return this.$dayjs.utc('2100');
      },
    },
    timeInterval: {
      // Minutentakt
      type: Number,
      default: 1,
    },
    twoLines: {
      // date time untereinander
      type: Boolean,
      default: false,
    },
    showDateChoice: {
      // 4 Tage zurück zur Auswahl im DatePicker anzeigen
      type: Boolean,
      default: false,
    },
    showTimeEdgeJumper: {
      // 00:00 und 23:59 im TimePicker anzeigen
      type: Boolean,
      default: false,
    },
    showTimeNowJumper: {
      // now() im TimePicker anzeigen
      type: Boolean,
      default: false,
    },
    outlined: {
      // outlined
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      oDT: null, // der value als dayjs-Object
      oD: null, // das date (nur intern) als dayjs-Object
      dtf: '', // String im Date-Textfield (TT.MM.JJJJ)
      ttf: '', // String im Time-Textfield (HH:mm)
      dp: '', // String im DatePicker
      tp: '', // String im TimePicker
      lastKey: null,
      // showDatePicker: false,
      // showTimePicker: false,
      dateValid: false, // gültiges Datum
      timeValid: false, // gültiges Datum

      timepickerMenu: false,
      datepickerMenu: false,
      showTime: false,

      showDatePicker: false,
      showTimePicker: false,
    };
  },

  computed: {
    valid: function () {
      return (
        (!this.date || (this.date && this.dateValid)) &&
        (!this.time || (this.time && this.timeValid))
      );
    },
    dateOnly: function () {
      return this.date && !this.time;
    },
    timeOnly: function () {
      return !this.date && this.time;
    },
    datetime: function () {
      return this.date && this.time;
    },
    // tooltip: function () {
    //   return this.dateOnly
    //     ? (this.oD ? this.oD.format('dddd') : '')
    //     : (this.oDT ? this.oDT.format('ddd, ' + this.$BC.F_DE_DATETIME) : '')
    // },
    prefix: function () {
      return this.prefixWeekday && this.oD ? this.oD.format('ddd') + ',' : '';
    },
    appendIcon: function () {
      if (!this.time) return '';

      if (this.tp === '00:00') {
        return 'mdi-clock-start';
      }
      if (this.tp === '23:59') {
        return 'mdi-clock-end';
      }
      return 'mdi-clock-fast';
    },
    errorMsgDate() {
      if (this.date && !this.dateValid && !this.dtf && !this.mayBeEmpty) {
        return this.$BC.S_DE_DATE;
      }
      if (this.max && this.oDT && this.oDT.isAfter(this.max)) {
        return (
          'muss vor ' +
          this.max.utc(!this.dateOnly).format(this.$BC.F_DE_DATE) +
          ' liegen'
        );
      }
      if (this.min && this.oDT && this.oDT.isBefore(this.min)) {
        return (
          'muss nach ' +
          this.min.utc(!this.dateOnly).format(this.$BC.F_DE_DATE) +
          ' liegen'
        );
      }
      return '';
    },
    errorMsgTime() {
      return this.time && !this.timeValid && !this.ttf && !this.mayBeEmpty
        ? this.$BC.S_DE_TIME +
            (this.timeInterval > 1 ? ' (' + this.timeInterval + 'min)' : '')
        : '';
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  watch: {
    datepickerMenu(show) {
      if (show) {
        // this.showTimePicker = false
        if (this.oD && this.oD.isValid()) {
          this.$nextTick(() => (this.$refs.datepicker.activePicker = 'DATE'));
        } else {
          this.$nextTick(() => (this.$refs.datepicker.activePicker = 'YEAR'));
        }
      }
    },
    timepickerMenu(show) {
      // console.log('watch timepickerMenu ' + show)
      if (show) {
        this.tpFocus();
      }
    },
    // showDatePicker (val) {
    //   console.log('watch showDatePicker')
    //   // val && !this.value && !this.suggestCurrentDate && setTimeout(() => (this.$refs.datepicker.activePicker = 'YEAR'))
    //   // val && !this.value && setTimeout(() => (this.$refs.datepicker.activePicker = 'YEAR'))
    // },
    // min () {
    //   this.validate()
    // },
    // max () {
    //   this.validate()
    // },
    value(value) {
      // console.log('watch value')
      this.processValue(value);
    },
    // oD: function (val) {
    oD(val) {
      // console.log('watch oD')
      if (!val) {
        this.oD = null;
        this.dp = '';
        return;
      }
      this.dp = this.oD.format(this.$BC.F_EN_DATE);
      this.dtf = this.oD.format(this.$BC.F_DE_DATE);
      this.dateValid = true;
    },
    oDT(val) {
      // console.log('watch oDT')
      if (!val) {
        // this.oD = null // nein, weil das passiert auch, wenn nur time=''
        this.dp = '';
        this.tp = '';
        return;
      }
      this.oD = this.$dayjs(this.oDT.format(this.$BC.F_EN_DATE));
      this.dp = this.oDT.format(this.$BC.F_EN_DATE);
      this.tp = this.oDT.format(this.$BC.F_EN_TIME);
      this.dtf = this.oDT.format(this.$BC.F_DE_DATE);
      this.ttf = this.oDT.format(this.$BC.F_DE_TIME);
    },
    relativeDate(dtVal) {
      // console.log('relativeDate')
      // console.log(dtVal)
      if (!dtVal) return;
      if (!this.oDT) {
        this.oD = dtVal.startOf('day');
        this.dateValid = true;
        return;
      }
      if (dtVal > dtVal.hour?.(this.oDT.hour()).minute(this.oDT.minute())) {
        this.checkDate(dtVal.add(1, 'day').format(this.$BC.F_DE_DATE));
      } else {
        this.checkDate(dtVal.format?.(this.$BC.F_DE_DATE));
      }
    },
  },

  created() {
    // console.log('created')
    this.processValue(this.value);
  },

  methods: {
    closePickers() {
      this.showDatePicker = false;
      this.showTimePicker = false;
    },
    datePicked(value) {
      // this.$refs.showDatePicker.save(value) // übergibt value nicht korrekt(?), schließt aber den picker
      this.closePickers();
      // this.showDatePicker = false
      this.oD = this.$dayjs(value);
      if (this.oDT) {
        // time behalten
        this.oDT = this.oDT
          .date(this.oD.date())
          .month(this.oD.month())
          .year(this.oD.year());
      } else {
        // neu > nur date
        this.oDT = this.$dayjs(this.oD.format(this.$BC.F_EN_DATE));
      }
      this.dateValid = this.oDT.isValid();
      this.returnValue();
      // if (this.time) { // automatisches Öffnen des timepickers nach dem Datum
      //   this.pickTime()
      // }
    },
    // pickDate () {
    //   // this.showTimePicker = false
    //   // this.$nextTick(() => {
    //   //   this.showDatePicker = true
    //   // })
    // },
    // pickTime () {
    //   // console.log('pickTime')
    //   if (this.oD) {
    //     // this.showDatePicker = false
    //     // console.log('showTimePicker')
    //     this.timepickerMenu = true
    //     // this.showTimePicker = true
    //     // this.$refs.timepicker.selecting = 1 // immer mit Stunden beginnen @@@ wirft error !?!?
    //   } else {
    //     // this.showDatePicker = true
    //   }
    // },
    timePicked(value) {
      // console.log('change > timePicked: ' + value)
      this.closePickers();
      this.tp = value;
      const HH = value.substr(0, 2);
      const mm = value.substr(3, 2);
      // if (this.oDT) {
      if (this.oD) {
        // this.oDT = this.oDT.hour(HH).minute(mm)
        this.oDT = this.oD.hour(HH).minute(mm);
      } else {
        // no date but time
        this.ttf = HH + ':' + mm;
      }
      this.timeValid = true;
      // console.log(this.oDT)
      this.returnValue();
    },
    setDay(rel) {
      this.closePickers();
      this.dtf = this.$dayjs().add(rel, 'day').format(this.$BC.F_DE_DATE);
      this.checkDate(this.dtf);
    },
    setDayStart() {
      this.closePickers();
      this.ttf = '00:00';
      this.checkTime(this.ttf);
    },
    setDayEnd() {
      this.closePickers();
      this.ttf = '23:59';
      this.checkTime(this.ttf);
    },
    setTimeNow() {
      this.closePickers();
      this.ttf = this.nearestIntervalTo(this.$dayjs()).format(
        this.$BC.F_DE_TIME
      );
      this.checkTime(this.ttf);
    },
    clearDateTextfield() {
      // this.oD = null // weil das später via watcher nicht passiert :-/
      this.dateValid = false;
      this.checkDate('');
    },
    clearTimeTextfield() {
      this.tp = '';
      this.timeValid = false;
      this.checkTime('');
    },
    DateTextfieldKeyup(keyup) {
      // console.log('DateTextfieldKeyup')
      // console.log(keyup)

      if (keyup.key === 'F4') {
        // Dialog öffnen (und thoretisch schließen)
        this.showDatePicker = !this.showDatePicker;
      }
      if (keyup.key === ' ') {
        // heute
        this.dtf = this.$dayjs().format(this.$BC.F_DE_DATE);
        this.checkDate(this.dtf);
        this.dtfSelectRangeAtNextTick();
      }

      if (this.oD) {
        // geht nur wenn bereits Datum
        switch (keyup.key) {
          case 'ArrowUp': // Tag +/-
            this.dtf = this.oD.add(1, 'day').format(this.$BC.F_DE_DATE);
            this.dtfSelectRangeAtNextTick(0, 2);
            break;
          case 'ArrowDown':
            this.dtf = this.oD.add(-1, 'day').format(this.$BC.F_DE_DATE);
            this.dtfSelectRangeAtNextTick(0, 2);
            break;
          case 'PageUp': // Monat +/-
            this.dtf = this.oD.add(1, 'month').format(this.$BC.F_DE_DATE);
            this.dtfSelectRangeAtNextTick(3, 5);
            break;
          case 'PageDown':
            this.dtf = this.oD.add(-1, 'month').format(this.$BC.F_DE_DATE);
            this.dtfSelectRangeAtNextTick(3, 5);
            break;
        }
        this.checkDate(this.dtf);
      }
    },
    TextfieldKeydown(keyup) {
      // console.log('TimeTextfieldKeydown')
      // console.log(keyup)
      this.lastKey = keyup.key;
    },
    TimeTextfieldKeyup(keyup) {
      // console.log('TimeTextfieldKeyup')
      // console.log(keyup)
      if (keyup.key === 'F4') {
        // Dialog öffnen (und thoretisch schließen)
        this.timepickerMenu = !this.timepickerMenu;
      }
      if (keyup.key === ' ') {
        // jetzt
        this.ttf = this.nearestIntervalTo(this.$dayjs()).format(
          this.$BC.F_DE_TIME
        );
        this.checkTime(this.ttf);
        this.ttfSelectRangeAtNextTick();
      }
      if (this.timeValid && this.oDT) {
        // geht nur wenn bereits Zeit
        switch (keyup.key) {
          case 'ArrowUp': // Minute +/-
            this.ttf = this.oDT
              .add(this.timeInterval, 'minute')
              .format(this.$BC.F_DE_TIME);
            this.ttfSelectRangeAtNextTick(3, 5);
            break;
          case 'ArrowDown':
            this.ttf = this.oDT
              .add(-this.timeInterval, 'minute')
              .format(this.$BC.F_DE_TIME);
            this.ttfSelectRangeAtNextTick(3, 5);
            break;
          case 'PageUp': // Stunde +/-
            this.ttf = this.oDT.add(1, 'hour').format(this.$BC.F_DE_TIME);
            this.ttfSelectRangeAtNextTick(0, 2);
            break;
          case 'PageDown':
            this.ttf = this.oDT.add(-1, 'hour').format(this.$BC.F_DE_TIME);
            this.ttfSelectRangeAtNextTick(0, 2);
            break;
        }
        this.checkTime(this.ttf);
      }
    },
    DateTextfieldChanged() {
      // console.log('DateTextfieldChanged')

      // Prüfen ob Cursor im Text
      if (
        this.dtf &&
        this.$refs.BcDateTF.$el.querySelector('input').selectionStart <
          this.dtf.length
      )
        return this.checkDate(this.dtf);

      // console.log('>>' + this.dtf + '<<')
      let TT = '';
      let MM = '';
      let JJ = '';
      const dot = '.';

      this.dp = '';

      if (!this.dtf) this.dtf = '';

      // keine Leerzeichen
      this.dtf = this.dtf.trim();

      // Trennzeichen interpretieren
      const delimiter = ['.', '-', '/'];
      if (delimiter.includes(this.dtf.substr(1, 1))) {
        // '1-1-70' > '01.1-70'
        this.dtf = '0' + this.dtf.substr(0, 1) + dot + this.dtf.substr(2);
        // console.log('TZ_1: ' + this.dtf)
      }
      if (delimiter.includes(this.dtf.substr(4, 1))) {
        // '01.1-70' > '01.01-70'
        this.dtf = this.dtf.substr(0, 3) + '0' + this.dtf.substr(3);
        // console.log('TZ_2: ' + this.dtf)
      }

      // nur aus den Ziffern
      // const tfDigits = this.dtf.match(/[0-9]/g)?.join('') || [] // Optional Chaining requires #391
      const tfDigits = this.dtf.match(/[0-9]/g)
        ? this.dtf.match(/[0-9]/g).join('')
        : '';
      const digitsLen = tfDigits.length;
      if (digitsLen) {
        TT = tfDigits.substr(0, 2);
        MM = tfDigits.substr(2, 2);
        JJ = parseInt(tfDigits.substr(4, 2));
      }
      switch (digitsLen) {
        case 1:
          if (tfDigits >= 4) this.dtf = '0' + tfDigits + dot;
          break;
        case 2: // TT
          // console.log('case 2')
          if (tfDigits <= 31) this.dtf = tfDigits + dot; // @@@ das korrigiert die Eingabe z.B. 05+++ nicht - wieso auch immer @@@
          break;
        case 3:
          if (tfDigits.substr(2) >= 2)
            this.dtf = TT + dot + '0' + tfDigits.substr(2) + dot;
          break;
        case 4: // TTMM
          if (tfDigits.substr(2) <= 12)
            this.dtf = TT + dot + tfDigits.substr(2) + dot;
          break;
        case 6: // TTMMJJ
          // vor 19. Jhdt - wohl nicht
          if (JJ < 19) this.dtf = TT + dot + MM + dot + '20' + JJ;
          // und auch nicht 21xx
          if (JJ > 20) this.dtf = TT + dot + MM + dot + '20' + JJ;
          break;
      }
      // console.log('step 1: ' + this.dtf)
      if (!['Backspace', 'Delete'].includes(this.lastKey)) {
        // nicht bei backspace, Delete
        this.dtf = this.suggestDate(this.dtf);
        this.dp = this.dtf;
      }

      this.checkDate(this.dtf);
    },
    TimeTextfieldChanged() {
      // console.log('TimeTextfieldChanged')

      // Prüfen ob Cursor im Text
      if (
        this.ttf &&
        this.$refs.BcTimeTF.$el.querySelector('input').selectionStart <
          this.ttf.length
      )
        return this.checkTime(this.ttf);

      // console.log('>>' + this.ttf + '<<')
      let HH = '';
      let mm = '';
      const colon = ':';
      // const now = this.$dayjs()

      this.tp = '';

      if (!this.ttf) this.ttf = '';

      // keine Leerzeichen
      this.ttf = this.ttf.trim();

      // Trennzeichen interpretieren
      const delimiter = ['.', '-', '/', ':'];
      if (delimiter.includes(this.ttf.substr(1, 1))) {
        // '5:30' > '05:30'
        this.ttf = '0' + this.ttf.substr(0, 1) + colon + this.ttf.substr(2);
        // console.log('TZ_1: ' + this.ttf)
      }

      // nur aus den Ziffern
      // const tfDigits = this.ttf.match(/[0-9]/g)?.join('') || [] // Optional Chaining requires #391
      const tfDigits = this.ttf.match(/[0-9]/g)
        ? this.ttf.match(/[0-9]/g).join('')
        : null;
      const digitsLen = tfDigits ? tfDigits.length : 0; // falls this.ttf = ""

      if (digitsLen) {
        HH = tfDigits.substr(0, 2);
        mm = tfDigits.substr(2, 2);
      }
      switch (digitsLen) {
        case 1: // H
          if (tfDigits >= 3) this.ttf = '0' + tfDigits + colon;
          break;
        case 2: // HH
          if (tfDigits <= 24) this.ttf = tfDigits + colon;
          break;
        case 3: // HHm
          if (tfDigits.substr(2) >= 6)
            this.ttf = HH + colon + '0' + tfDigits.substr(2);
          break;
        case 4: // HHmm
          this.ttf = HH + colon + mm;
          break;
      }

      // console.log('Eingabe: ' + this.ttf)
      if (!['Backspace', 'Delete'].includes(this.lastKey)) {
        // nicht bei backspace, Delete
        this.ttf = this.suggestTime(this.ttf);
      }
      this.tp = this.ttf;
      // console.log('sugTime: ' + this.ttf)

      this.checkTime(this.ttf);
    },
    // prüft den überarbeiteten Eingabestring
    // setzt valid und oDT
    checkDate(dateString) {
      // console.log('checkDate')
      this.dateValid = true;
      this.oD = null;
      if (dateString === '') {
        // leer
        this.oDT = null;
      } else {
        const newDate = this.$dayjs(dateString, this.$BC.F_DE_DATE);
        if (!newDate.isValid()) {
          // ungültig, kein Datum
          this.dateValid = false;
          this.oDT = null;
        } else {
          // gültiges Datum
          this.oD = newDate;
          if (!this.time) {
            // nur Date
            this.oDT = newDate;
          } else {
            // auch Time
            if (this.timeValid && this.ttf) {
              // time behalten
              // this.oDT = this.oDT.date(this.oD.date()).month(this.oD.month()).year(this.oD.year())
              this.oDT = this.oD
                .hour(this.ttf.substr(0, 2))
                .minute(this.ttf.substr(3, 2))
                .second(0);
            } else {
              // Time noch ungültig
              // this.oDT = newDate
            }
          }
        }
      }
      this.returnValue();
    },
    // prüft den überarbeiteten Eingabestring
    // setzt valid und oDT @@@ ??
    checkTime(timeString) {
      // console.log('checkTime')
      this.timeValid = true;

      if (timeString === '') {
        // leer
        if (this.oDT) {
          this.oD = this.oDT.hour(0).minute(0).second(0);
        }
        this.timeValid = false; // 1.3. ist leer nicht valid? NEIN!
        this.oDT = null;
        this.returnValue();
        return;
      }

      // Länge möglich? HH:mm
      if (timeString.length !== 5) {
        this.timeValid = false;
        this.returnValue();
        return;
      }

      // eigene Date Eingabe oder heute()
      let newDTime = this.oDT || this.oD || this.relativeDate || this.$dayjs();
      newDTime = newDTime
        .hour(timeString.substr(0, 2))
        .minute(timeString.substr(3, 2));
      // console.log(newDTime)
      if (!newDTime.isValid()) {
        // ungültig
        this.timeValid = false;
      } else {
        // Interval prüfen
        if (newDTime.minute() % this.timeInterval) {
          this.timeValid = false;
          this.returnValue();
          return;
        }
        // gültig
        // if (this.date && this.dateValid) { // Datum auch gültig - 1.3. ist leer valid?
        if (this.date && this.oD) {
          // Datum auch vorhanden
          this.oDT = this.oD.hour(newDTime.hour()).minute(newDTime.minute());
        } else if (this.date && this.relativeDate) {
          // Datum war leer
          this.oDT = this.relativeDate
            .hour(newDTime.hour())
            .minute(newDTime.minute());
        } else if (!this.date) {
          // mur Zeit
          // this.value = newDTime.format(this.$BC.S_DE_TIME) // als String @@@ ?
          this.value = newDTime; // als Object
        }
        // Tagessprung
        if (this.oDT && this.relativeDate && this.oDT < this.relativeDate) {
          this.oDT = this.oDT.add(1, 'day');
        }
        if (
          this.oDT &&
          this.relativeDate &&
          this.oDT.diff(this.relativeDate, 'minute') > 24 * 60
        ) {
          this.oDT = this.oDT.subtract(1, 'day');
        }
      }
      this.returnValue();
    },
    // validiert und setzt ErrorMsgDate
    // emittet raus
    returnValue() {
      // output for v-model
      // console.log('returnValue()')

      if (!this.valid) {
        // console.log('not valid')
        this.$emit('input', null);
        // this.$emit('input', this.value)
        return;
      }

      if (this.dateOnly) {
        // Date
        if (this.oD) {
          // ok
          this.$emit('input', this.oD.format(this.$BC.F_EN_DATE));
        } else {
          // not ok
          this.$emit('input', null);
        }
      } else if (this.datetime) {
        // console.log('datetime')
        // Date + Time
        if (this.oDT) {
          // ok
          // console.log('ok')
          // Tagesende exakt
          if (this.oDT & (this.oDT.hour() === 23) && this.oDT.minute() === 59) {
            // this.oDT = this.oDT.endOf('day') // schafft die DB scheinbar nicht und rundet auf 23:00:00 (zulu)
            this.oDT.second(59);
          }
          // Validierungen
          // this.validate()
          this.$emit('input', this.oDT);
        } else {
          // not ok
          // console.log('notok')
          this.$emit('input', null);
        }
      } else if (this.timeOnly) {
        // Time
        alert('DEV: BcDatetimeWidget 787: timeOnly');
      } else {
        // ???
        alert('DEV: BcDatetimeWidget 789: whatIsIt?');
      }
    },
    suggestDate(ds) {
      // dateString
      // console.log('suggestDate')
      if (!this.suggest.date) return ds; // nicht gewünscht
      if (!ds) return ''; // leer
      if (ds.match(/[^0-9.]/g)) return ds; // nicht-Ziffern gefunden
      if (this.$dayjs(ds, this.$BC.F_DE_DATE).isValid()) return ds; // ist schon Datum

      const now = this.suggest.date;
      let bestDate = now.add(1, 'year');
      let tryDate = null;
      let suggestion = '';
      let TT = '';
      let M = '';
      let MM = '';
      let YYYY = '';
      let i = 0;
      let j = 0;

      switch (ds.length) {
        case 1: // 0, 1, 2, 3
          tryDate = now;
          i = -1;
          // Zukunft
          do {
            i++;
          } while (
            i < 100 &&
            now.add(i, 'day').format('DD').substr(0, 1) !== ds
          );
          // Vergangenheit
          j = -1;
          do {
            j++;
          } while (
            j < 100 &&
            now.subtract(j, 'day').format('DD').substr(0, 1) !== ds
          );
          suggestion = now.add(i < j ? i : -j, 'day');
          this.dtfSelectRangeAtNextTick(1);
          break;

        case 3: // TT.
          // console.log('case 3')
          TT = parseInt(ds.substr(0, 2));
          for (i = -3; i <= 3; i++) {
            // Monate varieren: wegen 28/30/31 mehr versuchen!
            tryDate = now.add(i, 'month').date(TT);
            if (tryDate.date() !== TT) {
              // Problem: am 17.2. wird aus dem Versuch 31.2. der 3.3. ...
              // console.log('ungültig: ' + tryDate.format(this.$BC.F_DE_DATE))
            } else {
              // console.log(tryDate.format(this.$BC.F_DE_DATE))
              if (Math.abs(now - tryDate) < Math.abs(now - bestDate)) {
                bestDate = tryDate;
              }
            }
          }
          suggestion = bestDate;
          this.dtfSelectRangeAtNextTick(3);
          break;

        case 4: // TT.1 || TT.0
          M = parseInt(ds.substr(3, 1));
          TT = parseInt(ds.substr(0, 2));

          switch (M) {
            case 0:
              for (MM = 0; MM <= 8; MM++) {
                // Monate varieren: 01-09 (zero indexed!)
                tryDate = now.month(MM).date(TT);

                // if ((tryDate.date() !== TT) && (Math.abs(now - tryDate) < Math.abs(now - bestDate))) bestDate = tryDate
                // ... so geht's nicht, aber so... (k.A.why)
                if (tryDate.date() !== TT) {
                  // console.log('ungültig: ' + tryDate.format(this.$BC.F_DE_DATE))
                } else {
                  // console.log('gültig: ' + tryDate.format(this.$BC.F_DE_DATE))
                  if (Math.abs(now - tryDate) < Math.abs(now - bestDate)) {
                    bestDate = tryDate;
                  }
                }
              }
              break;
            case 1:
              YYYY =
                now.month() > 5 // Months are zero indexed, so 5 is June !
                  ? now.year()
                  : now.year() - 1;
              for (MM = 9; MM <= 11; MM++) {
                // Monate varieren: 10-12 (zero indexed!)
                tryDate = now.month(MM).year(YYYY).date(TT);
                if (tryDate.date() !== TT) {
                } else {
                  if (Math.abs(now - tryDate) < Math.abs(now - bestDate)) {
                    bestDate = tryDate;
                  }
                }
              }
              break;
            case 2:
              console.log('case 4.2');
              console.log('case 4 > TT.2 dürft gar nit sein!');
              break;
          }
          suggestion = bestDate;
          this.dtfSelectRangeAtNextTick(4);
          break;

        case 6: // TT.MM.
          TT = ds.substr(0, 2);
          MM = ds.substr(3, 2) - 1; // Months are zero indexed
          for (YYYY = now.year() - 1; YYYY <= now.year() + 1; YYYY++) {
            // Jahre varieren
            tryDate = now.month(MM).year(YYYY).date(TT);
            if (
              tryDate.date() !== TT &&
              Math.abs(now - tryDate) < Math.abs(now - bestDate)
            )
              bestDate = tryDate;
          }
          suggestion = bestDate;
          this.dtfSelectRangeAtNextTick(6);
          break;

        case 7: // TT.MM.Y
          // console.log('case 7')
          TT = parseInt(ds.substr(0, 2));
          MM = ds.substr(3, 2) - 1; // Months are zero indexed
          YYYY = parseInt(ds.substr(6));
          switch (YYYY) {
            case 1:
              // console.log('case 7-1')
              bestDate = now.month(MM).year(1999).date(TT);
              this.dtfSelectRangeAtNextTick(7);
              break;
            case 2:
              // console.log('case 7-2')
              for (YYYY = now.year() - 1; YYYY <= now.year() + 1; YYYY++) {
                // Jahre varieren
                tryDate = now.month(MM).year(YYYY).date(TT);
                if (tryDate.date() !== TT) {
                } else {
                  if (Math.abs(now - tryDate) < Math.abs(now - bestDate)) {
                    bestDate = tryDate;
                  }
                }
              }
              // console.log(bestDate)
              this.dtfSelectRangeAtNextTick(7);
              break;
            default:
              // console.log('case 7-d')
              bestDate = now
                .month(MM)
                .year(19 + YYYY)
                .date(TT);
              this.dtfSelectRangeAtNextTick(9);
          }
          suggestion = bestDate;
          break;

        case 8: // TT.MM.YY
          // @@@ t.b.d.
          // console.log('case 8')
          this.dtfSelectRangeAtNextTick(8);
          break;

        case 9: // TT.MM.YYY
          // console.log('case 9')
          // TT = parseInt(ds.substr(0, 2))
          // MM = ds.substr(3, 2) - 1 // Months are zero indexed
          // YYYY = ds.substr(6)
          // bestDate = now.month(MM).year(YYYY.concat(now.format('YY').substr(1))).date(TT)
          // suggestion = bestDate
          this.dtfSelectRangeAtNextTick(9);
          break;

        default:
          console.log('unhandled length ' + ds.length);
      }
      // console.log('suggestion: ' + suggestion.format(this.$BC.F_DE_DATE))
      try {
        return suggestion.isValid()
          ? suggestion.format(this.$BC.F_DE_DATE)
          : suggestion;
      } catch {
        return ds;
      }
    },
    timeStringToDate(dateObject, timeString) {
      if (!dateObject || !dateObject.isValid()) return null;
      return dateObject
        .hour(timeString.substr(0, 2))
        .minute(timeString.substr(3, 2))
        .second(0)
        .millisecond(0);
    },
    suggestTime(ts) {
      // timeString
      // console.log('suggestTime')
      // console.log(this.suggest.time)
      // console.log('Eingabe (ts): ' + ts)
      if (!this.suggest.time) return ts; // nicht gewünscht
      if (!ts) return ''; // leer
      if (ts.match(/[^0-9:]/g)) return ts; // nicht-Ziffern gefunden
      if (ts.length === 5 && this.timeStringToDate(this.$dayjs(), ts).isValid())
        return ts; // ist schon Zeit

      const day = this.oD || this.$dayjs();
      const now = this.timeStringToDate(day, this.suggest.time);
      // console.log('now: ' + now.format(this.$BC.F_DE_DATETIME))
      let tryTime = null;
      let suggestion = '';
      let HH = '';
      let mm = '';
      let i = 0;
      let j = 0;

      switch (ts.length) {
        case 1: // H.... 0, 1, 2
          // console.log('case 1')
          i = -1;
          do {
            i++;
          } while (
            i < 12 &&
            now.add(i, 'hour').format('HH').substr(0, 1) !== ts
          );
          j = -1;
          do {
            j++;
          } while (
            j < 12 &&
            now.subtract(j, 'hour').format('HH').substr(0, 1) !== ts
          );
          suggestion = now.add(i < j ? i : -j, 'hour');
          this.ttfSelectRangeAtNextTick(1);
          break;

        case 3: // HH. ... 00-59
          // console.log('case 3')
          HH = parseInt(ts.substr(0, 2));
          if (HH === now.hour()) {
            mm = now.minute();
          } else if (HH > now.hour()) {
            mm = 0; // frühest möglich
          } else {
            mm = 60; // spätest mögliche - von 59 zurück probieren
            while (!this.isValidIntervalTime(now.hour(HH).minute(--mm))) {}
          }
          suggestion = now.hour(HH).minute(mm);
          this.ttfSelectRangeAtNextTick(3);
          break;

        case 4: // HH:m ... m0-m9
          // console.log('case 4')
          HH = parseInt(ts.substr(0, 2));
          mm = ts.substr(3, 1) * 10; // 0, 10, ... 50
          for (i = 0; i <= 9; i++) {
            // 10 Minuten durchlaufen
            tryTime = now.hour(HH).minute(mm++); // 00-09, 01-19
            // console.log(tryTime.format(this.$BC.F_DE_TIME))
            if (this.isValidIntervalTime(tryTime)) {
              // kann auch keine valide gefunden werden
              suggestion = tryTime;
            }
          }
          // console.log(suggestion) // kann '' sein
          this.ttfSelectRangeAtNextTick(4);
          break;

        default:
          console.log('unhandled length ' + ts.length);
      }

      try {
        // console.log('suggestion: ')
        // console.log(suggestion.format(this.$BC.F_DE_TIME))
        return suggestion.format(this.$BC.F_DE_TIME);
      } catch {
        // console.log('no suggestion')
        return ts;
      }
    },
    dtfSelectRangeAtNextTick(start, end) {
      this.$nextTick(() =>
        this.$refs.BcDateTF.$el
          .querySelector('input')
          .setSelectionRange(start, end || 999)
      );
    },
    ttfSelectRangeAtNextTick(start, end) {
      this.$nextTick(() =>
        this.$refs.BcTimeTF.$el
          .querySelector('input')
          .setSelectionRange(start, end || 999)
      );
    },
    processValue(value) {
      let tryDate = {};

      // ungültig / false
      if (value === null || value === false) {
        // can this be?
        return;
      }

      this.oDT = null;
      this.oD = null;
      this.validDate = false;
      this.validTime = false;
      this.dtf = '';
      this.ttf = '';
      this.dp = '';
      this.tp = '';

      this.dateValid = true;
      this.timeValid = true;

      // Objekt
      if (typeof value === 'object') {
        // console.log(value)
        // console.log('BcDate > watch value > it\'s an object!')
        this.oDT = value;
        this.dtf = this.oDT.format(this.$BC.F_DE_DATE);
        this.ttf = this.oDT.format(this.$BC.F_DE_TIME);
      }

      // String?
      if (typeof value === 'string') {
        // console.log(value)
        // console.log('BcDate > watch value > a String!')
        // leer
        if (value.trim() === '') {
          // console.log('... an empty string :-/')
          // tryDate = null
          return;
        }
        tryDate = this.$dayjs(value);
        if (!tryDate.isValid()) return;
        this.oDT = tryDate;
        return;
      }

      // Object
      if (!value.isValid()) {
        // console.log(value)
        // console.log('BcDate > watch value > an invalid Object :-(')
      }

      this.oDT = value;
    },
    dtfFocus(select) {
      if (this.dtf === '' && this.suggest.date) {
        this.checkDate(this.suggest.date.format(this.$BC.F_DE_DATE));
      }
      if (select) this.dtfSelectRangeAtNextTick();
    },
    ttfFocus(select) {
      console.log(select);
      if (!this.ttf && this.suggest.time) {
        this.checkTime(this.suggest.time);
      }
      if (select) this.ttfSelectRangeAtNextTick();
    },
    tpFocus() {
      if (!this.tp && this.suggest.time) {
        this.checkTime(this.suggest.time);
      }
    },
    nearestIntervalTo(timestamp) {
      // if (!this.timerecmodel) return timestamp
      if (!this.timeInterval) return timestamp;
      const mins = timestamp.diff(timestamp.startOf('date'), 'minute');
      return timestamp
        .startOf('date')
        .add(
          Math.round(mins / this.timeInterval) * this.timeInterval,
          'minute'
        );
    },
    isValidIntervalTime(timestamp) {
      return (
        this.nearestIntervalTo(timestamp).format(this.$BC.F_DE_TIME) ===
        timestamp.format(this.$BC.F_DE_TIME)
      );
    },
  },
};
</script>
