<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-card
      max-width="900"
      outlined
      :loading="loading"
      class="pa-0"
      @keyup.native.escape="goBack"
    >
      <v-toolbar
        v-if="!embedded"
        dense
        flat
        color="alabaster"
        class="gunmetal--text"
      >
        <v-toolbar-title>
          <span>{{ user.username }}</span>
        </v-toolbar-title>

        <v-spacer />

        <v-menu v-if="!loading" id="actions" offset-y left>
          <template v-slot:activator="{ on }">
            <v-btn icon title="weitere Aktionen..." v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list one-line dense>
            <v-list-item
              v-if="$store.getters[$storenames.auth.getters.userCan]('user')"
              @click="alert(klicksi)"
            >
              <v-list-item-icon
                ><v-icon>mdi-account-key</v-icon></v-list-item-icon
              >
              als User aktivieren...
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon title="schließen">
          <v-icon @click="goBack"> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>

      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
        :display-success="displaySuccessResponse"
      />

      <v-card-text class="pa-2">
        <div class="d-flex flex-wrap">
          <v-card id="userdata" min-width="300" outlined class="mt-2">
            <v-card-title
              class="d-flex justify-space-between align-center pa-2"
            >
              {{ $me.amI(user) ? 'Deine ' : '' }}User-Daten
              <v-btn
                v-if="canEdit"
                fab
                right
                x-small
                text
                title="Userdaten bearbeiten..."
                @click="showUserPopup = true"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <bv-user-edit
                :show="showUserPopup"
                :user="user"
                :person="person"
                @evClose="showUserPopup = false"
                @evStored="applyData"
              />
            </v-card-title>

            <v-card-text class="py-0">
              <v-text-field v-model="user.username" label="Username" readonly />
              <v-text-field
                v-model="user.email"
                label="Anmeldung / E-Mail"
                readonly
              >
                <template v-slot:append>
                  <Bc-Info v-if="canChangeUserEmail">
                    Du kannst die E-Mail-Adresse noch solange ändern, bis sich
                    {{ person.label }} das erste Mal angemeldet hat.
                  </Bc-Info>
                </template>
              </v-text-field>
            </v-card-text>
          </v-card>

          <v-card
            id="useractions"
            min-width="300"
            max-width="500"
            outlined
            class="mt-2 ml-2"
          >
            <v-card-title
              class="d-flex justify-space-between align-center pa-2"
            >
              Aktivierung
            </v-card-title>

            <v-card-text>
              <v-switch
                v-model="user.active"
                dense
                color="boo"
                class=""
                :loading="userActiveStoring"
                :disabled="loading || userActiveStoring || !canEdit"
                @change="userActiveChange"
              >
                <template v-slot:label>
                  <span v-if="user.active" class="success--text"
                    >User ist aktiviert</span
                  >
                  <span v-if="!user.active" class="warning--text"
                    >User ist deaktiviert!</span
                  >
                </template>
                <template v-slot:append>
                  <Bc-Info
                    >Wenn der User deaktiviert ist, kann er sich in BOO zwar
                    anmelden, jedoch sonst nichts tun.</Bc-Info
                  >
                </template>
              </v-switch>

              <bc-btn-confirm
                btn-text="Passwort-Mail versenden"
                width="300"
                :disabled="
                  loading || userActiveStoring || !user.active || !canEdit
                "
                @evDone="sendPwMail"
              />
            </v-card-text>
          </v-card>

          <v-card
            id="userinfos"
            min-width="300"
            max-width="500"
            outlined
            class="mt-2 ml-2"
          >
            <v-card-title
              class="d-flex justify-space-between align-center pa-2"
            >
              Infos
            </v-card-title>
            <v-card-text>
              <v-list subheader>
                <v-list-item v-if="user.protected" dense>
                  <v-list-item-icon
                    ><v-icon>mdi-account-lock-outline</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>{{
                      user.protected ? 'geschützter User' : '(ungeschützt)'
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon
                    ><bc-info
                      >Kann nur vom Zuständigen ({{ user.owner.person.label }})
                      bearbeitet werden!</bc-info
                    ></v-list-item-icon
                  >
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon
                    ><v-icon
                      >mdi-account-arrow-right-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >als User aktiviert am
                      {{
                        $dayjs(user.created_at).format('DD.MM.YYYY')
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon
                    ><v-icon>mdi-account-sync-outline</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-tooltip
                      bottom
                      :disabled="!$dayjs(user.lastaction_at, true).isValid()"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-list-item-subtitle>
                            <span
                              v-if="$dayjs(user.lastaction_at, true).isValid()"
                            >
                              letzte Aktivität
                              {{ $dayjs(user.lastaction_at).fromNow() }}
                            </span>
                            <span v-else>noch nie angemeldet</span>
                          </v-list-item-subtitle>
                        </span>
                      </template>
                      {{
                        $dayjs(user.lastaction_at).format(
                          '[am] DD.MM.YYYY [um] HH:mm'
                        )
                      }}
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>

          <v-card id="userabilties" outlined class="ma-2">
            <v-card-text class="py-0 d-flex flex-wrap">
              <v-simple-table dense>
                <th
                  v-if="user && user.domains && user.domains.length"
                  class="pb-3 text-left"
                  style="vertical-align: bottom; border-bottom: 1pt solid grey"
                >
                  Berechtigungen
                </th>
                <th
                  v-else
                  class="pb-3 text-left warning--text"
                  style="
                    vertical-align: middle;
                    border-bottom: 1pt solid grey;
                    max-width: 100px;
                  "
                >
                  Für KEINE Sphäre berechtigt!
                </th>
                <th
                  v-for="domain in allDomains"
                  :key="domain.name"
                  style="border-bottom: 1pt solid grey"
                >
                  <bc-user-domain-switch
                    :user="user"
                    :domain="domain"
                    :value="userDomains.includes(domain.slug)"
                    :available="
                      !$me.amI(user) &&
                      ($me.owns(user) || $me.canIn('user.all', domain)) &&
                      (!userAbilities['user.protected'] ||
                        !userAbilities['user.protected'].domains[domain.slug]
                          .user ||
                        $me.owns(user) ||
                        $me.isSysadmin())
                    "
                    :protected="
                      userAbilities['user.protected'] &&
                      userAbilities['user.protected'].domains[domain.slug].user
                    "
                    @evStored="userDomainStored"
                  />
                </th>

                <tr
                  v-for="userAbility in userAbilities"
                  :key="userAbility.name"
                >
                  <td class="pr-5 pb-2">
                    {{ userAbility.ability.name }}<br />
                    <small>{{ userAbility.ability.title }}</small>
                  </td>
                  <td
                    v-for="domain in allDomains"
                    :key="domain.id"
                    style="border: 1px solid white"
                  >
                    <bc-user-ability-switch
                      :user="user"
                      :domain="domain"
                      :ability="userAbility.ability"
                      :value="
                        userAbility.domains[domain.slug]
                          ? userAbility.domains[domain.slug]['user']
                          : null
                      "
                      :available="abilitySwitchIsAvaiable(userAbility, domain)"
                      @evStored="userAbilityStored"
                    />
                  </td>
                </tr>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>

      <user-settings
        v-if="user.username"
        :user="user"
        :on-success="
          //onSuccess also gets passed the user. We therefore could just set the new user.
          // However he then misses all abilities and soe more things need to  be refactored.
          // Therefore I just decided to reload for now. Adding a TODO here:
          // TODO: set the user instead of loading all data again.

          (user) => loadData()
        "
      />
    </v-card>
  </v-container>
</template>

<script>
import BvUserEdit from '@/views/user/UserEdit';
import BcUserAbilitySwitch from '@/views/user/UserAbilitySwitch';
import BcUserDomainSwitch from '@/views/user/UserDomainSwitch';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import BcInfo from '@/components/global/BcInfo.vue';
import BcBtnConfirm from '@/components/global/BcBtnConfirm.vue';
import UserSettings from './UserSettings.vue';

export default {
  name: 'UserShow',

  components: {
    BcBtnConfirm,
    BcInfo,
    BcResponseHandler,
    BvUserEdit,
    BcUserAbilitySwitch,
    BcUserDomainSwitch,
    UserSettings,
  },

  props: {
    userslug: {
      type: String,
      default: null,
    },
    person: {
      type: Object,
      default: null,
    },
    embedded: Boolean,
  },

  data() {
    return {
      user: {},
      userAbilities: {},

      loading: false,
      error: null,

      axiosSuccess: null,
      axiosError: null,
      displaySuccessResponse: false,

      showUserPopup: false,

      userActiveStoring: false,
    };
  },

  computed: {
    canEdit() {
      if (this.$me.amI(this.user)) return false; // selber

      if (
        this.user.protected &&
        !(this.$me.owns(this.user) || this.$me.isSysadmin())
      )
        return false;

      return (
        this.$me.owns(this.user) ||
        this.$me.isSysadmin() || // @@@ deprecated?
        this.$me.can('user.all')
      );
    },
    userDomains() {
      const array = [];
      for (const domain of Object.values(this.user.domains)) {
        array.push(domain.slug);
      }
      return array;
    },
    allDomains() {
      if (!this.user.domains) return null;
      const allDomains = this.user.domains.slice(); // clone, not reference(!)
      const userDomainsSlug = [];
      for (const userDomain of Object.values(allDomains)) {
        userDomainsSlug.push(userDomain.slug);
      }
      for (const myDomain of Object.values(this.$store.state.auth.domains)) {
        if (!userDomainsSlug.includes(myDomain.slug))
          allDomains.unshift(myDomain);
      }
      return allDomains.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
    userDomainsProtected() {
      return this.userDomains.filter(
        (domain) => this.userAbilities['user.protected'].domains[domain].user
      );
    },
    canChangeUserEmail() {
      return !this.user.lastaction_at;
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    goBack() {
      this.$router.push({
        name: 'persons',
        // params: { }
      });
    },
    applyData(data) {
      this.user = data;
    },

    abilitySwitchIsAvaiable(userAbility, domain) {
      // ich versuche mich selber zu verändern
      if (this.$me.amI(this.user)) return false;

      // Ich habe die ability selbst nicht
      if (!userAbility.domains[domain.slug]?.['mine']) {
        return false;
      }

      // user hat user.protected aber ich bin nicht sein owner (und auch nicht sysAdmin)
      if (
        this.userAbilities['user.protected']?.domains?.[domain.slug]?.[
          'user'
        ] &&
        !this.$me.owns(this.user) &&
        !this.$me.isSysadmin()
      ) {
        return false;
      }

      // ich hab in der domain die ability user.all
      if (this.userAbilities['user.all']?.domains?.[domain.slug]?.['mine']) {
        return true;
      }

      // ich hab in der domain die ability user und owne den user
      /** @noinspection */
      if (
        this.userAbilities['user']?.domains?.[domain.slug]?.['mine'] &&
        this.$me.owns(this.user)
      ) {
        return true;
      }

      return false;
    },

    loadData() {
      this.loading = true;
      this.error = null;

      window.axios
        .get(this.$boo.lboUrl() + 'user/' + this.userslug)
        .then((response) => {
          this.axiosSuccess = response;
          this.user = response.data.success;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('router-replace');
            // this.$router.replace({name: 'users'})
            return;
          }
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });

      window.axios
        .get(this.$boo.lboUrl() + 'user/' + this.userslug + '/abilities')
        .then((response) => {
          this.axiosSuccess = response;
          this.userAbilities = response.data.success;
        })
        .catch((error) => {
          this.axiosError = error;
        });
    },

    userActiveChange() {
      this.userActiveStoring = true;

      window.axios
        .patch(this.$boo.lboUrl() + 'user/' + this.userslug, {
          active: this.user.active,
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.user = response.data.success;
          this.$store.commit('app/SETSNACKBAR', {
            text: `User ${this.user.username} wurde ${
              this.user.active ? 'aktiviert' : 'deaktiviert'
            }!`,
            color: this.user.active ? 'success' : 'warning',
          });
        })
        .catch((error) => {
          this.axiosError = error;
          // restore data; no error handler because loading worked once
          window.axios
            .get(this.$boo.lboUrl() + 'user/' + this.userslug)
            .then((response) => (this.user = response.data.success));
        })
        .finally(() => {
          this.userActiveStoring = false;
        });
    },
    userAbilityStored(data) {
      this.userAbilities[data.ability].domains[data.domain].user = data.value;
    },
    userDomainStored(data) {
      this.user = data;
    },
    sendPwMail() {
      this.alert = false;
      window.axios
        .post(this.$boo.lboUrl() + 'user/' + this.userslug + '/pwreset')
        .then((response) => {
          this.axiosSuccess = response;
        })
        .catch((error) => {
          this.axiosError = error;
        });
    },
  },
};
</script>
