<template>
  <v-card flat color="transparent">
    <div id="commentcontainer">
      <div id="comments" class="pb-16">
        <!-- <v-btn v-if="$boo.envIsDev()" @click="addDevComments"
          >Push DEV Comments</v-btn
        > -->
        <div v-if="!hasComments">
          <p class="ma-10 caption text-center grey--text">
            Erfasse unten dein Kommentar
          </p>
        </div>
        <bc-comment-bubble
          v-else
          v-for="(comment, n) in commentableClone.comments"
          :key="n"
          :comment="comment"
          :commentBefore="
            commentableClone.comments[n - 1]
              ? commentableClone.comments[n - 1]
              : null
          "
          :commentAfter="
            commentableClone.comments[n + 1]
              ? commentableClone.comments[n + 1]
              : null
          "
          :commentable="commentableClone"
          @deleted="removeComment"
          @enableEditMode="enableEditMode"
        />

        <div id="commentinput">
          <v-divider />

          <bc-comment-textfield
            :commentable="commentableClone"
            :commentToEdit="commentToEdit"
            @messageSent="addCommentToFakeResponsiveness"
            @reset="reset"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      commentToEdit: null,
      commentableClone: {}, // für FakeResponsiveness erforderlich
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
        commentable: {
        } // Pojo
      },
    },
  },

  created() {
    // muss in BcCdComment im created() sein, weil watch() nicht passiert
    // this.commentableClone = { ...this.data.commentable } // so vererbt(?) sich der push(tempcomment) trotzdem rauf - k.A. wieso
    this.commentableClone = JSON.parse(JSON.stringify(this.data.commentable));
  },

  watch: {
    data() {
      // muss bei timeEdit in watch() sein, weil bei created() noch leer
      this.commentableClone = JSON.parse(JSON.stringify(this.data.commentable));
    },
  },

  mounted() {
    this.scrollToNewestMessage();
  },

  computed: {
    hasComments() {
      return (
        !!this.commentableClone.comments &&
        this.commentableClone.comments.length > 0
      );
    },
  },

  methods: {
    reset() {
      this.commentToEdit = null;
      this.$emit('refresh');
    },
    addCommentToFakeResponsiveness(comment) {
      let tempcomment = comment;
      tempcomment.id = 999999;
      tempcomment.owner = this.$me.me();
      this.commentableClone.comments.push(tempcomment);
      this.commentableClone.comment_count++;
      setTimeout(() => {
        this.scrollToNewestMessage();
      }, 100);
    },
    removeComment(comment) {
      let index = this.commentableClone.comments.indexOf(comment);
      this.commentableClone.comments.splice(index, 1);
      this.commentableClone.comment_count--;
    },
    enableEditMode(comment) {
      this.commentToEdit = comment;
    },
    scrollToNewestMessage() {
      window.HTMLElement.prototype.scrollIntoView = function () {};

      if (!this.hasComments) return;
      let div = document.getElementById('comments');
      div.lastElementChild.previousSibling.scrollIntoView({
        behavior: 'smooth',
      });
    },
    // addDevComments() {
    //   this.commentableClone.comments.push({
    //     text: 'Das ist ein langes Kommentar von mir. Hier kommt mehr Text hin. Leider fällt mir dann nie ein, was ich schreiben soll.',
    //     owner: this.$store.state.user.person,
    //   })
    //   this.commentableClone.comments.push({
    //     text: 'Ein Kurzes von mir',
    //     owner: this.$store.state.user.person,
    //   })
    //   this.commentableClone.comments.push({
    //     text: 'Ein Kurzes von jemand anderes',
    //     owner: {
    //       id: 100,
    //       label: 'Kurt',
    //     },
    //   })
    //   this.commentableClone.comments.push({
    //     text: 'Ein langes von jemand anderes. Aber dem selben wie davor. Ein langes von jemand anderes. Aber dem selben wie davor. Ein langes von jemand anderes. Aber dem selben wie davor.',
    //     owner: {
    //       id: 100,
    //       label: 'Kurt',
    //     },
    //   })
    //   this.commentableClone.comments.push({
    //     text: 'Ein Kurzes von noch jemand anderes',
    //     owner: {
    //       id: 99,
    //       label: 'Herbert',
    //     },
    //   })
    // },
  },
};
</script>

<style scoped>
div#commentcontainer {
  height: calc(90vh - 110px);
}

div#comments {
  height: 100%;
  overflow: scroll;
}

div#commentinput {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  bottom: env(safe-area-inset-bottom);
}
</style>
