<template>
  <v-card :disabled="loading" flat color="transparent">
    <bc-btn-cancel-action
      v-if="dataDirty"
      :valid="valid"
      :enable-action="isNew || dataDirty"
      :action-running="storing"
      :title="title.name"
      :header="true"
      @evAction="submit"
      @evCancel="$store.dispatch($storenames.contentDrawer.actions.close)"
    />
    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="$store.dispatch($storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>
    <v-card-text class="mt-3">
      <v-form ref="formEditProject" v-model="valid" lazy-validation>
        <v-text-field
          v-model="projectEdit.name"
          label="Projekttitel"
          autocomplete="off"
          :rules="nameRules"
          @input="checkDirty"
        />
        <!-- <bc-autocomplete
          label="Kunde/Kundin"
          api-url="persons"
          :customer="true"
          @inputSelected="addCustomer"
          @cleared="removeCustomer"
        /> -->
        <bc-autocomplete
          v-model="projectEdit.customer_id"
          label="KundIn"
          :items="customers"
          itemvalue="customer.id"
          @input="checkDirty"
        />

        <v-text-field
          v-model="projectEdit.description"
          label="Beschreibung"
          autocomplete="off"
          :rules="descriptionRules"
          @input="checkDirty"
        />
        <v-select
          v-if="!isNew"
          v-model="projectEdit.status_id"
          label="Status"
          :items="data.statusOptions"
          item-text="name"
          item-value="id"
          @input="checkDirty"
        />
      </v-form>
    </v-card-text>
    <v-card-text v-if="$boo.envIsDev()" class="blue">
      dataDirty: {{ dataDirty }} <br />
      {{ projectEdit.name }}<br />
      {{ projectEdit.description }}<br />
      {{ projectEdit.customer_id }}<br />
      {{ projectEdit.status_id }}
    </v-card-text>

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />
  </v-card>
</template>

<script>
import store from '@/store';

export default {
  name: 'BcProjectEdit',

  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      storing: false,
      deleting: false,
      test: null,

      axiosError: null,
      axiosSuccess: null,

      projectEdit: {
        // name: '',
        // description: '',
        // customer_id: null,
        // status_id: null
      },
      dataDirty: false,
      customers: [],

      // rules
      nameRules: [
        (v) => !!v || 'Eingabe erforderlich',
        (v) => !v || (v && v.length >= 3) || 'bitte mindestens 3 Zeichen',
        (v) => !v || (v && v.length <= 100) || 'bitte maximal 100 Zeichen',
      ],
      descriptionRules: [
        (v) => !v || (v && v.length >= 5) || 'bitte mindestens 5 Zeichen',
        (v) => !v || (v && v.length <= 512) || 'bitte maximal 512 Zeichen',
      ],
    };
  },
  computed: {
    apiURL() {
      if (!this.isNew) {
        return this.$boo.lboUrl() + 'projects/' + this.projectSlug;
      } else {
        return this.$boo.lboUrl() + 'projects/';
      }
    },
    title() {
      if (this.isNew) {
        return { name: 'Neu', icon: 'mdi-clipboard' };
      } else {
        return { name: 'Bearbeiten', icon: 'mdi-clipboard' };
      }
    },
    isNew() {
      return this.data === null;
    },
    projectSlug() {
      return this.data.project.slug;
    },
  },

  watch: {
    dataDirty: function () {
      this.$store.commit('contentDrawer/LOCK', this.dataDirty);
    },
  },
  created() {
    this.loading = true;
    this.loadCustomers();
    if (!this.isNew) {
      this.projectEdit = { ...this.data.project };
      this.loadProject();
    }
    this.loading = false;
  },

  methods: {
    loadProject() {
      window.axios
        .get(this.apiURL)
        .then((response) => {
          this.axiosSuccess = response;
          this.projectEdit = response.data.success;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {});
    },
    loadCustomers() {
      window.axios
        .get(`${this.$boo.lboUrl()}persons?customer=true`)
        .then((response) => {
          this.customers = response.data.success;
        })
        .catch((error) => {
          this.$emit('error-received', error);
        })
        .finally(() => {});
    },
    // Überprüfung auf Veränderung
    checkDirty() {
      // console.log('checkDirty')

      // jedenfalls dirty
      if (this.isNew) {
        this.dataDirty = true;
        return;
      }
      // jedenfalls nicht dirty
      if (
        this.$boo.objIsEmpty(this.projectEdit) ||
        this.$boo.objIsEmpty(this.data.project)
      ) {
        this.dataDirty = false;
        return;
      }

      const checkData = [];
      // prüfe alle Felder
      if (this.projectEdit.name !== this.data.project.name)
        checkData.push('name');

      if (this.projectEdit.description !== this.data.project.description)
        checkData.push('description');

      if (this.projectEdit.customer_id !== this.data.project.customer_id)
        checkData.push('customer');
      // if (this.projectEdit.customer_id !== this.data.project.customer.id) checkData.push('customer')

      if (this.projectEdit.status_id !== this.data.project.status_id)
        checkData.push('status_id');

      // console.log(checkData)
      this.dataDirty = checkData.length > 0;
    },
    submit: function () {
      if (!this.$refs.formEditProject.validate()) return;

      this.storing = true;

      const transfer = {
        name: this.projectEdit.name,
        description: this.projectEdit.description,
        customer_id: this.projectEdit.customer_id,
        domain: this.$store.state.app.domain.id,
        status_id: this.projectEdit.status_id,
        user_id: this.$store.state.auth.user.id, // @@@ sollte eh im BE gesetzt werden!
      };
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url: this.apiURL,
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          const project = response.data.success;
          this.$boo.storeApiData(response.data.apiData); // apiData aktualisieren
          if (this.isNew) {
            store.dispatch.contentDrawer.close();
            // goto project
            this.$router.push({
              name: 'projectView',
              params: { projectSlug: project.slug, project: project },
            });
          } else if (project.slug !== this.projectSlug) {
            // slug wurde neu generiert > URL ändern
            this.$router.replace({
              name: 'projectView',
              params: { projectSlug: project.slug },
            });
            window.location.reload(false); // reload
            return;
          }
          this.$emitter.emit('project', { project });
          this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    // addCustomer (customer) {
    //   this.projectEdit.customer_id = customer.customer.id
    //   this.checkDirty()
    // },
    // removeCustomer () {
    //   // console.log('removeCustomer')
    //   this.projectEdit.customer_id = null
    //   this.checkDirty()
    // }
  },
};
</script>
