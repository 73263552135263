<template>
  <v-layout justify-center class="pt-5">
    <v-card width="360" class="mt-5 mx-a" flat color="transparent">
      <v-card-title>
        <v-img
          data-cy="boo-logo"
          src="/img/boo-logo.png"
          class="mb-0"
          contain
          height="90"
        />
      </v-card-title>
      <v-form
        ref="pwReset"
        v-model="valid"
        @keyup.native.enter="nextStepOnKeyUp"
      >
        <v-stepper v-model="pwResetStep">
          <v-stepper-items color="transparent">
            <v-stepper-content class="pa-0" step="1">
              <v-card class="pa-5" flat>
                <v-card-subtitle class="headline text-center">
                  Passwort zurücksetzen
                </v-card-subtitle>
                <v-card-text>
                  <p class="text-center mt-3 mb-8">
                  Bitte gib die E-Mail-Adresse deines boo-Kontos ein. Wir
                  senden dir einen Link zum Zurücksetzen deines Passwortes
                  zu.
                  </p>
                  <v-text-field
                    data-cy="email-input"
                    v-model="email"
                    outlined
                    label="E-Mail-Adresse"
                    autocomplete="username"
                    prepend-inner-icon="mdi-email-outline"
                    :rules="EmailRules"
                    class=""
                    validate-on-blur
                  />
                  <bc-response-handler
                    :axios-success="axiosSuccess"
                    :axios-error="axiosError"
                    show-in-dialog
                  />
                  <v-btn
                    data-cy="submit-button"
                    block
                    :color="status === 'waiting' ? 'grey' : 'warning'"
                    :disabled="!emailIsValid"
                    @click="submit"
                    class="mb-1"
                  >
                    Zurücksetzen-Link anfordern
                    <v-progress-circular
                      v-if="status === 'waiting'"
                      color="boo"
                      indeterminate
                      size="20"
                      width="3"
                      class="ml-2"
                    />
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0" step="2">
              <v-card class="pa-5" flat>
                <v-card-subtitle class="text-center title">
                  Wir haben dir eine E-Mail an</br>
                  < {{ email }} ></br>
                  geschickt!
                </v-card-subtitle>
                <v-card-text class="text-center">
                  <v-icon x-large class="boo--text pa-3">mdi-email-check-outline</v-icon>
                </v-card-text>
                <v-card-text
                  data-cy="instructions"
                >
                  <p>Bitte überprüfe deinen Posteingang und folge den Anweisungen
                  in der E-Mail.</p>
                  <p>Keine E-Mail erhalten?</p>
                  <ol>
                    <li v-for="(item, n) in instructions" :key="n">
                      {{ item }}
                    </li>
                  </ol>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
      <v-card-text>
        <v-btn
          data-cy="back-button"
          text
          class="caption"
          color="grey"
          :to="{
            name: 'login',
            query: { email }
          }"
          >
          <v-icon small> mdi-chevron-left </v-icon>Zurück
        </v-btn>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  name: 'PasswordForgot',

  data() {
    return {
      email: this.$route.query.email || '',
      valid: false,

      EmailRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => this.$BC.EMAIL_PATTERN.test(v) || 'keine valide E-Mail-Adresse',
      ],

      status: 'loading', // 'asking', 'waiting', 'warning', 'error', 'done'

      axiosSuccess: null,
      axiosError: null,

      pwResetStep: 1,
      instructions: [
        'Überprüfe deinen Spam Ordner',
        'Überprüfe deine Angaben',
        'Warte 10 Minuten',
      ],
    }
  },

  computed: {
    emailIsValid() {
      if (this.email === '') return false
      return this.$BC.EMAIL_PATTERN.test(this.email)
    },
  },

  methods: {
    nextStepOnKeyUp() {
      if (this.emailIsValid) {
        this.submit()
      }
      return
    },
    submit() {
      if (!this.$refs.pwReset.validate()) {
        return
      }
      this.status = 'waiting'
      window.axios
        .post(this.$BC.LBO_URL + '/api/auth/forgotPW', { email: this.email })
        .then((response) => {
          this.axiosSuccess = response
          this.pwResetStep = 2
        })
        .catch((error) => {
          this.axiosError = error
          this.status = 'warning'
        })
    },
  },
}
</script>
