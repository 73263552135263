import { DataTableHeaderType } from '@/types/types';
import { Breakpoint } from 'vuetify/types/services/breakpoint';

export const createProjectsDataTableHeaders = (
  breakpoint: Breakpoint
): DataTableHeaderType[] => {
  return [
    { text: 'Name', value: 'label', visible: true },
    {
      text: 'Tags',
      value: 'tags',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'Beschreibung',
      value: 'description',
      visible: breakpoint.mdAndUp,
    },
    {
      text: 'Status',
      value: 'status',
      align: 'end',
      visible: true,
      sortable: false,
    },
  ];
};
