<template>
  <bv-frame
    headline="Bohoo..."
    subtitle="...you're lost!"
    :refresh-button="false"
  >
    <v-container class="my-5" fluid>
      <p>
        Hier willst du nicht sein. Hier ist nichts. Der Weg war vergebens, die
        Adresse verkehrt. Irrtum oder Täuschung. Egal. Klick auf den Geist.
        Jetzt!
      </p>
    </v-container>
  </bv-frame>
</template>

<script>
import BvFrame from '@/views/Frame';

export default {
  name: 'BvLost',

  components: { BvFrame },
};
</script>
