<template>
  <bc-widget-frame
    v-if="
      $store.getters[$storenames.auth.getters.userCan]('person.show') &&
      persons &&
      persons.length
    "
    title="Deine Kontakte"
    subtitle=""
    icon="mdi-account-multiple"
  >
    <v-list dense>
      <v-subheader>Deine zuletzt besuchten Kontakte</v-subheader>

      <v-list-item-group>
        <v-list-item
          v-for="(person, n) in persons"
          :key="n"
          @click="showPerson(person)"
        >
          <v-list-item-icon>
            <bc-avatar :pojo="person" :size="25" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-text="person.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn text :to="$boo.domainRouteTo('persons')"> Alle Personen </v-btn>
    </v-card-actions>
  </bc-widget-frame>
</template>
<script>
export default {
  data() {
    return {
      // loading: false,
      axiosSuccess: null,
      axiosError: null,
    };
  },
  created() {
    if (
      this.$store.getters[this.$storenames.auth.getters.userCan]('person.show')
    )
      this.loadData();
  },
  computed: {
    persons() {
      return this.$store.getters[this.$storenames.data.getters.recentXPersons](
        5
      );
    },
  },
  methods: {
    loadData() {
      // this.loading = true
      this.$store.dispatch('data/refresh', ['getPersons']);
      //Muss ich hier auf das ergebnis warten?
    },
    showPerson(person) {
      this.$router.push({
        name: 'personView',
        params: {
          personSlug: person.slug,
          person: person,
        },
      });
    },
  },
};
</script>
