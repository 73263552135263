<template>
  <v-autocomplete
    v-model="item"
    :label="label"
    :items="items"
    :item-text="itemtext"
    :item-value="itemvalue"
    :rules="rules"
    :clearable="clearable"
    :hint="hint"
    persistent-hint
    @input="returnValue"
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-list-item-avatar
        v-bind="attr"
        :input-value="selected"
        color="blue-grey"
        class="headline font-weight-light white--text"
        v-on="on"
      >
        <bc-avatar :pojo="item" :size="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.label" />
      </v-list-item-content>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-avatar class="headline font-weight-light white--text">
        <bc-avatar :pojo="item" :size="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.label" />
        <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BcAutocomplete',
  props: {
    value: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Suche',
    },
    itemvalue: {
      type: String,
      default: 'id',
    },
    itemtext: {
      type: String,
      default: 'label',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  watch: {
    value() {
      this.item = JSON.parse(JSON.stringify(this.value));
    },
  },
  methods: {
    returnValue() {
      this.$emit('input', this.item);
    },
  },
  created() {
    this.item = JSON.parse(JSON.stringify(this.value));
  },

  data() {
    return {
      item: null,
    };
  },
};
</script>
