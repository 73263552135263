<script lang="ts">
import { UserType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import { getUserSettings, setOverDueNotificationsSetting } from './userService';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';

export default defineComponent({
  name: 'UserSettings',

  components: { BcResponseHandler },

  props: {
    user: {
      required: true,
      type: Object as PropType<UserType>,
    },
    onSuccess: {
      type: Function as PropType<(user: UserType) => void>,
    },
  },
  data() {
    return {
      settings: null as null | Record<string, any>[],
      settingsToShow: ['OverDueNotifications'],
      axiosError: null as null | any,
    };
  },
  computed: {
    overDueNotificationsValue(): boolean {
      return Boolean(
        this.user?.settings?.find(
          (setting) => setting.name === 'OverDueNotifications'
        )?.value
      );
    },
    computedSettings(): any {
      const settings = [];

      if (this.settingsToShow.includes('OverDueNotifications')) {
        const overDueNotificationsSetting = this.settings?.find(
          (setting) => setting.name === 'OverDueNotifications'
        );

        settings.push({
          name: overDueNotificationsSetting?.name,
          description: overDueNotificationsSetting?.description,
          action: async () => {
            try {
              const response = await setOverDueNotificationsSetting(
                this.user,
                !this.overDueNotificationsValue
              );

              const user: UserType = response.data.success;
              this.onSuccess?.(user);
            } catch (error) {
              console.log('Error updating setting OverDueNotifications', error);
              this.axiosError = error;
            }
          },
          value: this.overDueNotificationsValue,
        });
      }

      return settings;
    },
  },

  async created() {
    try {
      this.settings = (await getUserSettings()).data.success;
    } catch (error) {
      console.log('Error getting Settings', error);
      this.axiosError = error;
    }
  },
});
</script>

<template>
  <v-card>
    <v-card-title>
      <v-badge content="beta" offset-x="5" offset-y="5">
        User-Settings
      </v-badge>
    </v-card-title>
    <v-card-text>
      <BcResponseHandler :axios-error="axiosError" />
      <v-list v-for="setting in computedSettings" :key="setting.name">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap"
              >E-Mail Benachrichtigungen für überfällige
              Zeitabrechnungen</v-list-item-title
            >
            <v-list-item-subtitle class="text-wrap">
              Benachrichtige {{ user.person.label }} am Monatsersten, wenn eine
              Abrechnung überfällig ist
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch @change="setting.action" v-model="setting.value" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
