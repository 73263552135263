<template>
  <v-dialog :value="showDialog" max-width="440" persistent>
    <v-card color="error--text text--darken-3" class="cdIncluded">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
        <v-checkbox
          v-if="checkboxLabel"
          v-model="deleteCheck"
          :label="checkboxLabel"
          color="red darken-3"
          hide-details
          dense
        />
      </v-card-text>
      <bc-btn-cancel-action
        delete-btn
        :enable-action="deleteCheck"
        :action-running="deleting"
        @evDelete="doDelete"
        @evCancel="doCancel"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import BcBtnCancelAction from '@/components/global/BcBtnCancelAction.vue';

export default {
  name: 'BcDialogDelete',
  components: { BcBtnCancelAction },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Löschen',
    },
    checkboxLabel: {
      type: String,
      default: 'ja, jetzt löschen', // wenn leer, dann nicht anzeigen
    },
    deleting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteCheck: false,
    };
  },

  watch: {
    showDialog: function () {
      this.deleteCheck = this.checkboxLabel === '';
    },
  },

  methods: {
    doDelete: function () {
      this.$emit('evDelete');
    },
    doCancel: function () {
      this.$emit('evCancel');
    },
  },
};
</script>
