<script lang="ts">
import store from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MaintenanceDialog',
  computed: {
    showMaintenance() {
      return store.state.app.maintenanceEnabled;
    },
  },

  methods: {
    onHandleRefresh() {
      window.location.reload();
    },
  },
});
</script>

<template>
  <v-dialog fullscreen persistent v-model="showMaintenance">
    <v-card class="d-flex flex-column text-center justify-center">
      <div class="w-4">
        <img
          src="/img/boo-brand-offline.png"
          width="200"
          class="align-self-center"
        />
        <h1>Wartungsmodus</h1>
        <p>
          boo ist aufgrund von Wartungsarbeiten gerade nicht erreichbar. Bitte
          versuche es später noch einmal.
        </p>

        <v-btn @click="onHandleRefresh" color="primary" class="mt-5"
          >Seite neu laden</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
