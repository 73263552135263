<script lang="ts">
import { defineComponent } from 'vue';
import boo from '@/boo';
import { BOO_NEXT_URL } from '@/constants';

export default defineComponent({
  name: 'BooNextListItem',
  setup() {
    return {
      // Add your properties here
    };
  },

  methods: {
    goToBooNext() {
      const url = BOO_NEXT_URL;
      if (!window.open) return;
      //@ts-ignore
      window.open(url, '_blank').focus();
    },
  },
});
</script>

<template>
  <v-list-item link @click="goToBooNext" class="primary">
    <v-list-item-icon>
      <v-icon>mdi-test-tube</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="text-wrap">
        Jetzt das neue boo testen!
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
