/**
 * app.store.js
 */
import { DomainType, WidgetType } from '../../types/types';

type AppStoreStateType = {
  snackbar: {
    show: boolean;
    text: string;
    color: string;
    timeout: number;
  };
  domain: DomainType | null;
  dashboardWidgets: Array<WidgetType> | undefined;
  expectedVersion: string;
  boobeVersion: string;
  maintenanceEnabled: boolean;
};

// State object
const initialState = () => ({
  snackbar: {
    show: false, // trigger
    text: '',
    color: 'boo',
    timeout: 3000,
  },
  domain: null,
  dashboardWidgets: undefined,
  expectedVersion: '',
  boobeVersion: '',
  maintenanceEnabled: false,
});

const state: AppStoreStateType = initialState();

const getters = {};

const actions = {
  reset({ commit }: any) {
    commit('RESET');
  },
};

const mutations = {
  RESET(state: any) {
    const newState = initialState();
    // @ts-ignore: next-line
    Object.keys(newState).forEach((key) => (state[key] = newState[key])); // TODO: ist das erlaubt?
  },
  SETSNACKBAR(state: AppStoreStateType, sbAttr: any) {
    state.snackbar.text = sbAttr.text || '';
    state.snackbar.timeout = sbAttr.timeout || 3000;
    state.snackbar.color = sbAttr.color || 'boo';
    state.snackbar.show = true;
  },
  HIDESNACKBAR(state: AppStoreStateType) {
    state.snackbar.show = false;
  },
  SETDOMAIN(state: AppStoreStateType, domain: any) {
    state.domain = domain;
  },
  SETEXPECTEDVERSION(state: AppStoreStateType, version: any) {
    state.expectedVersion = version;
  },
  SETBOOBEVERSION(state: AppStoreStateType, version: any) {
    state.boobeVersion = version;
  },
  SETDASHBOARDWIDGETS(state: AppStoreStateType, widgets: any) {
    state.dashboardWidgets = widgets;
  },
  SETMAINTENANCEENABLED(state: AppStoreStateType, enabled: boolean) {
    state.maintenanceEnabled = enabled;
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
