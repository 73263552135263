var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-center"},[(
        !_vm.commentBefore ||
        _vm.$dayjs(_vm.comment.created_at).format('DD MMM') !==
          _vm.$dayjs(_vm.commentBefore.created_at).format('DD MMM')
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"my-5"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$boo.humanDayDiff(_vm.$dayjs(_vm.comment.created_at)))+" ")])]}}],null,false,4036505986)},[_c('span',[_vm._v(_vm._s(_vm.$dayjs(_vm.comment.created_at).format(_vm.$BC.F_DE_DATE_NICE)))])]):_vm._e()],1),_c('div',{class:_vm.rowClass},[(!_vm.isMyMessage)?_c('Bc-comment-avatar',{attrs:{"author":_vm.comment.owner,"isLastMessageOfAuthor":_vm.commentAfter ? _vm.comment.owner.id !== _vm.commentAfter.owner.id : true}}):_vm._e(),_c('v-card',{class:_vm.bubbleClass,attrs:{"color":_vm.chatBubbleBackgroundColor,"id":_vm.comment.id,"flat":_vm.isSymbol}},[(
          !_vm.isMyMessage &&
          _vm.comment.owner &&
          (_vm.commentBefore ? _vm.comment.owner.id !== _vm.commentBefore.owner.id : true)
        )?_c('v-card-text',{class:_vm.authorClass},[_vm._v(_vm._s(_vm.comment.owner.label)+" ")]):_vm._e(),_c('v-card-text',{class:`pa-0 ${_vm.isSymbol ? 'text-h2' : 'body-1'}`},[_vm._v(_vm._s(_vm.comment.text))]),_c('v-card-subtitle',{staticClass:"pa-0 mt-2 text-caption text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$boo.visibilityInfo(_vm.comment.visibility, _vm.comment, _vm.commentable) .icon)+" ")])]}}])},[_c('span',[_vm._v("Sichbarkeit: "+_vm._s(_vm.$boo.visibilityInfo(_vm.comment.visibility, _vm.comment, _vm.commentable) .label))])]),_vm._v(" · "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.timeOfComment))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.timeAndDateOfComment))])]),(_vm.isMyMessage && _vm.comment.id !== 999999)?_c('span',[_vm._v(" · "),_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" mdi-delete-forever-outline ")]),_c('bc-dialog-delete',{attrs:{"show-dialog":_vm.showDeleteDialog,"title":"Kommentar löschen","checkboxLabel":"","deleting":_vm.deleting},on:{"evCancel":function($event){_vm.showDeleteDialog = false},"evDelete":function($event){return _vm.deleteComment(_vm.comment.id)}}},[_vm._v(" Soll dieses Kommentar "),_c('strong',[_vm._v("endgültig gelöscht")]),_vm._v(" werden? ")]),_vm._v(" · "),_c('v-icon',{attrs:{"x-small":""},on:{"click":_vm.editComment}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('bc-response-handler',{attrs:{"axios-success":_vm.axiosSuccess,"axios-error":_vm.axiosError,"show-in-dialog":""}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }