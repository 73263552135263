<script lang="ts">
import { getTagsRequest } from '@/components/tags/tagService';
import { Tag } from '@/components/tags/tagTypes';
import { defineComponent } from 'vue';
import BvFrame from '../Frame.vue';

export default defineComponent({
  name: 'TagsView',
  data() {
    return {
      headline: 'Tags',
      subtitle:
        'Hier kannst du die Tags der Sphäre ' + this.$store.state.app.domain.slug + ' verwalten. Tags sind eine einfache Möglichkeit, um Daten zu gruppieren.',
      newTagButtonLabel: 'Tag hinzufügen',
      tags: [] as Tag[],
      isLoading: false,
      axiosError: null as any,
    };
  },
  components: { BvFrame },

  methods: {
    async getTags() {
      this.isLoading = true;
      try {
        this.tags = await getTagsRequest();
      } catch (e: any) {
        this.axiosError = e.response;
      } finally {
        this.isLoading = false;
      }
    },
    onNewTagButtonClick() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-tag-edit',
        data: {
          onTagChanged: this.getTags,
        },
      });
    },
    editTag(tag: Tag) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-tag-edit',
        data: {
          tag,
          onTagChanged: this.getTags,
        },
      });
    },
  },

  computed: {
    headers() {
      return [
        { text: 'Name', value: 'name', sortable: false, width: '25' },
        { text: 'getaggte Personen', value: 'counts.persons', sortable: false, width: '25' },
        { text: 'getaggte Projekte', value: 'counts.projects', sortable: false, width: '25' }
      ];
    },
  },

  created() {
    this.getTags();
  },
});
</script>

<template>
  <BvFrame
    :headline="headline"
    :subtitle="subtitle"
    :loading="true"
    :new-button="true"
    :new-button-label="newTagButtonLabel"
    @newButton="onNewTagButtonClick"
  >
    <bc-response-handler :axios-error="axiosError" />

    <v-data-table
      :items="tags"
      :loading="isLoading"
      :headers="headers"
      sort-by="name"
      :items-per-page="-1"
      hide-default-footer
      @click:row="editTag"
    >
      <template v-slot:no-data> Keine Tags vorhanden. </template>
      <template v-slot:loading> Lade Tags... </template>
    </v-data-table>
  </BvFrame>
</template>
