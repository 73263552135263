<template>
  <v-dialog :value="showDialog" max-width="440" persistent>
    <v-card color="warning--text text--darken-3" class="cdIncluded">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
        <v-checkbox
          v-if="checkboxLabel"
          v-model="confirmCheck"
          :label="checkboxLabel"
          color="red darken-3"
          hide-details
          dense
        />
      </v-card-text>

      <bc-btn-cancel-action
        :cancelText="btnEscapeLabel"
        :actionText="btnConfirmLabel"
        :action-running="proceeding"
        @evAction="proceed"
        @evCancel="cancel"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import BcBtnCancelAction from '@/components/global/BcBtnCancelAction.vue';

export default {
  name: 'BcDialogConfirm',
  components: { BcBtnCancelAction },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Aktion bestätigen',
    },
    checkboxLabel: {
      type: String,
      default: '', // wenn leer, dann nicht anzeigen
    },
    btnEscapeLabel: {
      type: String,
      default: 'Abbrechen',
    },
    btnConfirmLabel: {
      type: String,
      default: 'OK',
    },
    proceeding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  watch: {
    showDialog: function () {
      this.confirmCheck = this.checkboxLabel === '';
    },
  },

  methods: {
    proceed: function () {
      this.$emit('evProceed');
    },
    cancel: function () {
      this.$emit('evCancel');
    },
  },
};
</script>
