<template>
  <div v-if="loading" class="d-flex flex-wrap justify-start mt-2">
    <v-skeleton-loader
      v-for="n in 5"
      :key="n"
      type="avatar, card-heading, text"
      class="pa-2 ma-1"
      style="flex: 1 1 300px"
      min-width="250px"
      max-width="100%"
      elevation="1"
    />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
