import * as BC from '@/constants';
import boo from '@/boo';
import dayjs from 'dayjs';
import axios from 'axios';
import {
  TimeType,
  PTMType,
  PersonType,
  NotificationType,
  FilterType,
} from '@/types/types';

type DataStoreType = {
  ptms: Array<PTMType>;
  persons: Array<PersonType>;
  notifications: Array<NotificationType>;
  myRecordingTimes: Array<TimeType>;
  myQuickPtms: Array<PTMType>;
  isLoading: boolean;
  filter: FilterType;
  latest_access: any;
};

// State object
const initialState = () => ({
  ptms: [],
  persons: [],
  myQuickPtms: [],
  myRecordingTimes: [],
  filter: {},
  notifications: [],
  isLoading: false,
  latest_access: {
    times: dayjs().year(1972),
  },
});

const state: DataStoreType = initialState();

const getters = {
  recentXPersons: (state: any) => (count: any) => {
    const persons_copy = [...state.persons]
      .filter((person) => person.latest_access != null)
      .sort(boo.sortByDesc('latest_access'));
    return persons_copy.slice(0, count);
  },

  /* filtert diejenigen PTMs, welche für die Erstellung eines ZEs verwendet werden können
   * - müssen ein timeRecModel haben
   * - müssen aktiv sein ODER der User ist PL
   */
  timeablePTMs: (state: any) =>
    state.ptms.filter(
      (ptm: any) =>
        (ptm.project.isProjectLead || ptm.active) && ptm.timerecmodel
    ),
};

const actions = {
  reset({ commit }: any) {
    commit('RESET');
  },
  async refresh({ commit, dispatch }: any, actions: any) {
    commit('SETLOADING', true);
    await Promise.all(
      actions.map((action: any) => {
        return dispatch(action);
      })
    ).then(() => {
      commit('SETLOADING', false);
    });
  },

  async getPersons({ commit, rootGetters }: any, params: any) {
    if (!rootGetters['auth/userCan']('person.show')) return;
    await axios
      .get(`${boo.lboUrl()}persons`, {
        params: params,
      })
      .then((response: any) => {
        commit('setPersons', response.data.success);
      })
      .catch((error: any) => {
        console.error(error);
      });
  },

  async getPTMs({ commit }: any, params: any) {
    await axios
      .get(`${boo.lboUrl()}times/ptms`, {
        params: params,
      })
      .then((response) => {
        commit('setPTMs', response.data.success);
      })
      .catch((error: any) => {
        console.error(error);
      });
  },

  async getMyQuickPtms({ commit }: any, params: any) {
    await axios
      .get(`${boo.lboUrl()}times/ptms`, {
        params: {
          onlyMine: true,
          canQuick: true,
        },
      })
      .then((response) => {
        commit('setMyQuickPtms', response.data.success);
      })
      .catch((error: any) => {
        console.error(error);
      });
  },

  async getNotifications({ commit }: any) {
    await axios.get(`${BC.LBO_API_URL}notifications`).then((response) => {
      commit('SETNOTIFICATIONS', response.data.success);
    });
  },
};

const mutations = {
  RESET(state: any) {
    const newState = initialState();
    // @ts-ignore
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  SETLOADING(state: any, boolean: any) {
    state.isLoading = boolean;
  },
  setDashboardWidgets(state: any, widgets: any) {
    state.dashboardWidgets = widgets;
  },

  setPersons(state: any, persons: any) {
    state.persons = persons;
  },

  setPTMs(state: any, ptms: any) {
    state.ptms = ptms;
  },

  setMyQuickPtms(state: any, quickTimePTMs: any) {
    state.myQuickPtms = quickTimePTMs;
  },

  setLatestAccess(state: any, attribute: any) {
    state.latest_access[attribute] = dayjs();
  },

  // setMyRecordingTimes(state: any, recordingTimes: any) {
  //   state.myRecordingTimes = recordingTimes;
  // },

  setFilter(state: any, filter: any) {
    state.filter = filter;
  },

  SETNOTIFICATIONS(state: any, notifications: any) {
    state.notifications = notifications;
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
