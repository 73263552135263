<!--suppress SpellCheckingInspection -->
<template>
  <v-layout justify-center class="pt-5">
    <v-card width="360" class="mt-5 mx-a" flat color="transparent">
      <v-card-title>
        <v-img
          data-cy="boo-logo"
          src="/img/boo-logo.png"
          class="mb-0"
          contain
          height="90"
        />
      </v-card-title>
      <v-form
        ref="formLogin"
        v-model="valid"
        lazy-validation
        @keyup.native.tab="nextStepOnKeyUp"
        @keyup.native.enter="nextStepOnKeyUp"
      >
        <v-stepper v-model="loginStep">
          <v-stepper-items color="transparent">
            <v-stepper-content class="pa-0" step="1">
              <v-card class="pa-5" flat>
                <v-card-subtitle class="text-center title"
                  ><h1>{{ title }}</h1></v-card-subtitle
                >

                <v-card-text class="body-1 text-center my-4">
                  Wie lautet deine E-Mail-Adresse?
                </v-card-text>
                <v-text-field
                  data-cy="email-input"
                  v-model="email"
                  outlined
                  label="E-Mail-Adresse"
                  autocomplete="username"
                  prepend-inner-icon="mdi-email-outline"
                  :Xrules="EmailRules"
                  validate-on-blur
                  autofocus
                />
                <v-btn
                  data-cy="next-step-button"
                  class="boo mb-1 white--text"
                  block
                  :disabled="!emailIsValid"
                  @click="loginStep = 2"
                >
                  Weiter
                </v-btn>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0" step="2">
              <v-card class="pa-5" flat>
                <v-card class="pa-0 ma-0" flat>
                  <v-card-subtitle class="text-center headline pa-0"
                    >Anmeldung
                  </v-card-subtitle>
                  <v-card-text class="text-center pb-0">
                    <v-chip
                      class="ma-7"
                      style="cursor: pointer"
                      color="blue"
                      outlined
                      pill
                      @click="loginStep = 1"
                    >
                      {{ email }}
                      <v-icon right>mdi-account-outline</v-icon>
                    </v-chip>

                    <v-text-field
                      data-cy="password-input"
                      v-model="password"
                      class="mt-2"
                      autofocus
                      outlined
                      :type="showPassword ? 'text' : 'password'"
                      label="Passwort eingeben"
                      autocomplete="current-password"
                      prepend-inner-icon="mdi-lock-outline"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :Xrules="PasswordRules"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-card-text>

                  <v-card-actions class="mx-2">
                    <v-btn
                      data-cy="login-button"
                      class="boo white--text"
                      block
                      :disabled="
                        !password || waitingForPWlogin || waitingForMagicLink
                      "
                      :loading="waitingForPWlogin"
                      @click="loginWithPassword"
                    >
                      <span>Anmelden</span>
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="mx-2">
                    <v-btn
                      data-cy="password-reset-button"
                      class="caption justify-center"
                      text
                      block
                      color="grey"
                      :to="{
                        name: 'password_forgot',
                        query: { email },
                      }"
                    >
                      Passwort vergessen?
                    </v-btn>
                  </v-card-actions>

                  <bc-response-handler
                    :axios-success="axiosSuccess"
                    :axios-error="axiosError"
                    :show-in-dialog="loginStep !== 5"
                    @ev-oked="waitingForPWlogin = false"
                  />
                </v-card>

                <v-card-text class="d-flex align-center pa-0 mb-4">
                  <v-divider class="mr-2" />
                  oder
                  <v-divider class="ml-2" />
                </v-card-text>

                <v-card class="pa-0 ma-0" flat>
                  <v-card-subtitle class="title text-center pt-0"
                    >Ohne Passwort einloggen
                  </v-card-subtitle>
                  <v-card-text class="text-center">
                    Wir können dir einen magischen Link via E-Mail zusenden. Mit
                    diesem kannst du dich ohne Passwort einloggen.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="boo--text"
                      data-cy="request-magic-link-button"
                      block
                      outlined
                      :loading="waitingForMagicLink"
                      @click="requestMagicLink"
                    >
                      <span>
                        <v-icon left color="pink accent-2"
                          >mdi-magic-staff</v-icon
                        >
                        Magischen Link mailen
                      </span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0" step="3">
              <v-card class="pa-5" flat>
                <v-card-subtitle
                  data-cy="magic-link-heading"
                  class="title text-center"
                >
                  Dein magischer Anmelde-Link ist auf dem Weg!
                </v-card-subtitle>
                <v-card-text class="text-center">
                  <v-icon x-large class="boo--text pa-3"
                    >mdi-email-check-outline
                  </v-icon>
                  <p>
                    Wir haben dir eine E-Mail mit einem Link geschickt. Klicke
                    auf den Link, um dich direkt ohne Passwort einzuloggen.
                  </p>
                  <p>
                    Überprüfe auch, ob die E-Mail eventuell in deinem
                    Spam-Ordner gelandet ist.
                  </p>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0" step="4">
              <v-card class="pa-5" flat>
                <v-card-subtitle class="title text-center"
                  >Du wirst eingeloggt...
                </v-card-subtitle>
                <v-card-text class="text-center pa-5">
                  <v-progress-circular
                    color="boo"
                    indeterminate
                    size="30"
                    width="3"
                  />
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-0" step="5">
              <v-card class="pa-5" flat>
                <v-card-subtitle class="title text-center">
                  Ein Fehler ist aufgetreten...
                </v-card-subtitle>
                <v-card-text>
                  <bc-response-handler
                    :axios-success="axiosSuccess"
                    :axios-error="axiosError"
                  />
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
      <v-btn
        v-if="![1, 4].includes(loginStep)"
        text
        class="caption"
        color="grey"
        @click="loginStep = 1"
      >
        <v-icon small> mdi-chevron-left</v-icon>
        Zurück zum Login
      </v-btn>
    </v-card>
  </v-layout>
</template>

<script>
import store from '@/store';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';

export default {
  name: 'LoginView',
  components: { BcResponseHandler },

  data() {
    return {
      showPassword: false,
      loginStep: 1,

      // // Steps
      // 1: E-Mail-Abfrage
      // 2: Passwort oder Magic Link wählen
      // 3: Magic Link auf dem Weg
      // 4: Login mit Magic Link
      // 5: Fehler

      valid: false,

      email: this.$route.query.email || '',
      password: '',

      EmailRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => this.$BC.EMAIL_PATTERN.test(v) || 'keine valide E-Mail-Adresse',
      ],

      PasswordRules: [(v) => v !== '' || 'Eingabe erforderlich'],

      axiosError: null,
      axiosSuccess: null,

      waitingForPWlogin: false,
      waitingForMagicLink: false,
    };
  },
  watch: {
    loginStep() {
      if (this.loginStep === 1) {
        this.axiosError = null;
      }
    },
  },
  computed: {
    title() {
      if (this.$boo.envIsDev()) return 'd EVEL opment';
      if (this.$boo.envIsTest()) return 'wanna test me?';
      if (this.$boo.envIsStaging()) return 'SPielWieSE...!';
      return 'Willkommen!';
    },
    otp() {
      return this.$route.query.otp || null;
    },
    emailIsValid() {
      if (this.email === '') return false;
      return this.$BC.EMAIL_PATTERN.test(this.email);
    },
  },
  created() {
    // Gibt es ein OTP und eine valide E-Mail-Adresse? Dann starte Login via OTP
    if (
      this.otp &&
      this.$route.query.email &&
      this.$BC.EMAIL_PATTERN.test(this.$route.query.email)
    ) {
      this.loginStep = 4;
      this.loginWithMagicLink();
    } else if (
      this.$route.query.email &&
      this.$BC.EMAIL_PATTERN.test(this.$route.query.email)
    ) {
      // Gibt es eine valide E-Mail-Adresse? Gehe direkt zum nächsten Schritt.
      this.loginStep = 2;
    }
  },
  methods: {
    loginWithPassword() {
      if (!this.$refs.formLogin.validate()) return;

      this.waitingForPWlogin = true;
      window.axios
        .post(this.$BC.LBO_URL + '/api/auth/login', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.onLoginSuccess(response.data.apiData);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.waitingForPWlogin = false;
        });
    },
    requestMagicLink() {
      this.waitingForMagicLink = true;
      window.axios
        .post(this.$BC.LBO_URL + '/api/auth/generateOTP', {
          email: this.email,
        })
        .then(() => {
          this.loginStep = 3;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.waitingForMagicLink = false;
        });
    },
    loginWithMagicLink() {
      this.waitingForMagicLink = true;
      window.axios
        .post(this.$BC.LBO_URL + '/api/auth/OTPlogin', {
          email: this.email,
          otp: this.otp,
        })
        .then((response) => {
          this.onLoginSuccess(response.data.apiData);
        })
        .catch((error) => {
          this.loginStep = 5;
          this.axiosError = error;
          // this.$router.replace({ name: 'login' }) // um (invalides) otp & email aus URL zu löschen
        })
        .finally(() => {
          this.waitingForMagicLink = false;
        });
    },
    nextStepOnKeyUp() {
      if (this.loginStep === 1 && this.emailIsValid) this.loginStep = 2;
      else if (this.loginStep === 2 && this.password) this.loginWithPassword();
    },
    onLoginSuccess(apiData) {
      this.$boo.storeApiData(apiData);
      this.$cookies.set('user', store.state.auth.user.id);

      const domainCount = this.$boo.objCount(store.state.auth.domains);

      const goToDashboardOfDomain = () => {
        this.$boo.setDomain(
          store.state.auth.domains[Object.keys(store.state.auth.domains)[0]]
        );
        this.$router.replace(this.$boo.domainRouteTo('dashboard'));
      };

      const domainForMarketingCookie =
        store.state.auth.domains[Object.keys(store.state.auth.domains)[0]];

      if (domainForMarketingCookie) {
        this.$cookies.set(
          'boo-domain',
          JSON.stringify({
            name: domainForMarketingCookie.name,
            initial: domainForMarketingCookie.initial,
            avatar: domainForMarketingCookie.avatar
              ? domainForMarketingCookie.thumbnail
              : '',
          }),
          undefined,
          undefined,
          this.$boo.cookieDomain()
        );
      }

      if (domainCount === 1) {
        goToDashboardOfDomain();
      } else {
        this.$router.replace({ name: 'home' });
      }
    },
  },
};
</script>
