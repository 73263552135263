<template>
  <div>
    <bc-notification-text :data="notification.text" />
    <bc-notification-list :data="notification.list" />
    <bc-notification-text
      data="Wir hoffen, dass dir die Änderungen gefallen! Wenn du Feedback für uns hast, kannst du uns gerne direkt eine Nachricht zukommen lassen:"
    />
    <bc-user-feedback-form-dialog class="mx-5 pt-5" />
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
