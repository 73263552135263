import boo from '@/boo';
import { LBO_API_URL } from '@/constants';
import authStore from '@/store/modules/auth.store';
import { UserType } from '@/types/types';
import axios from 'axios';

export const getUserSettings = async () => {
  const response = await axios.get(`${boo.lboUrl()}settings`);
  return response;
};

export const setUserSetting = async (
  user: UserType,
  settingName: string,
  value: unknown
) => {
  const response = await axios.patch(
    `${boo.lboUrl()}user/${user?.slug}/setting/${settingName}`,
    {
      value,
    }
  );
  return response;
};

export const setOverDueNotificationsSetting = async (
  user: UserType,
  value: unknown
) => {
  const response = await setUserSetting(user, 'OverDueNotifications', value);
  return response;
};
