<template>
  <v-select
    prepend-icon="mdi-notebook-check-outline"
    v-model="activities"
    :items="items"
    outlined
    chips
    small-chips
    deletable-chips
    flat
    label="Welche Aktivitäten wurden durchgeführt?"
    no-data-text="Keine Aktivitäten verfügbar"
    multiple
    solo
    @input="returnValue"
  />
</template>

<script>
export default {
  name: 'TimeActivities',

  props: {
    value: {
      // time-Einträge - werden als array übergeben
      type: Array,
      default: () => [],
    },
    options: {
      // Auswahlmöglichkeiten (aus ZEM)
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    // items: ['Grundversorgung', 'Haushalt', 'Mobilität', 'Freizeit', 'Gesundheit', 'Kommunikation'],
    items: {},

    newValues: [],
  }),

  created() {
    // aktuelle Auswahlmöglichkeiten aufbauen
    let objArray = this.options.reduce(
      (acc, curr) => (acc.push({ text: curr }), acc),
      []
    );

    // gibt es bestehende andere?
    const oldActivities = this.value.filter(
      (activity) => !this.options.includes(activity)
    );
    // console.log(oldActivities.length)
    if (oldActivities.length > 0) {
      objArray.push({ divider: true }); // Trennline
      objArray.push({ header: 'veraltete Aktivitäten' }); // Erklärung
      // bestehende anhängen
      objArray = oldActivities.reduce(
        (acc, curr) => (acc.push({ text: curr }), acc),
        objArray
      );
    }
    this.items = objArray;
  },

  computed: {
    activities: {
      get: function () {
        // array >> object
        return this.value.reduce(
          (acc, curr) => (acc.push({ text: curr }), acc),
          []
        );
      },
      set: function (newValue) {
        // object >> array
        this.newValues = Object.values(newValue);
      },
    },
  },

  methods: {
    returnValue() {
      this.$emit('input', this.newValues);
    },
  },
};
</script>
