<template>
  <v-card
    class="ma-0 px-2 px-md-5"
    flat
    color="transparent"
    :loading="loading"
    max-width="1400"
  >
    <v-card-title class="boo--text mt-5">
      <span class="text-h4">{{ headline }}</span>
      <div v-if="false" class="pull-right mx-2">
        <v-btn
          icon
          title="aktualisieren"
          color="grey lighten-1"
          :loading="loading"
          small
          @click="$emit('refresh')"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>

      <v-spacer />

      <div>
        <v-btn
          data-cy="newButton"
          v-if="newButton"
          :fab="isMobile"
          bottom
          dark
          :right="isMobile"
          :fixed="isMobile"
          class="boo"
          @click="$emit('newButton')"
        >
          <v-icon color="white" small> mdi-plus </v-icon>
          {{ !isMobile ? newButtonLabel : '' }}
        </v-btn>
      </div>
      <slot name="buttonRight" />
    </v-card-title>
    <v-card-subtitle class="font-weight-bold body-2 mt-1">
      {{ subtitle }}
    </v-card-subtitle>
    <div :loading="loading">
      <slot />

      <!-- SCHALTER nur für DEV-->
      <div v-if="$boo.envIsDev() && false" id="test" class="blue">
        <br /><br /><br /><br /><br />
        <v-tooltip top color="warning">
          <template v-slot:activator="{ on }">
            <v-card id="testgrid" flat v-on="on">
              <v-container class="grey lighten-5" fluid>
                <v-row no-gutters>
                  <v-col v-for="n in 12" :key="n" sm="1">
                    <v-card class="text-center" outlined>
                      {{ n }}
                    </v-card>
                  </v-col>
                </v-row>

                <v-card outlined> xs (handset) </v-card>
                <v-card outlined class="hidden-xs-only"> sm (tablet) </v-card>
                <v-card outlined class="hidden-sm-and-down">
                  md (laptop)
                </v-card>
                <v-card outlined class="hidden-md-and-down">
                  lg (desktop)
                </v-card>
                <v-card outlined class="hidden-lg-and-down"> xl (wide) </v-card>
              </v-container>
            </v-card>
          </template>
          <span>Layout-Hilfe - wird nur während Development angezeigt!</span>
        </v-tooltip>
      </div>
      <!-- SCHALTER nur für DEV-->
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BvFrame',
  props: {
    headline: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    refreshButton: {
      type: Boolean,
      default: true,
    },
    newButton: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: 'Neu',
    },
  },

  data() {
    return {
      showHelp: false,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },
});
</script>
