<template>
  <div>
    <v-select
      background-color="transparent"
      style="width: min-content"
      v-model="status_id"
      item-text="name"
      item-value="id"
      hide-details
      :items="items"
      :append-icon="readonly ? '' : 'mdi-chevron-down'"
      solo
      flat
      dense
      :readonly="readonly"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :color="chipColor(data.item.id)"
          @click="data.select"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-select>

    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
      show-in-dialog
    />
  </div>
</template>

<script>
import { getProjectStatusColor } from '@/views/project/helpers';

export default {
  name: 'ProjectStatusChip',
  props: {
    value: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    project: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      axiosSuccess: null,
      axiosError: null,
    };
  },
  computed: {
    status_id: {
      get() {
        return this.value;
      },
      set(newStatus) {
        this.changeStatus(newStatus);
        this.$emit('input', newStatus);
      },
    },
  },
  methods: {
    changeStatus(status) {
      let transfer = {
        status_id: status,
      };

      window
        .axios({
          method: 'patch',
          url: `${this.$boo.lboUrl()}projects/${this.project.slug}`,
          data: transfer,
        })
        .then((response) => {
          this.$boo.storeApiData(response.data.apiData); // apiData aktualisieren
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    chipColor(id) {
      return getProjectStatusColor(id);
    },
  },
};
</script>
