import boo from '@/boo';
import * as BC from '@/constants';
import arrayIncludesAny from 'array-includes-any';
import axios from 'axios';
import {
  AttributesType,
  DomainType,
  PermissionsType,
  SettingsType,
  UserType,
} from '@/types/types';
import Vue from 'vue';
import router from '@/plugins/router';

type AuthStoreStateType = {
  user: UserType | null;
  permissions: PermissionsType | undefined;
  settings: SettingsType | undefined;
  attributes: AttributesType | undefined;
  domains: Record<string, DomainType> | undefined;
  token: string;
  status: string;
};

// state object
const initialState = () => ({
  user: null,
  permissions: undefined,
  settings: undefined,
  attributes: undefined,
  domains: undefined,

  token: localStorage.getItem('user-token') || '',
  status: '',
});

const state: AuthStoreStateType = initialState();

const getters = {
  // maybe useful to understand:
  // https://stackoverflow.com/questions/32782922/what-do-multiple-arrow-functions-mean-in-javascript
  // https://vuex.vuejs.org/guide/getters.html > #Method-Style Access
  userCan:
    (state: any, getters: any, rootState: any, rootGetters: any) =>
    (query: any) => {
      try {
        return state.permissions.abilities[rootState.app.domain?.slug].includes(
          query
        );
      } catch {
        return false;
      }
    },

  userCanAny:
    (state: any, getters: any, rootState: any, rootGetters: any) =>
    (query: any) => {
      try {
        return arrayIncludesAny(
          state.permissions.abilities[rootState.app.domain.slug],
          query
        );
      } catch {
        return false;
      }
    },

  userActive: (state: { user: { active: any } }) => {
    try {
      return state.user.active;
    } catch {
      return false;
    }
  },

  isAuthenticated: (state: { token: any }) => !!state.token,
  authStatus: (state: { status: any }) => state.status,
};

const actions = {
  // reset this module's store
  reset({ commit }: any) {
    console.log('Resetting store');
    commit('RESET');
  },

  // login request
  request({ commit, dispatch }: any, credentials: any) {
    return new Promise((resolve, reject) => {
      commit('REQUEST'); // set status to 'loading'
      axios({
        url: BC.LBO_URL + '/api/auth/login',
        data: credentials,
        method: 'POST',
      })
        .then((response) => {
          boo.storeApiData(response.data.apiData);

          // const token = response.data.token
          // localStorage.setItem('user-token', token)
          // commit(_types.mutations.SUCCESS, token)
          // dispatch(USER_REQUEST) // log in user (?)

          resolve(response);
        })
        .catch((error) => {
          commit('ERROR', error);
          localStorage.removeItem('user-token');
          reject(error);
        });
    });
  },

  async getApiData({ dispatch }: any) {
    const userCookie = Vue.$cookies.get('user');
    if (!userCookie) {
      dispatch.logout();
      return;
    }

    try {
      const response = await axios.get(`${BC.LBO_API_URL}apiData`);
      boo.storeApiData(response.data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  logout({ dispatch }: any) {
    console.log('Logging out...');

    axios({
      url: BC.LBO_URL + '/api/auth/logout',
      method: 'POST',
    })
      .then(() => {
        Vue.$cookies.remove('user');
        Vue.$cookies.remove('boo-domain');
        dispatch('reset');
        router.push({ name: 'login' });
      })
      .catch(() => {
        console.log('Error sending logout request');
        Vue.$cookies.remove('user');
        router.push({ name: 'login' });
      });
  },
};

const mutations = {
  REQUEST(state: { status: string }) {
    state.status = 'loading';
  },
  SUCCESS(state: { status: string; token: any }, token: any) {
    state.status = 'success';
    state.token = token;
  },
  ERROR(state: { status: string }) {
    state.status = 'error';
  },

  // reset this module's store
  RESET(state: { [x: string]: any }) {
    const newState = initialState();
    // @ts-ignore: next-line
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  SETUSER(state: { user: any }, user: any) {
    state.user = user;
  },
  SETPERMISSIONS(state: { permissions: any }, permissions: any) {
    state.permissions = permissions;
  },

  SETSETTINGS(state: { settings: any }, settings: any) {
    state.settings = settings;
  },
  SETSETTING(state: { settings: any }, oItemValue: { item: any; value: any }) {
    state.settings = {
      ...state.settings,
      [oItemValue.item]: oItemValue.value,
    };
  },
  SETATTRIBUTES(state: { attributes: any }, attributes: any) {
    state.attributes = attributes;
  },
  SETDOMAINS(state: { domains: any }, domains: any) {
    state.domains = domains;
  },
  SETDOMAIN(state: { domain: any }, domain: any) {
    state.domain = domain;
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
