<template>
  <bv-frame
    :headline="$store.state.app.domain.name"
    :subtitle="subtitle"
    :refresh-button="false"
  >
    <div class="d-flex flex-wrap justify-start mt-2">
      <draggable
        class="d-flex flex-wrap justify-start mt-2"
        v-model="widgets"
        v-bind="dragOptions"
      >
        <v-component
          :is="widget.name"
          v-for="(widget, n) in widgets"
          :key="n"
          class="my-1 ma-sm-1"
          :style="`flex: 1 1 ${widget.width}px; min-height: 300px`"
        />
      </draggable>
    </div>
  </bv-frame>
</template>

<script>
import store from '@/store';
import BvFrame from '@/views/Frame';
import draggable from 'vuedraggable';

export default {
  name: 'BvDashboard',

  components: { BvFrame, draggable },
  data() {
    return {
      subtitle:
        'Willkommen am Dashboard deiner ' +
        this.$store.state.app.domain.name +
        '-Sphäre. Was möchtest du heute tun, ' +
        this.$store.state.auth.user.adress +
        '?',
    };
  },

  computed: {
    widgets: {
      get() {
        return this.$store.state.app.dashboardWidgets;
      },
      set(widgets) {
        this.$store.commit('app/SETDASHBOARDWIDGETS', widgets);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    disableDraggable() {
      return this.isMobile;
    },
    dragOptions() {
      return {
        disabled: this.disableDraggable,
      };
    },
  },

  created() {
    let widgets = [];
    widgets.push({ name: 'bc-dashboard-times-quick', width: 400 });
    widgets.push({ name: 'bc-dashboard-times-list', width: 300 });
    widgets.push({ name: 'bc-dashboard-todos', width: 300 });
    widgets.push({ name: 'bc-dashboard-person', width: 300 });
    widgets.push({ name: 'bc-dashboard-project', width: 300 });
    // widgets.push({ name: "bc-dashboard-times-weekly", width: 400 });
    this.widgets = widgets;
  },
  mounted() {
    // Scroll to Top if changed to this route
    window.scrollTo(0, 0);

    store.dispatch.timebillingStore.fetchAllTimebillings();
    store.dispatch.timesStore.fetchAllTimes();

    this.$store.dispatch(this.$storenames.data.actions.refresh, [
      'getPTMs',
      'getMyQuickPtms',
      // 'getMyRecordingTimes',
      'getPersons',
    ]);
  },
};
</script>
