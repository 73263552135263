<template>
  <v-container fluid class="pa-0" v-if="billing">
    <v-toolbar flat color="alabaster" class="gunmetal--text" width="100%" dense>
      <v-btn text class="pl-0" exact :to="$boo.domainRouteTo('timebilling')">
        <v-icon>mdi-chevron-left</v-icon>
        Alle Abrechnungen
      </v-btn>
    </v-toolbar>
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />
    <v-row class="no-gutters">
      <v-col class="mb-2">
        <v-card outlined class="ma-2" height="100%">
          <v-card-title>
            <div v-if="isMobile">
              <v-btn
                v-if="ptmBillings.length > 1"
                small
                icon
                :disabled="!prevMonthBilling"
                @click="gotoBilling(prevMonthBilling.id)"
                ><v-icon small>mdi-chevron-double-left</v-icon>
              </v-btn>
              {{ $dayjs(billing.startdate).format('MMM YYYY') }}
              <v-btn
                v-if="ptmBillings.length > 1"
                small
                icon
                :disabled="!nextMonthBilling"
                @click="gotoBilling(nextMonthBilling.id)"
                ><v-icon small>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <p>Abrechnung für</p>
              <v-btn
                v-if="ptmBillings.length > 1"
                small
                icon
                :disabled="!prevMonthBilling"
                @click="gotoBilling(prevMonthBilling.id)"
                ><v-icon small>mdi-chevron-double-left</v-icon>
              </v-btn>
              {{ $dayjs(billing.startdate).format('MMMM YYYY') }}
              <v-btn
                v-if="ptmBillings.length > 1"
                small
                icon
                :disabled="!nextMonthBilling"
                @click="gotoBilling(nextMonthBilling.id)"
                ><v-icon small>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <bc-ptm-item v-if="billing.ptm" :ptm="billing.ptm" link />
          </v-card-text>
          <Bc-comment-badge
            class="text-right"
            :count="billing.comment_count"
            @click.stop="openComment()"
          />
        </v-card>
      </v-col>

      <v-col class="mb-2">
        <v-card outlined class="ma-2" height="100%">
          <v-card-title>Statistik</v-card-title>
          <v-card-text>
            <div class="mb-4 d-flex">
              <h4>Einträge:</h4>
              <v-spacer />
              <h3>{{ billing.entries }}</h3>
            </div>
            <v-divider />
            <div class="mt-4 d-flex align-end">
              <h4>Stunden gesamt:</h4>
              <v-spacer />
              <h3>{{ billing.hours.toFixed(2) }}</h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="mb-2">
        <v-card outlined class="ma-2" height="100%" data-cy="statsCard">
          <v-card-title>
            Status
            <v-spacer />
            <bc-time-billing-status-chip :value="billing.status_id" icon />
          </v-card-title>
          <v-card-text>
            <div>
              <p v-if="billing.status_id === 0">
                Sobald alle Zeiten erfasst sind, bitte die Abrechnung einreichen
              </p>
              <p v-if="billing.status_id === 10">
                Die Abrechnung muss nun geprüft und freigegeben werden
              </p>
              <p v-if="billing.status_id === 20">
                Die Abrechnung ist geprüft und sollte nun abgeschlossen werden
              </p>
              <p v-if="billing.status_id === 30">
                Die Abrechnung ist abgeschlossen
              </p>
            </div>
          </v-card-text>
          <v-card-actions>
            <Status-switch
              v-model="billing.status_id"
              :item="billing"
              type="timebilling"
              :on-success="setTimebilling"
            />
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="mb-2">
        <v-card outlined class="ma-2" height="100%">
          <v-card-title>
            Aktionen
            <v-spacer />
            <div class="pull-right mx-2">
              <v-btn
                icon
                title="aktualisieren"
                color="grey lighten-1"
                :loading="isLoading"
                small
                @click="loadData()"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-list one-line dense class="mt-n4">
            <v-list-item
              :disabled="billing.status_id !== 0 || !billing.ptm.active"
              @click="newTime"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-plus
                  {{
                    billing.status_id !== 0 || !billing.ptm.active
                      ? ' mdi-light mdi-inactive'
                      : ''
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Zeiteintrag hinzufügen</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="!billing.comment_count" @click="openComment()">
              <v-list-item-icon
                ><v-icon>mdi-message-reply-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>kommentieren</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item v-if="$me.can('time.export.pdf')" @click="downloadPDF">
              <v-list-item-icon
                ><v-icon>mdi-file-pdf-box</v-icon></v-list-item-icon
              >
              <v-list-item-title>als PDF exportieren</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="$me.can('time.export.django') && !isMobile"
              :disabled="billing.status_id !== 30"
              @click="downloadDjango"
            >
              <v-list-item-icon
                ><v-icon
                  >mdi-microsoft-excel
                  {{
                    billing.status_id !== 30 ? ' mdi-light mdi-inactive' : ''
                  }}</v-icon
                ></v-list-item-icon
              >
              <v-list-item-title>für Django exportieren</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="$me.can('time.export.csv') && !isMobile"
              @click="downloadCSV"
            >
              <v-list-item-icon
                ><v-icon>mdi-file-excel</v-icon></v-list-item-icon
              >
              <v-list-item-title>als CSV exportieren</v-list-item-title>
            </v-list-item>

            <v-divider />

            <TimeBullingTimeBulkStatusChangeModal
              v-if="billing.id"
              :times="times"
              :timebilling-id="billing.id"
              :statusPermissionLevels="billing.status_permission_level"
              :on-completed="setTimebilling"
            />
            <v-divider />
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-card outlined class="ma-2">
      <times-data-table
        :items="times"
        :headers="headers"
        :items-per-page="-1"
      />
    </v-card>
  </v-container>
</template>

<script>
import BcTimeStatusChip from '@/views/time/TimeStatusChip.vue';
import BcTimeRecording from '@/views/time/TimeRecording.vue';
import BcTimeBillingStatusChip from '@/views/timebilling/TimeBillingStatusChip.vue';
import TimeBullingTimeBulkStatusChangeModal from './TimeBillingTimeBulkStatusChangeModal.vue';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import BcCommentBadge from '@/components/global/comments/BcCommentBadge.vue';
import BcPtmItem from '@/components/global/BcPtmItem.vue';
import store from '@/store';
import TimesDataTable from '../time/TimesDataTable.vue';
import { createTimesHeaderForTimebilling } from '@/views/time/timeHeaders';

export default {
  name: 'TimeBillingDetail',

  components: {
    BcPtmItem,
    BcCommentBadge,
    BcResponseHandler,
    BcTimeStatusChip,
    BcTimeRecording,
    BcTimeBillingStatusChip,
    TimeBullingTimeBulkStatusChangeModal,
    TimesDataTable,
  },

  props: {
    billingID: null,
  },

  data() {
    return {
      sortBy: 'start',
      sortDesc: false,

      pauses: null,

      axiosSuccess: null,
      axiosError: null,

      level: 1,

      activeRow: '',

      bulkStatusDialogValue: 0,
    };
  },

  computed: {
    billing() {
      return store.getters.timebillingStore.getTimeBilling(
        parseInt(this.$route.params.billingID)
      );
    },

    times() {
      return store.getters.timesStore.getTimesOfTimebilling(
        { ...this.billing }.id
      );
    },

    isLoading() {
      return store.state.timebillingStore.isLoading;
    },
    headers() {
      return createTimesHeaderForTimebilling(this.$vuetify.breakpoint);
    },

    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    /*
    myLevels() {
      return this.billing?.status_permission_level;
    },
    canCheck() {
      return this.myLevels?.includes(2);
    },
    canComplete() {
      return this.myLevels?.includes(3);
    },
    // returns object with two attributes (10, 20), each containing an array with all corresponding time entries
    timesStatus() {
      return this.billing?.times
        ? {
            0: this.billing.times.filter((time) => time.status_id === 0),
            10: this.billing.times.filter((time) => time.status_id === 10),
            20: this.billing.times.filter((time) => time.status_id === 20),
          }
        : { 0: [], 10: [], 20: [] };
    },
 */
    ptmBillings() {
      if (!store.state.timebillingStore.timebillings || !this.billing?.ptm)
        return [];
      return store.state.timebillingStore.timebillings.filter(
        (tb) => tb.ptm.id === this.billing?.ptm.id
      );
    },
    prevMonthBilling() {
      if (!this.ptmBillings.length) return null;
      return this.ptmBillings
        .filter((tb) => tb.startdate < this.billing.startdate)
        .sort(this.$boo.sortBy('startdate'))
        .reverse()[0];
    },
    nextMonthBilling() {
      if (!this.ptmBillings.length) return null;
      return this.ptmBillings
        .filter((tb) => tb.startdate > this.billing.startdate)
        .sort(this.$boo.sortBy('startdate'))[0];
    },
  },

  watch: {
    billingID() {
      this.loadData();
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    setTimebilling(timebilling) {
      store.commit.timebillingStore.setTimebilling(timebilling);
    },

    gotoBilling(id) {
      this.$router.push({
        name: 'timebillingdetail',
        params: { billingID: +id },
      });
    },

    newTime() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          new: true,
          ptms: [this.billing.ptm],
          billing: this.billing,
        },
      });
    },

    loadData() {
      store.dispatch.timebillingStore.fetchSingleTimebilling(this.billingID);
      store.dispatch.data.getPTMs();
    },

    downloadPDF() {
      // https://medium.com/@kennethteh90/open-download-pdf-in-vue-from-rails-api-cdd1402eea77
      window.axios
        .request({
          url: this.$boo.lboUrl() + `timebillings/${this.billing.id}/pdf`,
          method: 'GET',
          responseType: 'blob', // @@@ LuJ: vermaselt das das errorReporting??
          // params: {}
        })
        .then((response) => {
          this.axiosSuccess = response; // weiß nicht, ob überhaupt sinnvoll
          return response.data; // erforderlich!
        })
        .then((blob) => {
          const data = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = data;
          if (this.$boo.envIsDev() && false) {
            // für DEV-Tests
            // show in browser
            link.target = '_blank';
          } else {
            // for download
            link.download =
              this.$dayjs(this.billing.startdate).format('YYYY-MM') +
              '_' +
              this.billing.ptm.project.slug +
              '_' +
              this.billing.ptm.person.slug +
              '_' +
              this.billing.ptm.id +
              '.pdf';
          }
          link.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch((error) => {
          this.axiosError = error;
        });
      // .finally(() => { this.loading = false })
    },
    downloadCSV() {
      window.axios
        .request({
          url: this.$boo.lboUrl() + 'times/csv',
          method: 'GET',
          responseType: 'blob',
          // params: {}
        })
        .then((response) => {
          this.axiosSuccess = response; // weiß nicht, ob überhaupt sinnvoll
          return response.data; // erforderlich!
        })
        .then((blob) => {
          const data = URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = data;
          link.target = '_blank'; // show in browser
          // link.download = 'times.pdf' // for download
          link.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch((error) => {
          this.axiosError = error;
        });
      // .finally(() => { this.loading = false })    }
      // isMobile () {
      //   return this.$vuetify.breakpoint.name === 'xs'
      // }
    },
    downloadDjango() {
      window.axios
        .request({
          url: this.$boo.lboUrl() + `timebillings/${this.billing.id}/django`,
          method: 'GET',
          responseType: 'blob',
          // params: {}
        })
        .then((response) => {
          this.axiosSuccess = response; // weiß nicht, ob überhaupt sinnvoll
          return response.data; // erforderlich!
        })
        .then((blob) => {
          const data = URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = data;

          link.download = `${this.$dayjs(this.billing.startdate).format(
            'YYYY-MM'
          )}_${this.billing.ptm.person.slug}_${this.billing.ptm.project.slug}_${
            this.billing.ptm.id
          }.xlsx`; // for download
          link.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch((error) => {
          this.axiosError = error;
        });
    },
    openComment() {
      this.$store.commit(this.$storenames.contentDrawer.commit.load, {
        component: 'bc-cd-comment',
        data: {
          commentable: this.billing,
          // gotoComment: true
        },
      });
    },
  },
};
</script>
