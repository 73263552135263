<template>
  <v-alert
    v-if="infoType !== ''"
    dense
    text
    :type="infoType"
    transition="expand-y-transition"
    :value="showAlert"
  >
    <div class="d-flex justify-space-between">
      <span>{{ alertText }}</span>
      <v-spacer />
      <v-btn
        icon
        outlined
        small
        depressed
        color="info"
        @click="showAlert = false"
      >
        <v-icon small color="info" dark> mdi-close </v-icon>
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'BcEcho',

  props: {
    private: {
      type: Boolean,
      default: false,
    },
    config: {
      type: [String, Object], // "boo|.boo.updated" or { channel: 'boo', listen: '.boo.updated' }
      default: null,
    },
    // @@@ wieso sollte man das nicht  wollen?
    reactWithEventdata: {
      type: Boolean,
      default: true,
    },
    infoType: {
      // zeige Änderung an
      type: String, // 'info', 'warning', 'error'
      default: '',
    },

    // @@@ braucht man das jemals (manuelles Verlassen vor destruction)?
    // leave: {
    //   type: Function,
    //   default: Date.now // now-change triggers leave ;-)
    // }
  },

  data() {
    return {
      // default
      channel: 'boo',
      // listen: '.boo.updated'
      listen: '.updated', // @@@ LuJ: wozu ist eigentlich dieser führende Punkt??

      showAlert: false,
      alertText: 'Die Daten wurden im Hintergrund geändert',
    };
  },

  watch: {
    // @@@ braucht man das jemals (manuelles Verlassen vor destruction)?
    // leave: function (newVal, oldVal) {
    //   // console.log('echo > watch leave')
    //   this.leaveChannel()
    // }
  },

  created() {
    // console.log('BcEcho > created()')
    this.readConfig(); // @@@ ev. beide func zusammenfassen
    if (this.private) {
      this.listenPrivate();
    } else {
      this.listenChannel();
    }
  },

  destroyed() {
    // console.log('BcEcho destroyed')
    this.leaveChannel();
  },

  methods: {
    readConfig: function () {
      // console.log('readConfig()')
      // console.log(typeof this.config)
      // console.log(this.config)

      if (this.config === null) {
        // console.log("ist's null > default...")
        return;
      }

      if (typeof this.config === 'string') {
        // console.log("ist's a String!")
        const parts = this.config.split('|');
        // console.log(parts)
        this.channel = parts[0];
        this.listen = parts[1];
        // return
      }

      if (typeof this.config === 'object') {
        // console.log("ist's an Object!!")
        this.channel = this.config.channel;
        this.listen = this.config.listen;
        // return
      }
    },
    listenChannel: function () {
      // console.log('echo > listenChannel')
      window.Echo.channel(this.channel).listen(this.listen, (e) => {
        // console.log(e)
        if (this.reactWithEventdata) {
          this.$emit('react', e);
          // @@@ wieso nicht immer so
        } else {
          this.$emit('react');
        }
        this.showAlert = true;
      });
    },
    listenPrivate: function () {
      // console.log('echo > listenPrivate')
      // window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + this.$route.query.token
      // console.log('+++++')
      // console.log(window.Echo.connector.pusher.config.auth.headers)

      // const cookie="jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZGZmODk3Y2RkMjg5NmI2NzEzMzdlMjVlN2E4MTQ2OWFiMjEzMDQzZDdlMDk4ODIxNmY3MGYwOTA1NjBjYmI3NjllNTdjZWQxYzFlMDUxZTYiLCJpYXQiOjE2MDU4MTk2ODcsIm5iZiI6MTYwNTgxOTY4NywiZXhwIjoxNjM3MzU1Njg3LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.kupLHUwgu20-U0IgsnkTJfj7MZ-MTYOl0OL7Vi-vUtiF8CS34Qpocjog9n675PH-c6HSetS6emp_pQ6OIOH6NQ8UnkK00Ve6ZpLOVvsXQ0gwFTQYWQbx_5J8vKK4VJ3qU5InXAYBi_Ge7yrrbxAAgmfMYLsVqcYQbHq_4gyT6QIIt0LTu3pMf2ZVgL-h4lKVBrdSmkljdX43PJ3_c40hkCRUaTzyDJBClQWImVSFeQauh3xTIILfrCfIUyvBecv6DSX2mxEsOPvzcRBBEzSspsXQKi_dy3P9ZhDfOnX547ygYZh3Ka9_X6VXze1EpVKoP571GPCq_A9ef7dY0_TJlQt1_eVPejxdLgzE3XEXgEaOaEITZO3V3roGZJO_MAsOWj7WKw7yxFIjCxbW7VKgnF_rZcdOnvPdjIosX-xth8Ir9IXhdV5IV_xqYbKldot9e1IYRL_hCHp6f04ce3K8qcDdhALileRB9lBe3LkuFYy5pnamYkAsKmf1J1urgX0haLy2F9XL5HekngIWMNRic8G3KlKS9aMfyO9RrIrD3uR3nTi3Iy6kFMqPzRrZdqKp2Uuicto36gy5yzlY8eFuzvLZFLBdVGCKc13araPpxwktNX5wK3Dn9djklHRAWv5EVInDoVr9oDvRthhK9_nHzuMYwo5SLwofZ4TQnoXbAkk"
      // window.Echo.connector.pusher.config.auth.headers.Authorization = cookie

      window.Echo.private(this.channel).listen(this.listen, (e) => {
        this.$emit('react', e);
        this.showAlert = true;
      });
    },
    leaveChannel: function () {
      // console.log('echo > leave')
      window.Echo.leaveChannel(this.channel);
      // window.Echo.leave(this.channel) // @@@ oder gleich immer komplett?
    },
  },
};
</script>
