<template>
  <v-card
    outlined
    hover
    class="ma-1"
    min-height="220px"
    :min-width="isMobile ? '98%' : '313px'"
    max-width="313px"
    @click="$emit('click')"
  >
    <v-app-bar dense flat>
      <v-spacer />
      <router-link
        class="text-decoration-none"
        link
        :to="{
          name: 'projectView',
          params: { projectSlug: project.slug },
          query: { tab: 'members' },
        }"
      >
        <span v-if="project.project_memberships_count > 0" class="mr-2">
          <v-icon title="Projektmitgliedschaften">{{
            project.project_memberships_count > 1
              ? 'mdi-account-multiple'
              : 'mdi-account'
          }}</v-icon>
          {{ project.project_memberships_count }}
        </span>
      </router-link>
      <Bc-Project-Status-Chip
        v-model="project.status_id"
        :items="statusOptions"
        :project="project"
        small
      />
    </v-app-bar>
    <v-list-item>
      <v-list-item-avatar color="" size="60">
        <bc-avatar :pojo="project" :size="50" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="">
          {{ project.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      {{ comment_max }}
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <TagIconChip v-for="(tag, key) in project.tags" :tag="tag" :key="key" />
    </v-card-actions>
  </v-card>
</template>

<script>
import TagIconChip from '@/components/tags/TagIconChip.vue';

export default {
  components: { TagIconChip },

  props: {
    project: {
      type: Object,
      default: () => {
        return {
          name: 'Titel',
          comment: 'Beschreibung',
        };
      },
    },
    edit: Boolean,
    clickable: Boolean,
    statusOptions: { type: Array, default: null },
  },

  data() {
    return {
      showEditIcon: false,
      boo_logo: require('../../../public/img/boo-icon.png'), // @@@ echt, so adressiert man runter?
    };
  },

  computed: {
    comment_max() {
      var maxLength = 100;
      if (!this.project.description) return '';
      if (this.project.description.length > maxLength)
        return this.project.description.substr(0, maxLength - 3) + '...';
      return this.project.description;
    },
    status() {
      return this.statusOptions[this.project.status_id].name;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  mounted() {
    console.log(this.project);
  },

  methods: {
    findStatus(status, id) {
      return status.id === id;
    },
  },
};
</script>
