<template>
  <div>
    <v-tooltip
      :disabled="!$boo.statusInfo('time', value).status.label"
      left
      open-delay="500"
      :color="$boo.statusInfo('time', value).status.color"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-chip
            :color="$boo.statusInfo('time', value).status.color"
            small
            pill
            outlined
          >
            <v-icon small>
              {{ $boo.statusInfo('time', value).status.icon }}
            </v-icon>
          </v-chip>
        </div>
      </template>
      <span>{{ $boo.statusInfo('time', value).status.label }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'BcTimeStatusChip',

  props: {
    value: {
      type: Number,
      default: null,
      validator: function (value) {
        return [null, 0, 10, 20, 30].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {},
};
</script>
