export default {
  // generell
  actions: {
    reset: 'reset',
  },

  auth: {
    // commit: {
    //   SETUSER: 'auth/SETUSER'
    // },
    actions: {
      reset: 'auth/reset',
      request: 'auth/request',
    },
    getters: {
      userCan: 'auth/userCan',
    },
  },

  data: {
    actions: {
      refresh: 'data/refresh',
    },
    getters: {
      times: 'data/times',
      recentXPersons: 'data/recentXPersons',
      timeablePTMs: 'data/timeablePTMs',
    },
  },

  contentDrawer: {
    commit: {
      load: 'contentDrawer/LOAD',
    },
    actions: {
      close: 'contentDrawer/close',
    },
  },
};
