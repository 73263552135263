<template>
  <div>
    <v-row>
      <v-col> </v-col>
      <v-col class="d-flex justify-end">
        <v-switch
          v-if="hasInactivePtms"
          v-model="showInactive"
          class=""
          dense
          label="inaktive anzeigen"
        />
      </v-col>
    </v-row>
    <v-data-table
      :items="filteredMember"
      class="elevation-0"
      :loading="loading"
      hide-default-header
      hide-default-footer
      no-data-text="Es wurden noch keine Projektmitglieder hinzugefügt."
      loading-text="Lade Projektmitglieder..."
      :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <v-list-group>
          <template v-slot:activator>
            <!--
            <bc-ptm-item
              v-if="true"
              :ptm="item.project_memberships[0]"
              viewpoint="project"
            />
             -->
            <v-list-item-avatar>
              <bc-avatar :pojo="item" :size="40" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-container>
                <v-row no-gutters>
                  <v-col>
                    <v-list-item-title v-text="item.label" />
                    <v-list-item-subtitle
                      v-text="
                        item.project_memberships
                          ? item.project_memberships[0].role.name
                          : '[role.name]'
                      "
                    />
                  </v-col>
                  <v-col>
                    <span v-if="item.project_memberships.length === 1">
                      <span v-if="item.project_memberships[0].active">
                        <span
                          v-if="
                            item.project_memberships[0].start &&
                            item.project_memberships[0].end
                          "
                        >
                          {{ formatDate(item.project_memberships[0].start) }} -
                          {{ formatDate(item.project_memberships[0].end) }}
                        </span>
                      </span>
                      <span v-else> Inaktiv </span>
                    </span>
                    <span v-if="item.project_memberships.length > 1">
                      {{ item.project_memberships.length }}
                      <v-icon
                        :title="
                          item.project_memberships.length + ' Mitgliedschaften'
                        "
                        small
                        >mdi-account-multiple</v-icon
                      >
                    </span>
                    <v-icon
                      class="pl-2"
                      v-if="
                        item.project_memberships.filter(
                          (ptm) => ptm.timerecmodel_id
                        ).length
                      "
                      title="Zeiterfassung"
                      small
                      >mdi-clock-outline</v-icon
                    >
                    <v-icon
                      class="pl-2"
                      :title="
                        item.project_memberships.every((ptm) => ptm.active)
                          ? 'aktiv'
                          : item.project_memberships.every((ptm) => !ptm.active)
                          ? 'inaktiv'
                          : '(in)aktive Mitgliedschaft(en)'
                      "
                      small
                      :color="
                        item.project_memberships.every((ptm) => ptm.active)
                          ? 'green'
                          : item.project_memberships.every((ptm) => !ptm.active)
                          ? 'red'
                          : ''
                      "
                      >{{
                        'mdi-checkbox' +
                        (item.project_memberships.length > 1
                          ? '-multiple'
                          : '') +
                        (item.project_memberships.every((ptm) => !ptm.active)
                          ? '-blank-outline'
                          : '-marked')
                      }}</v-icon
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item-content>
          </template>
          <v-card class="mb-5" outlined flat inset>
            <v-data-table
              :headers="membershipHeaders"
              :items="item.project_memberships"
              hide-default-footer
              dense
              :hide-default-header="isMobile"
              mobile-breakpoint="200"
              :item-class="itemRowBackground"
              @click:row="editMember"
            >
              <template v-slot:item.role="{ item }">
                {{
                  $boo.getValueFromList(roleOptions, 'id', item.role.id, 'name')
                }}
              </template>

              <template v-slot:item.label="{ item }">
                {{ item.memberlabel || '-' }}
              </template>

              <template v-slot:item.start="{ item }">
                <span
                  :class="`${
                    $dayjs() < $dayjs(item.start) && item.active
                      ? 'red--text'
                      : ''
                  }`"
                >
                  {{ formatDate(item.start) }} <br />
                  {{ formatTime(item.start) }}
                </span>
              </template>

              <template v-slot:item.end="{ item }">
                <span
                  :class="`${
                    $dayjs() > $dayjs(item.end) && item.active
                      ? 'red--text'
                      : ''
                  }`"
                >
                  {{ formatDate(item.end) }} <br />
                  {{ formatTime(item.end) }}
                </span>
              </template>

              <template v-slot:item.timerecmodel="{ item }">
                {{
                  $boo.getValueFromList(
                    timerecOptions,
                    'id',
                    item.timerecmodel_id,
                    'name'
                  )
                }}
              </template>

              <template v-slot:item.auditor="{ item }">
                <span v-if="item.auditor_id">
                  {{
                    $boo.getValueFromList(
                      project.project_memberships,
                      'id',
                      item.auditor_id,
                      'person'
                    ).label || '-'
                  }}
                </span>
              </template>

              <template v-slot:item.active="{ item }">
                <v-checkbox
                  v-model="item.active"
                  readonly
                  :color="item.active ? 'green' : 'red'"
                />
              </template>

              <template v-slot:item.action="{ item }">
                <v-icon
                  v-if="canEdit && false"
                  small
                  class="mr-2"
                  title="bearbeiten"
                  @click="editMember(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
            <v-card-actions>
              <v-btn
                text
                small
                :to="
                  $boo.domainRouteTo('personView', { personSlug: item.slug })
                "
              >
                Zum Kontakt
              </v-btn>
              <v-spacer />
              <v-btn v-if="canEdit" text small @click="addMember(item)">
                <v-icon x-small class="mr-2"> mdi-account-plus </v-icon>
                Mitgliedschaft hinzufügen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-group>
      </template>
    </v-data-table>

    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />
  </div>
</template>

<script lang="ts">
import {
  PersonType,
  ProjectMemberShip,
  ProjectStatusType,
  ProjectType,
  PTMRoleType,
  TimeRecModelType,
} from '@/types/types';
import { defineComponent, PropType } from 'vue';

//@ts-ignore
import { preference } from 'vue-preferences';

export default defineComponent({
  name: 'ProjectMemberTable',

  props: {
    member: { type: Array as PropType<PersonType[]>, default: null },
    loading: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    roleOptions: { type: Array as PropType<PTMRoleType[]>, default: null },
    timerecOptions: {
      type: Array as PropType<
        Pick<TimeRecModelType, 'id' | 'name' | 'description'>[]
      >,
      default: null,
    },
    project: { type: Object as PropType<ProjectType>, default: null },
  },

  data: () => ({
    membershipHeaders: [
      { text: 'Rolle', value: 'role', sortable: false },
      { text: 'Bezeichnung', value: 'label', sortable: false },
      { text: 'von', value: 'start' },
      { text: 'bis', value: 'end' },
      { text: 'ZE-Modell', value: 'timerecmodel', sortable: false },
      { text: 'Prüfer', value: 'auditor', sortable: false },
      { text: 'aktiv', value: 'active' },
      { text: '', value: 'action', sortable: false },
    ],

    axiosError: null,
    axiosSuccess: null,
  }),

  computed: {
    showInactive: preference('project.showInactivePTMs', {
      defaultValue: true,
    }),

    hasInactivePtms(): boolean {
      return (
        this.project?.project_memberships?.filter((ptm) => !ptm.active).length >
        0
      );
    },

    filteredMember(): PersonType[] {
      if (this.showInactive || !this.hasInactivePtms) return this.member;

      return this.member.reduce(function (result: PersonType[], member) {
        let filteredMember = { ...member };
        filteredMember.project_memberships = member.project_memberships.filter(
          (ptm) => ptm.active
        );
        if (filteredMember.project_memberships.length > 0)
          result.push(filteredMember);
        return result;
      }, []);
    },

    projectSlug(): string {
      return this.$route.params.projectSlug;
    },
    isMobile(): boolean {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      );
    },
  },

  methods: {
    editMember(item: ProjectMemberShip) {
      if (!this.canEdit) return;
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-project-add-member',
        data: {
          project: this.project,
          member: item,
          roleOptions: this.roleOptions,
          timerecOptions: this.timerecOptions,
        },
      });
    },
    addMember(person: PersonType) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-project-add-member',
        data: {
          project: this.project,
          person,
          roleOptions: this.roleOptions,
          timerecOptions: this.timerecOptions,
        },
      });
    },
    formatDate(dateTimeISOString: Date) {
      const dt = this.$dayjs(dateTimeISOString);
      return dt.isValid() ? dt.utc(true).format('DD.MM.YYYY') : '-';
    },
    formatTime(dateTimeISOString: Date) {
      const dt = this.$dayjs(dateTimeISOString);
      return dt.isValid() ? dt.utc(true).format('HH:mm') : '-';
    },
    formatDateTime(dateTimeISOString: Date) {
      const dt = this.$dayjs(dateTimeISOString);
      return dt.isValid() ? dt.utc(true).format('DD.MM.YYYY HH:mm') : '';
    },
    itemRowBackground(item: ProjectMemberShip) {
      if (!item.active) return 'grey--text';
      return '';
    },
  },
});
</script>
