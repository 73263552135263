import store from './store';

import * as $BC from './constants';
import dayjs, { Dayjs } from 'dayjs';
import me from './me';
import { DomainType, ID, SexType, UserType } from './types/types';

export default {
  // lboUrl: () => $BC.LBO_Url + '/api/' + store.state.app.domain.slug + '/'
  lboUrl: function () {
    if (store.state.app.domain?.slug) {
      return $BC.LBO_API_URL + store.state.app.domain.slug + '/';
    } else {
      return $BC.LBO_API_URL + 'default/'; // manchmal, beim reload, ist das undefined :-/
    }
  },

  // refreshing Api-Data
  reloadApiData() {
    // console.log('updateApiData')
    window.axios.get($BC.LBO_API_URL + 'apiData').then((response: any) => {
      this.storeApiData(response.data);
    });
  },

  // storing apiData in Vuex
  storeApiData: function (apiData: any) {
    // console.log('storeApiData')
    if (!apiData) return;

    store.commit.auth.SETUSER(apiData.user);
    store.commit.auth.SETDOMAINS(apiData.domains);
    store.commit.auth.SETPERMISSIONS(apiData.permissions);
    store.commit.auth.SETSETTINGS(apiData.settings);
    store.commit.auth.SETATTRIBUTES(apiData.attributes);
    store.commit.app.SETEXPECTEDVERSION(apiData.version?.boo2_version);
    store.commit.app.SETBOOBEVERSION(apiData.version?.lbo_version);
  },

  /**
   * Make the first letter of a string uppercase
   * @param  string
   * @return String
   */
  capitalizeFirstLetter: (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1),

  /**
   * Checks if user is logged in
   * @return Boolean
   */
  userLoggedIn: () => store.state.auth.user !== null,

  userIsAdmin: function (user?: UserType) {
    if (user) {
      return user.slug === 'sysadmin';
    } else {
      return store.state.auth.user?.slug === 'sysadmin';
    }
  },

  userAmI: (id: ID) => store.state.auth.user?.id === id,

  envIsDev: () => $BC.ENV === 'development',
  envIsTest: () => $BC.ENV === 'test' || $BC.ENV === 'testenv', // 'test' failt beim build (?)
  envIsStaging: () => $BC.ENV === 'staging',
  envIsProd: () => $BC.ENV === 'production',

  objIsEmpty: function (obj: Object) {
    // if (typeof obj !== 'object') return true
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
  },

  isObject: function (val: Object) {
    if (val === null) return false;
    return typeof val === 'function' || typeof val === 'object';
  },

  objCount: function (obj: Object) {
    return Object.keys(obj).length;
  },

  /**
   * Sets domain (vuex)
   * stores setting in vuex and BOObE
   * @param domain
   */
  setDomain: function (domain: DomainType) {
    // domain umschalten
    const domainChanged = domain !== store.state.app.domain;

    store.commit.app.SETDOMAIN(domain);
    // Browser Titel
    document.title = domain.name + ' - ' + domain.description;
    // domain-Wahl merken
    store.commit.auth.SETSETTING({ item: 'domain', value: domain.slug });

    if (
      domainChanged &&
      store.state.auth.domains &&
      this.objCount(store.state.auth.domains) > 1
    ) {
      store.commit.data.RESET();
      store.commit.timesStore.RESET();
      store.commit.timebillingStore.RESET();

      // info in snackbar
      store.commit.app.SETSNACKBAR({
        text: `Sphäre "${domain.name}" aktiviert`,
        color: 'info',
        timeout: 4444,
      });
    }
  },

  genderdata(sex: SexType) {
    switch (sex) {
      case 'm':
        return { text: 'männlich', Anrede: 'Herr', color: 'blue darken-2' };
      case 'w':
        return { text: 'weiblich', Anrede: 'Frau', color: 'pink darken-2' };
      case 'd':
        return { text: 'divers', Anrede: '*', color: 'blue-grey darken-2' };
      default:
        return { text: 'unbekannt', Anrede: '', color: 'blue-grey lighten-2' };
    }
  },

  domainTypeData(domainType: DomainType['type']) {
    switch (domainType) {
      case 0:
        return {
          text: 'Systemsphäre',
          color: '',
          icon: 'mdi-alpha-s-circle-outline',
        };
      case 1:
        return {
          text: 'Standardsphäre',
          color: '',
          icon: 'mdi-checkbox-multiple-blank-circle-outline',
        };
      case 2:
        return {
          text: 'private Sphäre',
          color: '',
          icon: 'mdi-account-circle-outline',
        };
      default:
        return {
          text: 'unbekannter Sphärentyp',
          color: '',
          icon: 'mdi-alert-circle-outline',
        };
    }
  },

  visibilityInfo(id: any, comment: any, commentable: any) {
    let label = 'Ersteller';
    switch (id) {
      case 0:
        return {
          label: 'nur ich persönlich',
          icon: 'mdi-account',
        };
      case 1:
        if (me.amI(commentable.owner)) {
          label = 'ich (als Ersteller)';
        } else {
          label = 'ich und ' + commentable.owner.label;
        }
        if (comment && !me.amI(comment.owner)) {
          label = comment.owner.label + ' und ' + label;
        }
        return {
          label: label,
          icon: 'mdi-account-multiple',
        };
      case 2:
        return {
          label: 'jeder',
          icon: 'mdi-earth',
        };
      default:
        return {
          label: 'Visibility Label',
          icon: 'mdi-earth',
        };
    }
  },

  statusInfo(type: any, id: any, isOwner = false) {
    if (type === 'time') {
      switch (id) {
        case 0:
          return {
            prev: {
              label: 'Zurück',
              icon: '',
            },
            status: {
              label: 'Offen',
              icon: 'mdi-progress-question',
              color: 'grey',
            },
            next: {
              label: 'Erfassen',
              icon: 'mdi-check',
            },
          };
        case 10:
          return {
            prev: {
              label: isOwner ? 'Erfassung zurücknehmen' : 'Ablehnen',
              icon: '',
            },
            status: {
              label: 'Erfasst',
              icon: 'mdi-clock-check-outline',
              color: 'boo',
            },
            next: {
              label: 'Bestätigen',
              icon: 'mdi-check-all',
            },
          };
        case 20:
          return {
            prev: {
              label: isOwner ? 'Bestätigung zurücknehmen' : 'Ablehnen',
              icon: '',
            },
            status: {
              label: 'Bestätigt',
              icon: 'mdi-check',
              color: 'green',
            },
            next: {
              label: '',
              icon: 'mdi-lock-open',
            },
          };
        case 30:
          return {
            status: {
              label: 'gesperrt',
              icon: 'mdi-lock-check-outline',
              color: 'green',
            },
          };
        default:
          return {
            status: {
              label: '',
            },
          };
      }
    } else if (type === 'timebilling') {
      switch (id) {
        case 0:
          return {
            prev: {
              label: 'Zurück',
              icon: '',
            },
            status: {
              label: 'In Arbeit',
              icon: 'mdi-playlist-edit',
              color: 'grey',
            },
            next: {
              label: 'Einreichen',
              icon: 'mdi-check',
            },
          };
        case 10:
          return {
            prev: {
              label: isOwner ? 'Einreichung zurücknehmen' : 'Ablehnen',
              icon: '',
            },
            status: {
              label: 'Eingereicht',
              icon: 'mdi-playlist-check',
              color: 'boo',
            },
            next: {
              label: 'Freigeben',
              icon: 'mdi-check-all',
            },
          };
        case 20:
          return {
            prev: {
              label: isOwner ? 'Freigabe zurücknehmen' : 'Ablehnen',
              icon: '',
            },
            status: {
              label: 'Geprüft',
              icon: 'mdi-check-all',
              color: 'green',
            },
            next: {
              label: 'Abschließen',
              icon: 'mdi-lock-open',
            },
          };
        case 30:
          return {
            status: {
              label: 'Abgeschlossen',
              icon: 'mdi-lock-check-outline',
              color: 'green',
            },
          };
        default:
          return {
            status: {
              label: '',
            },
          };
      }
    }
  },

  /**
   * durchsucht Array mit Objekten
   * z.B. cars[
   *   0: {
   *     type: 'Opel',
   *     speed: 'slow' },
   *   1: {
   *     type: 'Alfa',
   *     speed: 'fast' }
   *  ]
   * @param list          zu durchsuchende Collection z.B. cars
   * @param searchKey     geprüfter Item-Key z.B. 'type'
   * @param searchValue   Suchbegriff z.B. 'Alfa'
   * @param returnValue   gewünschter Key z.B. 'speed'
   * @return mixed        z.B. 'fast'
   */
  getValueFromList(
    list: Array<any>,
    searchKey: string,
    searchValue: string,
    returnValue: string
  ) {
    if (!list) return null;
    // if (!searchValue) return null // kann schon 0 sein!
    if (searchValue === null || searchValue === '') return null; // darf aber 0 sein
    if (this.objIsEmpty(list)) return null;
    return list.find((item: any) => item[searchKey] === searchValue)[
      returnValue
    ];
  },

  /**
   * link-to-object zum routen, angereichert um work domain
   * @param  name       name einer route
   * @param  params     optionale url-Parameter
   * @param query
   * @return Object     zum routen
   */
  domainRouteTo(name: string, params = {} as any, query = {}) {
    // @@@ wird bei Start igendwodurch mit 'times' ohne domain aufgerufen > console.warning
    // return { name: name, params: { domain: store.state.app.domain?.slug } }
    // params.domain = store.state.app.domain?.slug // Optional Chaining requires #391
    params.domain = store.state.app.domain ? store.state.app.domain.slug : null;
    return { name: name, params: params, query: query };
  },

  /**
   *  deprecated - use objIsEmpty() instead
   *  (didn't pass snazzy check)
   */
  // Nimmt ein Object an und überprüft ob es leer ist. Wird für isNew Überprüfungen genutzt.
  // isEmpty (object) {
  //   for (var i in object) return false
  //   return true
  // },

  /**
   * retourniert immer einen String
   * @param  str zu prüfender String
   * @return String den geputzten oder einen leeren String
   */
  trimString(str: string) {
    if (!str) return '';
    return str.trim();
  },

  /**
   * retourniert immer eine Zahl
   * @param num zu prüfende Variable
   * @return Number   die geputzte Zahl oder 0
   */
  trimNumber(num: number) {
    if (!num) return 0;
    try {
      return num * 1;
    } catch {
      return 0;
    }
  },

  /**
   * compareFunction for ascending array.sort()
   * usage: array.sort(this.$boo.sortBy('[key]'))
   * @param key key to sort by
   * @return {function}         callback für array.sort()
   */
  sortBy(key: string) {
    return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  },

  /**
   * compareFunction for descending array.sort()
   * usage: array.sort(this.$boo.sortByDesc('[key]'))
   * @param key key to sort by
   * @return {function}         callback für array.sort()
   */
  sortByDesc(key: string) {
    return (a: any, b: any) =>
      (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0) * -1;
  },

  /**
   * compareFunction (w.o.) aber unter Einbeziehung der Browser-lokalen Sprache (Sonderzeichen)
   * usage: array.sort(this.$boo.sortLocaleBy('[key]'))
   * caution: rather slow
   * @param {string} key key to sort by
   * @return {function} callback für array.sort()
   */
  sortLocaleBy(key: string) {
    return (a: any, b: any) => a[key].localeCompare(b[key]);
  },

  /**
   * converts $dayjs or string to ISO-string
   * @param  {String|dayjs} dt
   * @return {String}    19-stelliger timestamp
   */
  datetimeToISO(dt: any) {
    if (typeof dt === 'string') {
      // ...ss.SSSZ vs. ...ss.SSSSSSZ
      return dt.substring(0, 19);
    }
    if (typeof dt === 'object') {
      // $dayjs >> string
      return dt.toISOString().substring(0, 19);
    }
    console.log('datetimeToISO failed');
    console.log(dt);
    console.log(typeof dt);
    return false;
  },

  /**
   * liefert ausgeschriebene Zahlen
   * @param  {int} num
   * @param  {string} type 'all'
   * @return {text}
   */
  spellnumber(num: any, type?: any) {
    const text = [
      'eine(n)',
      'zwei',
      'drei',
      'vier',
      'fünf',
      'sechs',
      'sieben',
      'acht',
      'neun',
      'zehn',
      'elf',
      'zwölf',
    ];

    if (type === 'all') {
      if (num === 2) return 'beide ';
      if (num > 1 && num < 13) {
        return 'alle ' + text[num - 1];
      }
      return 'alle ' + num;
    } else {
      if (num > 1 && num < 13) {
        return text[num - 1];
      }
      return num;
    }
  },

  /**
   * retourniert unique Array of Objects
   * @param  {array} arrOfObj ein großes Array aus Objekten
   * @return {array}          Array ohne Duplikate
   */
  uniqueObjects(arrOfObj: Array<Object>) {
    // step by step (explanation):
    // const arrOfJson = arrOfObj.map((obj) => JSON.stringify(obj))
    // const unique_arrOfJson = [... new Set(arrOfJson)] // würde mit arrOfObj nicht funktionieren!
    // return unique_arrOfJson.map(json => JSON.parse(json)) // wieder zu Object machen

    // oneLiner
    return [...new Set(arrOfObj.map((obj: any) => JSON.stringify(obj)))].map(
      (json) => JSON.parse(json as string)
    );
  },

  // Benutzt für Dashboard-Statistik. Baut einem die Range zusammen,
  // anhand derer die Daten dann aggregiert werden.
  getPreviousWeeks(number: number) {
    const weeks = [];

    for (let i = number - 1; i >= 0; i--) {
      weeks.push({
        start: dayjs().startOf('day').weekday(0).subtract(i, 'week'),
        end: dayjs().endOf('day').weekday(6).subtract(i, 'week'),
        cw: dayjs().startOf('week').add(1, 'day').subtract(i, 'week').week(),
      });
    }

    return weeks;
  },

  // retourniert netten String für Datumsdifferenz zu heute
  // TODO: rename `oDT`
  humanDayDiff(oDT: Dayjs) {
    const daydiff = dayjs().dayOfYear() - oDT.dayOfYear();

    if (daydiff === 0) return 'Heute';
    if (daydiff === 1) return 'Gestern';
    if (daydiff < 7) return dayjs(oDT).format('dddd');
    return dayjs(oDT).format('dd, DD.MM.');
  },

  // Returned Domain depending on Environment
  cookieDomain() {
    if (this.envIsDev()) return 'localhost';
    return 'booapp.eu';
  },

  // sums values of an array
  sumArray(array: Array<any>, key: string) {
    return array.reduce(
      (total: any, currentItem: any) => total + currentItem[key],
      0
    );
  },

  // formatiert Zahl
  formatDec(number: number) {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 0, // 12.345
      maximumFractionDigits: 2, // 12.345,67
      maximumSignificantDigits: 4, // 12.340
    }).format(number);
  },

  copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  },
};
