import Vue from 'vue';

import App from './App.vue';

import vuetify from './plugins/vuetify';

import router from './plugins/router';

// import store from './plugins/store'
import store from './store';

import VueCookies from 'vue-cookies';

import Echo from 'laravel-echo';

// https://github.com/75lb/sort-array#readme
import sortArray from 'sort-array';

import boo from './boo';
import me from './me';
import storenames from './store/names';

import VueApexCharts from 'vue-apexcharts';

import Hotjar from 'vue-hotjar';

// BOO constants
import * as constants from './constants';

import dayjs from 'dayjs';
import 'dayjs/locale/de';

import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import mitt from 'mitt';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// @ts-ignore: next-line
import VuePreferences from 'vue-preferences';
import { Store } from 'vuex';
import axios from 'axios';

Vue.use(VuePreferences);

if (constants.ENV !== 'development') {
  Bugsnag.start({
    apiKey: '190fc8d7b61a5c0c1538d80353426510',
    plugins: [new BugsnagPluginVue()],
    appVersion: constants.VERSION,
    releaseStage: constants.ENV,
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue?.installVueErrorHandler(Vue);
}

Vue.prototype.$Bugsnag = Bugsnag;

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts); // load on demand
dayjs.locale('de');
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);

Vue.prototype.$dayjs = dayjs;

Vue.use(VueCookies);
Vue.$cookies.config('7d');

// Vue.use(Hotjar, {
//   id: '2281364', // Hotjar Site ID,
//   isProduction: constants.ENV === 'production'
// })

/* wäre global definiert sinnvoll/schöner, LuJ weiß aber (noch) nicht, wie das geht
    const axios = require('axios').default;
*/

declare global {
  interface Window {
    axios: any;
  }
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Handle any errors here
    if (JSON.parse(JSON.stringify(error)).status === 503) {
      store.commit.app.SETMAINTENANCEENABLED(true);
    }

    return Promise.reject(error);
  }
);

if (window) {
  window.axios = require('axios');
  window.axios.defaults.withCredentials = true;
}

//
// window.Pusher = require('pusher-js')
//   // window.Pusher.Runtime.createXHR = function () {
//   //   const xhr = new XMLHttpRequest()
//   //   xhr.withCredentials = true
//   //   return xhr
//   // }
//
// window.Echo = new Echo({
// broadcaster: 'pusher',
// key: '2044f8d5d8ee34e943aa',
// cluster: 'eu',
//
// authEndpoint: 'https://lbo.local/broadcasting/auth',
//
//   // authEndpoint: '/broadcasting/auth',
//   // authHost: 'https://lbo.local'
//
// encrypted: true,
//   // forceTLS: true
//
// auth: {
//   headers: {
//     Authorization: 'Bearer ' + token
//   }
// }
//
// })

// https://vuejsdevelopers.com/2017/04/22/vue-js-libraries-plugins/
Object.defineProperty(Vue.prototype, '$sortArray', { value: sortArray });

Vue.prototype.$boo = boo;
Vue.prototype.$BC = constants;
Vue.prototype.$storenames = storenames;
Vue.prototype.$me = me;
Vue.prototype.$emitter = mitt(); // TODO: can we remove this? afaik wie also have `this.$emit()` and `this.$on`
Vue.prototype.$preference = VuePreferences;

declare module 'vue/types/vue' {
  interface Vue {
    $boo: typeof boo;
    $BC: typeof constants;
    $storenames: typeof storenames;
    $me: typeof me;
    $emitter: typeof mitt;
    $preference: typeof VuePreferences;
    $Bugsnag: typeof Bugsnag;
    $dayjs: typeof dayjs;
    $sortArray: typeof sortArray;
    $store: Store<any>;
  }
}

Vue.config.productionTip = false;

// Configure global components - from vuejs.org (https://vuejs.org/v2/guide/components-registration.html)
const requireComponent = require.context(
  // The relative path of the components folder
  './components/global',
  // Whether or not to look in subfolders
  true, // Somehow does not work?! so workaround below in componentName registration...
  // The regular expression used to match base component filenames
  /\w+\.(vue)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // console.log(fileName)

  // Get PascalCase name of component
  const componentName = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
    // Split in Folders
    .split('/')
    // Remove everything except last Item
    .slice(-1)
    // Join together again
    .join('')
    // Split up kebabs
    .split('-')
    // Upper case
    .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join('');

  // console.log(componentName)

  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});

const app = new Vue({
  vuetify,
  router,
  store: store.original,
  render: (h) => h(App),
}).$mount('#app');

export default app;
