<template>
  <div>
    <v-icon v-if="type === 'icon'" :color="iconColor">
      mdi-clock-outline mdi-spin
    </v-icon>
    <span v-if="type === 'duration'" style="color: grey">
      {{ durationText }}
    </span>
    <span v-if="type === 'clock'" :class="iconColor + '--text darken-3--text'">
      <h2>
        {{ durationTime }}
      </h2>
    </span>
    <div />
  </div>
</template>

<script>
export default {
  name: 'TimeRecording',

  props: {
    time: {
      type: Object,
      default: null,
    },
    type: {
      validator: function (value) {
        return ['icon', 'duration', 'clock'].indexOf(value) !== -1;
      },
      default: 'icon',
    },
  },

  data() {
    return {
      now: false,
      warnStd: 10, // nach x Std wird Warnung formatiert
    };
  },

  computed: {
    hours() {
      return this.now.diff(this.time.start, 'hour', true);
    },
    duration() {
      return this.$dayjs().startOf('year').add(this.hours, 'hour');
    },
    durationText() {
      if (this.hours <= 0) return '[ ... ]';
      if (this.hours >= 24) return '> 24 Std';
      return '[' + this.duration.format('HH:mm') + ']';
    },
    durationTime() {
      // if (this.hours === 0) return '--:--'
      if (this.hours < -24) return '';
      if (this.hours < -1)
        return this.$dayjs()
          .startOf('year')
          .subtract(this.hours, 'hour')
          .format('H [Std.] m [Min.]');
      if (this.hours < 0)
        return this.$dayjs()
          .startOf('year')
          .subtract(this.hours, 'hour')
          .format('mm:ss');
      if (this.hours < 1)
        return this.$dayjs()
          .startOf('year')
          .add(this.hours, 'hour')
          .format('mm:ss');
      if (this.hours < 24)
        return this.$dayjs()
          .startOf('year')
          .add(this.hours, 'hour')
          .format('H [Std.] m [Min.]');
      return '> 24 Std';
    },
    iconColor() {
      if (this.hours < 0) return 'grey';
      if (this.hours >= 24) return 'error';
      if (this.hours >= this.warnStd) return 'warning';
      return 'success';
    },
    tooltip() {
      if (this.hours < 0) return '';
      if (this.hours >= 24) return 'Beginn bereits über 24 Stunden her!';
      if (this.hours >= this.warnStd)
        return 'Dauer mittlerweile >' + this.warnStd + ' Stunden';
      return 'Zeitmessung läuft...';
    },
  },
  watch: {
    now: {
      handler: function () {
        setTimeout(() => {
          this.now = this.$dayjs();
        }, 1000);
      },
      immediate: true,
    },
  },

  created() {
    this.now = this.$dayjs();
  },
};
</script>
