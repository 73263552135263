<template>
  <div>
    <div class="text-center">
      <v-tooltip
        bottom
        v-if="
          !commentBefore ||
          $dayjs(comment.created_at).format('DD MMM') !==
            $dayjs(commentBefore.created_at).format('DD MMM')
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="my-5" v-bind="attrs" v-on="on">
            {{ $boo.humanDayDiff($dayjs(comment.created_at)) }}
          </v-chip>
        </template>
        <span>{{ $dayjs(comment.created_at).format($BC.F_DE_DATE_NICE) }}</span>
      </v-tooltip>
    </div>
    <div :class="rowClass">
      <Bc-comment-avatar
        v-if="!isMyMessage"
        :author="comment.owner"
        :isLastMessageOfAuthor="
          commentAfter ? comment.owner.id !== commentAfter.owner.id : true
        "
      />
      <v-card
        :color="chatBubbleBackgroundColor"
        :class="bubbleClass"
        :id="comment.id"
        :flat="isSymbol"
      >
        <v-card-text
          v-if="
            !isMyMessage &&
            comment.owner &&
            (commentBefore ? comment.owner.id !== commentBefore.owner.id : true)
          "
          :class="authorClass"
          >{{ comment.owner.label }}
        </v-card-text>
        <v-card-text :class="`pa-0 ${isSymbol ? 'text-h2' : 'body-1'}`">{{
          comment.text
        }}</v-card-text>
        <v-card-subtitle class="pa-0 mt-2 text-caption text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" x-small>
                {{
                  $boo.visibilityInfo(comment.visibility, comment, commentable)
                    .icon
                }}
              </v-icon>
            </template>
            <span
              >Sichbarkeit:
              {{
                $boo.visibilityInfo(comment.visibility, comment, commentable)
                  .label
              }}</span
            >
          </v-tooltip>
          ·
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ timeOfComment }}</span>
            </template>
            <span>{{ timeAndDateOfComment }}</span>
          </v-tooltip>
          <span v-if="isMyMessage && comment.id !== 999999">
            ·
            <v-icon x-small @click="showDeleteDialog = true">
              mdi-delete-forever-outline
            </v-icon>

            <bc-dialog-delete
              :show-dialog="showDeleteDialog"
              title="Kommentar löschen"
              checkboxLabel=""
              :deleting="deleting"
              @evCancel="showDeleteDialog = false"
              @evDelete="deleteComment(comment.id)"
            >
              Soll dieses Kommentar <strong>endgültig gelöscht</strong> werden?
            </bc-dialog-delete>
            ·
            <v-icon x-small @click="editComment"> mdi-pencil </v-icon>
          </span>

          <bc-response-handler
            :axios-success="axiosSuccess"
            :axios-error="axiosError"
            show-in-dialog
          />

          <!-- <v-menu offset-y v-if="isMyMessage">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-0" left small v-bind="attrs" v-on="on"
                >mdi-chevron-down</v-icon
              >
              ·
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="deleteComment(comment.id)"
                  >Kommentar löschen</v-list-item-title
                >
              </v-list-item>
            </v-list></v-menu
          > -->
        </v-card-subtitle>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    commentBefore: {
      type: Object,
      default: () => null,
    },
    commentAfter: {
      type: Object,
      default: () => null,
    },
    commentable: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      showDeleteDialog: false,
      deleting: false,

      axiosSuccess: null,
      axiosError: null,
    };
  },

  methods: {
    deleteComment(id) {
      this.deleting = true;
      window.axios
        .delete(this.$boo.lboUrl() + 'comment/' + id)
        .then((response) => {
          this.axiosSuccess = response;
          this.$emit('deleted', this.comment);
          this.$emitter.emit('comments.updated');
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.deleting = false;
          this.showDeleteDialog = false;
        });
    },
    editComment() {
      this.$emit('enableEditMode', this.comment);
    },
  },
  computed: {
    isMyMessage() {
      return this.$me.owns(this.comment);
    },
    isSymbol() {
      return this.$BC.CHATSYMBOLS.includes(this.comment.text);
    },
    chatBubbleBackgroundColor() {
      if (this.isSymbol) return 'transparent';
      if (this.isMyMessage) return 'boo';
      return 'dark';
    },
    rowClass() {
      return `justify-${this.isMyMessage ? 'end' : 'start'} d-flex`;
    },
    bubbleClass() {
      return `pa-2 ma-2 mt-4 rounded-0 ${
        this.isMyMessage
          ? 'rounded-tl-lg rounded-bl-lg rounded-br-lg'
          : 'rounded-tl-lg rounded-tr-lg rounded-br-lg'
      }`;
    },
    authorClass() {
      return `pa-0 text-${
        this.isMyMessage ? 'right' : 'left'
      } font-weight-bold`;
    },
    timeOfComment() {
      return this.$dayjs(this.comment.created_at).format('HH:mm');
    },
    timeAndDateOfComment() {
      return `${this.$dayjs(this.comment.created_at).format(
        'DD.MM.YYYY'
      )} um ${this.$dayjs(this.comment.created_at).format('HH:mm')} Uhr`;
    },
  },
};
</script>
