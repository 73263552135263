<template>
  <bv-frame
    headline="Sphären"
    subtitle="Auf dieser Seite werden dir deine Sphären angezeigt"
    icon="mdi-buffer"
    :loading="loading"
    @refresh="loadData"
  >
    <bc-response-handler
      :axios-success="axiosSuccess"
      :axios-error="axiosError"
    />

    <div class="d-flex flex-wrap justify-start mt-2">
      <div v-if="loading && !this.$boo.objCount(domains)" class="">
        <v-skeleton-loader
          type="card-heading, text"
          :width="300"
          :height="100"
          class="mr-4 mb-4 pa-2"
          elevation="1"
        />
      </div>
      <bc-domain-card
        v-for="domain in domains"
        :key="domain.id"
        :domain="domain"
        :show-stats="true"
        class="mr-4 mb-4"
        @click="showDomain(domain)"
      />
    </div>

    <v-btn
      v-if="$me.isSysadmin()"
      fab
      bottom
      right
      fixed
      class="boo elevation-10"
      @click="showDomainNew = true"
    >
      <v-icon class="white--text"> mdi-plus </v-icon>
    </v-btn>

    <bc-domain-edit
      :show="showDomainNew"
      @close="showDomainNew = false"
      @data="appendData"
    />
  </bv-frame>
</template>

<script>
import BvFrame from '@/views/Frame';
import BcDomainEdit from '@/views/domain/DomainEdit';
import BcDomainCard from '@/views/domain/DomainCard';

export default {
  name: 'Domains',
  components: {
    BvFrame,
    BcDomainEdit,
    BcDomainCard,
  },

  data() {
    return {
      domains: [],

      showDomainNew: false,
      loading: false,

      axiosSuccess: null,
      axiosError: null,
    };
  },

  computed: {},

  mounted() {
    this.loadData();
    // this.loading = true
  },

  methods: {
    loadData() {
      this.loading = true;
      window.axios
        .get(this.$BC.LBO_API_URL + 'domains')
        .then((response) => {
          this.axiosSuccess = response;
          this.domains = response.data.success;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    appendData(domain) {
      this.domains.push(domain);
    },
    showDomain(domain) {
      this.$router.push({
        name: 'domainView',
        params: {
          domainSlug: domain.slug,
          domain: domain,
        },
      });
    },
  },
};
</script>
──
