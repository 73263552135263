<script lang="ts">
import { PTMType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import BcPtmItem from '@/components/global/BcPtmItem.vue';

export default defineComponent({
  name: 'ProjectTab',
  components: { BcPtmItem },
  props: {
    ptms: {
      type: Array as PropType<PTMType[]>,
      default: () => [],
    },
  },
});
</script>

<template>
  <v-card>
    <v-card-title>Projektmitgliedschaften</v-card-title>
    <v-card-text>
      <v-list-item-content v-for="ptm in ptms" :key="ptm.id" class="pa-2">
        <div class="d-flex">
          <v-icon
            class="pl-2"
            :title="ptm.active ? 'aktiv' : 'inaktiv'"
            :color="ptm.active ? 'green' : 'red'"
            >{{
              'mdi-checkbox' + (!ptm.active ? '-blank-outline' : '-marked')
            }}</v-icon
          >
          <v-icon class="pl-2" v-if="ptm.timerecmodel_id" title="Zeiterfassung">
            mdi-clock-outline
          </v-icon>
          <bc-ptm-item
            :ptm="ptm"
            viewpoint="person"
            link
            left-align
            class="ml-3"
          />
        </div>
      </v-list-item-content>
    </v-card-text>
  </v-card>
</template>
