<template>
  <v-card elevation="0" class="pa-1" min-width="100">
    <v-badge
      :value="protected"
      bordered
      color="error"
      icon="mdi-lock"
      Xoverlap
      offset-x="25"
      offset-y="20"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <bc-avatar :pojo="domain" :size="40" no-tooltip />
          </span>
        </template>
        <span>{{ domain.description }}</span>
        <span v-if="protected"> (geschützt)</span>
      </v-tooltip>
      {{ domain.name }}
      <bc-domain-icon :domain="domain" />
      <v-switch
        v-model="switchStatus"
        dense
        color="boo"
        class="ml-6 mt-1 mb-n2"
        :loading="storing"
        :disabled="storing || !available"
        @change="change"
      />
      <bc-response-handler
        :axios-success="axiosSuccess"
        :axios-error="axiosError"
        :display-success="false"
      />
    </v-badge>
  </v-card>
</template>

<script>
export default {
  name: 'UserDomainSwitch',

  props: {
    user: { type: Object, default: null },
    domain: { type: Object, default: null },
    value: { type: Boolean, default: false },
    available: { type: Boolean, default: false },
    protected: { type: Boolean, default: false },
  },

  data() {
    return {
      switchStatus: false,
      storing: false,
      axiosSuccess: null,
      axiosError: null,
    };
  },

  created() {
    this.switchStatus = this.value;
  },

  methods: {
    change() {
      this.storing = true;
      window.axios
        .post(
          this.$boo.lboUrl() +
            'user/' +
            this.user.slug +
            '/domain/' +
            this.domain.slug,
          { value: this.switchStatus }
        )
        .then((response) => {
          this.axiosSuccess = response;
          this.$emit('evStored', response.data.success);
          this.$store.commit('app/SETSNACKBAR', {
            text: response.data.message,
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.switchStatus = this.value;
          this.storing = false;
        });
    },
  },
};
</script>
