<template>
  <div class="text-right" v-if="value.original.length">
    <div v-if="!isMobile" class="d-flex">
      <Bc-date-picker
        v-model="filter.dateRange"
        v-if="filterByDateRange"
        :monthOnly="monthOnly"
        :items="allMonthsForSelect"
        @input="returnValue"
      />
      <Bc-Select
        label="Projekte"
        v-model="filter.projects"
        v-if="filterByProjects && allProjectsForSelect.length > 1"
        :items="allProjectsForSelect"
        @input="returnValue"
      />
      <Bc-Select
        label="Personen"
        v-model="filter.persons"
        v-if="filterByPersons && allPersonsForSelect.length > 1"
        :items="allPersonsForSelect"
        @input="returnValue"
      />
      <Bc-Select
        label="Status"
        v-model="filter.statusLevels"
        v-if="filterByStatusLevels && allStatusLevelsForSelect.length > 1"
        :items="allStatusLevelsForSelect"
        @input="returnValue"
      />
    </div>
    <div v-else class="d-flex">
      <Bc-date-picker
        v-model="filter.dateRange"
        v-if="filterByDateRange"
        :monthOnly="monthOnly"
        :items="allMonthsForSelect"
        @input="returnValue"
      />

      <!-- @TODO: Aus irgendeinem Grund müssen die drei auch im DOM sein, sonst funktioniert das Filtering nicht... -->
      <Bc-Select
        style="display: none"
        label="Projekte"
        v-model="filter.projects"
        v-if="filterByProjects && allProjectsForSelect.length > 1"
        :items="allProjectsForSelect"
      />
      <Bc-Select
        style="display: none"
        label="Personen"
        v-model="filter.persons"
        v-if="filterByPersons && allPersonsForSelect.length > 1"
        :items="allPersonsForSelect"
        @input="returnValue"
      />
      <Bc-Select
        style="display: none"
        label="Status"
        v-model="filter.statusLevels"
        v-if="filterByStatusLevels && allStatusLevelsForSelect.length > 1"
        :items="allStatusLevelsForSelect"
        @input="returnValue"
      />
      <v-dialog
        v-if="
          allProjectsForSelect.length > 1 ||
          allPersonsForSelect.length > 1 ||
          allStatusLevelsForSelect.length > 1
        "
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            style="align-self: center"
            :value="badgeValue"
            :content="badgeValue"
            overlap
            offset-x="20"
            offset-y="18"
            bordered
          >
            <v-btn
              icon
              color="white"
              dark
              v-bind="attrs"
              v-on="on"
              class="boo ma-2"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-card>
          <v-card-title class="headline boo--text">Filter</v-card-title>
          <v-card-text>
            <Bc-Select
              label="Projekte"
              v-model="filter.projects"
              v-if="filterByProjects && allProjectsForSelect.length > 1"
              :items="allProjectsForSelect"
              @input="returnValue"
            />
            <Bc-Select
              label="Personen"
              v-model="filter.persons"
              v-if="filterByPersons && allPersonsForSelect.length > 1"
              :items="allPersonsForSelect"
              @input="returnValue"
            />
            <Bc-Select
              label="Status"
              v-model="filter.statusLevels"
              v-if="filterByStatusLevels && allStatusLevelsForSelect.length > 1"
              :items="allStatusLevelsForSelect"
              @input="returnValue"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="clearSelection" class="caption"
              >Filter zurücksetzen</v-btn
            >
            <v-spacer />
            <v-btn @click="closeMobilePopup" class="boo white--text"
              >Anwenden</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- <div>
        Filter: {{ filter }} <br/>
        <strong>Value (Array):</strong> {{value.original.length}} <br/>
        <strong>filteredArray:</strong> {{filteredArray.length}}
    </div> -->
  </div>
</template>

<script>
import AcceptButton from '../statusSwitch/acceptButton.vue';
import BcDateRange from './BcDateRange.vue';
import BcSelect from './BcSelect.vue';

export default {
  name: 'BcFilter',

  components: { BcDateRange, BcSelect, AcceptButton },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filterByDateRange: {
      type: Boolean,
      default: false,
    },
    filterByProjects: {
      type: Boolean,
      default: false,
    },
    filterByPersons: {
      type: Boolean,
      default: false,
    },
    filterByStatusLevels: {
      type: Boolean,
      default: false,
    },
    monthOnly: {
      type: Boolean,
      default: false,
    },
    onlyMine: {
      type: Boolean,
      default: false,
    },
    disableQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {
        dateRange: [],
        projects: [],
        persons: [],
        statusLevels: [],
      },

      dialog: false,
    };
  },
  computed: {
    allProjectsForSelect() {
      return this.$boo
        .uniqueObjects(this.value.original.map((o) => o.ptm.project))
        .sort(this.$boo.sortLocaleBy('label'));
    },
    allPersonsForSelect() {
      return this.$boo
        .uniqueObjects(this.value.original.map((o) => o.ptm.person))
        .sort(this.$boo.sortLocaleBy('label'));
    },
    allStatusLevelsForSelect() {
      let statusLevels = [];
      this.value.original.forEach((item) => {
        if (statusLevels.every((status) => status.id !== item.status_id))
          statusLevels.push({
            id: item.status_id,
            label: this.$boo.statusInfo(
              item.startdate ? 'timebilling' : 'time',
              item.status_id
            ).status.label,
          });
        // if(this.statusLevels.every(status => status !== item.status_id )) statusLevels.push(item.status_id)
      });
      return statusLevels.sort((a, b) => (a.id > b.id ? 1 : -1));
    },
    originalList() {
      return this.value.original;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    allMonthsForSelect() {
      let months = [];
      this.value.original.forEach((item) => {
        if (
          !months.includes(
            this.$dayjs(item.startdate).startOf('month').format('YYYY-MM')
          )
        ) {
          months.push(
            this.$dayjs(item.startdate).startOf('month').format('YYYY-MM')
          );
        }
      });
      return months;
    },
    badgeValue() {
      let value = 0;

      if (this.filter.persons && this.filter.persons.length > 0) {
        value++;
      }

      if (this.filter.projects && this.filter.projects.length > 0) {
        value++;
      }

      if (this.filter.statusLevels && this.filter.statusLevels.length > 0) {
        value++;
      }

      return value;
    },
  },
  methods: {
    returnValue() {
      this.$emit('input', {
        ...this.value,
        filtered: this.filterArray(),
        filter: this.filter,
      });
      if (!this.disableQuery) this.changeQuery();
    },
    changeQuery() {
      // Überwacht und ändert die Filter-Settings in der URL
      if (
        this.$route.query.filter !==
        encodeURIComponent(JSON.stringify(this.filter))
      ) {
        let query = { ...this.$route.query };
        query.filter = encodeURIComponent(JSON.stringify(this.filter));
        this.$router.replace({ query: query });
      }
    },
    filterArray() {
      //Kümmert sich um das Filtering des Arrays
      let itemArray = [...this.value.original];
      return itemArray.filter((item) => {
        return (
          this.filterDateRange(item) &&
          this.filterProjects(item) &&
          this.filterPersons(item) &&
          this.filterStatusLevels(item)
        );
      });
    },
    filterDateRange(item) {
      if (
        this.filterByDateRange &&
        this.filter.dateRange &&
        this.filter.dateRange.length !== 0
      ) {
        // console.log('Filtering by DateRange...')
        if (item.start) {
          // Zeiteinträge
          return (
            this.$dayjs(item.start) >=
              this.$dayjs(this.filter.dateRange[0]).startOf('day') &&
            this.$dayjs(item.start) <=
              this.$dayjs(this.filter.dateRange[1]).endOf('day')
          );
        } else if (item.startdate) {
          // Zeitabrechnungen
          return (
            this.$dayjs(item.startdate) >=
              this.$dayjs(this.filter.dateRange).startOf('day') &&
            this.$dayjs(item.startdate) <=
              this.$dayjs(this.filter.dateRange).endOf('day')
          );
        }
      } else {
        return item;
      }
    },
    filterProjects(item) {
      if (
        this.filterByProjects &&
        this.filter.projects &&
        this.filter.projects.length !== 0
      ) {
        // console.log('Filtering by Projects...')
        return this.filter.projects.includes(item.ptm.project.id);
      } else {
        return item;
      }
    },
    filterPersons(item) {
      if (
        // this.filterByPersons &&
        this.filter.persons &&
        this.filter.persons.length !== 0
      ) {
        // console.log('Filtering by Persons...')
        return this.filter.persons.includes(item.ptm.person.id);
      } else {
        return item;
      }
    },
    filterStatusLevels(item) {
      if (
        this.filterByStatusLevels &&
        this.filter.statusLevels &&
        this.filter.statusLevels.length !== 0
      ) {
        // console.log('Filtering by StatusLevels...')
        return this.filter.statusLevels.includes(item.status_id);
      } else {
        return item;
      }
    },
    clearSelection() {
      this.filter.dateRange = [
        this.$dayjs().startOf('month').format('YYYY-MM-DD'),
        this.$dayjs().endOf('month').format('YYYY-MM-DD'),
      ];
      this.filter.projects = [];
      this.filter.persons = [];
      this.filter.statusLevels = [];
      this.closeMobilePopup();
    },
    closeMobilePopup() {
      this.returnValue();
      this.dialog = !this.dialog;
    },
  },
  created() {
    this.filter = { ...this.value.filter };
    if (this.$route.query.filter) {
      this.filter = {
        ...JSON.parse(decodeURIComponent(this.$route.query.filter)),
      };
    }
  },
  watch: {
    originalList(before, after) {
      // Wenn sich an der Orignalliste etwas verändert hat (z.B. weil ein Eintrag gelöscht wurde)
      // dann soll der Filter erneut feuern.
      if (before !== after) {
        this.returnValue();
      }
    },
  },
};
</script>
