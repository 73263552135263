<template>
  <v-list-item v-if="!showEmpty || slotLength" two-line>
    <v-list-item-content>
      <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
      <v-list-item-title ref="slot" style="white-space: normal">
        <slot />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BcTextWidget',

  props: {
    label: {
      type: String,
      default: '',
    },
    flex: {
      type: String,
      default: '1 1 content',
    },
    showEmpty: {
      type: Boolean,
      default: false,
    },
    // clickable: {
    //   type: Boolean,
    //   default: false
    // }
  },

  computed: {
    slotLength() {
      return 1;
      // @@@ das funktioniert noch nicht
      // return this.$refs.slot.children.item(0)
    },
  },
};
</script>
