<template>
  <bv-frame
    headline="Alle Zeiten"
    subtitle="Erfasse, bearbeite und überprüfe hier alle Projektarbeitszeiten."
    :new-button="
      $store.getters[$storenames.data.getters.timeablePTMs].length > 0
    "
    new-button-label="Neuer Zeiteintrag"
    :loading="$store.state.isLoading"
    @newButton="newTime"
    @refresh="refreshData()"
  >
    <v-card
      flat
      :class="`mb-5 ${isMobile ? 'transparent' : ''}`"
      :outlined="!isMobile"
    >
      <v-card-text class="pa-0 pa-sm-5">
        <times-data-table :items="listData.filtered" :headers="headers">
          <template v-slot:top>
            <Bc-filter
              v-model="listData"
              filterByDateRange
              filterByProjects
              filterByPersons
              filterByStatusLevels
            />

            <div class="d-flex justify-space-between">
              <v-card-title
                v-if="listData.filtered.length > 1"
                class="body-1 pt-2"
              >
                <p>
                  <span class="font-weight-bold">
                    {{ $boo.formatDec(listData.filtered.length) }}
                  </span>
                  Einträge |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec(
                        $boo.sumArray(listData.filtered, 'duration') / 60
                      )
                    }}
                  </span>
                  Stunden {{ isFiltered ? '(gefiltert)' : '' }}
                </p>
              </v-card-title>

              <div class="self-center"></div>
            </div>
          </template>
        </times-data-table>
      </v-card-text>
    </v-card>
  </bv-frame>
</template>

<script>
import BvFrame from '@/views/Frame';
import BcTimeStatusChip from '@/views/time/TimeStatusChip';
import BcTimeRecording from '@/views/time/TimeRecording';
import { preference } from 'vue-preferences';
import store from '@/store';
import TimesDataTable from './TimesDataTable.vue';
import { createTimesHeader } from './timeHeaders';

export default {
  name: 'BvTimes',

  components: {
    BvFrame,
    BcTimeStatusChip,
    BcTimeRecording,
    TimesDataTable,
  },

  props: {},

  data() {
    return {
      filteredList: [],
      filter: {
        dateRange: [],
      },
      page: 1,
      pauses: false,
    };
  },

  computed: {
    paginationStep: preference('times.paginationStep', { defaultValue: 20 }),
    listData: {
      get() {
        return {
          original: store.state.timesStore.times,
          filtered: this.filteredList,
          filter: this.filter,
        };
      },
      set(newValue) {
        this.filteredList = newValue.filtered;
        this.filter = newValue.filter;
      },
    },
    ptms: {
      get() {
        return this.$store.state.data.ptms;
      },
      set() {
        this.$store.commit('data/setPTMS', ptms);
      },
    },
    headers() {
      return createTimesHeader(this.$vuetify.breakpoint);
    },

    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },

    paginationChoice() {
      let ret = [];
      ret.push(10);
      if (this.listData.filtered.length > 20) ret.push(20);
      if (this.listData.filtered.length > 50) ret.push(50);
      // if (this.listData.filtered.length > 100) ret.push(100)
      ret.push(100);
      // ret.push({text:'Alle', value: -1}) // Alle > schlechte Idee, weil bei vielen Zeilen Browser crasht!!
      return ret;
    },

    isFiltered() {
      return this.listData.original.length !== this.listData.filtered.length;
    },
  },

  watch: {},

  created() {
    this.refreshData();

    if (
      this.listData.original.filter(
        (time) => this.$dayjs(time.start).month === this.$dayjs().month
      ).length
    ) {
      // wenn es zumindest einen ZE im aktuellen Monat gibt, dann default auf dieses filtern
      this.filter.dateRange = [
        this.$dayjs().startOf('month').format('YYYY-MM-DD'),
        this.$dayjs().endOf('month').format('YYYY-MM-DD'),
      ];
    }
  },

  methods: {
    refreshData() {
      store.dispatch.timesStore.fetchAllTimes();
      this.$store.dispatch('data/refresh', ['getPTMs']);
    },
    newTime() {
      if (!this.ptms.length) {
        alert('no ptms, sorry...'); // betrifft nur SysAdmin (siehe #558)
        return;
      }

      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          new: true,
          ptms: this.ptms,
        },
      });
    },
    openTime(time, gotoComment) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          timeId: time.id,
          ptms: this.ptms,
          gotoComment: gotoComment === true,
        },
      });
    },
  },
};
</script>
