<template>
  <bv-frame
    v-if="user"
    :headline="`Hey, ${user.adress}!`"
    :subtitle="loggedInMessage"
    :loading="loading"
    :refresh-button="false"
  >
    <bc-response-handler
      :axios-error="axiosError"
      :axios-success="axiosSuccess"
    />
    <v-container class="my-5 pa-0" fluid>
      <v-alert
        v-if="$boo.envIsTest()"
        shaped
        outlined
        icon="mdi-atom-variant mdi-spin mdi-24px"
        type="warning"
        >Achtung, du befindest dich hier in der Testumgebung! Diese ist nicht
        unbedingt stabil und Daten können inkonsistent sein.
      </v-alert>
      <v-alert
        v-if="$boo.envIsStaging()"
        shaped
        prominent
        icon="mdi-atom-variant mdi-spin mdi-24px"
        type="warning"
        >Du befindest dich hier in der Staging-Umgebung!
      </v-alert>
      <div v-if="user.active" id="domainList" class="mt-5 d-flex flex-wrap">
        <bc-domain-card
          v-for="domain in domains"
          :key="domain.id"
          :domain="domain"
          :active="domain === currentDomain"
          class="mr-4 mb-4"
          width="300px"
          @click="setDomain(domain)"
        />
      </div>
    </v-container>
  </bv-frame>
</template>

<script lang="ts">
import store from '@/store';
import { DomainType } from '@/types/types';
import BvFrame from '@/views/Frame.vue';
import BcDomainCard from '@/views/domain/DomainCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BvHome',

  components: { BvFrame, BcDomainCard },

  data() {
    return {
      loading: false,

      axiosSuccess: null,
      axiosError: null,
    };
  },

  computed: {
    domains() {
      return Object.values(store.state.auth.domains || {}).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    currentDomain(): DomainType | null {
      if (store.state.app.domain) {
        return store.state.app.domain;
      } else {
        return null;
      }
    },
    user() {
      return store.state.auth.user;
    },
    loggedInMessage(): string {
      if (!this.user?.active)
        return 'Dein Zugang zu boo wurde leider deaktivert - bohoo...';
      switch (this.$boo.objCount(this.$store.state.auth.domains)) {
        case 0:
          return 'Damit du boo verwenden kannst, musst du zunächst in eine sogenannte "Sphäre" eingeladen werden. Bitte wende dich diesbezüglich an deine/n Betreuer*in.';
        case 1:
          return 'Starte hier mit der Arbeit in deiner Sphäre:';
      }
      if (!this.currentDomain)
        return 'Bitte wähle die Sphäre aus, in welcher du arbeiten möchtest:';
      if (this.$boo.objCount(this.$store.state.auth.domains) === 1)
        return `Du bist in der ${this.currentDomain.name}-Sphäre angemeldet`;
      return `Du bist in der ${this.currentDomain.name}-Sphäre angemeldet, kannst aber auch eine andere auswählen:`;
    },
  },

  created() {},
  mounted() {
    window.scrollTo(0, 0);
    if (this.domains.length === 1) {
      this.setDomain(this.domains[0]);
    }
  },

  methods: {
    setDomain(domain: DomainType) {
      this.$boo.setDomain(domain);
      this.$router.push(domain.slug + '/dashboard');
    },
  },
});
</script>
