<template>
  <div class="test">
    <h1 class="subheading grey--text">Dev-Test</h1>

    <v-sheet color="grey">
      <p class="pa-3">Jürgen's Spielwiese :-)</p>

      <v-card class="ma-5">
        <v-card-title>Allgemeines Zeugzeug</v-card-title>
        <v-card-text>
          {{ 'TEST_HUGO' }}
        </v-card-text>
      </v-card>

      <v-card class="ma-5">
        <v-card-title>DateTime Widget</v-card-title>
        <v-card-text>
          {{ datetime }}
          <bc-datetime-widget
            v-model="datetime"
            date
            time
            time-icon
            prefix-weekday
            :suggest="{ date: $dayjs() }"
          />
        </v-card-text>
      </v-card>

      <v-card class="ma-5">
        <v-card-title>flex boot camp</v-card-title>
        <v-card-text class="">
          <div class="d-flex justify-space-between">
            <div class="">
              <div style="background-color: grey" class="ma-1">div 1</div>

              <div
                style="background-color: red; height: 100px; width: 500px"
                class="ma-1"
              >
                div 2
              </div>
            </div>

            <div
              style="background-color: lightblue; min-height: 50px; width: 100%"
              class="ma-1"
            >
              div 3
            </div>
          </div>

          <div style="background-color: green; max-width: 500px" class="ma-1">
            div 4
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mx-5">
        <v-card-title>Default-Farben</v-card-title>
        <v-card-text>
          <v-btn min-width="200" color="primary"> primary </v-btn>
          <v-btn min-width="200" color="secondary"> secondary </v-btn>
          <v-btn min-width="200" color="accent"> accent </v-btn>
          <v-btn min-width="200" color="error"> error </v-btn>
          <v-btn min-width="200" color="info"> info </v-btn>
          <v-btn min-width="200" color="success"> success </v-btn>
          <v-btn min-width="200" color="warning"> warning </v-btn>
        </v-card-text>
      </v-card>

      <v-card class="ma-5">
        <v-card-title>BOO-Farben</v-card-title>
        <v-card-text>
          <v-btn min-width="200" color="boo"> boo </v-btn>
          <v-btn min-width="200" color="white"> white </v-btn>
          <v-btn min-width="200" color="gunmetal"> gunmetal </v-btn>
          <v-btn min-width="200" color="alabaster"> alabaster </v-btn>
          <v-btn min-width="200" color="tuscany"> tuscany </v-btn>
          <v-btn min-width="200" color="sunset"> sunset </v-btn>
        </v-card-text>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
// import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'Test',
  components: {
    // vueDropzone: vue2Dropzone
  },
  data() {
    return {
      msg: 'Hello World X',
      names: ['Jürgen', 'Benni', 'Raphael'],
      version: this.$BC.VERSION,
      version_old: process.env.VUE_APP_VERSION,
      env: process.env.NODE_ENV,
      url: this.$BC.LBO_URL,

      vmodel: false,
      inputvalue: false,

      dropzoneOptions: {
        url: 'the server url, where you want your filesto be sent',
        maxFilesize: 0.5,
        headers: {
          'My-Awesome-Header': 'header value',
          Authorization: '<your application access-token>',
        },
        paramName: () => 'file[]',
        dictDefaultMessage: 'Upload Files Here xD',
        includeStyling: false,
        previewsContainer: false,
        thumbnailWidth: 250,
        thumbnailHeight: 140,
        uploadMultiple: true,
        parallelUploads: 20,
      },

      datetime: this.$dayjs().format('YYYY-DD-MM HH:mm'),
    };
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    base_url() {
      return process.env.BASE_URL;
    },
  },
  mounted() {
    console.log('>>>>>' + 'TEST_console' + '<<<<<<<<<');
  },
  methods: {
    snack() {
      const sbAttr = {
        text: 'huhu',
        // color: 'sunset'
        timeout: 3333,
      };
      this.$store.commit('app/SETSNACKBAR', sbAttr);
    },

    // dropzone
    uploadProgress() {},
    fileAdded() {},
    sendingFiles() {},
    success() {},
  },
};
</script>
