<script lang="ts">
import boo from '@/boo';
import store from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CopyToClipboardButton',

  props: {
    textToCopy: {
      type: String,
      required: true,
    },
  },

  methods: {
    copyToClipboard: (text: string) => {
      try {
        boo.copyToClipboard(text);
        store.commit.app.SETSNACKBAR({
          text: 'Link in die Zwischenablage kopiert',
          color: 'success',
          timeout: 2000,
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
});
</script>

<template>
  <v-btn small @click="() => copyToClipboard(textToCopy)" icon>
    <v-icon small>mdi-content-copy</v-icon>
  </v-btn>
</template>
