<template>
  <v-dialog v-model="show" max-width="480" persistent retain-focus scrollable>
    <v-card>
      <v-card-title color="boo">
        <v-icon large left class="mr-5">
          {{ !isNew ? 'mdi-account-edit' : 'mdi-account-plus' }}
        </v-icon>
        {{ !isNew ? 'User bearbeiten' : `${person.label} als User aktivieren` }}
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-3">
        <v-form
          ref="formUserEdit"
          v-model="valid"
          class="px-3"
          lazy-validation
          @keyup.native.enter="submit"
          @keyup.native.escape="cancel"
        >
          <v-alert v-if="possibleEmailsCount === 0" color="info">
            Bitte {{ person.label }} zunächst einen (aktiven) E-Mail-Kontakt
            eingetragen!
          </v-alert>
          <v-text-field
            v-if="!canSetUserEmail"
            label="E-Mail-Adresse zur Anmeldung"
            :value="user.email"
            readonly
            title="(dzt. noch nicht änderbar)"
          />
          <v-text-field
            v-if="canSetUserEmail && possibleEmailsCount === 1"
            label="E-Mail-Adresse"
            :value="person.emailcontacts[0].text"
            readonly
          />
          <v-select
            v-if="canSetUserEmail && possibleEmailsCount > 1"
            v-model="emailId"
            label="E-Mail-Adresse"
            :items="possibleEmails"
            :rules="[(v) => v !== '' || 'Eingabe erforderlich']"
            required
          >
            <template v-if="!isNew" v-slot:append-outer>
              <v-tooltip open-on-click bottom max-width="300px">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information-outline </v-icon>
                </template>
                <span
                  >Bitte eine bereits erfasste E-Mail-Adresse auswählen. Mit
                  dieser wird sich {{ person.label }} in Folge in BOO anmelden
                  können.</span
                >
              </v-tooltip>
            </template>
          </v-select>

          <v-combobox
            v-model="username"
            label="Username"
            :items="nameProposal"
            :rules="nameRules"
            required
            no-filter
            @keyup.native.enter.stop
            @keyup.native.escape.stop
          >
            <template v-slot:append-outer>
              <v-tooltip open-on-click bottom max-width="300px">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information-outline </v-icon>
                </template>
                <span
                  >Einen Usernamen auswählen oder eingeben -
                  {{ person.label }} wird dann in BOO mit diesem Namen
                  angesprochen. Beachte, dass dieser in deiner Organisation
                  eindeutig sein sollte.</span
                >
              </v-tooltip>
            </template>
          </v-combobox>

          <v-switch
            v-if="$me.me().protected"
            v-model="protected"
            label="geschützter User"
          />
        </v-form>
      </v-card-text>

      <bc-response-handler :axios-error="axiosError" />

      <bc-btn-cancel-action
        :valid="valid"
        :enable-action="possibleEmailsCount > 0"
        :action-text="isNew ? 'aktivieren' : 'speichern'"
        :action-running="storing"
        @evAction="submit"
        @evCancel="cancel"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import BcBtnCancelAction from '@/components/global/BcBtnCancelAction.vue';

export default {
  name: 'UserEdit',
  components: { BcBtnCancelAction, BcResponseHandler },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    user: { type: Object, default: null },
    person: { type: Object, default: null },
  },

  data() {
    return {
      // user:{},
      emailId: '',
      username: '',
      protected: null,

      nameRules: [
        (v) => v !== '' || 'Eingabe erforderlich',
        (v) => (v && v.length >= 3) || 'mindestens 3 Zeichen',
        (v) => (v && v.length <= 33) || 'maximal 33 Zeichen',
      ],

      valid: false,
      storing: false,

      axiosError: null,
    };
  },

  computed: {
    possibleEmails() {
      if (!this.person.emailcontacts) return null;
      return this.person.emailcontacts
        .filter((email) => !email.deactivated)
        .map(function (value) {
          return {
            value: value.id,
            email: value.text,
            text: value.text + (value.label !== '' ? ` (${value.label})` : ''),
          };
        });
    },
    possibleEmailsCount() {
      if (!this.possibleEmails) return 0;
      return this.possibleEmails.length;
    },
    isNew: function () {
      return !this.user;
    },
    canSetUserEmail() {
      return true;
      // falls E-Mail-Änderung nur bis zur ersten Anmeldung möglich sein soll
      // return this.isNew || !this.user.lastaction_at;
    },
    nameProposal() {
      if (!this.person) return [];

      const nameProposal = new Set(); // Set überschreibt Dubletten ;-)
      if (this.person.nickname) {
        nameProposal.add(this.person.nickname.trim());
      }
      if (this.person.forename) {
        nameProposal.add(this.person.forename.trim());
      }
      if (this.person.forename && this.person.surname !== '') {
        nameProposal.add(
          this.person.surname.slice(0, 2) +
            this.person.forename.slice(0, 1).trim()
        );
        nameProposal.add(
          this.person.forename.slice(0, 1) + '. ' + this.person.surname.trim()
        );
        nameProposal.add(
          this.person.forename +
            ' ' +
            this.person.surname.slice(0, 1) +
            '.'.trim()
        );
        nameProposal.add(
          this.person.forename + ' ' + this.person.surname.trim()
        );
      }
      if (this.person.label) {
        nameProposal.add(this.person.label.trim());
      }

      return [...nameProposal] // to array
        .filter((name) => name !== ''); // keine leeren
      // .filter(name => name.length <= 20) // unter 20 Zeichen; wird eh mit rule abgefangen
    },
  },

  watch: {
    show: function () {
      if (this.user && this.show) {
        this.username = this.user.username;
        if (this.user.email) {
          this.emailId = this.possibleEmails.filter(
            (ec) => ec.email === this.user.email
          )[0].value;
        }
        this.protected = this.user.protected;
      }
    },
  },

  methods: {
    cancel: function () {
      this.$emit('evClose', null);
      // this.$refs.formUserEdit.reset()
      this.username = '';
      this.emailId = '';
    },
    submit: function () {
      if (!this.$refs.formUserEdit.validate()) return;
      this.storing = true;

      const transfer = {
        id: this.isNew ? '' : this.user.id,
        person_id: this.person.id,
        emailcontact_id:
          this.possibleEmailsCount === 1
            ? this.possibleEmails[0].value // von text-field > id holen
            : this.emailId, // Value aus Select
        email:
          this.possibleEmailsCount === 1
            ? this.person.emailcontacts[0].text
            : this.possibleEmails.filter((e) => e.value === this.emailId)[0]
                .email, // mit value aus Select von possible holen
        username: this.username,
        user_id: this.$store.state.auth.user.id,
        // active: false,
        protected: this.protected,
      };
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$boo.lboUrl() +
            'user' +
            (this.isNew ? '' : '/' + this.user.slug),
          data: transfer,
        })
        .then((response) => {
          this.$emit('evStored', response.data.success);
          this.$emit('evClose');
          // this.$refs.formUserEdit.reset()
          this.$store.commit('app/SETSNACKBAR', {
            text: response.data.message,
          });
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
  },
};
</script>
