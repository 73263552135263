<template>
  <v-card-title>{{ data }}</v-card-title>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: null,
    },
  },
};
</script>
