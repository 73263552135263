<template>
  <bc-widget-frame v-if="show" title="Schnellzugriff" icon="mdi-flash-outline">
    <v-list menu>
      <v-list-item-group>
        <v-list-item
          v-if="$me.can('person.show')"
          :to="$boo.domainRouteTo('persons')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zu den Kontakten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$me.has('canMyTimes')"
          :to="$boo.domainRouteTo('times')"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zur Zeitliste</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$me.can('project.show')"
          :to="$boo.domainRouteTo('projects')"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zu den Projekten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </bc-widget-frame>
</template>
<script>
export default {
  name: 'BcDashboardQuicklinks',

  data() {
    return {};
  },

  computed: {
    show() {
      return (
        this.$me.can('person.show') ||
        this.$me.can('project.show') ||
        this.$me.has('canMyTimes')
      );
    },
  },

  methods: {},
};
</script>
