import me from '@/me';
import store from '@/store';
import { ProjectType } from '@/types/types';

export const canEditProject = (project: Partial<ProjectType>): boolean => {
  return (
    (me.owns(project) && store.getters.auth.userCan('project.update')) ||
    store.getters.auth.userCan('project.update.all') ||
    Boolean(project.isProjectLead) //TODO: this is undefined, it's a bug -> https://lutz.atlassian.net/browse/BOO-1020
  );
};

export const getProjectStatusColor = (id: number) => {
  switch (id) {
    case 0:
      return 'grey';
    case 1:
      return 'grey';
    case 2:
      return 'green';
    case 3:
      return 'yellow';
    case 4:
      return 'red';
    case 5:
      return 'red';
    default:
      return 'grey';
  }
};
