<template>
  <bv-frame
    headline="Abrechnungen"
    subtitle="Hier kannst du Zeitabrechnungen einsehen und prüfen."
    :loading="$store.state.isLoading"
    @refresh="refreshData()"
  >
    <v-card
      flat
      :class="`mb-5 ${isMobile ? 'transparent' : ''}`"
      :outlined="!isMobile"
    >
      <v-card-text class="pa-0 pa-sm-5">
        <v-data-table
          class="elevation-0 transparent"
          hide-default-footer
          mobile-breakpoint="200"
          disable-sort
          v-model="selectedTimeBillings"
          :items="listData.filtered"
          :headers="headers"
          :show-select="listData.filtered.length > 1 && !isMobile"
          :items-per-page="paginationStep"
          :page.sync="page"
          @click:row="openBilling"
          x:loading="$store.state.isLoading"
        >
          <template v-slot:top>
            <div>
              <Bc-filter
                v-model="listData"
                filterByPersons
                filterByProjects
                filterByDateRange
                monthOnly
                filterByStatusLevels
                @input="selectedTimeBillings = []"
              />
            </div>
            <div class="d-flex justify-space-between">
              <v-card-title
                v-if="listData.filtered.length > 1"
                class="body-1 pt-2"
              >
                <p v-if="selectedTimeBillings.length < 2">
                  <span v-if="pageCount > 1"> insgesamt </span>
                  <span class="font-weight-bold">
                    {{ $boo.formatDec(listData.filtered.length) }}
                  </span>
                  Abrechnungen |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec(
                        $boo.sumArray(listData.filtered, 'entries')
                      )
                    }}
                  </span>
                  Einträge |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec($boo.sumArray(listData.filtered, 'hours'))
                    }}
                  </span>
                  Stunden {{ isFiltered ? '(gefiltert)' : '' }}
                </p>
                <p v-else>
                  selektiert:
                  <span class="font-weight-bold">
                    {{ selectedTimeBillings.length }}
                  </span>
                  Abrechnungen |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec(
                        $boo.sumArray(selectedTimeBillings, 'entries')
                      )
                    }}
                  </span>
                  Einträge |
                  <span class="font-weight-bold">
                    {{
                      $boo.formatDec(
                        $boo.sumArray(selectedTimeBillings, 'hours')
                      )
                    }}
                  </span>
                </p>
                <p v-if="isTruncated">&nbsp; (nur letzte 3 Monate)</p>
              </v-card-title>

              <div class="self-center">
                <bc-time-bulk-export-button
                  v-if="
                    !isMobile &&
                    ($me.can('time.export.pdf') ||
                      $me.can('time.export.csv') ||
                      $me.can('time.export.django'))
                  "
                  :itemsToExport="selectedTimeBillings"
                  type="timebilling"
                  pdf
                  csv
                  django
                />
              </div>
            </div>
          </template>

          <template v-slot:item.month="{ item }">
            <span v-if="isMobile">{{
              $dayjs(item.startdate).format('MMM YYYY')
            }}</span>
            <span v-else>{{ $dayjs(item.startdate).format('MMMM YYYY') }}</span>
          </template>

          <template v-slot:item.ptm.person="{ item }">
            <bc-ptm-item
              :ptm="item.ptm"
              :size="30"
              viewpoint="project"
              Xhide-role
              :only-avatar="isMobile"
            />
          </template>

          <template v-slot:item.ptm.project="{ item }">
            <bc-ptm-item
              :ptm="item.ptm"
              :size="30"
              viewpoint="person"
              :only-avatar="isMobile"
            />
          </template>

          <template v-slot:item.hours="{ item }">
            {{ item.hours.toFixed(2) }}
          </template>

          <template v-slot:item.status="{ item }">
            <div class="text-right">
              <bc-time-billing-status-chip
                :value="item.status_id"
                icon
                :no-text="true"
              />
            </div>
          </template>

          <template v-slot:item.status_id="{ item }">
            <div class="text-right">
              <Status-switch
                :value="item.status_id"
                :item="item"
                type="timebilling"
                :on-success="setTimebilling"
              />
            </div>
          </template>

          <template v-slot:item.comment_count="{ item }">
            <Bc-comment-badge
              :count="item.comment_count"
              @click.stop="openComment(item)"
            />
          </template>

          <template v-slot:no-data> Keine Abrechnungen vorhanden. </template>
          <template v-slot:loading> Lade Abrechnungen... </template>

          <template v-slot:footer>
            <v-row>
              <v-col>
                <v-pagination
                  v-if="
                    listData.filtered.length > paginationStep &&
                    paginationStep != -1
                  "
                  class="my-5"
                  v-model="page"
                  :length="pageCount"
                  total-visible="5"
                  @input="selectedTimeBillings = []"
                ></v-pagination>
              </v-col>
              <v-col
                v-if="listData.filtered.length > 5"
                class="d-flex justify-end"
              >
                <v-select
                  class="pt-5 float-right"
                  style="max-width: 60px"
                  hide-details
                  v-model="paginationStep"
                  :items="paginationChoice"
                />
              </v-col>
            </v-row>
            <!--            <v-row v-if="isTruncated" class="justify-center">-->
            <!--              <div class="self-center">-->
            <!--                <v-btn text @click="getAllTimeBillings" small>-->
            <!--                  <v-icon left>mdi-reload-alert</v-icon>-->
            <!--                  Ältere Abrechnungen laden-->
            <!--                </v-btn>-->
            <!--              </div>-->
            <!--            </v-row>-->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </bv-frame>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BvFrame from '@/views/Frame.vue';
import BcTimeBillingStatusChip from '@/views/timebilling/TimeBillingStatusChip.vue';
import BcTimeBulkExportButton from '@/views/time/TimeBulkExportButton.vue';

// @ts-ignore
import { preference } from 'vue-preferences';
import {
  DataTableHeaderType,
  ListDataReturnType,
  TimeBillingType,
} from '@/types/types';
import store from '@/store';
import boo from '@/boo';
import BcFilter from '@/components/global/filter/BcFilter.vue';
import BcPtmItem from '@/components/global/BcPtmItem.vue';
import BcCommentBadge from '@/components/global/comments/BcCommentBadge.vue';

export default defineComponent({
  name: 'TimeBillingList',

  components: {
    BcCommentBadge,
    BcPtmItem,
    BcFilter,
    BvFrame,
    BcTimeBillingStatusChip,
    BcTimeBulkExportButton,
  },

  props: {},

  data() {
    return {
      filteredList: [] as TimeBillingType[],
      filter: {
        dateRange: '',
        persons: [],
        projects: [],
      },
      page: 1,
      selectedTimeBillings: [] as TimeBillingType[],
    };
  },

  computed: {
    paginationStep: preference('timebillings.paginationStep', {
      defaultValue: 12,
    }) as () => number,
    listData: {
      get(): ListDataReturnType {
        return {
          original: store.state.timebillingStore.timebillings,
          filtered: this.filteredList,
          filter: this.filter,
        };
      },
      set(newValue: { original: any; filtered: any; filter: any }) {
        // store.commit.timebillingStore.setTimebillings(newValue.original);
        // store.commit.data.setTimebillings(newValue.original);
        this.filteredList = newValue.filtered;
        this.filter = newValue.filter;
      },
    },
    headers(): DataTableHeaderType[] {
      return this.headersAll.filter(
        (h) =>
          !h.hidden ||
          (h.hidden !== true &&
            !h.hidden.includes(this.$vuetify.breakpoint.name))
      );
    },
    headersAll(): DataTableHeaderType[] {
      return [
        {
          text: 'Monat',
          value: 'month',
          sortable: false,
          hidden: this.listData.filter.dateRange.length > 0,
        },

        // hidden wenn nur 1 person
        {
          text: 'Person',
          value: 'ptm.person',
          sortable: false,
          hidden:
            // this.uniqueProjects.length < 2 || // @@@ das zeigt 2 MA in 1 Projekt nicht an!
            this.listData.filter.persons?.length === 1 || [],
          class: this.isMobile ? 'pa-1' : '',
          cellClass: this.isMobile ? 'pa-0' : '',
        },
        // hidden wenn nur 1 project
        {
          text: this.isMobile ? 'Projekt' : 'Projekt / Rolle',
          value: 'ptm.project',
          sortable: false,
          hidden:
            this.uniqueProjects.length < 2 ||
            this.listData.filter.projects?.length === 1 ||
            [], // ["xs"],
          class: this.isMobile ? 'pa-1' : '',
          cellClass: this.isMobile ? 'pa-0' : '',
        },
        // { text: 'Rolle', value: 'ptm.role.name', hidden: this.projects.length === 1 || ['xs', 'sm'] },

        {
          text: 'Einträge',
          align: 'center',
          value: 'entries',
          sortable: true,
          hidden: ['xs'],
        },

        {
          text: 'Stunden',
          align: 'center',
          value: 'hours',
          sortable: true,
          class: this.isMobile ? 'pa-1' : '',
          cellClass: this.isMobile ? 'pa-1' : '',
        },

        { text: 'Status', value: 'status' },
        { text: '', value: 'status_id' },
        // { text: 'erfasst', value: 'statusChip', hidden: true || ['sm', 'md', 'lg'] },
        { text: 'Status', value: 'statusSwitch', hidden: true || ['xs'] },
        { text: '', value: 'comment_count', hidden: false || ['xs'] },
      ];
    },
    isMobile(): Boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    pageCount() {
      // @ts-ignore
      return Math.ceil(this.listData.filtered.length / this.paginationStep);
    },
    paginationChoice() {
      let ret = [];
      ret.push(6);
      if (this.listData.filtered.length > 12) ret.push(12);
      if (this.listData.filtered.length > 24) ret.push(24);
      ret.push(50);
      // ret.push({text:'Alle', value: -1}) // Alle > schlechte Idee, weil bei vielen Zeilen Browser crasht!!
      return ret;
    },
    uniqueProjects() {
      // @ts-ignore
      if (this.listData.original.length === 0) return [];
      // @ts-ignore
      return boo.uniqueObjects(
        // @ts-ignore
        this.listData.original.map((billing) => billing.ptm.project)
      );
    },
    isFiltered() {
      // @ts-ignore
      return this.listData.original.length != this.listData.filtered.length;
    },

    isTruncated() {
      return store.state.timebillingStore.timebillingsOptions.truncated;
    },
  },

  watch: {
    listData() {
      if (!this.paginationChoice.includes(this.paginationStep)) {
        // this.paginationStep = -1 // Alle > schlechte Idee, weil bei vielen Zeilen Browser crasht!!
        this.paginationStep = this.paginationChoice[0]; // besser wegen Performance das kleinste als default
      }
    },
  },

  created() {
    this.refreshData();

    // @ts-ignore
    this.$emitter.on('comments.updated', () => this.refreshData());
  },

  methods: {
    refreshData() {
      store.dispatch.timebillingStore.fetchAllTimebillings({ truncated: true });
    },
    setTimebilling(timebilling: TimeBillingType) {
      store.commit.timebillingStore.setTimebilling(timebilling);
    },
    // getAllTimeBillings() {
    //   store.dispatch.timebillingStore.fetchAllTimebillings({
    //     truncated: false,
    //   });
    // },
    openBilling(item: TimeBillingType) {
      if (this.selectedTimeBillings.length === 0 && item.id) {
        this.$router.push({
          name: 'timebillingdetail',
          params: {
            billingID: item.id?.toString(),
          },
        });
      } else {
        if (!this.selectedTimeBillings.includes(item)) {
          this.selectedTimeBillings.push(item);
        } else {
          this.selectedTimeBillings.splice(
            this.selectedTimeBillings.indexOf(item),
            1
          );
        }
      }
    },
    openComment(billing: TimeBillingType) {
      store.commit.contentDrawer.LOAD({
        component: 'bc-cd-comment',
        data: {
          commentable: billing,
        },
      });
    },
  },
});
</script>
