import {
  findTimebillingRequest,
  findTimebillingsRequest,
} from '@/views/timebilling/timebillingService';
import {
  TimeBillingOptionsType,
  TimeBillingType,
  TimeType,
} from '../../types/types';
import { addOrReplaceItemInArray, mergeArray } from '../helpers';
import router from '@/plugins/router';
import { Commit } from 'vuex';
import store from '../';

type TimebillingStoreStateType = {
  isLoading: boolean;
  timebillings: TimeBillingType[];
  timebillingsOptions: TimeBillingOptionsType;
};

// State object
const initialState = (): TimebillingStoreStateType => ({
  isLoading: false,
  timebillings: [],
  timebillingsOptions: {},
});

const state: TimebillingStoreStateType = initialState();

const getters = {
  timebillings: (state: TimebillingStoreStateType) => state.timebillings,
  getTimeBilling:
    (state: TimebillingStoreStateType) => (id: TimeBillingType['id']) => {
      const timebilling = state.timebillings.find(
        (timebilling) => timebilling.id === id
      );
      return timebilling;
    },
};

type ActionContext = {
  commit: Commit;
};

const actions = {
  async fetchAllTimebillings(
    { commit }: ActionContext,
    params = { truncated: true }
  ) {
    try {
      commit('setIsLoading', true);
      const response = await findTimebillingsRequest(params);
      commit('setTimebillings', response.data.success);
      commit('setTimebillingsOptions', response.data.options);
    } catch (e) {
      console.error('Error fetching timebillings', e);
    } finally {
      commit('setIsLoading', false);
    }
  },

  async fetchSingleTimebilling(
    { commit }: ActionContext,
    id: TimeBillingType['id']
  ) {
    try {
      commit('setIsLoading', true);

      const response = await findTimebillingRequest(id);
      commit('setTimebilling', response.data.success);
      commit('timesStore/setTimes', response.data.success.times, {
        root: true,
      });
    } catch (e) {
      console.error(`Error fetching timebilling ${id}`, e);
      router.replace({ name: 'timebilling' });
    } finally {
      commit('setIsLoading', false);
    }
  },
};

const mutations = {
  setTimebilling: (
    state: TimebillingStoreStateType,
    timebilling: TimeBillingType
  ) => {
    state.timebillings = [
      ...addOrReplaceItemInArray(state.timebillings, timebilling),
    ];
  },
  setTimebillings: (
    state: TimebillingStoreStateType,
    timebillings: TimeBillingType[]
  ) => {
    state.timebillings = timebillings;
    // state.timebillings = [
    //   ...mergeArray(state.timebillings, timebillings, (a, b) => a.id === b.id),
    // ];
  },

  setTimebillingsOptions(
    state: TimebillingStoreStateType,
    timebillingsOptions: TimeBillingOptionsType
  ) {
    state.timebillingsOptions = timebillingsOptions;
  },

  setIsLoading: (state: TimebillingStoreStateType, status: boolean) => {
    state.isLoading = status;
  },

  RESET(state: any) {
    const newState = initialState();
    // @ts-ignore: next-line
    Object.keys(newState).forEach((key) => (state[key] = newState[key])); // TODO: ist das erlaubt?
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
