<template>
  <v-menu
    ref="showTimePicker"
    v-model="showTimePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="timeEdit"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{}">
      <v-text-field
        v-model="timeEdit"
        :rules="rules"
        :label="label"
        :append-icon="icon"
        @click:append="showTimePicker = true"
      />
    </template>
    <v-time-picker
      v-if="showTimePicker"
      v-model="timeEdit"
      scrollable
      full-width
      format="24hr"
      color="boo"
      @click:minute="$refs.showTimePicker.save(timeEdit)"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'BcTimePicker',
  props: {
    time: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Uhrzeit',
    },
    icon: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: function () {
        return [
          (v) =>
            /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(v) ||
            'Eingabe im Format hh:mm erforderlich',
        ];
      },
    },
  },
  data() {
    return {
      showTimePicker: false,
      timeEdit: '',
    };
  },
  watch: {
    timeEdit() {
      this.$emit('timeselected', this.timeEdit);
    },
    time() {
      this.timeEdit = this.time;
    },
  },
};
</script>
