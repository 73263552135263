<template>
  <bc-content-drawer-frame>
    <template v-slot:headerActionLeft>
      <v-btn
        text
        color="boo"
        @click="$store.dispatch($storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </template>
    <template v-slot:headerActionRight>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="unreadNotificationCount === 0"
            dense
            @click="markAllAsRead"
          >
            <v-list-item-icon
              ><v-icon>mdi-email-open-outline</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title> Alle als gelesen markieren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <Bc-Bulk-Progress
        title="Als gelesen markieren"
        :show="showBulkDialog"
        :progress="bulkProgress"
        :max="unreadNotificationCount"
      />
    </template>
    <template v-slot:headerTitle> Benachrichtigungen </template>
    <v-skeleton-loader v-if="loading" type="list-item@4" />
    <v-list flat subheader three-line>
      <v-subheader> {{ unreadNotificationCountText }}</v-subheader>
      <v-data-table
        :items="notifications"
        :items-per-page="itemsShown"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <v-list-item @click="readNotification(item)">
            <v-list-item-icon>
              <v-badge :dot="!item.read" color="boo" :value="!item.read">
                <v-icon>{{ item.icon || notificationIcon(item.type) }}</v-icon>
              </v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :class="!item.read ? 'font-weight-bold' : ''">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                item.prevText || item.text
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                $dayjs(item.date).format('ddd DD.MM.YYYY')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:footer>
          <div v-if="hiddenNotificationCount > 0" class="text-center">
            <v-btn text @click="showMore"
              >{{ hiddenNotificationCount }} weitere laden</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-list>
    <template v-slot:dev>
      <v-btn
        class="text-center"
        color="blue"
        block
        @click="addTestNotifications"
      >
        Push Test Notifications
      </v-btn>
    </template>
  </bc-content-drawer-frame>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      // notifications: [],
      loading: false,
      itemsShown: 15,
      showBulkDialog: false,
      bulkProgress: 0,
    };
  },
  computed: {
    notifications: {
      get() {
        return this.$store.state.data.notifications;
      },
      set(newValue) {
        this.$store.commit('data/setNotifications', newValue);
      },
    },
    unreadNotitications() {
      return this.notifications.filter((n) => !n.read);
    },
    unreadNotificationCount() {
      return this.unreadNotitications.length;
    },
    unreadNotificationCountText() {
      if (this.unreadNotificationCount === 0)
        return 'Keine ungelesenen Nachrichten';
      if (this.unreadNotificationCount === 1)
        return 'Eine ungelesene Nachricht';
      if (this.unreadNotificationCount < 99)
        return this.unreadNotificationCount + ' ungelesene Nachrichten';
      return '99+ ungelesene Nachrichten';
    },
    hiddenNotificationCount() {
      return Math.max(0, this.notifications.length - this.itemsShown);
    },
    // alternativ geht übrigens auch das - wenn man 1-Zeiler mag
    // hiddenNotificationCount: ({notifications, itemsShown}) => Math.max(0, notifications.length - itemsShown),
  },
  created() {
    this.$store.dispatch('data/refresh', ['getNotifications']);
  },
  methods: {
    readNotification(notification) {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-notification-reader',
        data: notification,
      });
    },
    async markAllAsRead() {
      if (this.unreadNotificationCount > 0) {
        this.showBulkDialog = true;

        for (
          this.bulkProgress;
          this.bulkProgress < this.unreadNotificationCount;
          this.bulkProgress++
        ) {
          await this.markAsRead(this.unreadNotitications[this.bulkProgress].id);
        }
        this.bulkProgress = 0;
        this.showBulkDialog = false;
        this.$store.dispatch('data/refresh', ['getNotifications']);
      }
    },
    async markAsRead(id) {
      await window.axios.patch(
        `${this.$BC.LBO_API_URL}notifications/${id}/read`
      ); // mark as read
    },
    notificationIcon(type) {
      switch (type) {
        case 'SystemUpdate':
          return 'mdi-new-box';
        case 'birthday':
          return 'mdi-cake';
        default:
          return 'mdi-ghost';
      }
    },
    showMore() {
      this.itemsShown += 10;
    },
    addTestNotifications() {
      this.notifications.push(
        {
          type: 'SystemUpdate',
          title: 'Changelog Test',
          prevText: 'Version 4.3.0 ist da! Sieh dir jetzt die Neuerungen an.',
          version: '4.3.0',
          text: 'Test einer Changelog Notification. Dieser Text kann auch länger sein und eine Art Intro darstellen. ',
          list: [
            'Change 1',
            'Change 2',
            'Change 3',
            'Change 4',
            'Change 5',
            'Change 6',
            'Change 7',
            'Change 8',
          ],
        },
        {
          type: 'birthday',
          title: 'Birthday Test',
          prevText: 'Hier gibts einen prevText',
          birthday: '1990-11-13',
          text: 'Happy Birthday alter',
        },
        {
          type: 'default',
          title: 'Default Test',
          text: 'Diese Nachricht hat keinen festgelegten prevText.',
        },
        {
          type: 'custom',
          title: 'Custom Notification',
          icon: 'mdi-cart',
          prevText: 'Diese Notification ist komplett Customizable',
          blocks: [
            {
              name: 'bc-notification-text',
              data: 'Das ist ein Textblock',
            },
            {
              name: 'bc-notification-list',
              data: ['LE 1', 'LE 2', 'LE 3'],
            },
            {
              name: 'bc-notification-text',
              data: 'Das ist ein zweiter Textblock',
            },
            {
              name: 'bc-notification-list',
              data: ['LE 1', 'LE 2', 'LE 3'],
            },
            {
              name: 'bc-notification-button',
              data: { text: 'Klick hier!', link: '/projects' },
            },
          ],
        }
      );
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
};
</script>
