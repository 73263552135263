<template>
  <v-card :disabled="loading" color="transparent" flat>
    <bc-btn-cancel-action
      v-if="inputHasChanged"
      :valid="valid"
      :enable-action="inputHasChanged"
      :action-running="storing"
      :header="true"
      :title="title.name"
      @evAction="submit"
      @evCancel="cancel"
    />
    <v-app-bar v-else elevate-on-scroll clipped-right width="100%">
      <v-btn
        text
        @click="$store.dispatch($storenames.contentDrawer.actions.close)"
      >
        <v-icon>mdi-chevron-left</v-icon> Zurück
      </v-btn>
    </v-app-bar>

    <v-card-text class="mt-3">
      <v-form
        ref="formAddPerson"
        v-model="valid"
        class="px-3"
        lazy-validation
        @keyup.native.enter="submit"
        @keyup.native.escape="cancel"
      >
        <v-select
          v-model="personEdit.sex"
          :items="anreden"
          label="Anrede"
          class="cdIncluded"
        />

        <v-text-field
          v-model="personEdit.forename"
          label="Vorname"
          autocomplete="off"
          @focus="dontUCase = false"
          @keyup.delete="dontUCase = true"
          @keypress="personEdit.forename = ucFirst(personEdit.forename)"
        />

        <v-text-field
          v-model="personEdit.surname"
          label="Nachname"
          autocomplete="off"
          @focus="dontUCase = false"
          @keyup.delete="dontUCase = true"
          @keypress="personEdit.surname = ucFirst(personEdit.surname)"
        />

        <v-text-field v-model="personEdit.nickname" label="Spitzname" />

        <bc-datetime-widget
          v-model="personEdit.birthday"
          label-date="Geburtsdatum"
          date
          may-be-empty
          :max="$dayjs()"
        />
      </v-form>

      <bc-response-handler
        :axios-error="axiosError"
        :local-warning="localWarning"
      />

      <DevelopmentNotice>
        InputHasChanged: {{ inputHasChanged }} <br />
        {{ personEdit }}
      </DevelopmentNotice>
    </v-card-text>
  </v-card>
</template>

<script>
import DevelopmentNotice from '@/components/DevelopmentNotice.vue';
import store from '@/store';

export default {
  name: 'PersonEdit',
  props: {
    data: { type: Object, default: null }, // person
  },
  data() {
    return {
      // stati
      loading: false,
      valid: false,
      storing: false,
      // response
      axiosError: null,
      axiosSuccess: null,
      localWarning: '',
      personEdit: {},
      anreden: [
        { value: '', text: '' },
        { value: 'w', text: 'Frau' },
        { value: 'm', text: 'Herr' },
        { value: 'd', text: 'neutral (divers)' },
      ],
      dontUCase: false,
    };
  },
  computed: {
    isNew() {
      return this.data === null;
    },
    inputHasChanged() {
      if (this.isNew) return true;
      return (
        JSON.stringify(this.personEdit) !== JSON.stringify(this.data.person)
      );
    },
    title() {
      if (this.isNew) {
        return { name: 'Neu', icon: 'mdi-clipboard' };
      } else {
        return { name: 'Bearbeiten', icon: 'mdi-clipboard' };
      }
    },
  },
  watch: {
    inputHasChanged: function (value) {
      this.$store.commit('contentDrawer/LOCK', value);
    },
  },
  created() {
    if (!this.isNew) {
      this.personEdit = { ...this.data.person };
      // this.loadPerson() @@@LuB TODO: Hier sollte man die Person nachladen???
    }
  },
  methods: {
    ucFirst: function (string) {
      if (string) {
        if (this.dontUCase) return string;
        var Phrase = '';
        string.split(' ').forEach(function (item) {
          // console.log(item)
          Phrase += item.charAt(0).toUpperCase() + item.slice(1) + ' ';
        });
        return Phrase.trim();
      }
    },
    cancel: function () {
      this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
      // this.$emit('closed', null)
      // this.$refs.formAddPerson.reset()
    },
    submit: function () {
      if (!this.$refs.formAddPerson.validate()) return;
      if (
        (this.personEdit.forename === undefined ||
          this.personEdit.forename.trim() === '') &&
        (this.personEdit.surname === undefined ||
          this.personEdit.surname.trim() === '') &&
        (this.personEdit.nickname === undefined ||
          this.personEdit.nickname.trim() === '')
      ) {
        this.localWarning = 'Bitte zumindest ein Namensfeld befüllen...';
        return;
      }
      this.storing = true;
      const transfer = {
        slug: this.personEdit.personSlug,
        sex: this.personEdit.sex,
        forename: this.personEdit.forename,
        surname: this.personEdit.surname,
        nickname: this.personEdit.nickname,
        birthday: this.personEdit.birthday
          ? this.$dayjs().format(this.personEdit.birthday)
          : '',
        user_id: this.$store.state.auth.user.id,
        domain_id: this.$store.state.app.domain.id,
      };
      window
        .axios({
          method: this.isNew ? 'post' : 'patch',
          url:
            this.$boo.lboUrl() +
            'persons' +
            (this.isNew ? '' : '/' + this.personEdit.slug),
          data: transfer,
        })
        .then((response) => {
          this.axiosSuccess = response;
          const person = response.data.success;
          if (this.isNew) {
            store.dispatch.contentDrawer.close();
            // goto person
            this.$router.push({
              name: 'personView',
              params: { personSlug: person.slug },
            });
          } else if (person.slug !== this.data.person.slug) {
            // slug wurde neu generiert > URL ändern
            this.$router.replace({
              name: 'personView',
              params: { personSlug: person.slug },
            });
            window.location.reload(false); // reload
            return;
          }
          this.data.onSuccess?.(person);
          this.$store.dispatch(this.$storenames.contentDrawer.actions.close);
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
  },
  components: { DevelopmentNotice },
};
</script>
