<template>
  <div>
    <v-list>
      <v-list-item v-for="(item, n) in data" :key="n">
        <v-list-item-icon
          ><v-icon>mdi-check-circle-outline</v-icon></v-list-item-icon
        >

        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            {{ item }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
