<script lang="ts">
import store from '@/store';
import { PersonType, ProjectType, TimeType } from '@/types/types';
import { Dayjs } from 'dayjs';
import { PropType, defineComponent } from 'vue';
import BcTimeStatusChip from '@/views/time/TimeStatusChip.vue';
import BcTimeRecording from '@/views/time/TimeRecording.vue';
import { DataTableHeader } from 'vuetify';
import BcPtmItem from '@/components/global/BcPtmItem.vue';
import BcCommentBadge from '@/components/global/comments/BcCommentBadge.vue';

type HeaderType = (DataTableHeader & { visible: boolean })[];

const defaultHeaders: HeaderType = [
  { text: 'Datum', value: 'date', visible: true },
  { text: 'Person', value: 'person', visible: true },
  { text: 'Projekt', value: 'project', visible: true },
  { text: 'von/bis', value: 'startstop', visible: true },
  { text: 'Pause', align: 'center', value: 'pause', visible: true },
  { text: 'Stunden', align: 'center', value: 'duration', visible: true },
  { text: 'Status', value: 'status', visible: true },
];

export default defineComponent({
  name: 'TimeDataTable',

  components: {
    BcCommentBadge,
    BcPtmItem,
    BcTimeStatusChip,
    BcTimeRecording,
  },

  props: {
    items: {
      type: Array as PropType<TimeType[]>,
      required: true,
    },
    headers: {
      type: Array as PropType<HeaderType>,
      default: () => defaultHeaders,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pauses: false,
      page: 1,
    };
  },

  methods: {
    openTime(time: TimeType, gotoComment?: boolean) {
      // sicherheitshalber aktualisieren
      // store.dispatch.data.getPTMs(); // ToDo: wieso gibt's hier einen Compile error?
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-time-edit',
        data: {
          timeId: time.id,
          ptms: store.getters.data.timeablePTMs,
          gotoComment: gotoComment === true,
        },
      });
    },
    daydiff(oDT: Dayjs) {
      return this.$dayjs().dayOfYear() - oDT.dayOfYear();
    },

    isRecording(time: TimeType) {
      return time.start && !time.stop;
    },

    formatEnde(stop: string) {
      return stop ? this.$dayjs(stop).format('HH:mm') : '';
    },

    itemClass(item: TimeType) {
      return `time-entry-${item.id}`;
    },
  },

  computed: {
    headersFiltered(): HeaderType {
      return this.headers.filter?.((header) => header.visible);
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    uniqueProjects(): ProjectType[] {
      if (this.items.length === 0) return [];
      return this.$boo.uniqueObjects(
        this.items.map((time) => time.ptm.project)
      );
    },
    uniquePersons(): PersonType[] {
      if (this.items.length === 0) return [];
      return this.$boo.uniqueObjects(this.items.map((time) => time.ptm.person));
    },
    // ptms() {
    //   return store.state.data.ptms;
    // },
  },
});
</script>

<template>
  <v-data-table
    :items="items"
    :headers="headersFiltered"
    sort-by="start"
    sort-desc
    class="elevation-0 transparent"
    mobile-breakpoint="200"
    :items-per-page="itemsPerPage"
    @click:row="openTime"
    :hide-default-footer="hideDefaultFooter"
    :disable-sort="disableSort"
    :item-class="itemClass"
  >
    <template v-slot:top>
      <slot name="top" />
    </template>

    <template v-slot:header.duration="{ header }">
      {{ isMobile ? 'Std' : 'Stunden' }}
    </template>

    <template v-slot:header.status="{ header }"> Status </template>

    <template v-slot:item.date="{ item }">
      <span v-if="daydiff($dayjs(item.start)) === 0"> Heute </span>
      <span v-else-if="daydiff($dayjs(item.start)) === 1"> Gestern </span>
      <span v-else-if="daydiff($dayjs(item.start)) < 7">
        <span class="hidden-sm-and-up">{{
          $dayjs(item.start).format('ddd,')
        }}</span>
        <span class="hidden-xs-only">{{
          $dayjs(item.start).format('dddd,')
        }}</span>
        <br />
        <span>{{ $dayjs(item.start).format('DD.MM.') }}</span>
      </span>
      <div v-else>
        <span>{{ $dayjs(item.start).format('dd,') }}</span>
        <br />
        <span>{{ $dayjs(item.start).format('DD.MM.') }}</span>
      </div>
    </template>

    <template v-slot:item.ptm.project="{ item }">
      <bc-ptm-item
        :ptm="item.ptm"
        viewpoint="person"
        link
        :only-avatar="$vuetify.breakpoint.smAndDown"
      />
    </template>

    <template v-if="true" v-slot:item.start="{ item }">
      <span class="hidden-xs-only">{{
        $dayjs(item.start).format('HH:mm')
      }}</span>
    </template>

    <template v-slot:item.person="{ item }">
      <bc-ptm-item
        :ptm="item.ptm"
        viewpoint="project"
        link
        :only-avatar="$vuetify.breakpoint.smAndDown"
      />
    </template>

    <template v-slot:item.project="{ item }">
      <bc-ptm-item
        :ptm="item.ptm"
        viewpoint="person"
        link
        :only-avatar="$vuetify.breakpoint.smAndDown"
      />
    </template>

    <template v-if="true" v-slot:item.stop="{ item }">
      <bc-time-recording v-if="isRecording(item)" :time="item" type="icon" />
      <span v-else class="hidden-xs-only">{{ formatEnde(item.stop) }}</span>
    </template>

    <template v-slot:item.startstop="{ item }">
      <span>{{ $dayjs(item.start).format('HH:mm') }}</span>
      <br />
      <bc-time-recording v-if="isRecording(item)" :time="item" type="icon" />
      <span v-else>{{ formatEnde(item.stop) }}</span>
    </template>

    <template v-slot:item.duration="{ item }">
      <bc-time-recording
        v-if="isRecording(item)"
        :time="item"
        type="duration"
      />
      <span v-else>{{ (item.duration / 60).toFixed(2) }}</span>
    </template>

    <template v-slot:item.status="{ item }">
      <span>
        <bc-time-status-chip :value="item.status_id" />
      </span>
    </template>

    <template v-slot:item.statusSwitch="{ item }">
      <div class="d-flex align-center">
        <bc-time-status-chip :value="item.status_id" />
        <v-spacer />
        <Status-switch
          v-if="!isRecording(item)"
          :value="item.status_id"
          :item="item"
          type="time"
        />
      </div>
    </template>

    <template v-slot:item.comment_count="{ item }">
      <Bc-comment-badge
        :count="item.comment_count"
        @click.stop="openTime(item, true)"
      />
    </template>

    <template v-slot:no-data> Keine Zeiteinträge vorhanden. </template>
    <template v-slot:loading> Lade Zeiteinträge... </template>

    <!-- <template v-slot:footer>
      <slot
        name="footer"
        :page="page"
        :pagination-step="paginationStep"
        :page-count="pageCount"
      />
    </template> -->
  </v-data-table>
</template>
