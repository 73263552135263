import boo from '@/boo';
import { TimeBillingType } from '@/types/types';
import axios from 'axios';

export const bulkChangeTimeStatusOfTimebilling = async (
  timebillingId: TimeBillingType['id'],
  oldStatusId: number,
  newStatusId: number
) => {
  console.log(
    `Setting status of times in timebilling ${timebillingId} from ${oldStatusId} to ${newStatusId}`
  );
  const response = await axios.patch(
    `${boo.lboUrl()}timebillings/${timebillingId}/statustimebulk`,
    {
      old_status_id: oldStatusId,
      new_status_id: newStatusId,
    }
  );

  return response;
};

export const findTimebillingsRequest = async (params?: any) => {
  const response = await axios.get(`${boo.lboUrl()}timebillings/list`, {
    params,
  });
  return response;
};

export const findTimebillingRequest = async (id: TimeBillingType['id']) => {
  const response = await axios.get(`${boo.lboUrl()}timebillings/${id}`);
  return response;
};
