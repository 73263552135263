<template>
  <div>
    <v-divider />

    <v-app-bar :Xabsolute="header" elevate-on-scroll clipped-right width="100%">
      <v-btn
        data-cy="button-cancel"
        text
        :small="header && isMobile"
        @click="doCancel"
      >
        {{ cancelText }}
      </v-btn>

      <v-spacer />

      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />

      <v-btn
        v-if="show && canEdit"
        text
        class="primary"
        width="120"
        @click="doEdit"
      >
        Bearbeiten
      </v-btn>

      <v-btn
        data-cy="button-action"
        v-if="edit"
        :disabled="!valid || actionRunning || loading || !enableAction"
        :color="deleteBtn ? 'error' : 'boo'"
        class="float-right"
        :small="header && isMobile"
        :text="header && isMobile"
        @click="doAction"
      >
        {{ deleteBtn ? 'Löschen' : actionText }}
        <v-progress-circular
          v-if="actionRunning"
          indeterminate
          color="boo"
          size="20"
          width="3"
          class="ml-2"
        />
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'BcBtnCancelAction',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    actionRunning: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableAction: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: 'Abbrechen',
    },
    actionText: {
      type: String,
      default: 'Speichern',
    },
    deleteBtn: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    edit: function () {
      return !this.show;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },

  methods: {
    doEdit: function () {
      this.$emit('evEdit');
    },
    doAction: function () {
      this.$emit(this.deleteBtn ? 'evDelete' : 'evAction');
    },
    doCancel: function () {
      this.$emit('evCancel');
    },
  },
};
</script>
