<script lang="ts">
import boo from '@/boo';
import AcceptButton from '@/components/global/statusSwitch/acceptButton.vue';
import { TimeBillingType, TimeType } from '@/types/types';
import { defineComponent, PropType } from 'vue';
import { bulkChangeTimeStatusOfTimebilling } from './timebillingService';
import BcResponseHandler from '@/components/global/BcResponseHandler.vue';
import store from '@/store';
import BcBtnCancelAction from '@/components/global/BcBtnCancelAction.vue';

export default defineComponent({
  name: 'TimeBulkStatusChangeModal',
  props: {
    times: {
      type: Array as PropType<TimeType[]>,
      default: () => [],
    },
    timebillingId: {
      type: Number as PropType<TimeBillingType['id']>,
      required: true,
    },
    statusPermissionLevels: {
      type: Array,
      default: () => [],
    },
    onCompleted: {
      type: Function as PropType<(timebilling: TimeBillingType) => void>,
      default: () => {},
    },
    onRejected: {
      type: Function as PropType<(data?: any) => void>,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: [false, false, false],
      isLoading: false,
      axiosError: undefined,
    };
  },
  computed: {
    /*
    openTimes(): TimeType[] {
      return this.times.filter((time) => time.status_id === 0);
    },
*/
    capturedTimes(): TimeType[] {
      return this.times.filter((time) => time.status_id === 10);
    },
    checkedTimes(): TimeType[] {
      return this.times.filter((time) => time.status_id === 20);
    },
    bulkStatusActions() {
      return [
        // We do not (yet?) allow capturing multiple time entries
        // {
        //   status: 0,
        //   times: this.openTimes,
        // },
        {
          status: 10,
          times: this.capturedTimes,
        },
        {
          status: 20,
          times: this.checkedTimes,
        },
      ];
    },
  },
  methods: {
    acceptButtonProps(status: number) {
      return {
        label: boo.statusInfo('time', status)?.next?.label,
        icon: boo.statusInfo('time', status)?.next?.icon,
      };
    },
    bulkStatusDialogWording(status: number) {
      let label, text, action;

      switch (status) {
        case 0:
          label = 'offenen';
          text = 'erfasst';
          action = 'erfassen';
          break;
        case 10:
          label = 'erfassten';
          text = 'bestätigt';
          action = 'bestätigen';
          break;
        case 20:
          label = 'bestätigten';
          text = 'gesperrt';
          action = 'sperren';
          break;
        default:
          throw new Error('unknown status');
      }

      return { text, action, label };
    },

    async bulkChangeTimes(oldStatusId: number, newStatusId: number) {
      this.isLoading = true;

      try {
        const response = await bulkChangeTimeStatusOfTimebilling(
          this.timebillingId,
          oldStatusId,
          newStatusId
        );

        store.commit.timebillingStore.setTimebilling(response.data.success);
        store.commit.timesStore.setTimes(response.data.success.times);

        this.closeModal();
      } catch (e: any) {
        this.axiosError = e;
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    /*
    handleResults(results: PromiseSettledResult<AxiosResponse<any, any>>[]) {
      const rejected = results
        .filter((result) => result.status === 'rejected')
        .map((result: any) => result.reason.response.data);

      const fulfilled = results
        .filter((result) => result.status === 'fulfilled')
        .map((result: any) => result.value.data);

      return { rejected, fulfilled };
    },
*/

    closeModal() {
      this.showModal = this.showModal.map(() => false);
    },
    canChangeStatus(status: number) {
      if (status === 0 && this.statusPermissionLevels.includes(10)) return true;
      if (status === 10 && this.statusPermissionLevels.includes(20))
        return true;
      if (status === 20 && this.statusPermissionLevels.includes(30))
        return true;
      return false;
    },
  },
  components: { BcBtnCancelAction, AcceptButton, BcResponseHandler },
});
</script>

<template>
  <div>
    <v-list-item
      v-for="(action, key) in bulkStatusActions"
      v-if="action.times.length > 1 && canChangeStatus(action.status)"
      :key="key"
    >
      <v-list-item-title>
        {{ $boo.spellnumber(action.times.length, 'all') }}
        {{ bulkStatusDialogWording(action.status).label }}
        Zeiteinträge</v-list-item-title
      >

      <v-dialog v-model="showModal[key]" max-width="440" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small rounded v-bind="attrs" v-on="on" class="boo white--text">
            <v-icon small left>
              {{ acceptButtonProps(action.status).icon }}
            </v-icon>
            {{ acceptButtonProps(action.status).label }}
          </v-btn>
        </template>

        <v-card color="boo--text">
          <v-card-title
            >Zeiteinträge
            {{ bulkStatusDialogWording(action.status).action }}</v-card-title
          >
          <v-card-text>
            Sollen jetzt
            {{ $boo.spellnumber(action.times.length, 'all') }}
            erfassten Zeiteinträge
            {{ bulkStatusDialogWording(action.status).text }}
            werden? Diese Aktion kann nicht gesamtheitlich rückgängig gemacht
            werden.
          </v-card-text>
          <BcResponseHandler :axios-error="axiosError" />
          <bc-btn-cancel-action
            :loading="isLoading"
            enable-action
            :action-text="bulkStatusDialogWording(action.status).action"
            @evAction="bulkChangeTimes(action.status, action.status + 10)"
            @evCancel="closeModal"
          />
        </v-card>
      </v-dialog>
    </v-list-item>
  </div>
</template>
