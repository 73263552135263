<script lang="ts">
import store from '@/store';
import { ExternalDataSource, PersonType } from '@/types/types';
import { PropType, defineComponent } from 'vue';
import { removeExternalDataSourceRequest } from '../personService';
import { ExternalAction } from './helpers';
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';

type PropData = {
  sources: ExternalDataSource[];
  slug: PersonType['slug'];
  onChange: (person: PersonType) => void;
};

export default defineComponent({
  name: 'ShowExternalConnectionsDrawer',
  props: {
    data: {
      type: Object as PropType<PropData>,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      showDialogConnectionDelete: [...this.data.sources].reduce((acc, key) => {
        acc[key.id] = false;
        return acc;
      }, {} as any),
      connectionDeleting: false,
    };
  },
  computed: {
    addConnectionItems(): {
      title: string;
      icon: string;
      action: () => void;
    }[] {
      return [
        {
          title: 'Bitrix24',
          icon: 'mdi-plus',
          action: this.addBitrix24Connection,
        },
      ];
    },
  },
  methods: {
    async closeDrawer() {
      await store.dispatch.contentDrawer.close();
    },
    async removeConnection({
      dataSourceId,
    }: {
      dataSourceId: ExternalDataSource['id'];
    }) {
      try {
        const person = await removeExternalDataSourceRequest({
          dataSourceId,
          slug: this.data.slug,
        });
        this.data.onChange(person);
        this.closeDrawer();
      } catch (e) {
        console.log(e);
      }
    },
    addBitrix24Connection(): any {
      store.commit.contentDrawer.LOAD({
        component: 'EditPersonExternalDataSources',
        data: {
          type: 'bitrix24',
          action: ExternalAction.add,
          personSlug: this.data.slug,
          onChange: this.data.onChange,
        },
      });
    },
  },
  components: { CopyToClipboardButton },
});
</script>

<template>
  <bc-content-drawer-frame>
    <template v-slot:headerActionLeft>
      <v-btn @click="closeDrawer" text color="boo">
        <v-icon left large color="boo">{{ 'mdi-chevron-left' }}</v-icon>
        Zurück
      </v-btn>
    </template>
    <v-card flat color="transparent">
      <v-card-title>Verknüpfungen</v-card-title>
      <v-card-text v-if="data.sources.length === 0">
        Diese Person hat noch keine Verknüpfungen.
      </v-card-text>
      <v-list-item
        v-for="(item, key) in addConnectionItems"
        :key="`add-con-${key}`"
      >
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn @click="item.action">
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <v-list v-if="data.sources.length > 0">
      <v-divider />
      <v-list-item v-for="(source, key) in data.sources" :key="key">
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            {{ source.name }}
            <CopyToClipboardButton :text-to-copy="source.url" />
            <v-btn small :href="source.url" target="_blank" icon>
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn @click="() => (showDialogConnectionDelete[source.id] = true)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>

        <bc-dialog-delete
          :show-dialog="showDialogConnectionDelete[source.id]"
          :title="`Connection ${source.name} Löschen?`"
          :deleting="connectionDeleting"
          @evDelete="
            () =>
              removeConnection({
                dataSourceId: source.id,
              })
          "
          @evCancel="showDialogConnectionDelete[source.id] = false"
        >
          Soll diese Verknüpfung <b>endgültig gelöscht</b> werden?
        </bc-dialog-delete>
      </v-list-item>
    </v-list>
  </bc-content-drawer-frame>
</template>
