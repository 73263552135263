<template>
  <v-menu
    v-model="showRangePicker"
    offset-y
    max-width="290px"
    :close-on-content-click="false"
    :close-on-click="false"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style=""
        outlined
        :value="rangeTitle"
        label="Zeitraum"
        readonly
        v-bind="attrs"
        hide-details
        x:dense="isMobile"
        x:disabled="monthOnly"
        x:clearable="!monthOnly"
        clearable
        @click:clear="clearSelection"
        v-on="on"
        class="ma-2 grey--text"
      >
        <template v-if="showPrevNextButtons" v-slot:prepend-inner>
          <v-icon @click="prevRange"> mdi-chevron-double-left </v-icon>
        </template>
        <template v-if="showPrevNextButtons" v-slot:append>
          <v-icon @click="nextRange"> mdi-chevron-double-right </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-date-picker
        v-model="range"
        :type="monthOnly ? 'month' : 'date'"
        no-title
        :range="monthOnly ? true : true"
        locale-first-day-of-year="4"
        show-week
        first-day-of-week="1"
        @input="returnValue"
      />
      <v-container v-if="!monthOnly">
        <div>
          <v-chip class="ma-1" @click.stop="setDate('yesterday')">
            Gestern
          </v-chip>
          <v-chip class="ma-1" @click="setDate('today')"> Heute </v-chip>
          <v-chip class="ma-1" @click="setDate('lastWeek')">
            Letzte Woche
          </v-chip>
          <v-chip class="ma-1" @click="setDate('thisWeek')">
            Diese Woche
          </v-chip>
          <v-chip class="ma-1" @click="setDate('lastMonth')">
            Letzter Monat
          </v-chip>
          <v-chip class="ma-1" @click="setDate('thisMonth')">
            Dieser Monat
          </v-chip>
          <v-chip class="ma-1" @click="setDate('lastYear')">
            Letztes Jahr
          </v-chip>
          <v-chip class="ma-1" @click="setDate('thisYear')">
            Dieses Jahr
          </v-chip>
          <v-chip class="ma-1" @click="setDate('')"> Gesamter Zeitraum </v-chip>
        </div>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    monthOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      range: [],
      showRangePicker: false,
      rangeType: '',
      rangeTitle: '',
    };
  },
  computed: {
    showPrevNextButtons() {
      return !(this.rangeType === 'custom' || this.rangeType === '');
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },
  methods: {
    returnValue() {
      this.$emit('input', this.range);
    },
    analyzeRange() {
      if (!this.range) return false;
      if (this.range.length !== 2) return false;
      if (this.range[1] < this.range[0]) return false;

      if (this.rangeType) return true;
      this.rangeType = 'custom';

      const from = this.range[0];
      const to = this.range[1];

      if (to === from) {
        this.rangeType = 'day';
        return true;
      }
      if (
        from === this.$dayjs(from).startOf('week').format('YYYY-MM-DD') &&
        to === this.$dayjs(from).endOf('week').format('YYYY-MM-DD')
      ) {
        this.rangeType = 'week';
        return true;
      }
      if (
        from === this.$dayjs(from).startOf('month').format('YYYY-MM-DD') &&
        to === this.$dayjs(from).endOf('month').format('YYYY-MM-DD')
      ) {
        this.rangeType = 'month';
        return true;
      }
      // this.rangeType = 'custom'
      return true;
    },
    setDate(range) {
      switch (range) {
        case 'today':
          this.rangeType = 'day';
          this.range = [
            this.$dayjs().startOf('day').format('YYYY-MM-DD'),
            this.$dayjs().endOf('day').format('YYYY-MM-DD'),
          ];
          break;
        case 'yesterday':
          this.rangeType = 'day';
          this.range = [
            this.$dayjs()
              .subtract(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.$dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
          ];
          break;
        case 'thisWeek':
          this.rangeType = 'week';
          this.range = [
            this.$dayjs().startOf('week').format('YYYY-MM-DD'),
            this.$dayjs().endOf('week').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastWeek':
          this.rangeType = 'week';
          this.range = [
            this.$dayjs()
              .subtract(1, 'week')
              .startOf('week')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'week')
              .endOf('week')
              .format('YYYY-MM-DD'),
          ];
          break;
        case 'thisMonth':
          this.rangeType = 'month';
          this.range = [
            this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            this.$dayjs().endOf('month').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastMonth':
          this.rangeType = 'month';
          this.range = [
            this.$dayjs()
              .subtract(1, 'month')
              .startOf('month')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'month')
              .endOf('month')
              .format('YYYY-MM-DD'),
          ];
          break;
        case 'thisYear':
          this.rangeType = 'year';
          this.range = [
            this.$dayjs().startOf('year').format('YYYY-MM-DD'),
            this.$dayjs().endOf('year').format('YYYY-MM-DD'),
          ];
          break;
        case 'lastYear':
          this.rangeType = 'year';
          this.range = [
            this.$dayjs()
              .subtract(1, 'year')
              .startOf('year')
              .format('YYYY-MM-DD'),
            this.$dayjs()
              .subtract(1, 'year')
              .endOf('year')
              .format('YYYY-MM-DD'),
          ];
          break;
        case '':
          this.rangeType = '';
          this.range = [];
          break;
        case 'custom':
          this.rangeType = 'custom';
          break;
        default:
          alert('TimeFilter > unknown range "' + range + '"');
      }
      this.rangeTitle = this.dateRangeText();
      this.showRangePicker = false;
      this.returnValue();
    },
    dateRangeText() {
      switch (this.rangeType) {
        case 'day':
          return this.$dayjs(this.range[0]).format('ddd, D. MMMM YYYY');
        case 'week':
          return (
            'KW ' +
            this.$dayjs(this.range[0]).week() +
            ' (' +
            this.smartDateRangeText() +
            ')'
          );
        case 'month':
          return this.$dayjs(this.range[0]).format('MMMM YYYY');
        case 'year':
          return this.$dayjs(this.range[0]).format('YYYY');
        case 'custom':
          return this.smartDateRangeText();
        case 'all':
          return 'alle Daten';
        default:
      }
    },
    // deleteFilter () {
    //   if (this.showDeleteFilter) {
    //     // this.setDate('') // reset Date-Range
    //     this.projectId = null
    //     this.personId = null
    //     this.statusId = null
    //   }
    // },
    smartDateRangeText() {
      const from = this.$dayjs(this.range[0]).utc(true).startOf('day');
      const to = this.$dayjs(this.range[1]).utc(true).endOf('day');

      if (!from.isValid || !to.isValid) return '';

      // Bereich
      let ret = from.format('D.');
      if (from.month() !== to.month() || from.year() !== to.year()) {
        ret += from.format(' MMM. ');
      }
      if (from.year() !== to.year()) ret += from.format('YYYY');
      ret += ' - ';
      ret += to.format('D.');
      ret += to.format(' MMM. ');
      ret += to.format('YYYY');

      return ret;
    },
    prevRange() {
      let prevRange = [];
      prevRange[0] = this.$dayjs(this.range[0])
        .subtract(1, this.rangeType)
        .format('YYYY-MM-DD');
      if (this.rangeType == 'month') {
        // Monatsende muss berechnet werden (28-31)
        prevRange[1] = this.$dayjs(this.range[1])
          .subtract(1, this.rangeType)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        prevRange[1] = this.$dayjs(this.range[1])
          .subtract(1, this.rangeType)
          .format('YYYY-MM-DD');
      }
      this.range = prevRange;
      this.rangeTitle = this.dateRangeText();
      this.returnValue();
      // this.$emit('input', this.range)
    },
    nextRange() {
      let nextRange = [];
      nextRange[0] = this.$dayjs(this.range[0])
        .add(1, this.rangeType)
        .format('YYYY-MM-DD');
      if (this.rangeType == 'month') {
        // Monatsende muss berechnet werden (28-31)
        nextRange[1] = this.$dayjs(this.range[1])
          .add(1, this.rangeType)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        nextRange[1] = this.$dayjs(this.range[1])
          .add(1, this.rangeType)
          .format('YYYY-MM-DD');
      }
      this.range = nextRange;
      this.rangeTitle = this.dateRangeText();
      this.returnValue();
    },
    clearSelection() {
      this.range = [];
      this.returnValue();
    },
  },
  watch: {
    range() {
      if (this.range[1] < this.range[0]) {
        // wenn to < from
        this.range[0] = this.range[1]; // from setzen
        this.range.pop(); // to löschen
      }
      if (this.analyzeRange()) {
        this.rangeTitle = this.dateRangeText();
        this.returnValue();
        this.showRangePicker = false;
        return;
      }
      this.rangeType = '';
      this.rangeTitle =
        this.range.length === 1
          ? this.$dayjs(this.range[0]).format('DD.MM.YYYY') + ' bis ...'
          : '';
    },
  },
  created() {
    this.range = [...this.value];
    this.returnValue();
  },
};
</script>

<style></style>
