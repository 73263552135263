<template>
  <v-layout justify-center class="pt-5">
    <v-card width="360" class="mt-5 mx-a" flat color="transparent">
      <v-card-title v-if="!loggedIn">
        <v-img src="/img/boo-logo.png" class="mb-10" contain height="90" />
      </v-card-title>

      <v-stepper v-model="pwResetStep">
        <v-stepper-items color="transparent">
          <v-stepper-content class="pa-0" step="1">
            <v-card class="pa-5" v-if="canChangePassword">
              <v-card-text class="text-center boo--text title"
                >Einladung annehmen</v-card-text
              >
              <v-card-text>
                <v-form
                  v-if="!done && !loading && !different && !wrong"
                  ref="form"
                  lazy-validation
                  class="my-2"
                  @keyup.native.enter="submit"
                >
                  <v-text-field
                    v-if="true"
                    v-model="email"
                    autocomplete="username"
                    label="E-Mail-Adresse"
                    disabled
                  />

                  <v-text-field
                    v-if="loggedIn"
                    v-model="password_current"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    label="bestehendes Passwort"
                    autocomplete="current-password"
                    :append-icon="
                      showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="PasswordRules"
                    @click:append="showCurrentPassword = !showCurrentPassword"
                  />

                  <div class="mt-2">Neues Passwort:</div>
                  <password-strength-meter
                    v-model="password"
                    :rules="PasswordRules"
                    :strength-meter-only="false"
                    :secure-length="8"
                    :toggle="true"
                    :user-inputs="notAllowedPasswords"
                    label-show="Passwort anzeigen"
                    label-hide="Passwort ausblenden"
                    autocomplete="new-password"
                    @show="showNewPW"
                    @hide="hideNewPW"
                  />

                  <v-alert
                    class="info--text caption"
                    type="info"
                    text
                    dismissible
                    :icon="false"
                  >
                    <p v-if="!validPassword" class="font-weight-bold">
                      {{ passwordSpecification }}
                    </p>
                    Für ein gutes Passwort verwende
                    <ul>
                      <li>Groß- und Kleinbuchstaben sowie Ziffern</li>
                      <li>mindestens ein Symbol (# $ ! % & etc...)</li>
                      <li>keine Wörter aus dem Wörterbuch</li>
                    </ul>
                  </v-alert>

                  {{ showNewPassword ? '' : 'Neues Passwort wiederholen' }}
                  <password-strength-meter
                    v-model="password_confirmation"
                    :disabled="showNewPassword"
                    :badge="false"
                    :show-strength-meter="false"
                    autocomplete="new-password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="!done" text class="caption" color="grey" to="/">
                  Abbrechen
                </v-btn>
                <v-spacer />
                <v-btn
                  v-if="!loading && !done && !different && !wrong"
                  color="storing? grey : warning"
                  :disabled="storing || !validPassword"
                  @click="submit"
                >
                  neues Passwort setzen
                  <v-progress-circular
                    v-if="storing"
                    color="boo"
                    indeterminate
                    size="20"
                    width="3"
                    class="ml-2"
                  />
                </v-btn>
                <v-btn v-if="done" text class="caption" color="grey" to="/">
                  ok
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card class="pa-5" v-else-if="different">
              <v-card-text>
                Dieser Einladungs-Link ist für einen anderen User, als derjenige
                welcher aktuell angemeldet ist! Falls dieser Link trotzdem in
                diesem Browser verwendet werden soll, musst du dich vorher
                abmelden.
              </v-card-text>
              <v-card-actions>
                <v-btn block class="boo" @click="logout">Abmelden</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content class="pa-0" step="2">
            <v-card class="pa-5" flat>
              <v-card-title class="justify-center"
                ><v-icon x-large>mdi-account-key</v-icon></v-card-title
              >
              <v-card-text class="text-center boo--text title">
                Das Passwort wurde erfolgreich gesetzt.
              </v-card-text>

              <v-card-text v-if="!loggedIn"
                >Du kannst dich nun mit deinem Passwort anmelden.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="loggedIn"
                  @click="$router.push({ name: 'home' })"
                  class="boo"
                  block
                  >Zum Dashboard</v-btn
                >
                <v-btn
                  v-if="!loggedIn"
                  @click="$router.push({ name: 'login' })"
                  class="boo"
                  block
                  >Zum Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="3">
            <v-card class="pa-5" flat>
              <v-card-title class="justify-center"
                ><v-icon x-large>mdi-alert</v-icon></v-card-title
              >
              <v-card-text class="text-center boo--text title">
                {{ alertText }}
              </v-card-text>
              <v-card-text
                >Klicke auf die nachfolgende Schaltfläche, um einen neuen Link
                an
                <span
                  @click="
                    $router.push({
                      name: 'password_forgot',
                      query: { email: email },
                    })
                  "
                  >{{ email
                  }}<v-icon class="mx-1" x-small>mdi-pencil</v-icon></span
                >
                zu senden:</v-card-text
              >
              <v-card-actions>
                <v-btn @click="requestNewLink" block class="boo"
                  >Neuen Link anfordern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="4">
            <v-card class="pa-5" flat>
              <v-card-title class="justify-center"
                ><v-icon x-large>mdi-email-check-outline</v-icon></v-card-title
              >
              <v-card-text class="text-center boo--text title"
                >Wir haben dir eine E-Mail an
                {{ email }} geschickt!</v-card-text
              >
              <v-card-text>
                Bitte überprüfe deinen Posteingang und folge den Anweisungen in
                der E-Mail.
              </v-card-text>
              <v-card-subtitle>Keine E-Mail erhalten?</v-card-subtitle>
              <v-card-text>
                <ol>
                  <li v-for="(item, n) in instructions" :key="n">
                    {{ item }}
                  </li>
                </ol>
              </v-card-text>
            </v-card></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>

      <!-- <v-card width="360" class="" :loading="loading">
        <v-card-subtitle class="headline text-center">
          Passwort neu setzen
        </v-card-subtitle>
        <v-card-text>
          <p v-if="loading && !loggedIn">
            Der Link zum Zurücksetzen des Passwortes wird geprüft...
          </p>
          <p v-if="!loading && !done && !different && !wrong">
            {{ this.$boo.capitalizeFirstLetter(username) }}, bitte
            {{
              loggedIn
                ? 'aus Sicherheitsgründen zuerst das bestehende und dann '
                : ''
            }}
            das neue Passwort (mind. acht Zeichen lang) eingeben und bestätigen.
          </p>
          <p v-if="!loading && different" class="error--text">
            Dieser Passwort-Link ist für einen anderen User, als derjenige
            welcher aktuell angemeldet ist!
          </p>
          <p v-if="!loading && different" class="">
            Falls dieser Link trotzdem in diesem Browser verwendet werden soll,
            musst du dich vorher abmelden.
          </p>
          <v-form
            v-if="!done && !loading && !different && !wrong"
            ref="form"
            lazy-validation
            class="my-2"
            @keyup.native.enter="submit"
          >
            <v-text-field
              v-if="true"
              v-model="email"
              autocomplete="username"
              label="E-Mail-Adresse"
              disabled
            />

            <v-text-field
              v-if="loggedIn"
              v-model="password_current"
              :type="showCurrentPassword ? 'text' : 'password'"
              label="bestehendes Passwort"
              dense
              autocomplete="current-password"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="PasswordRules"
              @click:append="showCurrentPassword = !showCurrentPassword"
            />

            <div class="mt-2">Neues Passwort:</div>
            <password-strength-meter
              v-model="password"
              :rules="PasswordRules"
              :strength-meter-only="false"
              :secure-length="8"
              :toggle="true"
              :user-inputs="notAllowedPasswords"
              label-show="Passwort anzeigen"
              label-hide="Passwort ausblenden"
              autocomplete="new-password"
              @show="showNewPW"
              @hide="hideNewPW"
            />

            <v-alert
              class="info--text caption"
              type="info"
              text
              dismissible
              :icon="false"
            >
              <p v-if="!validPassword" class="font-weight-bold">
                {{ passwordSpecification }}
              </p>
              Für ein gutes Passwort verwende
              <ul>
                <li>Groß- und Kleinbuchstaben sowie Ziffern</li>
                <li>mindestens ein Symbol (# $ ! % & etc...)</li>
                <li>keine Wörter aus dem Wörterbuch</li>
              </ul>
            </v-alert>

            {{ showNewPassword ? '' : 'Neues Passwort wiederholen' }}
            <password-strength-meter
              v-model="password_confirmation"
              :disabled="showNewPassword"
              :badge="false"
              :show-strength-meter="false"
              autocomplete="new-password"
            />
          </v-form>

          <bc-response-handler
            :axios-success="axiosSuccess"
            :display-success="true"
            :axios-error="axiosError"
            :warn-on-error="true"
            :local-warning="alertText"
            :alert-type="alertType"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn v-if="!done" text class="caption" color="grey" to="/">
            Abbrechen
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!loading && !done && !different && !wrong"
            color="storing? grey : warning"
            :disabled="storing || !validPassword"
            @click="submit"
          >
            neues Passwort setzen
            <v-progress-circular
              v-if="storing"
              color="boo"
              indeterminate
              size="20"
              width="3"
              class="ml-2"
            />
          </v-btn>
          <v-btn v-if="done" text class="caption" color="grey" to="/">
            ok
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </v-card>

    <!-- </v-dialog> -->
  </v-layout>
</template>

<script>
import PasswordStrengthMeter from 'vue-password-strength-meter';

export default {
  name: 'PasswordReset',

  components: { PasswordStrengthMeter },

  data() {
    return {
      pwResetStep: 1,
      email: '',
      token: '',
      password_current: '',
      password: '',
      password_confirmation: '',
      validPassword: false,
      passwordSpecification: '',

      username: '',

      showCurrentPassword: false,
      showNewPassword: false,

      PasswordRules: [(v) => v !== '' || 'Eingabe erforderlich'],
      notAllowedPasswords: [],

      // different: false,
      wrong: false,
      done: false,

      loading: false,
      storing: false,
      alertType: 'info',
      alertText: '',

      axiosSuccess: null,
      axiosError: null,
      instructions: [
        'Überprüfe deinen Spam Ordner',
        'Überprüfe deine Angaben',
        'Warte 10 Minuten',
      ],
    };
  },
  computed: {
    canChangePassword() {
      if (this.different) return false;
      if (this.loggedIn) return true;
      if (!this.token) return false;
      if (this.wrong) return false;

      return true;
    },
    loggedIn() {
      return this.$boo.userLoggedIn();
    },
    different() {
      if (!this.loggedIn) return false;
      return this.$route.query.email && this.$route.query.email !== this.email;
    },
  },

  watch: {
    password: function () {
      if (this.showNewPassword) {
        this.password_confirmation = this.password;
      }
      this.validPassword = this.password.length >= 8;
      this.passwordSpecification = 'Das Passwort muss ';
      if (this.password.length < 8) {
        this.passwordSpecification += ' mindestens acht Zeichen lang sein';
      }
    },
  },

  created() {
    if (this.loggedIn) {
      // user angemeldet, will pw ändern
      this.email = this.$store.state.auth.user.email;
      this.username = this.$store.state.auth.user.slug;

      // Reset-Link für anderen User!
      // this.different =
      //   this.$route.query.email && this.$route.query.email !== this.email
    } else {
      // nicht angemeldet, will pw neu setzen
      if (!this.$route.query.email || !this.$route.query.token) {
        this.$router.push('/');
        return;
      }

      this.email = this.$route.query.email;
      this.token = this.$route.query.token;
      this.notAllowedPasswords.push(this.email);

      this.loading = true;
      window.axios
        .get(`${this.$BC.LBO_URL}/api/auth/resetPW`, {
          params: this.$route.query,
        })
        .then(({ data }) => {
          this.username = data.username;
        })
        .catch((error) => {
          this.wrong = true;
          if (error.response.status === 401) {
            this.alertType = 'warning';
            this.alertText =
              'Dieser Einladungs-Link ist ungültig oder abgelaufen.';
            this.pwResetStep = 3;
          } else {
            this.axiosError = error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    showNewPW() {
      this.showNewPassword = true;
      this.password_confirmation = this.password;
    },
    hideNewPW() {
      this.showNewPassword = false;
      this.password_confirmation = '';
    },

    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.alertText = '';
      this.storing = true;
      window.axios
        .post(
          `${this.$BC.LBO_URL}/api/auth/${
            this.loggedIn ? 'changePW' : 'resetPW'
          }`,
          {
            email: this.email,
            token: this.token,
            password_current: this.password_current,
            password: this.password,
            password_confirmation: this.password_confirmation,
          }
        )
        .then((response) => {
          this.axiosSuccess = response;
          this.pwResetStep = 2;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    requestNewLink() {
      window.axios
        .post(this.$BC.LBO_URL + '/api/auth/forgotPW', { email: this.email })
        .then((response) => {
          this.axiosSuccess = response;
          this.pwResetStep = 4;
        })
        .catch((error) => {
          this.axiosError = error;
          this.status = 'warning';
        });
    },
    logout() {
      this.$cookies.remove('user'); // Cookies löschen
      this.$store.dispatch(this.$storenames.actions.reset); // reset whole store instead...
      this.$router.push(this.$route);
      location.reload();
      this.axiosError = null;
    },
  },
};
</script>
