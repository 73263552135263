<template>
  <div>
    <v-btn
      data-cy="dispute-button"
      v-if="isMine"
      :disabled="disabled"
      :loading="loading"
      @click.stop="$emit('click')"
      class="ma-1"
      small
      rounded
    >
      <v-icon small left>mdi-progress-question</v-icon> {{ label }}
    </v-btn>

    <v-dialog v-else v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-cy="dispute-button"
          :loading="loading"
          class="ma-1"
          small
          rounded
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small left>mdi-progress-question</v-icon> {{ label }}
        </v-btn>
      </template>
      <v-card class="cdIncluded">
        <v-card-title class="boo--text headline">{{ label }}</v-card-title>
        <v-card-text>
          Bitte schreibe eine kurze Erklärung, weshalb du den Status ablehnst
          (dein Kommentar kann von anderen NutzerInnen gesehen werden).
        </v-card-text>
        <v-card-text>
          <v-textarea v-model="explanation" outlined rows="3" />
        </v-card-text>
        <bc-btn-cancel-action
          :valid="valid"
          action-text="Senden"
          :action-running="storing"
          @evAction="disputeStatus()"
          @evCancel="close()"
        />

        <!-- <v-btn @click="dialog = !dialog" outlined>Abbrechen</v-btn>
                <v-spacer/>
                <v-btn :loading="loading" @click.stop="$emit('click')" class="ma-1 primary">
                    <v-icon small left>mdi-progress-question</v-icon> {{ label }}
                </v-btn> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      storing: false,
      valid: true,
      explanation: null,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'ablehnen',
    },
    isMine: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    disputeStatus() {
      if (this.explanation) {
        this.storing = true;

        let transfer = {
          text: this.explanation,
          commentable_type: this.item.commentable_type,
          commentable_id: this.item.id,
          visibility: 2,
          status_id: 20,
        };

        window
          .axios({
            method: 'post',
            url: `${this.$boo.lboUrl()}comment`,
            data: transfer,
          })
          .then((response) => {
            this.axiosSuccess = response;
          })
          .catch((error) => {
            this.axiosError = error;
          })
          .finally(() => {
            this.explanation = null;
            this.storing = false;
          });
      }
      this.$emit('click');
      this.close();
    },
    close() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
