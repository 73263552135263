<template>
  <div>
    <bc-notification-text :data="notification.text" />
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
