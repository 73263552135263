type ContentDrawerStateType = {
  component: string;
  locked: boolean;
  visible: boolean;
  data: any;
};

// State object
const initialState = () => ({
  component: '', // component to be used
  locked: false, // lock backgroud
  visible: false, // show drawer
  data: null, // was zur Komponente durchgereicht wird
});

const state: ContentDrawerStateType = initialState();

const getters = {};

const actions = {
  reset({ commit }: any) {
    commit('RESET');
  },
  close({ commit }: any) {
    commit('HIDE');
    // etwas zeitversetzt, damit vue vor destroy der Komponente alles abarbeiten kann (z.B. apiData verarbeiten)
    setTimeout(() => commit('DESTROY'), 100); //TODO können wir den Timeout rausnehmen?
  },
};

const mutations = {
  RESET(state: any) {
    const newState = initialState();
    // @ts-ignore
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },

  // usage:
  // this.$store.commit('contentDrawer/LOAD', {
  //   component: 'bc-XXX',
  //   data: DATAXXX
  // })
  LOAD(state: any, content: any) {
    state.component = content.component;
    state.data = content.data;
    state.visible = true; // @@@ kann man das nicht automatisch bei comp-init passieren lassen?
  },
  // open without data
  OPEN(state: any, component: any) {
    state.component = component;
    state.visible = true; // @@@ s.o.
  },
  // neccessary for bg-click
  SHOW(state: any) {
    state.visible = true;
  },
  // kann im Hintergrund noch fertigrechnen...
  HIDE(state: any) {
    state.visible = false;
    state.locked = false;
  },
  // ... später erst zerstören
  DESTROY(state: any) {
    state.data = null;
    state.component = '';
  },
  // lock background
  LOCK(state: any, data: any) {
    state.locked = data || false;
  },
  UNLOCK(state: any) {
    state.locked = false;
  },
};

export default {
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
