<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        :color="$boo.domainTypeData(domain.type).color"
        v-on="on"
      >
        {{ $boo.domainTypeData(domain.type).icon }}
      </v-icon>
    </template>
    <span>{{ $boo.domainTypeData(domain.type).text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'DomainIcon',

  props: {
    domain: {
      type: Object,
      default: null,
    },
  },
};
</script>
