<template>
  <div>
    <v-btn
      data-cy="open-feedback-form-button"
      block
      width="150px"
      color="boo"
      dark
      @click.stop="show = true"
    >
      <v-icon color="white" left> mdi-comment-quote-outline </v-icon> Feedback
      senden
    </v-btn>
    <v-dialog
      id="capture"
      v-model="show"
      :persistent="!!feedback.type"
      width="600px"
      x:fullscreen="isMobile"
    >
      <v-card data-cy="feedback-form-dialog">
        <v-card-title class="boo--text headline">
          Feedback Formular
        </v-card-title>
        <v-card-text>
          Wir freuen uns, von deinen Gedanken, Anliegen oder Problemen zu hören!
          Dein Feedback ist wichtig, denn nur so können wir unsere App laufend
          verbessern.
        </v-card-text>
        <v-card-title class="body-1">
          Welche Art von Feedback möchtest du uns übermitteln?
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-select
              data-cy="feedback-type-select"
              v-model="feedback.type"
              outlined
              label="Bitte auswählen"
              :items="possibleFeedbackTypes"
              :rules="[(v) => v !== null || 'Eingabe erforderlich']"
            />
            {{ feedbackMessageLabel.message }}
          </v-card-text>
          <v-card-text>
            <v-textarea
              data-cy="feedback-text-box"
              v-if="feedback.type"
              v-model="feedback.message"
              maxlength="2048"
              counter
              outlined
              :label="feedbackMessageLabel.label"
              :rules="[
                (v) => v !== '' || 'Eingabe erforderlich',
                (v) => (v && v.length >= 2) || 'mindestens 2 Zeichen',
                (v) => (v && v.length <= 2048) || 'maximal 2048 Zeichen',
              ]"
            />
          </v-card-text>
        </v-form>

        <bc-response-handler
          :axios-error="axiosError"
          :axios-success="axiosSuccess"
        />
        <bc-btn-cancel-action
          :valid="valid"
          action-text="Senden"
          :action-running="storing"
          @evAction="sendFeedback()"
          @evCancel="close()"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  // props: {
  //   UserLoggedIn: {
  //     type: Boolean,
  //     default: false
  //   }
  // },

  data() {
    return {
      show: false,
      valid: false,
      storing: false,
      axiosError: null,
      axiosSuccess: null,

      possibleFeedbackTypes: [
        'Problem melden',
        'Feature Wunsch',
        'Lob',
        'Sonstiges',
      ],
      feedback: {
        type: null,
        message: null,
        version: this.$BC.VERSION,
        environment: this.$BC.ENV,
        url: this.$route.fullPath,
        // vue_store: JSON.stringify(this.$store.state) // Disabled because of error - store too big: BOO-625
        vue_store: JSON.stringify({
          app: this.$store.state.app,
          auth: this.$store.state.auth,
        }),
        // vue_store: 'Store not attached.',
      },
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    feedbackMessageLabel() {
      switch (this.feedback.type) {
        case 'Problem melden':
          return {
            message:
              'Bitte beschreibe hier dein Problem so ausführlich wie möglich. Je detaillierter deine Beschreibung, desto einfacher können wir das Problem finden und beheben.',
            label: 'Deine detaillierte Problembeschreibung',
          };
        case 'Feature Wunsch':
          return {
            message:
              'Was wünscht du dir von boo? Deiner Vorstellungskraft sind keine Grenzen gesetzt!',
            label: 'Dein Wunsch',
          };
        case 'Sonstiges':
          return {
            message:
              'Was möchtest du uns gerne mitteilen? Schreib es dir von der Seele!',
            label: 'Deine Nachricht',
          };
        case 'Lob':
          return {
            message:
              'Es freut uns sehr, dass dir unsere App gefällt! Erzähl uns doch gerne was dir besonders gut an boo gefällt - damit wir weiterhin in die richtige Richtung entwickeln.',
            label: 'Deine Nachricht',
          };
      }
      return '';
    },
  },

  mounted() {
    // this.printScreen()
  },

  methods: {
    printScreen() {
      // Soll später mal einen screenshot machen
    },
    sendFeedback() {
      this.storing = true;
      if (!this.$refs.form.validate()) return;
      window
        .axios({
          method: 'post',
          url: this.$BC.LBO_API_URL + 'feedback',
          data: this.feedback,
        })
        .then((response) => {
          this.axiosSuccess = response;
          this.close();
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    close() {
      this.$refs.form.reset();
      this.storing = false;
      this.show = !this.show;
    },
  },
};
</script>
