<template>
  <div>
    <v-skeleton-loader v-if="loading" type="avatar" />
    <v-menu
      v-else
      open-on-click
      :open-on-hover="!ownsPojo"
      open-delay="500"
      :close-on-content-click="false"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <bc-avatar v-if="!ownsPojo" :pojo="owner.user" :size="32" />
          <v-avatar v-else id="pojoOwner" color="grey" size="32">
            <span class="alabaster--text h3">{{ avatarAbbr }}</span>
          </v-avatar>
        </div>
      </template>

      <v-card>
        <v-card-subtitle>
          {{ ownsPojo ? 'in deiner Zuständigkeit' : 'Zuständigkeit' }}
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-list v-if="!ownsPojo && !loading">
            <v-list-item>
              <v-list-item-avatar>
                <bc-avatar :pojo="owner.user" :size="32" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ pojo.owner.username }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  pojo.owner.label
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions v-if="Object.keys(alternativeUsers).length">
          <v-spacer />
          <v-btn text color="primary" @click="showDialog = true">
            {{ ownsPojo ? 'Zuständigkeit übertragen' : 'Zuständigkeit ändern' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-dialog v-model="showDialog" max-width="360" retain-focus>
      <v-card>
        <v-card-title color="boo">
          <v-icon large left class="mr-5"> mdi-account-switch </v-icon>
          {{ ownsPojo ? 'Zuständigkeit übertragen' : 'Zuständigkeit ändern' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-3">
          <v-form
            ref="form"
            v-model="valid"
            class="px-3"
            lazy-validation
            @keyup.native.enter="submit"
            @keyup.native.escape="cancel"
          >
            <p>
              von derzeit
              <span :title="pojo.owner.label" class="font-weight-bold">
                {{ pojo.owner.username }}
              </span>
              auf zukünftig
            </p>
            <v-select
              v-model="newOwner"
              label="Benutzer wählen"
              :items="alternativeUsers"
              item-text="label"
              item-value="id"
              :rules="[(v) => v !== '' || 'Eingabe erforderlich']"
              required
            />
          </v-form>
          <p class="warning--text" v-html="corollary" />
        </v-card-text>

        <bc-response-handler
          :axios-error="axiosError"
          :axios-success="axiosSuccess"
        />

        <bc-btn-cancel-action
          :valid="valid"
          :enable-action="newOwner != null"
          action-text="übertragen"
          :action-running="storing"
          @evAction="submit"
          @evCancel="cancel"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'BcPojoOwner',

  props: {
    pojo: { type: Object, default: null },
    pojotype: {
      type: String,
      default: '',
      validator: function (value) {
        // The value must match one of these strings
        return ['person', 'project', 'domain', 'time'].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {
      loading: true,

      owner: {},

      alternativeUsers: [],
      newOwner: null,
      storing: false,

      showDialog: false,
      valid: true, // @@@ brauch ich nicht, oder?

      axiosSuccess: null,
      axiosError: null,
    };
  },

  computed: {
    avatarAbbr() {
      if (!this.owner.user) return '';
      return this.owner.user.username.substring(0, 1).toUpperCase();
    },
    ownsPojo() {
      return this.$me.owns(this.pojo);
    },
    canChangeOwner() {
      return (
        (this.ownsPojo &&
          this.$store.getters[this.$storenames.auth.getters.userCan](
            this.pojotype + '.update'
          )) ||
        this.$store.getters[this.$storenames.auth.getters.userCan](
          this.pojotype + '.update.all'
        )
      );
    },
    corollary() {
      if (!this.owner.user) return '';
      var text;
      if (this.owner.can(this.pojotype + '.update.all')) return '';

      text = this.ownsPojo
        ? 'Du wirst '
        : '<span title="' +
          this.owner.user.label +
          '">' +
          this.owner.user.username +
          '<span> wird ';
      text +=
        this.pojoTypeLabel + ' ' + this.pojo.label + ' in Folge nicht mehr ';
      text += this.owner.can(this.pojotype + '.show.all')
        ? 'bearbeiten'
        : 'sehen';
      text += ' können!';
      return text;
    },
    pojoTypeLabel() {
      switch (this.pojotype) {
        case 'person':
          return 'den Personeneintrag';
        case 'project':
          return 'das Projekt';
        case 'domain':
          return 'die Späre';
        case 'time':
          return 'den Zeiteintrag';
        default:
          return '<' + this.pojotype + '>';
      }
    },
  },

  watch: {
    pojo() {
      this.loadData();
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      // if (!this.canChangeOwner) return
      this.loading = true;
      window.axios
        .get(
          this.$boo.lboUrl() +
            this.pluralize(this.pojotype) +
            '/' +
            (this.pojo.slug || this.pojo.id) +
            '/owner'
        )
        .then((response) => {
          this.axiosSuccess = response;

          this.owner = new this.$boo.Owner(
            this.$store.state.app.domain,
            response.data.success.user
          );
          // this.$emit('evOwner', this.owner)

          if (response.data.success.alternativeUsers) {
            this.alternativeUsers = response.data.success.alternativeUsers;
            this.alternativeUsers.forEach((user) => {
              if (this.$boo.userIsAdmin(user))
                user.label = 'Systemadministrator';
              if (this.$boo.userAmI(user.id)) user.label = 'an dich';
            });
          }
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel: function () {
      this.showDialog = false;
      this.$refs.form.reset();
      this.newOwner = null;
    },
    submit: function () {
      if (!this.$refs.form.validate()) return;
      this.storing = true;
      window.axios
        .patch(
          this.$boo.lboUrl() +
            this.pluralize(this.pojotype) +
            '/' +
            (this.pojo.slug || this.pojo.id) +
            '/setOwner',
          { user_id: this.newOwner }
        )
        .then((response) => {
          this.axiosSuccess = response;
          this.$emit('evOwner', response.data.success);
          this.showDialog = false;
        })
        .catch((error) => {
          this.axiosError = error;
        })
        .finally(() => {
          this.storing = false;
        });
    },
    pluralize: function (pojotype) {
      return pojotype + 's';
    },
  },
};
</script>
