import { Tag } from '@/components/tags/tagTypes';
import { BitrixUser } from './bitrix';

export type ID = number | string;

export type DomainType = {
  id: ID;
  avatar: AvatarType;
  description: string;
  initial: string;
  name: string;
  notice: string;
  slug: string;
  type: number;
};

export type AvatarType = {
  original: string;
  thumbnail: string;
};

export type PermissionsType = {
  abilities: AbilitiesType;
  roles: RolesType;
};

export type SettingsType = {
  client_bit: string;
  domain: string;
};

export type AttributesType = {
  canSeeDomains: boolean;
  [key: string]: any;
};

export type AbilitiesType = {
  [key: string]: Array<string>;
};

export type RolesType = {
  [key: string]: Array<string>;
};

export type WidgetType = {
  name: string;
  width: number;
};

export type TimeType = {
  id: ID;
  ptm: PTMType;
  start: Date;
  stop: Date;
  pause: number;
  activities: Array<any>;
  notice: string;
  duration: number;
  status_id: number;
  status_permission_level: Array<any>;
  timebilling_id: number;
  comment_count: number;
};

export type TimesOptionsType = {
  truncated?: boolean;
  maximum_shown_times?: number;
  all_times_count?: number;
};

export type TimeBillingOptionsType = {
  truncated?: boolean;
};

export type PTMType = {
  id: ID;
  start: Date | null;
  end: Date | null;
  memberlabel: string;
  person: PersonType;
  project: ProjectType;
  role: PTMRoleType;
  active: number; // eigentlich boolean, aber wird als 1 / 0 verwendet
  timerecmodel_id: ID;
};

export type PersonType = {
  id: ID;
  avatar: AvatarType | null;
  birthday: Date | null;
  broadcast_channel: string;
  customer: CustomerType | null;
  employee: boolean;
  initial: string;
  label: string;
  latest_access: Date;
  nickname: string;
  projectsCount: 0;
  sex: SexType;
  slug: string;
  user: UserType;
  project_memberships: ProjectMemberShip[];
  phones: PhoneType[];
  emailcontacts: EmailType[];
  external_datasources: ExternalDataSource[];
  tags: Tag[];
};

export const ExternalDatasourceType = {
  UNDEFINED: 0,
  BITRIX_USER: 1,
  BITRIX_CRM_CONTACT: 2,
} as const;

type ExternalDatasourceTypeId =
  (typeof ExternalDatasourceType)[keyof typeof ExternalDatasourceType];

export type ExternalDataSource = {
  id: number;
  name: string;
  url: string;
  datasource_type_id: ExternalDatasourceTypeId;
  data: BitrixUser;
};

export enum SexType {
  m = 'm',
  w = 'w',
  d = 'd',
}

export type UserType = {
  id: ID;
  slug: string;
  username: string;
  active: boolean;
  adress: string; //TODO: Das ist wohl ein tippfehler?!
  person: PersonType;
  settings: {
    id: ID;
    name: string;
    value: unknown;
  }[];
};

export type ProjectType = {
  id: ID;
  slug: string;
  avatar: AvatarType | null;
  commentable_type: 'project';
  comments: Array<CommentType>;
  customer: CustomerType | null;
  customer_id: ID;
  image_path: string | null;
  initial: string;
  label: string;
  name: string;
  owner: OwnerType;
  project_memberships: Array<ProjectMemberShip>;
  status_id: number;
  created_at: Date;
  isProjectLead: boolean;
  description: string;
  tags: Tag[];
};

export type ProjectMemberShip = {
  id: ID;
  active: number;
  auditor: PersonType;
  start: Date | null;
  end: Date | null;
  memberlabel: string;
  person: PersonType;
  person_id: ID;
  project: ProjectType;
  recordingTimeentries: Array<TimeType>;
  role: PTMRoleType;
  role_id: ID;
  timerecmodel: TimeRecModelType;
  timerecmodel_id: ID;
};

export type TimeRecModelType = {
  id: ID;
  name: string;
  activities: Array<string>;
  comment: string;
  comment_input: number; //TODO: was ist das?
  created_at: Date;
  description: string;
  duration_min: number;
  duration_max: number;
  interval: number;
  pause_input: number;
  pdfView: null; // TODO: Was könnt das noch sein?
  start_stop_buttons: number;
  status: string;
};

export type CustomerType = {
  id: ID;
  label: string;
};

export type PTMRoleType = {
  id: ID;
  name: string;
};

export type TimeBillingType = {
  id: ID;
  ptm: PTMType;
  startdate: Date;
  enddate: Date;
  entries: number;
  hours: number;
  comment_count: number;
  commentable_type: 'timebilling';
  comments: Array<CommentType>;
  status_id: number; // TODO: Sollte man extrahieren,  10, 20 30 glaub ich
  status_permission_level: Array<any>;
  times: TimeType[];
};

export type CommentType = {
  id: ID;
  commentable_type: 'comment';
  owner: OwnerType;
  status_id: number; // TODO: Sollte man extrahieren,  10, 20 30 glaub ich
  text: string;
  created_at: Date;
  updated_at: Date;
  visibility: number;
};

export type OwnerType = {
  id: ID;
  label: string;
  personSlug: string;
  slug: string;
  username: string;
};

export type NotificationType = {
  id: string; //TODO: WHY?...
  broadcast_channel: 'notifications';
  date: Date;
  icon: string;
  read: boolean;
  title: string;
  text: string;
  type: string;
};

export type FilterType = {
  // TODO
};

export type ProjectStatusType = {
  id: ID;
  name:
    | 'Entwurf'
    | 'Planung'
    | 'Aktiv'
    | 'Pausiert'
    | 'Abgebrochen'
    | 'Abgeschlossen';
};

// export type LoadProjectResponseType = {
//   data: {
//     loglevel: string;
//     message: string;
//     options: {
//       roles: PTMRoleType[];
//       status: ProjectStatusType[];
//       timerecmodels: Pick<TimeRecModelType, 'id' | 'name' | 'description'>[];
//     };
//     success: ProjectType;
//   };
// };

// export type LoadPersonsResponseType = {
//   data: {
//     message: string;
//     success: PersonType[];
//   };
// };

// export type LoadTimeBillingsResponseType = {
//   data: {
//     message: string;
//     success: TimeBillingType[];
//   };
// };

export type DataTableHeaderType = {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  hidden?: boolean | string[];
  class?: string;
  cellClass?: string;
  width?: number | string;
  visible?: boolean;
};

export type ListDataReturnType = {
  original: TimeBillingType[] | TimeType[];
  filtered: TimeBillingType[] | TimeType[];
  filter: any;
};

export type PhoneType = {
  id: ID;
  deactivated: boolean;
  features: string;
  icon: string;
  hint: string;
  label: string;
  notice: string;
  text: string;
  value: string;
  created_at: Date;
  updated_at: Date;
};

export type EmailType = {
  id: ID;
  deactivated: boolean;
  hint: string;
  label: string;
  notice: string;
  text: string;
  created_at: Date;
  updated_at: Date;
};
