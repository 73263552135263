<template>
  <div>
    <v-tooltip left :disabled="!tooltipText || noTooltip" open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-hover
          v-slot:default="{ hover }"
          :disabled="!canEdit"
          :value="showmenu"
        >
          <v-avatar
            class="profile"
            :color="color"
            :size="avatarSize"
            :left="left"
            :tile="sphere"
          >
            <v-img
              v-if="hasImage && !hover"
              :src="$BC.LBO_URL + imageURL"
              :lazy-src="$BC.LBO_URL + pojo.avatar.thumbnail"
              v-bind="attrs"
              v-on="on"
            />

            <div
              v-else-if="!hasImage && !hover"
              :class="`white--text ${fontSize}`"
              v-bind="attrs"
              v-on="on"
            >
              {{ pojo ? pojo.initial : '' }}
            </div>

            <div v-else-if="hover">
              <v-btn text @click="openUploadDialog">
                <v-icon class="white--text" large> mdi-upload </v-icon>
              </v-btn>
              <v-btn v-if="pojo.avatar" text @click="deleteAvatar">
                <v-icon class="white--text" large> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
          </v-avatar>
        </v-hover>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'BcAvatar',

  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    pojo: {
      type: Object,
      default: () => {},
    },
    size: {
      type: Number,
      default: 60,
    },
    apiUrl: {
      type: String,
      default: '',
    },
    // collapsable: {
    //   type: Boolean,
    //   default: false
    // },
    left: {
      type: Boolean,
      default: false,
    },
    sphere: {
      type: Boolean,
      default: false,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      uploadDialog: false,
      showmenu: false,
      loading: false,
      windowTop: 0,
    };
  },

  computed: {
    avatarSize() {
      // if (this.collapsable && this.isMobile) {
      //   return ((this.size - this.windowTop) < 60) ? 60 : this.size - this.windowTop
      // }
      return this.size;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm'
      );
    },
    fontSize() {
      // @@@ ToDo: auf > oder < umstellen, sodass auch Zwischengrößen möglich sind!
      switch (this.size) {
        case 10:
          return 'caption';
        case 25:
        case 30:
          return 'caption';
        case 32:
        case 40:
        case 50:
          return 'headline';
        default:
          return 'display-2';
      }
    },
    hasImage() {
      return this.pojo && this.pojo.avatar;
    },
    color() {
      return this.pojo
        ? this.$boo.genderdata(this.pojo.sex).color
        : 'blue-grey lighten-4';
    },
    imageURL() {
      if (!this.pojo.avatar) return '';
      return this.$BC.LBO_URL + (this.size < 60)
        ? this.pojo.avatar.thumbnail
        : this.pojo.avatar.original;
    },
    tooltipText() {
      // return this.pojo?.label // Optional Chaining requires #391
      return this.pojo ? this.pojo.label : null;
    },
  },

  mounted() {
    var that = this;
    window.addEventListener('scroll', function () {
      that.windowTop = window.scrollY;
    });
    this.$emitter.on('uploaded', () => this.$emitter.emit('avatar.changed'));
  },

  methods: {
    updateImage(pojo) {
      this.pojo.avatar = pojo.avatar;
    },
    deleteAvatar() {
      window.axios
        .delete(this.$boo.lboUrl() + this.apiUrl + '/avatar')
        .then((response) => {
          this.$store.commit('app/SETSNACKBAR', {
            text: response.data.message,
          });
          this.pojo.avatar = null;
        })
        .catch((error) => {
          this.axiosError = error;
        });
    },
    openUploadDialog() {
      this.$store.commit('contentDrawer/LOAD', {
        component: 'bc-upload-dialog',
        data: {
          apiUrl: this.apiUrl,
          image: true,
        },
      });
    },
  },
};
</script>
